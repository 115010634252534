import React, {useCallback, useMemo} from "../../_snowpack/pkg/react.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import Button from "../../components/Button.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import IconLocation from "../../images/icon-location.js";
import IconSettings from "../../images/icon-settings.js";
import IconAlert from "../../images/icon-alert.js";
import ActionsModal from "../modals/ActionsModal.js";
import {multiboxEdit, multiboxUsers} from "../urls.js";
import "./MultiboxHeader.css";
const actionButtons = ({multiboxId, navigation, backLink, t}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
  modal: true,
  onClick: () => navigation.navigate(multiboxEdit(multiboxId))
}, t("multibox:fillAddress")), /* @__PURE__ */ React.createElement(Button, {
  modal: true,
  onClick: () => navigation.navigate(multiboxUsers(multiboxId))
}, t("permissions:label")), /* @__PURE__ */ React.createElement(Button, {
  modal: true,
  link: "tel:+420739304189"
}, t("lost")), backLink ? /* @__PURE__ */ React.createElement(Button, {
  modal: true,
  onClick: () => {
    navigation.navigate(backLink);
  }
}, t("leave")) : /* @__PURE__ */ React.createElement(Button, {
  modal: true,
  onClick: () => {
    if (window.confirm(t("auth:reallyLogout"))) {
      navigation.navigate("/logout");
    }
  }
}, t("logout")));
const MultiboxHeader = ({multiboxId, address, backLink}) => {
  const {openModal, closeModal, modal} = useMobileModal();
  const {t} = useTranslation();
  const navigation = useNavigation();
  const actionButtonsMemo = useMemo(() => actionButtons({navigation, multiboxId, closeModal, backLink, t}), [multiboxId, navigation, closeModal, backLink, t]);
  const handleOpenSettings = useCallback(() => openModal(/* @__PURE__ */ React.createElement(ActionsModal, {
    title: t("settings:label"),
    subtitle: `Multibox ${multiboxId}`,
    actionButtons: actionButtonsMemo
  })), [multiboxId, openModal, actionButtonsMemo, t]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxHeader"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxHeader-name"
  }, t("multibox:label"), " ", multiboxId), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxHeader-address"
  }, address ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconLocation, {
    className: "MultiboxHeader-address-icon"
  }), address) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconAlert, {
    width: 16,
    className: "color-white mr4"
  }), t("multibox:fillAddress")))), /* @__PURE__ */ React.createElement(IconSettings, {
    className: "MultiboxHeader-settings-icon",
    onClick: handleOpenSettings
  }), modal);
};
export default MultiboxHeader;
