import React, {cloneElement} from "../_snowpack/pkg/react.js";
import "./FormItem.css";
import cs from "../_snowpack/pkg/classnames.js";
export default function FormItem({
  label = "",
  name = "default",
  className,
  children: content,
  ...rest
}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("form-item", className)
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: name
  }, label), content && cloneElement(content, {...rest}));
}
