import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import "./LoginScreen.css";
import useAlert from "../../hooks/useAlert.js";
import {auth, functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import {
  useDeviceLanguage,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from "../../_snowpack/pkg/firebase/auth.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderLogo from "../../components/HeaderLogo.js";
import {Link, useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Card from "../../components/Card.js";
import FormTextInput from "../../components/FormTextInput.js";
import Button from "../../components/Button.js";
import {isPhoneValid} from "../../library/validation.js";
import {Capacitor} from "../../_snowpack/pkg/@capacitor/core.js";
import {removeSpaces} from "../../library/utils/functions.js";
import {Trans, useTranslation} from "../../_snowpack/pkg/react-i18next.js";
export default function AuthLoginScreen({
  route: {
    url: {query: {redirectTo} = {}}
  }
}) {
  const navigation = useNavigation();
  const [phoneNumber, setPhoneNumber] = useState("+420");
  const [submitting, setSubmitting] = useState(false);
  const alert = useAlert();
  const {t} = useTranslation();
  useEffect(() => {
    useDeviceLanguage(auth());
    if (!Capacitor.isNativePlatform()) {
      window.recaptchaVerifier = new RecaptchaVerifier("captcha-button", {size: "invisible"}, auth());
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submitting)
      return;
    setSubmitting(true);
    const formattedPhoneNumber = removeSpaces(phoneNumber);
    const userExists = await httpsCallable(functions(), "auth-userWithPhoneNumberExists")({
      phoneNumber
    }).then(({data: {exists}}) => exists);
    if (!userExists) {
      alert.error(t("auth:noUserWithThisPhoneRegister"));
      setSubmitting(false);
      return;
    }
    let verificationId;
    try {
      if (Capacitor.isNativePlatform()) {
        verificationId = await window.cordova.plugins.firebase.auth.verifyPhoneNumber(formattedPhoneNumber, 3e4);
        return;
      }
      const result = await signInWithPhoneNumber(auth(), formattedPhoneNumber, window.recaptchaVerifier);
      verificationId = result.verificationId;
    } catch (err) {
      console.error(err);
      alert.error(t("error:loginTryAgain"), err);
    } finally {
      setSubmitting(false);
      if (verificationId) {
        navigation.navigate(`verify`, {
          state: {verificationId, redirectTo}
        });
      }
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-login"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("other:welcomeByPostCube")), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "auth:haveNoAccountRegister",
    t
  }, "Nemám účet,", /* @__PURE__ */ React.createElement(Link, {
    className: "signup-link",
    href: "/register"
  }, "zaregistrovat se"), ".")), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt32"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "pt8 pb32"
  }, t("auth:loginToApp")), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "phoneNumber",
    label: "Telefon",
    type: "tel",
    required: true,
    value: phoneNumber,
    onChange: (e) => setPhoneNumber(e.target.value)
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: !isPhoneValid(phoneNumber) || submitting,
    id: "captcha-button",
    name: "login"
  }, t("login"))))));
}
