import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import Button from "../components/Button.js";
import IconAddress from "../images/icon-address.js";
import IconBox from "../images/icon-box.js";
import "./ModalCreateDelivery.css";
export default function ModalCreateDelivery({
  onBoxSelected,
  onAddressSelected
}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "modal-create-delivery__container"
  }, /* @__PURE__ */ React.createElement("h1", null, t("delivery:new")), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey mb8 mt8"
  }, t("delivery:whereToSend")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    className: "mt16 modal-create-delivery__button",
    name: "createDeliveryBox",
    onClick: onBoxSelected(`/send/1`)
  }, /* @__PURE__ */ React.createElement(IconBox, {
    height: "32px",
    fill: "var(--color-primary)",
    color: "var(--color-primary)",
    className: "mb8"
  }), t("delivery:toBox")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    className: "modal-create-delivery__button",
    onClick: onAddressSelected(`/send/1/address`)
  }, /* @__PURE__ */ React.createElement(IconAddress, {
    height: "32px",
    fill: "var(--color-primary)",
    className: "mb8"
  }), t("delivery:toAddress")));
}
