import React, {useState} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import Button from "../components/Button.js";
import {CenteredLoader} from "../components/Loader.js";
import "./ApproveUnlockRequestModal.css";
export default function ApproveUnlockRequestModal({
  unlockRequest,
  alert,
  onClose
}) {
  const {t} = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  const handleApprove = async (deny = false) => {
    setIsUpdating(true);
    try {
      await httpsCallable(functions(), "box-approveRequestUnlock")({
        unlockRequestId: unlockRequest.id,
        deny
      });
      if (typeof alert?.success === "function") {
        alert.success(deny ? t("box:openingRequest.refused") : t("box:openingRequest.approved"));
      }
    } catch (err) {
      if (typeof alert?.error === "function") {
        alert.error(deny ? t("error:refuseUnlockRequest") + `: ${err}` : t("error:approveUnlockRequest") + `: ${err}`);
      }
    } finally {
      handleClose();
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "approve-unlock-request-modal__container p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("box:openingRequest.labelShort")), isUpdating && /* @__PURE__ */ React.createElement(CenteredLoader, {
    className: "screen-delivery__loader",
    text: "Odesílám"
  }), !isUpdating && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, t("box:openingRequest.messageFromRequester")), /* @__PURE__ */ React.createElement("em", null, unlockRequest?.message)), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    className: "mt16",
    onClick: () => handleApprove()
  }, t("box:openingRequest.approve")), /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    className: "mt16",
    onClick: () => handleApprove(true)
  }, t("box:openingRequest.refuse"))));
}
