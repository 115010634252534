import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import Loader from "../../components/Loader.js";
import IconSad from "../../images/icon-sad.js";
export default function NotFound({loading}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__unknown"
  }, loading ? /* @__PURE__ */ React.createElement(Loader, {
    className: "screen-delivery__loader",
    text: t("loadingData")
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconSad, {
    width: "128px",
    className: "mb32"
  }), /* @__PURE__ */ React.createElement("span", {
    className: "delivery-not-found"
  }, t("delivery:notFound")))));
}
