import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderLogo from "../components/HeaderLogo.js";
import Button from "../components/Button.js";
import "./ErrorScreen.css";
export default function ErrorScreen({title}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-error"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, {
    link: "/"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-error__content"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "screen-error__title"
  }, title), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16",
    link: "."
  }, t("goStepBack"))));
}
