import * as chacha20 from "./chacha20.js";

import elliptic from "../../_snowpack/pkg/elliptic.js";
const ec = new elliptic.ec("secp256k1");

export function removePrefixFromPublicKey(key) {
  if (key.length == 65 && key[0] == 0x04) {
    return key.slice(1);
  }

  return key;
}

export function generateKeyPair() {
  return ec.genKeyPair();
}

export async function hash(data) {
  const buffer = new Uint8Array(data);
  const result = await crypto.subtle.digest("SHA-256", buffer);
  return new Uint8Array(result);
}

function ulongToArray(value) {
  return [
    (value >> 24) & 0xff,
    (value >> 16) & 0xff,
    (value >> 8) & 0xff,
    value & 0xff,
  ];
}

export async function createCommand({
  accountOfflinePrivateKey,
  boxPublicKey,
  expireAt = new Date().getTime() + 60000,
  payload = [],
}) {
  const privatePair = ec.keyFromPrivate(accountOfflinePrivateKey);
  const publicPair = ec.keyFromPublic(boxPublicKey);
  const sharedKey = privatePair.derive(publicPair.getPublic());
  const encryptKey = await hash(sharedKey.toArray());
  const commandId = window.crypto.getRandomValues(new Uint8Array(4));

  const magic = new Uint8Array([0x0b, 0x0e, 0x0e, 0x0f]);

  const expireBytes = ulongToArray(expireAt);

  const data = new Uint8Array([
    ...commandId,
    ...magic,
    ...expireBytes,
    ...payload,
  ]);

  const nonce = new Uint8Array(12);
  const encrypted = chacha20.encrypt(encryptKey, nonce, data);

  return encrypted;
}
