import useMyBoxes from "./useMyBoxes.js";
import useCollection from "./useCollection.js";

export default function useAwaitingUnlockRequests(
  user,
  { onError = console.error } = {}
) {
  const { boxes } = useMyBoxes(user, { onError });

  const boxIds = (boxes || []).map((box) => box.id);

  const [unlockRequests = [], isLoading = false] = useCollection(
    `/unlock-requests`,
    {
      skip: !user || boxIds.length < 1,
      where: [
        ["boxId", "in", boxIds],
        ["state", "==", "AWAITING_APPROVAL"],
      ],
      onError,
    }
  );

  return {
    awaitingUnlockRequests: unlockRequests,
    awaitingUnlockRequestsLoading: isLoading,
  };
}
