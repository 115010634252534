import React from "../_snowpack/pkg/react.js";
import "./SandboxScreen.css";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderLogo from "../components/HeaderLogo.js";
export default function SandboxScreen() {
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-sandbox"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, {
    link: "/"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-sandbox-container"
  }, /* @__PURE__ */ React.createElement("h1", null, "Sandbox"), /* @__PURE__ */ React.createElement("div", {
    className: "screen-sandbox__card"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-sandbox-animation__card"
  }, /* @__PURE__ */ React.createElement("img", {
    width: "100%",
    src: "../images/deliveryBTTutorial2.gif"
  })), /* @__PURE__ */ React.createElement("p", null, "V dalším kroku označte schránku a klikněte na “Spárovat”"))));
}
