export default {
  goTo: "Přejít k platbě",
  pay: "Zaplatit",
  newCardPayment: "Platba novou kartou",
  paymentInfo: "Platební informace",
  selectSavedCard: "Vybrat uloženou kartu",

  chooseMethod: "Vyber platební metodu",
  inProgress: "Platba probíhá ...",
  money:
    "{{amount, currency(currency: CZK; minimumFractionDigits: 2; maximumFractionDigits: 2), stripCurrencyFraction}}",
  payAmount: 'Zaplatit $t(payment:money, {"amount": {{amount}} })',
  paidAmount: 'Zaplaceno $t(payment:money, {"amount": {{amount}} })',
  totalPrice: 'Celková cena: $t(payment:money, {"amount": {{total}} })',
  wasSuccessful: "Platba proběhla úspěšně",

  card: {
    holder: "Jméno na kartě",
    number: "Číslo karty",
    expiry: "Platnost karty",
    cvc: "CVC kód",
  },
};
