import { useCallback, useEffect } from "../_snowpack/pkg/react.js";
import BleBox, {
  SUCCESSFUL_BOX_RESPONSE,
  boxResponseToMessage,
} from "../library/BleBox.js";
import useDoc from "./useDoc.js";
import { updateDoc } from "../_snowpack/pkg/firebase/firestore.js";
import { useTranslation } from "../_snowpack/pkg/react-i18next.js";

export default function useMessengerDelivery(
  deliveryId,
  { onError = console.error, callback = (data) => data } = {}
) {
  const { t } = useTranslation();
  const [data, loading, error, docRef] = useDoc(
    `/messenger-deliveries/${deliveryId}`,
    {
      skip: !deliveryId,
      onError,
    }
  );

  useEffect(() => {
    callback(data || undefined);
  }, [data]); // eslint-disable-line

  const updateDeliveryState = useCallback(
    async (state) => await updateDoc(docRef, { state }),
    [docRef]
  );

  const unlockBox = useCallback(
    async (bleDevice, command) => {
      const bleBox = BleBox(bleDevice);
      await bleBox.transaction(async () => {
        const response = await bleBox.unlockWithOnlineKey(command);
        if (response !== SUCCESSFUL_BOX_RESPONSE) {
          throw new Error(
            `${t("error:unlock")}: ${boxResponseToMessage(response)}`
          );
        }
      });
    },
    [t]
  );

  return {
    delivery: data || undefined,
    deliveryLoading: loading,
    deliveryError: error,
    updateDeliveryState,
    unlockBox,
  };
}
