import {t} from "../_snowpack/pkg/i18next.js";
import React, {Fragment} from "../_snowpack/pkg/react.js";
import ListSelect from "./ListSelect.js";
export const listSelectRender = (item) => /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("img", {
  src: "/images/image-package.png",
  alt: "virtual",
  width: "32px",
  className: "mr16 ml16"
}), /* @__PURE__ */ React.createElement("div", null, item.name, item.description && /* @__PURE__ */ React.createElement("div", {
  className: "color-grey"
}, item.description)), /* @__PURE__ */ React.createElement("p", {
  className: "list-select__right mr16"
}, t("payment:money", {amount: item.price})));
export const renderNoOptions = () => /* @__PURE__ */ React.createElement("span", null, t("box:delivery.notPossible"));
export default function ListSelectTransportProviders({
  selected,
  onChange,
  transportProviders
}) {
  return /* @__PURE__ */ React.createElement(ListSelect, {
    data: transportProviders,
    loaded: Boolean(transportProviders),
    render: listSelectRender,
    renderEmptyState: renderNoOptions,
    selected,
    onChange
  });
}
