import React, {Children, cloneElement, memo, useState} from "../../_snowpack/pkg/react.js";
import cs from "../../_snowpack/pkg/classnames.js";
import "./Tabs.css";
export const Tab = ({children, active}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("Tab", {
      "Tab--active": active
    })
  }, children);
};
const TabMenuItem = ({label, active, onClick}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("TabMenuItem", {
      "TabMenuItem--active": active
    }),
    onClick
  }, label);
};
const Tabs = ({children, className}) => {
  const [activeTab, setActiveTab] = useState(0);
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("Tabs", className)
  }, /* @__PURE__ */ React.createElement("div", {
    className: "TabMenu"
  }, Children.map(children, ({props}, index) => /* @__PURE__ */ React.createElement(TabMenuItem, {
    label: props.label,
    active: activeTab === index,
    onClick: () => setActiveTab(index)
  }))), Children.map(children, (tabElement, index) => cloneElement(tabElement, {active: activeTab === index})));
};
export default memo(Tabs);
