import React, {useCallback} from "../../_snowpack/pkg/react.js";
import cs from "../../_snowpack/pkg/classnames.js";
import "./TabbarWombat.css";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
export default function TabbarWombat({
  boxes,
  deliveries,
  users,
  logs,
  more,
  disableActiveTab
}) {
  const navigation = useNavigation();
  const navigateOnClick = useCallback((tab, path) => {
    if (tab && disableActiveTab) {
      return;
    }
    navigation.navigate(path);
  }, [navigation, disableActiveTab]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "tabbar-wombat"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-wombat__menu-item",
    onClick: () => navigateOnClick(deliveries, "/wombat/deliveries")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-wombat__menu-text", deliveries && "tabbar-wombat__menu-text--focus")
  }, "Home")), /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-wombat__menu-item",
    onClick: () => navigateOnClick(boxes, "/wombat/boxes")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-wombat__menu-text", boxes && "tabbar-wombat__menu-text--focus")
  }, "Boxes")), /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-wombat__menu-item",
    onClick: () => navigateOnClick(users, "/wombat/users")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-wombat__menu-text", users && "tabbar-wombat__menu-text--focus")
  }, "Users")), /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-wombat__menu-item",
    onClick: () => navigateOnClick(users, "/wombat/logs")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-wombat__menu-text", logs && "tabbar-wombat__menu-text--focus")
  }, "Logs")), /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-wombat__menu-item",
    onClick: () => navigateOnClick(users, "/wombat/more")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-wombat__menu-text", more && "tabbar-wombat__menu-text--focus")
  }, "⋯")));
}
