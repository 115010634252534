import useBox from "../../hooks/useBox.js";
import useCollection from "../../hooks/useCollection.js";

export default function useMultibox(
  multiboxId,
  { onError = console.error } = {}
) {
  const {
    box: multibox,
    isLoadingBox,
    errorBox,
  } = useBox(multiboxId, { onError });

  const [partitions = [], isLoadingPartitions, errorPartitions] = useCollection(
    "boxes",
    {
      skip: !multiboxId,
      where: [
        ["parentId", "==", multiboxId],
        ["isPartition", "==", true],
      ],
      onError,
    }
  );

  return {
    multibox,
    partitions,
    isLoadingBox,
    isLoadingPartitions,
    errorBox,
    errorPartitions,
  };
}
