import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {analytics} from "../firebase.js";
import {logEvent} from "../_snowpack/pkg/firebase/analytics.js";
import "./ScreenHeader.css";
const HelpLink = () => {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("a", {
    href: "tel:+420739304189",
    onClick: () => {
      logEvent(analytics, "calling_help_attempt");
    }
  }, t("help"));
};
export default function ScreenHeader({children, title}) {
  const [left, right] = Array.isArray(children) ? children : [children];
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-header__left"
  }, left), /* @__PURE__ */ React.createElement("div", {
    className: "screen-header__middle"
  }, title && /* @__PURE__ */ React.createElement("div", {
    className: "screen-header__title"
  }, title)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-header__right"
  }, right || /* @__PURE__ */ React.createElement(HelpLink, null)));
}
