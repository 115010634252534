import React from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import "./Button.css";
export default function Button({
  secondary,
  className,
  loader,
  large,
  modal,
  children: content,
  type,
  link,
  ...rest
}) {
  const ButtonElement = !link ? "button" : "a";
  return /* @__PURE__ */ React.createElement(ButtonElement, {
    className: cs("button-component", loader && "button-component--loader", modal && "button-component--modal", secondary && "button-component--secondary", type && `button-component--${type}`, large && "button-component--large", link && "button-component--link", className),
    ...rest,
    type,
    href: link
  }, content);
}
