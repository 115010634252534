import {firestore} from "../firebase.js";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot
} from "../_snowpack/pkg/firebase/firestore.js";
import {useEffect, useState} from "../_snowpack/pkg/react.js";
const getDocumentData = (doc) => doc.exists() ? {id: doc.id, ...doc.data()} : null;
const getCollectionData = (collection2) => collection2.docs.map(getDocumentData);
const normalizeWhereTerms = (terms) => {
  if (terms && !Array.isArray(terms?.[0])) {
    return [terms];
  }
  return terms;
};
const normalizeOrderByTerms = (terms) => {
  if (typeof terms === "string") {
    return [terms, "asc"];
  }
  if (terms && !Array.isArray(terms?.[0])) {
    return [terms];
  }
  return terms;
};
export default function useCollection(path, {
  skip = false,
  where: whereTerms,
  limit: limitTerm,
  orderBy: orderByTerms,
  onError = console.error
} = {}) {
  const [[data, loading, error, ref], setState] = useState([
    void 0,
    true,
    null,
    null
  ]);
  useEffect(() => {
    if (skip) {
      setState([void 0, false, null, null]);
      return;
    }
    const collectionRef = collection(firestore(), path);
    const constraints = [];
    if (whereTerms) {
      constraints.push(...normalizeWhereTerms(whereTerms).map(([col, op, val]) => where(col, op, val)));
    }
    if (orderByTerms) {
      constraints.push(...normalizeOrderByTerms(orderByTerms).map(([col, dir]) => orderBy(col, dir)));
    }
    if (typeof limit === "number") {
      constraints.push(limit(limitTerm));
    }
    const ref2 = query(collectionRef, ...constraints);
    return onSnapshot(ref2, (response) => {
      const payload = getCollectionData(response);
      setState([payload, false, null, ref2]);
    }, (error2) => {
      onError(error2);
      setState([void 0, false, error2, ref2]);
    });
  }, [
    path,
    skip,
    JSON.stringify(orderByTerms),
    JSON.stringify(whereTerms),
    limitTerm,
    JSON.stringify(onError)
  ]);
  return [data, loading, error, ref];
}
