export function updateState(setState) {
  return (e) => {
    const target = e.target;
    setState((x) => ({ ...x, [target.name]: target.value }));
  };
}

export const generateDeliveryCode = () => {
  const charArray = [
    "3",
    "4",
    "7",
    "9",
    "A",
    "C",
    "E",
    "F",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
  ];
  let code = "";
  let x;
  for (x = 0; x < 7; x++) {
    code +=
      x === 3 ? "-" : charArray[Math.floor(Math.random() * charArray.length)];
  }
  return code;
};

// // Lines bellow will test how many iterations it gives to generate duplit code.
// const codes = [];
// // eslint-disable-next-line no-constant-condition
// while (true) {
//   const code = generateDeliveryCode();
//   if (codes.includes(code)) {
//     console.log(codes.length);
//     break;
//   } else codes.push(code);
// }
// // Results are about 25k-60k iterations to generate first duplicity
