import { useState, useEffect } from "../_snowpack/pkg/react.js";
import { functions } from "../firebase.js";
import { httpsCallable } from "../_snowpack/pkg/firebase/functions.js";
// import { getBoolean } from "firebase/remote-config";

export default function useTransportProviders(
  deliveryId,
  { onError = console.error } = {}
) {
  const [providers, setProviders] = useState();
  const [state, setState] = useState(null);

  // const hasHolubiPostaFeature = useMemo(
  //   () => getBoolean(remoteConfig, "holubi_posta") || isNative,
  //   []
  // );
  // Feature flag for everyone (need to add ios remoteConfig support)
  //  but once a virtual box is invilved, holubi_posta will appear anyway
  const hasHolubiPostaFeature = false;

  useEffect(() => {
    if (["loading", "loaded", "error"].includes(state)) return;

    setState("loading");
    httpsCallable(
      functions(),
      "transportProvider-list"
    )({
      deliveryId,
      includeVirtualProvider: hasHolubiPostaFeature,
    })
      .then(({ data }) => {
        setState("loaded");
        setProviders(data);
      })
      .catch((err) => {
        setState("error");
        const retry = () => setState(null);
        onError(err, retry);
      });
  }, [deliveryId, hasHolubiPostaFeature, onError, state]);

  return providers;
}
