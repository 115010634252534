import React, {Component} from "../_snowpack/pkg/react.js";
import ErrorScreen from "../screens/ErrorScreen.js";
import {withTranslation} from "../_snowpack/pkg/react-i18next.js";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }
  static getDerivedStateFromError() {
    return {hasError: true};
  }
  componentDidCatch(error, errorInfo) {
    this.props.logger.error({error, errorInfo});
  }
  render() {
    if (this.state.hasError) {
      return /* @__PURE__ */ React.createElement(ErrorScreen, {
        title: this.props.t("error:somethingWentWrong")
      });
    }
    return this.props.children;
  }
}
export default withTranslation()(ErrorBoundary);
