import React, {useState} from "../_snowpack/pkg/react.js";
import "./ModalGenerateKey.css";
import Button from "../components/Button.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import Loader from "../components/Loader.js";
import {useTranslation, Trans} from "../_snowpack/pkg/react-i18next.js";
async function createOpenLink(boxId) {
  const result = await httpsCallable(functions(), "box-createOpenLink")({
    boxId
  });
  if (!result.data) {
    throw new Error("Failed to create open link: No Data");
  }
  return result.data;
}
export default function ModalGenerateKey({
  boxId,
  onDone = () => {
  },
  onError = () => {
  }
}) {
  const [generating, setGenerating] = useState(false);
  const {t} = useTranslation();
  const onGenerateKey = () => {
    setGenerating(true);
    createOpenLink(boxId).then((res) => {
      setGenerating(false);
      onDone(res);
    }).catch((e) => {
      setGenerating(false);
      onError(e);
    });
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "modal-generate-key__container"
  }, /* @__PURE__ */ React.createElement("h1", null, t("box:key.generateNew")), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey mb8 mt8"
  }, boxId), /* @__PURE__ */ React.createElement("p", {
    className: "mt16 mb16"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "box:key.generateInfo",
    t
  }, "Vygenerovaný klíč může použít kdokoliv, komu pošlete odkaz k otevření schránky. ", /* @__PURE__ */ React.createElement("b", null, "Klíč je jednorázový a platí 24 hodin."))), /* @__PURE__ */ React.createElement(Button, {
    loader: true,
    className: "mt16 modal-generate-key__button",
    onClick: onGenerateKey
  }, generating ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("box:key.generating"),
    light: true
  }) : t("box:key.generate")));
}
