import React, {useState, useCallback} from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import Button from "../components/Button.js";
import ListSelect from "../components/ListSelect.js";
import IconMastercard from "../images/logo-mastercard.js";
import IconVisa from "../images/logo-visa.js";
import IconApplePay from "../images/logo-apple-pay.js";
import Loader from "../components/Loader.js";
import "./CardModalContent.css";
import {useStripe} from "../_snowpack/pkg/@stripe/react-stripe-js.js";
import {Stripe as NativeStripe} from "../_snowpack/pkg/@capacitor-community/stripe.js";
export const APPLE_PAY_METHOD = {id: "apple_pay"};
function paymentMethodsListSelectRender(item, active) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-cardWrapper"
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("CardModalContent-card", {
      "CardModalContent-card--active": active
    })
  }, item.card?.brand === "mastercard" && /* @__PURE__ */ React.createElement(IconMastercard, {
    width: "32px"
  }), item.card?.brand === "visa" && /* @__PURE__ */ React.createElement(IconVisa, {
    width: "32px"
  }), item.id === APPLE_PAY_METHOD.id && /* @__PURE__ */ React.createElement(IconApplePay, {
    width: "64px"
  })), item.id === APPLE_PAY_METHOD.id && /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-card-number"
  }, "Apple Pay"), item.card?.last4 && /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-card-number"
  }, "••••", item.card.last4));
}
export default function PaymentUseCardModal({
  clientSecret,
  paymentMethods = [],
  onAddCard,
  order = {price: 0, label: "", currency: "CZK"},
  onError,
  onSuccess
}) {
  const [paying, setPaying] = useState(false);
  const [formValues, setFormValues] = useState({
    paymentMethod: paymentMethods[0]
  });
  const stripe = useStripe();
  const handlePaySubmit = useCallback(async (event) => {
    event.preventDefault();
    setPaying(true);
    const {paymentMethod} = formValues;
    const paymentSummaryItem = {label: order.label, amount: order.price};
    try {
      if (paymentMethod.type === APPLE_PAY_METHOD.type) {
        await NativeStripe.createApplePay({
          paymentIntentClientSecret: clientSecret,
          paymentSummaryItems: [paymentSummaryItem],
          merchantIdentifier: "merchant.com.topmonks.chytrybox",
          countryCode: "CZ",
          currency: order.currency
        });
        await NativeStripe.presentApplePay();
      } else {
        const {error, paymentIntent: {status, error: intentError} = {}} = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id
        });
        if (status === "succeeded") {
          onSuccess();
        } else {
          onError(intentError || error);
          setPaying(false);
        }
      }
    } catch (error) {
      console.warn('TODO, některé errory si zaslouží víc než "Jejda"..');
      onError(error);
      setPaying(false);
    }
  }, [
    clientSecret,
    formValues,
    onError,
    onSuccess,
    order.currency,
    order.label,
    order.price,
    stripe
  ]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-header"
  }, /* @__PURE__ */ React.createElement("h1", null, "Vyber platební metodu")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("form", {
    onSubmit: handlePaySubmit
  }, /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-cardList"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-cardWrapper mr12",
    onClick: onAddCard
  }, /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-card CardModalContent-card--default"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-card__plus"
  }, "+")), /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-card-number"
  }, "+ Přidat")), /* @__PURE__ */ React.createElement(ListSelect, {
    data: paymentMethods,
    render: paymentMethodsListSelectRender,
    selected: formValues.paymentMethod,
    onChange: (paymentMethod) => {
      setFormValues({paymentMethod: {id: paymentMethod.id}});
    },
    horizontal: true
  })), /* @__PURE__ */ React.createElement(Button, {
    className: "mt8 mb16",
    disabled: !formValues.paymentMethod.id || paying,
    type: "submit"
  }, paying && /* @__PURE__ */ React.createElement(Loader, {
    text: "Platba probíhá ..."
  }) || /* @__PURE__ */ React.createElement("p", null, "Zaplatit ", order.price, " Kč")))));
}
