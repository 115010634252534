import common from "./common.js";
import delivery from "./delivery.js";
import payment from "./payment.js";
import box from "./box.js";

export default {
  delivery,
  payment,
  box,
  common,

  multibox: {
    label: "Multibox",
    labelWithIdNumber: "Multibox no. {{multiboxId}}",
    fillAddress: "Fill in the Multibox address",
    editDetails: "Edit Multibox details",
    hasNoDeliveries: "This multibox has no orders yet...",
    editSuccess: "Multibox successfully edited",
  },

  organisation: {
    mineLabel: "My organization",
    myApps: "My Apps",
    loading: "Loading organization...",
    loadingApps: "Loading applications...",
    noApps: "You don't have an app yet",
    addApp: "Add app",
    newApp: "New app",
    appName: "App name",
    name: "Title / name",
    appMustHaveName: "The new app must have a name!",
    cbUrlRequired: "Callback URL is required for SPA type app",

    integrationInfo: `For more information on integration with Postcube, please see <1>https://sdk.postcube.cz</1> for the public documentation of the Postcube API`,
    rotateCredentials: `Rotate credentials`,
  },

  auth: {
    notLoggedIn: "You are not logged in.",
    loginToApp: "Log in to the application.",
    registerPostCube: "Register your PostCube",
    alreadyHaveAccountLogin: "I already have an account, <1>login</1>.",
    haveNoAccountRegister: "I don't have an account, <1>register</1>.",
    loginWithSMSCode: "Login with SMS code",
    verifyWithSMSCode: "Verify by SMS code",
    activateAccountWithSMSCode:
      "Activate your account with the code we sent you via SMS",
    confirmLoginWithSMSCode:
      "Confirm your login with the code sent you via SMS.",
    activateAccount: "Activate your account",
    confirmLogin: "Confirm login",
    verifyCode: "Activation code from SMS",
    sendCodeAgain: "Resend code",
    reallyLogout: "Are you sure you want to log out?",
    sectionAccessDenied: "This section is not for you!",
    noUserWithThisPhoneRegister:
      "The user with the given phone number does not exist. Please register first",
  },

  permissions: {
    label: "User permissions",
    noUserExistsWithThisPhone: "User with this number does not exist",
    insertUserPhone:
      "Enter the phone number of the user you want to assign permissions to",
    noUserFound: "No user found",
  },

  BT: {
    notAvailable: "Bluetooth not available",
    turnItOnPlease: "Please turn it on in the phone settings.",
    missingPermission: "Bluetooth permission is missing",
    allowItPlease: "Please enable it in Settings > PostCube > Bluetooth.",
    deviceIdNotFound: "Device with ID {{id}} not found.",
    noDeviceFound: "No device found.",
    turnItOnInDevice: "Turn on Bluetooth on your device",
    turnItOnBeforeOpeningBox:
      "You need to turn on Bluetooth on your device to open the PostCube box.",
    turnItOnAndClickBtn:
      "Turn on Bluetooth on your device and click the button.",
    chooseBoxInListAndPair:
      'Select <1>PostCube {{boxId}}</1> in the list and click "Pair"',
    boxWillNotifyAndOpen: "The box will beep and open",
  },

  pairing: {
    searchingAround: "Looking for PostCube boxes within range...",
    pairFirst:
      "Before a box can be used to deliver shipments, it must first be paired.",
    distanceNotice:
      "<0>It's important</0> to be at an unobstructed distance from the box during this process and to have <2>Bluetooth</2> enabled and turned on on your device.",

    step1: "1. Select a box to pair",
    step2: "2. Enter the code from the box manual",
    step2Label: "Enter the code from the box manual",
    overDevicesLimit:
      "Box is already paired with 4 devices. Select which one you want to replace",
  },

  settings: {
    label: "Settings",
    deviceName: "Device name",
    yourDeviceName: "Your device name",
    yourEmail: "Your e-mail",
    changeEmail: "Change e-mail",
    notSet: "Not set",
    serviceOpening: "Service opening",
    emailWrongFormat: "You entered your e-mail in the wrong format.",
    emailSaved: "E-mail saved successfully.",
  },

  error: {
    somethingWentWrong: "Something went wrong",
    oopsSomethingWentWrong: "Oops! Something went wrong.",
    unknown: "Unknown error",
    unlock: "Unlock error",
    saveKey: "Key save error",
    pair: "Pairing error",
    unknownBtError: "Unknown bluetooth error",
    carrier: "Carrier error",
    report: "Report problem",

    unlockBox: "Error while unlocking box",
    approveUnlockRequest: "Error while confirming open request",
    refuseUnlockRequest: "Error while rejecting open request",
    loadMultibox: "Error while loading Multibox",
    loadOrganisation: "Error while loading organisation",
    loadApp: "Error while loading application",
    saveEmail: "Error while saving the e-mail",
    loginTryAgain: "Login failed. Please try again.",
  },

  welcome: {
    1: {
      heading: "Your smart mailbox key",
      text: "Open your PostCube with your mobile phone by connecting via Bluetooth. The app will also tell you the battery status of the lock.",
    },
    2: {
      heading: "Send and receive shipments",
      text: "Prepare packages for shipment directly in the app. Keep track of your shipments as they arrive.",
    },
    3: {
      heading: "Opening code for each courier",
      text: "Is your favourite courier not fully connected to our system? Send the courier a link to open your PostCube.",
    },
  },

  help: {
    text: "Opening the PostCube mailbox step by step.",
    1: {
      heading: "1. Open the mailbox with the button",
      text: 'Start by pressing the "Open mailbox" button. A dialog box will open with the option to select a mailbox to open.',
    },
    2: {
      heading: "2. Turn on Bluetooth",
      text: "To open the box, you need to have Bluetooth enabled on your phone. In case it is not, click on the appropriate link in the dialog box.",
    },
    3: {
      heading: "3. Enable your browser location",
      text: "For the connection to the clipboard to work properly, you need to enable location services in your browser. If they are not enabled, click the appropriate link in the dialog box.",
      extra:
        "This setting will be saved for all future PostCube mailbox openings",
    },
    4: {
      heading: "4. Selecting the appropriate mailbox",
      text: 'Clicking on the mailbox with the corresponding box number and then clicking the "Pair" button will pair your mobile with the mailbox and open the door.',
    },
    5: {
      heading: "5. Back to your courier app",
      text: "After picking up your parcel, continue with your app as you are used to",
    },
  },

  install: {
    heading: "Download the app for your iPhone, iPad or Mac",
    download: "Download from AppStore",
    or: "or",
    goToWebApp: "Go to web app",
    withoutLockFuncs: "(without lock functions)",
  },

  other: {
    returnToMessengerAppAndContinue:
      "Return to your messenger app and continue as usual",
    callSupport: "Call PostCube support",
    welcomeByPostCube: "Welcome to PostCube!",
  },
};
