import {useEffect} from "../_snowpack/pkg/react.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import {Capacitor} from "../_snowpack/pkg/@capacitor/core.js";
import {App} from "../_snowpack/pkg/@capacitor/app.js";
const isNative = Capacitor.isNativePlatform();
const CapacitorApp = App;
export default function DeepLinkTrapper({rollbar}) {
  const navigation = useNavigation();
  useEffect(() => {
    if (!isNative)
      return;
    CapacitorApp.addListener("appUrlOpen", (data) => {
      rollbar.info("Deep link", data);
      try {
        const {pathname} = new URL(data.url);
        navigation.navigate(pathname, {
          replace: true,
          state: {deeplink: true}
        });
      } catch (err) {
        rollbar.error(err);
      }
    });
  }, [navigation, rollbar]);
  return null;
}
