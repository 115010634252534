import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import React, {useCallback, useEffect, useState} from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderLogo from "../components/HeaderLogo.js";
import Tabbar from "../components/Tabbar.js";
import Button from "../components/Button.js";
import Divider from "../components/Divider.js";
import IconHistory from "../images/icon-history.js";
import IconLogout from "../images/icon-logout.js";
import IconLogo from "../images/icon-logo.js";
import IconCzechFlag from "../images/icon-flag-czech.js";
import IconUkFlag from "../images/icon-flag-uk.js";
import IconSlovakiaFlag from "../images/icon-flag-slovakia.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import {getDeviceLabel, saveDeviceLabel} from "../hooks/useMyBoxes.js";
import {auth} from "../firebase.js";
import useAlert from "../hooks/useAlert.js";
import "./SettingsScreen.css";
import {onAuthStateChanged, updateEmail} from "../_snowpack/pkg/firebase/auth.js";
const version = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CIRCLE_SHA1 || "dev";
const SingleSetting = ({
  label,
  changeLabel,
  fieldName,
  fieldType = "text",
  defaultValue = "",
  handleSave
}) => {
  const {t} = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    handleSave(value, () => setIsEditing(false));
  }, [handleSave, value]);
  const handleStartEdit = useCallback(() => {
    setIsEditing(!isEditing);
    setValue(defaultValue);
  }, [defaultValue, isEditing]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "screen-settings__device-name mt16"
  }, /* @__PURE__ */ React.createElement("h3", null, label), /* @__PURE__ */ React.createElement("button", {
    onClick: handleStartEdit,
    className: cs({cancel: isEditing})
  }, isEditing ? t("cancel") : changeLabel)), /* @__PURE__ */ React.createElement("form", {
    className: "mt4",
    action: "#",
    onSubmit: handleSubmit
  }, isEditing ? /* @__PURE__ */ React.createElement("div", {
    className: "screen-settings__device-name-edit"
  }, /* @__PURE__ */ React.createElement("input", {
    type: fieldType,
    name: fieldName,
    onChange: (e) => setValue(e.target.value),
    placeholder: defaultValue,
    autoFocus: true,
    required: true
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "submit"
  }, t("change"))) : /* @__PURE__ */ React.createElement("p", null, value)));
};
const languages = {
  cs: {nativeName: "Česky", icon: /* @__PURE__ */ React.createElement(IconCzechFlag, null)},
  sk: {nativeName: "Slovensky", icon: /* @__PURE__ */ React.createElement(IconSlovakiaFlag, null)},
  en: {nativeName: "English", icon: /* @__PURE__ */ React.createElement(IconUkFlag, null)}
};
const LanguageSwitcher = () => {
  const {i18n} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-settings__language center-flex mt16"
  }, Object.keys(languages).map((lng) => /* @__PURE__ */ React.createElement(Button, {
    key: lng,
    secondary: i18n.resolvedLanguage !== lng,
    type: "submit",
    onClick: () => i18n.changeLanguage(lng),
    className: "center-flex"
  }, languages[lng].icon, languages[lng].nativeName)));
};
export default function SettingsScreen({
  route: {
    data: {userToken, user}
  }
}) {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const alert = useAlert();
  const [currentUser, setCurrentUser] = useState(void 0);
  useEffect(() => {
    return onAuthStateChanged(auth(), setCurrentUser);
  }, []);
  const saveEmail = useCallback((userEmail, onSuccess) => {
    if (!currentUser) {
      alert.error(t("auth:notLoggedIn"));
      return;
    }
    return updateEmail(currentUser, userEmail).then(() => {
      alert.success(t("settings:emailSaved"));
      onSuccess();
    }).catch((err) => {
      if (err.code === "auth/invalid-email") {
        alert.error(t("settings:emailWrongFormat"));
      } else {
        alert.error(t("error:saveEmail"), err);
      }
      console.error(err);
    });
  }, [alert, currentUser, t]);
  const handleSignOut = () => {
    if (window.confirm(t("auth:reallyLogout"))) {
      navigation.navigate("/logout");
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-settings"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-settings__header"
  }, /* @__PURE__ */ React.createElement("h1", null, t("settings:label"))), /* @__PURE__ */ React.createElement("div", {
    className: "pb16 pl16 pr16"
  }, /* @__PURE__ */ React.createElement(SingleSetting, {
    label: t("settings:yourDeviceName"),
    changeLabel: t("changeLabelName"),
    fieldName: "device-name",
    defaultValue: getDeviceLabel(),
    handleSave: saveDeviceLabel
  }), /* @__PURE__ */ React.createElement(SingleSetting, {
    label: t("settings:yourEmail"),
    changeLabel: t("settings:changeEmail"),
    fieldName: "user-email",
    fieldType: "email",
    defaultValue: user.email || t("settings:notSet"),
    handleSave: saveEmail
  }), /* @__PURE__ */ React.createElement(LanguageSwitcher, null), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    className: "screen-settings__button mb16",
    onClick: () => {
      navigation.navigate("/history");
    }
  }, /* @__PURE__ */ React.createElement(IconHistory, {
    width: "24px",
    className: "ml16 mr16"
  }), t("delivery:history")), userToken?.claims.servicer && /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    className: "screen-settings__button mb16",
    onClick: () => {
      navigation.navigate("/servicer");
    }
  }, /* @__PURE__ */ React.createElement(IconLogo, {
    width: "24px",
    className: "ml16 mr16"
  }), t("settings:serviceOpening")), userToken?.claims.wombat && /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    className: "screen-settings__button mb16",
    onClick: () => {
      navigation.navigate("/wombat");
    }
  }, /* @__PURE__ */ React.createElement(IconLogo, {
    width: "24px",
    className: "ml16 mr16"
  }), "Wombat"), /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    className: "screen-settings__button mb16",
    onClick: handleSignOut
  }, /* @__PURE__ */ React.createElement(IconLogout, {
    width: "24px",
    className: "ml16 mr16"
  }), t("logout")), /* @__PURE__ */ React.createElement("p", {
    className: "screen-settings__version"
  }, version)), /* @__PURE__ */ React.createElement(Tabbar, {
    settings: true
  }));
}
