import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import React, {useEffect, useState, useCallback} from "../../_snowpack/pkg/react.js";
import "./DeliveryMapScreen.css";
import {GoogleMap, LoadScript} from "../../_snowpack/pkg/@react-google-maps/api.js";
import {Marker} from "../../_snowpack/pkg/@react-google-maps/api.js";
import Geocode from "../../_snowpack/pkg/react-geocode.js";
import HeaderBack from "../../components/HeaderBack.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import {Polyline} from "../../_snowpack/pkg/@react-google-maps/api.js";
import useDoc from "../../hooks/useDoc.js";
const polylineOptions = {
  strokeColor: "blue",
  strokeOpacity: 0.75,
  strokeWeight: 2,
  icons: [
    {
      icon: {
        path: "M5 0 l0 0 l-2 0 l-15 -5 m15 5 l-15 5",
        fillColor: "blue",
        rotation: 90
      },
      repeat: "",
      offset: "100%"
    }
  ]
};
const apiKey = __SNOWPACK_ENV__.GOOGLE_MAPS_APIKEY;
Geocode.setApiKey(apiKey);
export default function DeliveryMapScreen({
  id,
  route: {
    state: {delivery: deliveryPreview}
  }
}) {
  const [senderAddress, setSenderAddress] = useState();
  const [recipientAddress, setRecipientAddress] = useState();
  const [path, setPath] = useState([]);
  const [delivery = deliveryPreview] = useDoc(`/deliveries/${id}`, {
    skip: deliveryPreview
  });
  const getAddress = useCallback(async (sender, recipient) => {
    const address1 = await Geocode.fromAddress(sender);
    setSenderAddress(address1.results?.[0].geometry.location);
    const address2 = await Geocode.fromAddress(recipient);
    setRecipientAddress(address2.results?.[0].geometry.location);
    setPath([
      {
        lat: address2.results?.[0].geometry.location.lat,
        lng: address2.results?.[0].geometry.location.lng
      },
      {
        lat: address1.results?.[0].geometry.location.lat,
        lng: address1.results?.[0].geometry.location.lng
      }
    ]);
  }, []);
  useEffect(() => {
    if (delivery) {
      getAddress(delivery.sender.address, delivery.recipient.address);
    }
  }, [delivery, getAddress]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "delivery-map-wombat"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "delivery-map-wombat__container"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "delivery-map-wombat__input"
  }, delivery?.sender.orderNumber, delivery?.code), /* @__PURE__ */ React.createElement("div", {
    className: "delivery-map-wombat__map"
  }, /* @__PURE__ */ React.createElement(LoadScript, {
    googleMapsApiKey: apiKey
  }, senderAddress && recipientAddress && /* @__PURE__ */ React.createElement(GoogleMap, {
    mapContainerStyle: {
      width: "100%",
      height: "100%"
    },
    center: senderAddress,
    zoom: 10
  }, /* @__PURE__ */ React.createElement(Marker, {
    key: "1",
    position: {lat: senderAddress.lat, lng: senderAddress.lng},
    icon: "../../../images/favicon-32x32.png"
  }), /* @__PURE__ */ React.createElement(Marker, {
    key: "2",
    position: {
      lat: recipientAddress.lat,
      lng: recipientAddress.lng
    },
    icon: "../../../images/favicon-32x32.png"
  }), /* @__PURE__ */ React.createElement(Polyline, {
    path,
    geodesic: true,
    options: polylineOptions
  }))))));
}
