import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from "../../_snowpack/pkg/react.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {unionBy} from "../../_snowpack/pkg/lodash.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {BOX_STATES, DELIVERY_STATE_NAMES} from "../../_snowpack/pkg/@topmonks/postcube.js";
import GoogleAddressInput from "../../components/GoogleAddressInput.js";
import Select, {components as ReactSelectComponents} from "../../_snowpack/pkg/react-select.js";
import IconDelete from "../../images/icon-delete-circle.js";
import Button from "../../components/Button.js";
import FormTextarea from "../../components/FormTextarea.js";
import Divider from "../../components/Divider.js";
import DeliveryAdress from "../../components/DeliveryAddress.js";
import FormItem from "../../components/FormItem.js";
import {generateDeliveryCode, updateState} from "../../library/forms.js";
import Stepper from "../../components/Stepper.js";
import useAlert from "../../hooks/useAlert.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import useDelivery from "../../hooks/useDelivery.js";
import useBoxAvailability from "../../hooks/useBoxAvailability.js";
import Loader from "../../components/Loader.js";
import Warning from "../../components/Warning.js";
import useMyBoxes from "../../hooks/useMyBoxes.js";
import {isValidBox, isValidAddress} from "../../library/validation.js";
import FormTextInput from "../../components/FormTextInput.js";
import {removeSpaces} from "../../library/utils/functions.js";
import useScanAndSelectSender from "../../hooks/useScanAndSelectSender.js";
import {remoteConfig} from "../../firebase.js";
import {getBoolean} from "../../_snowpack/pkg/firebase/remote-config.js";
import "./Step1Screen.css";
const ReactSelectSingleValue = (props) => /* @__PURE__ */ React.createElement(ReactSelectComponents.SingleValue, {
  ...props
}, /* @__PURE__ */ React.createElement("strong", null, props.data.id), " ", props.data.name, props.data.isMultibox && " (multibox)");
const ReactSelectOption = (props) => /* @__PURE__ */ React.createElement(ReactSelectComponents.Option, {
  ...props,
  isSelected: false
}, /* @__PURE__ */ React.createElement("strong", null, props.data.id), " ", props.data.name, props.data.isMultibox && " (multibox)");
const defaultFormValues = {
  note: "",
  name: "",
  address: "",
  addressStreetNumber: "",
  addressStreet: "",
  addressCity: "",
  addressPSC: "",
  placeId: "",
  phoneNumber: "+420"
};
const validation = ({sender, formValues}) => isValidBox(sender) && isValidAddress(formValues);
export default function Step1ScreenAddress({
  route: {
    data: {user},
    state: {deliveryId}
  }
}) {
  const {t} = useTranslation();
  const alert = useAlert();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const hasFeatureBoxBleSearch = useMemo(() => getBoolean(remoteConfig, "send_search_ble_box_nearby"), []);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const streetNumberInputRef = useRef();
  const {boxes: myBoxes, loading: myBoxesLoading} = useMyBoxes(user, {
    onError: handleScreenError
  });
  const {selectedNearbyBox, scanAndSelect: handleScanAndSelect} = useScanAndSelectSender({onError: handleScreenError});
  const boxes = useMemo(() => unionBy([...myBoxes, selectedNearbyBox].filter((box) => !!box), (box) => box.id), [myBoxes, selectedNearbyBox]);
  const [senderBoxId, setSenderBoxId] = useState();
  const sender = useMemo(() => boxes.find(({id}) => id === senderBoxId), [boxes, senderBoxId]);
  const {
    isLoading: isLoadingSenderBoxAvailability,
    boxState: senderBoxState
  } = useBoxAvailability(senderBoxId, {onError: handleScreenError});
  useEffect(() => {
    if (boxes.length && !senderBoxId)
      setSenderBoxId(boxes[0].id);
  }, [boxes, senderBoxId]);
  const {delivery, updateDelivery, deleteDelivery} = useDelivery(deliveryId, {onError: handleScreenError}, ({note, code, recipient, sender: _sender} = {}) => {
    if (recipient)
      setFormValues({...recipient, note, code});
    if (_sender?.id)
      setSenderBoxId(_sender.id);
  });
  const navigation = useNavigation();
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (!validation({sender, formValues})) {
      setSubmitting(false);
      return;
    }
    const completed = {
      note: formValues.note,
      code: generateDeliveryCode(),
      recipient: {
        type: "address",
        name: formValues.name,
        phoneNumber: removeSpaces(formValues.phoneNumber),
        addressStreetNumber: formValues.addressStreetNumber,
        addressStreet: formValues.addressStreet,
        addressCity: formValues.addressCity,
        addressPSC: formValues.addressPSC
      },
      sender
    };
    updateDelivery(completed).then(({data: deliveryId2} = {}) => {
      setSubmitting(false);
      if (deliveryId2) {
        navigation.navigate(`/send/2`, {
          state: {deliveryId: deliveryId2, deliveryPreview: completed}
        });
      }
    });
  };
  const canBeDeleted = [DELIVERY_STATE_NAMES.FORM_IN_PROGRESS].includes(delivery?.state);
  const handleDelete = () => {
    if (!confirm(t("delivery:reallyDelete")))
      return;
    if (!delivery) {
      navigation.goBack();
      return;
    }
    deleteDelivery().then(() => {
      alert.info(t("delivery:deleted"));
      navigation.goBack();
    });
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-send"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, {
    title: t("delivery:toAddress")
  }, /* @__PURE__ */ React.createElement(HeaderBack, {
    text: t("toHome"),
    onBack: (nav) => {
      if (!delivery && formValues !== defaultFormValues) {
        if (confirm(t("cancelChanges")))
          nav.goBack();
      } else
        nav.goBack();
    }
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement(Stepper, {
    step: "1"
  }), /* @__PURE__ */ React.createElement("h1", null, t("delivery:whereTo")), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "name",
    label: t("addressee"),
    value: formValues.name,
    onChange: updateState(setFormValues),
    required: true
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "phoneNumber",
    label: t("addresseePhone"),
    value: formValues.phoneNumber,
    onChange: updateState(setFormValues),
    required: true,
    type: "tel"
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(GoogleAddressInput, {
    value: formValues.addressStreet,
    onSelect: ({city, street, zipcode}) => {
      setFormValues({
        ...formValues,
        addressStreet: street,
        addressCity: city,
        addressPSC: zipcode
      });
      streetNumberInputRef.current.focus();
    },
    onChange: (value) => {
      setFormValues({...formValues, addressStreet: value});
    }
  }, (props) => /* @__PURE__ */ React.createElement(FormTextInput, {
    ...props,
    name: "addressStreet",
    label: t("address.street"),
    required: true
  })), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressStreetNumber",
    label: t("address.houseNo"),
    value: formValues.addressStreetNumber,
    required: true,
    onChange: updateState(setFormValues),
    customRef: streetNumberInputRef
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressCity",
    label: t("address.city"),
    value: formValues.addressCity,
    required: true,
    onChange: updateState(setFormValues)
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressPSC",
    label: t("address.zip"),
    value: formValues.addressPSC,
    required: true,
    onChange: updateState(setFormValues)
  }), !isValidAddress(formValues) && /* @__PURE__ */ React.createElement(Warning, {
    type: "alert",
    headline: t("box:warning.addressNotComplete.headline"),
    text: t("box:warning.addressNotComplete.text"),
    className: "mt16 mb16"
  }), /* @__PURE__ */ React.createElement(FormTextarea, {
    label: t("delivery:note"),
    value: formValues.note,
    name: "note",
    onChange: updateState(setFormValues)
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h1", null, t("delivery:fromWhichBox")), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("box:chooseSenderBox")
  }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Select, {
    options: boxes,
    onChange: ({id}) => {
      setSenderBoxId(id);
    },
    value: sender,
    placeholder: t("choose"),
    isLoading: myBoxesLoading || isLoadingSenderBoxAvailability,
    components: {
      SingleValue: ReactSelectSingleValue,
      Option: ReactSelectOption
    }
  }))), sender && !sender.name && /* @__PURE__ */ React.createElement(Warning, {
    type: "alert",
    headline: t("box:warning.noName.headline"),
    text: t("box:warning.noName.text"),
    className: "mt16 mb16",
    link: `/boxes/${sender.id}/edit`
  }), sender && !isValidBox(sender) && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.noAddress.headline"),
    text: t("box:warning.noAddress.text"),
    className: "mt16 mb16",
    link: `/boxes/${sender.id}/edit`
  }), senderBoxState === BOX_STATES.OFFLINE && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.outOfBattery.headline"),
    text: t("box:warning.outOfBattery.text"),
    className: "mt16 mb16"
  }), senderBoxState === BOX_STATES.BLOCKED_BY_DELIVERY && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.unavailable.headline"),
    text: t("box:warning.unavailable.text"),
    className: "mt16 mb16"
  }), senderBoxState === BOX_STATES.OUT_OF_PARTITIONS && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.occupied.headline"),
    text: t("box:warning.occupied.text"),
    className: "mt16 mb16"
  }), hasFeatureBoxBleSearch && /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleScanAndSelect
  }, t("box:searchAround")), /* @__PURE__ */ React.createElement(DeliveryAdress, {
    sender: sender || {}
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: submitting || !validation({sender, formValues}),
    name: "continue2transport"
  }, submitting ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("creating")
  }) : t("continue"))), canBeDeleted && /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    onClick: handleDelete,
    className: "screen-delivery__button-delete mt32",
    disabled: submitting
  }, /* @__PURE__ */ React.createElement(IconDelete, {
    width: "24px",
    className: "mr8"
  }), " ", t("delivery:cancel"))));
}
