import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import DeliveryStatus from "./DeliveryStatus.js";
import "./ItemDelivery.css";
export default function ItemDelivery({
  name,
  code,
  id,
  status = null,
  onClick,
  direction
}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "item-delivery-component p16 mb4",
    onClick: () => {
      if (id)
        onClick(id);
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "item-delivery-component__header"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", null, name || t("unknown")), code && /* @__PURE__ */ React.createElement("p", null, code)), /* @__PURE__ */ React.createElement(DeliveryStatus, {
    ...{direction, status}
  })));
}
