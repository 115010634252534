import {firestore} from "../firebase.js";
import {doc, onSnapshot} from "../_snowpack/pkg/firebase/firestore.js";
import {useEffect, useState} from "../_snowpack/pkg/react.js";
const getDocumentData = (doc2) => doc2.exists() ? {id: doc2.id, ...doc2.data()} : null;
export default function useDoc(path, {skip = false, onError = console.error} = {}) {
  const [[data, loading, error, ref], setState] = useState([
    void 0,
    true,
    null,
    null
  ]);
  useEffect(() => {
    if (skip) {
      setState([void 0, false, null, null]);
      return;
    }
    const ref2 = doc(firestore(), path);
    return onSnapshot(ref2, (response) => {
      const payload = getDocumentData(response);
      setState([payload, false, null, ref2]);
    }, (error2) => {
      onError(error2);
      setState([void 0, false, error2, ref2]);
    });
  }, [path, skip, JSON.stringify(onError)]);
  return [data, loading, error, ref];
}
