export default {
  label_one: "Zásilka",
  label_other: "Zásilky",
  number: "Číslo zásilky",
  sent: "Odeslané",
  sentDeliveries: "Odeslané zásilky",
  received: "Přijaté",
  receivedDeliveries: "Přijaté zásilky",
  detail: "Detail zásilky",
  receiving: "PŘIJÍMÁM",
  sending: "ODESÍLÁM",

  recipient: "Příjemce",
  sender: "Odesílatel",

  notPossible: "Omlouváme se, mezi těmito adresami zatím není možnost dopravy.",
  notFound: "Zásilka nenalezena",

  collect: "Vyzvedněte zásilku",
  collectDeliveryFromBox: "Vyzvedněte zásilku ze schránky",
  collectFromBox: "Vyzvednout z boxu",
  collectFromSeller: "Vyzvedněte zásilku u prodejce",
  collectAndCloseBox: "Vyzvedněte zásilku a zavřete schránku",

  deliverToBox: "Doručit do boxu",
  type: "Druh doručení",
  messageFromInserter: "Zpráva od pozanechavatele:",
  instantInsert: "Okamžité vložení",
  confirmedInsert: "Vložení schválené vámi",
  send: "Odeslat zásilku",
  sendToBox: "Odeslat zásilku do boxu",
  sendToAddress: "Odeslat zásilku na adresu",
  pay: "Zaplatit zásilku",
  note: "Poznámka k zásilce",

  insert: "Vložte zásilku",
  insertToBox: "Vložte zásilku do schránky",
  insertToBox2: "Vložte zásilku do boxu",
  insertAndCloseBox: "Vložte zásilku a zavřete schránku",
  linkForCarrier: "Odkaz pro dopravce",

  clearance: "Odbavení zásilky",
  cancel: "Zrušit zásilku",
  checkPlease: "Zkontrolujte si prosím zásilku",
  check: "Zkontrolovat zásilku",
  makeSureItsOkay: "Ujistěte se, že je zásilka v pořádku",
  markLegibly: "Označte čitelně zásilku",
  markLegiblyInfo:
    "Aby kurýr zásilku bezpečně poznal, napište čitelně na zásilku tento kód.",

  recipientBoxNotSelected: "Není vybrán box příjemce",
  senderBoxNotSelected: "Není vybrán box odesílatele",
  noneReceived: "Nemáte žádnou obdrženou zásilku.",
  noneSent: "Nemáte žádnou odeslanou zásilku.",
  noneAwaiting: "Nečekáte žádnou zásilku.",
  noneSending: "Neodesíláte žádnou zásilku.",

  unknownInsertedBySomeone: "Neznámá zásilka vložená kdoví kým",
  unknowInsertedWithPermission: "Neznámá zásilka vložená s vaším svolením",

  public: "Veřejné zásilky",
  preferencesQRCodeOpening:
    "Předvolby pro práci se zásilkami mimo PostCube dopravou. Týká se otevření schrány pomocí <1>QR kódu</1>.",

  price: "Cena zásilky",
  transport: "Doprava",
  chooseCarrier: "Vyberte dopravce",
  chooseCarrierPlease: "Vyberte prosím dopravce",

  reallyDelete: "Opravdu chcete zásilku zrušit?",
  deleted: "Zásilka odstraněna",
  confirmed: "Zásilka potvrzena",

  history: "Historie objednávek",
  historyReceived: "Historie přijatých zásilek",
  historySent: "Historie odeslaných zásilek",
  whereTo: "Kam to bude?",
  fromWhichBox: "Ze kterého boxu?",

  packageCode: "Kód balíku",
  packageCode2: "Kód označení zásilky",

  collection: "Přijetí zásilky",
  create: "Vytvořit zásilku",
  creation: "Vytvoření zásilky",
  new: "Nová zásilka",
  whereToSend: "Kam chcete odeslat vaši zásilku?",
  toBox: "Do schránky",
  toAddress: "Na adresu",

  state: "Stav zásilky",
  status: {
    messenger: {
      collect: "Vyzvedněte zásilku z boxu",
      deliver: "Doručte vyzvednutou zásilku",
      noStatus: "No status",
    },
    unfinished: "Rozpracovaná",
    insertToBox: "Vložte do boxu",
    inPreparation: "V přípravě",
    lookingForCourier: "Hledáme kurýra",
    courierOnTheWay: "Kurýr na cestě",
    beingCollectedByCourier: "Vyzvedává kurýr",
    beingDelivered: "Doručujeme",
    noStatus: "No status",
    waitingForApproval: "Čeká na schválení",

    forCollection: "Zásilka k vyzvednutí",
    inTransit: "Zásilka na cestě",

    delivered: "Zásilka doručena",
    collected: "Zásilka vyzvednuta",
    takenProperly: "Zásilka v pořádku převzata",

    beingPrepared: "Zásilka se připravuje",
    readyToShip: "Zásilka připravena k odeslání",
    readyToInsert: "Zásilka připravena k vložení do boxu",
    courierOnTheWayForDelivery: "Kurýr na cestě pro vaši zásilku",
    courierIsAtBox: "Kurýr je u vaši schránky",

    goingToYou: "Zásilka míří k vám",
    goingToDestination: "Zásilka míří na místo určení",
  },
};
