import React from "../_snowpack/pkg/react.js";
import {compose, mount, route, withState, redirect} from "../_snowpack/pkg/navi.js";
import MultiboxScreen from "./screens/MultiboxScreen.js";
import BoxHistoryScreen from "./screens/BoxHistoryScreen.js";
import BoxOrderDetailScreen from "./screens/BoxOrderDetailScreen.js";
import MultiboxEditScreen from "./screens/MultiboxEditScreen.js";
import "./styles.css";
import {t} from "../_snowpack/pkg/i18next.js";
const routes = {
  "/": redirect("/404"),
  "/:multiboxId": compose(withState(({state}) => state), route((req) => {
    return {
      title: `Multibox ${req.params.multiboxId}`,
      view: (pass) => /* @__PURE__ */ React.createElement(MultiboxScreen, {
        multiboxId: req.params.multiboxId,
        ...pass
      })
    };
  })),
  "/:multiboxId/edit": route((req) => ({
    title: "Editace multiboxu",
    view: (pass) => /* @__PURE__ */ React.createElement(MultiboxEditScreen, {
      multiboxId: req.params.multiboxId,
      ...pass
    })
  })),
  "/:multiboxId/users": route(() => ({
    title: t("permissions:label")
  })),
  "/boxes": compose(withState(({state}) => state), mount({
    "/:boxId/history": route((req) => ({
      title: t("delivery:history"),
      view: (pass) => /* @__PURE__ */ React.createElement(BoxHistoryScreen, {
        boxId: req.params.boxId,
        ...pass
      })
    })),
    "/:boxId/currentOrder": route((req) => ({
      title: "Detail objednávky",
      view: (pass) => /* @__PURE__ */ React.createElement(BoxOrderDetailScreen, {
        boxId: req.params.boxId,
        ...pass
      })
    }))
  }))
};
export default mount(routes);
