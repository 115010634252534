import React from "../_snowpack/pkg/react.js";
import Button from "../components/Button.js";
import "./ActionsModal.css";
export default function ActionsModal({title, actions, descriptions}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "actions-modal__container"
  }, /* @__PURE__ */ React.createElement("h1", null, title), (descriptions || []).map((description, index) => {
    return /* @__PURE__ */ React.createElement("p", {
      key: `${index}-${description}`,
      className: "color-grey mb8"
    }, description);
  }), (actions || []).map((action, index) => {
    return /* @__PURE__ */ React.createElement(Button, {
      key: `${index}-${action?.text}`,
      onClick: action?.onClick,
      modal: true
    }, action?.text);
  }));
}
