import React from "../_snowpack/pkg/react.js";
import * as ReactAlert from "../_snowpack/pkg/react-alert.js";
import InfoIcon from "../images/icon-alert.js";
import SuccessIcon from "../images/icon-check-circle.js";
import ErrorIcon from "../images/icon-delete-circle.js";
import CloseIcon from "../images/icon-close.js";
const alertStyle = {
  backgroundColor: "#282632",
  color: "white",
  padding: "16px",
  borderRadius: "4px",
  display: "flex",
  boxShadow: "0px 2px 2px 2px var(--color-shadow)",
  fontFamily: "proxima-nova",
  width: "calc(100vw - 32px)",
  boxSizing: "border-box",
  fill: "white"
};
const containerStyle = {
  marginBottom: "calc(env(safe-area-inset-bottom, 24px) + 32px)",
  wordBreak: "break-all",
  zIndex: 2147483646
};
const buttonStyle = {
  marginTop: "4px",
  fontSize: "16px",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  color: "#FFFFFF"
};
export const reactAlertOptions = {
  position: ReactAlert.positions.BOTTOM_CENTER,
  timeout: 4200,
  offset: "8px",
  transition: ReactAlert.transitions.FADE,
  containerStyle
};
export default function AlertTemplate({message, options, style, close}) {
  return /* @__PURE__ */ React.createElement("div", {
    style: {...alertStyle, ...style}
  }, /* @__PURE__ */ React.createElement("div", null, options.type === "info" && /* @__PURE__ */ React.createElement(InfoIcon, {
    className: "mr16",
    width: "24px",
    fill: "var(--color-silver)"
  }), options.type === "success" && /* @__PURE__ */ React.createElement(SuccessIcon, {
    className: "mr16",
    width: "24px",
    fill: "var(--color-success)"
  }), options.type === "error" && /* @__PURE__ */ React.createElement(ErrorIcon, {
    className: "mr16",
    width: "24px",
    fill: "var(--color-bigbad)"
  })), /* @__PURE__ */ React.createElement("span", {
    style: {flex: 2}
  }, message), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
    onClick: close,
    style: buttonStyle
  }, /* @__PURE__ */ React.createElement(CloseIcon, {
    width: "16px"
  }))));
}
