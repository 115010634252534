import React, {useCallback, useMemo} from "../../_snowpack/pkg/react.js";
import cs from "../../_snowpack/pkg/classnames.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {
  boxSizes,
  boxStates,
  deliveryStates,
  getDeliveryStatesLabels
} from "../constants.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import ActionsModal from "../modals/ActionsModal.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import SingleBoxActionButtons from "../modals/SingleBoxActionButtons.js";
import "./MultiboxGrid.css";
const dummyBoxes = [];
for (let i = 0; i < 27; i++) {
  const stateAndSize = i < 10 ? {state: boxStates.SENDING, size: boxSizes.DOUBLE} : i < 16 ? {state: boxStates.RECEIVING, size: boxSizes.HALF} : i < 20 ? {state: boxStates.FREE, size: boxSizes.DEFAULT} : {state: boxStates.DISABLED, size: boxSizes.DOUBLE};
  const cell = {
    id: i + 1,
    ...stateAndSize
  };
  dummyBoxes[i] = cell;
}
const MultiboxGridSingleBox = ({
  box: {id, occupancy: {deliveries} = {}, state},
  openModal
}) => {
  const {t} = useTranslation();
  const size = boxSizes.DEFAULT;
  const delivery = deliveries?.[0];
  const deliveryState = delivery && delivery.isOccupying ? deliveryStates.READY_FOR_COLLECTION : deliveryStates.IN_TRANSIT;
  const deliveryStateLabel = useMemo(() => delivery && getDeliveryStatesLabels(t)[deliveryState], [deliveryState, delivery, t]);
  const navigation = useNavigation();
  const handleOpenActions = useCallback(() => {
    openModal(/* @__PURE__ */ React.createElement(ActionsModal, {
      title: t("box:labelWithId", {boxId: id}),
      subtitle: deliveryStateLabel,
      actionButtons: /* @__PURE__ */ React.createElement(SingleBoxActionButtons, {
        navigation,
        boxState: state,
        boxId: id,
        userPhoneNumber: "737001657"
      })
    }));
  }, [id, deliveryStateLabel, openModal, navigation, state, t]);
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("MultiboxGrid-box", {
      "MultiboxGrid-box--sizeHalf": size === boxSizes.HALF,
      "MultiboxGrid-box--sizeDouble": size === boxSizes.DOUBLE,
      "MultiboxGrid-box--sending": state === boxStates.SENDING,
      "MultiboxGrid-box--receiving": state === boxStates.RECEIVING,
      "MultiboxGrid-box--free": state === boxStates.FREE,
      "MultiboxGrid-box--disabled": state === boxStates.DISABLED
    }),
    onClick: handleOpenActions
  }, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box-label"
  }, id.slice(-2)));
};
const MultiboxGrid = ({partitions}) => {
  const {openModal, modal} = useMobileModal();
  return /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid"
  }, partitions.map((partition, index) => /* @__PURE__ */ React.createElement(MultiboxGridSingleBox, {
    key: `${index}-${partition.id}`,
    box: partition,
    openModal
  })), modal);
};
export default MultiboxGrid;
