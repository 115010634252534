import React, {useState, useEffect, useMemo} from "../../_snowpack/pkg/react.js";
import "./BoxesScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import BoxItem from "../components/BoxItem.js";
import Tabbar from "../components/TabbarWombat.js";
import HeaderBack from "../../components/HeaderBack.js";
import IconBox from "../../images/icon-box.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Loader from "../../components/Loader.js";
import useAlert from "../../hooks/useAlert.js";
import useCollection from "../../hooks/useCollection.js";
export default function BoxesScreen() {
  const navigation = useNavigation();
  const alert = useAlert();
  const [showAllBoxes, setShowAllBoxes] = useState(false);
  const [searchText, setSearchText] = useState("");
  const applySearch = (data) => data.id.startsWith(searchText);
  const handleSearch = (text) => {
    setSearchText(text);
  };
  const handleChange = () => {
    setShowAllBoxes(!showAllBoxes);
  };
  const [productionBoxes = [], productionBoxesLoading, boxesError] = useCollection(`/production-boxes`);
  const [pairedBoxes = [], pairedBoxesLoading, pairedBoxesError] = useCollection(`/boxes`);
  useEffect(() => {
    const dataError = boxesError || pairedBoxesError;
    if (dataError)
      alert.error(dataError);
  }, [alert, boxesError, pairedBoxesError]);
  const boxes = useMemo(() => (showAllBoxes ? productionBoxes : pairedBoxes).map((box) => ({
    ...box,
    ...(showAllBoxes ? pairedBoxes : productionBoxes).find((b) => b.id === box.id)
  })), [showAllBoxes, productionBoxes, pairedBoxes]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "boxes-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    path: "/settings",
    text: "PostCube"
  }), /* @__PURE__ */ React.createElement(IconBox, {
    fill: "var(--color-white)",
    height: "24px",
    lenght: "24px",
    onClick: () => {
      navigation.navigate("boxes/map", {
        state: {boxes, filter: searchText}
      });
    }
  })), /* @__PURE__ */ React.createElement("div", {
    className: "boxes-wombat-screen__boxes"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "boxes-wombat-screen__header"
  }, /* @__PURE__ */ React.createElement("h1", null, "Schránky"), /* @__PURE__ */ React.createElement("div", {
    className: "boxes-wombat-screen__menu"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "boxes-wombat-screen__input",
    type: "text",
    placeholder: "Box",
    value: searchText,
    onChange: (event) => handleSearch(event.target.value)
  }), /* @__PURE__ */ React.createElement("div", {
    className: "boxes-wombat-screen__filters"
  }, /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("input", {
    className: "mr8",
    type: "checkbox",
    checked: showAllBoxes,
    onChange: handleChange
  }), /* @__PURE__ */ React.createElement("span", null, " Zobrazit všechny boxy"))))), boxes.filter(applySearch).map((box) => /* @__PURE__ */ React.createElement(BoxItem, {
    key: box.id,
    box,
    onClick: () => {
      navigation.navigate(`box/${box.id}`, {state: {box}});
    }
  })), (productionBoxesLoading || pairedBoxesLoading) && /* @__PURE__ */ React.createElement(Loader, null)), /* @__PURE__ */ React.createElement(Tabbar, {
    boxes: true
  }));
}
