import { W as WebPlugin } from './index-67482630.js';
import './_polyfill-node:global-acbc543a.js';

class SplashScreenWeb extends WebPlugin {
    async show(_options) {
        return undefined;
    }
    async hide(_options) {
        return undefined;
    }
}

export { SplashScreenWeb };
