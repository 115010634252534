import React from "../_snowpack/pkg/react.js";
import {useTranslation, Trans} from "../_snowpack/pkg/react-i18next.js";
import {firestore} from "../firebase.js";
import {deleteDoc, doc} from "../_snowpack/pkg/firebase/firestore.js";
import Button from "../components/Button.js";
import "./ModalKeyDetail.css";
export default function ModalKeyDetail({
  openLink,
  onDeactivate = () => {
  },
  onCopyToClipboard = () => {
  }
}) {
  const {t} = useTranslation();
  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(`https://app.postcube.cz/keys/${openLink.commandId}`);
    onCopyToClipboard();
  };
  const handleDeactivateKey = () => {
    deleteDoc(doc(firestore(), `/open-links/${openLink.commandId}`)).then(onDeactivate);
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "modal-key-detail__container"
  }, /* @__PURE__ */ React.createElement("h1", null, openLink.name), /* @__PURE__ */ React.createElement("b", {
    className: "color-primary modal-key-detail__link"
  }, "app.postcube.cz/keys/", openLink.commandId), /* @__PURE__ */ React.createElement("p", {
    className: "m16"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "box:key.openInfo",
    t
  }, "Klíčem může schránku otevřít kdokoliv, komu ho pošlete.", /* @__PURE__ */ React.createElement("b", null, " Klíč je jednorázový a platí 24 hodin."))), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: handleCopyClipboard
  }, t("box:key.copyToClipboard")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: handleDeactivateKey
  }, t("box:key.deactivate")));
}
