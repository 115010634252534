import React, {useEffect} from "../_snowpack/pkg/react.js";
import "./ListSelect.css";
import Loader from "./Loader.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
const defaultItemIdGetter = (item) => item && (item.id || item.uid);
const isEmpty = (obj) => !obj || Object.values(obj).length === 0;
export default function ListSelect({
  data = [],
  loaded = true,
  render = defaultItemIdGetter,
  getItemId = defaultItemIdGetter,
  renderEmptyState = () => {
  },
  selected = {},
  onChange,
  horizontal
}) {
  const {t} = useTranslation();
  useEffect(() => {
    if (data.length === 1 && isEmpty(selected)) {
      onChange(data[0]);
    }
  }, [data, selected, onChange]);
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("list-select-component", horizontal && "list-select-component--horizontal")
  }, !loaded && /* @__PURE__ */ React.createElement(Loader, {
    className: "screen-delivery__loader",
    text: t("loading")
  }), loaded && data.length === 0 && renderEmptyState(), data.map((item, index) => {
    const itemId = getItemId(item);
    const active = getItemId(selected) === itemId;
    return /* @__PURE__ */ React.createElement("div", {
      className: cs("list-select-component__radio", active && "list-select-component__radio--active"),
      key: itemId || index
    }, /* @__PURE__ */ React.createElement("input", {
      className: "list-select-component__radio__input",
      type: "radio",
      name: "selectType",
      id: itemId,
      value: itemId,
      checked: active,
      onChange: () => onChange(item)
    }), /* @__PURE__ */ React.createElement("label", {
      className: "list-select-component__radio__label",
      htmlFor: itemId
    }, /* @__PURE__ */ React.createElement("div", {
      className: "list-select-component__radio__button"
    }, render(item, active))));
  }));
}
