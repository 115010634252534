export function pipe(...fns) {
  return (...x) =>
    fns.reduce((v, f) => {
      if (!Array.isArray(v)) {
        v = [v];
      }
      return f(...v);
    }, x);
}

export const removeSpaces = (str) => str.replace(/ /g, "");
