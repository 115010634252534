import React, {useState} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import Button from "../components/Button.js";
import FormItem from "../components/FormItem.js";
import FormTextInput from "../components/FormTextInput.js";
import FormTextarea from "../components/FormTextarea.js";
import useBleDevicesNearby from "../hooks/useBleDevicesNearby.js";
import BleBox from "../library/BleBox.js";
import {removeSpaces} from "../library/utils/functions.js";
import {isPhoneValid} from "../library/validation.js";
import "./FinishInstantReceptionModal.css";
export default function FinishInstantReceptionModal({
  boxId,
  onError = console.error,
  onComplete = () => {
  }
}) {
  const {t} = useTranslation();
  const [isRequestingUnlock, setIsRequestingUnlock] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [unlockRequestId, setUnlockRequestId] = useState();
  const [phoneNumber, setPhoneNumber] = useState("+420");
  const [message, setMessage] = useState(t("box:openingRequest.messagePlaceholder"));
  const {findNearby: findBleDeviceNearby} = useBleDevicesNearby();
  const handleRequestUnlock = async (event) => {
    event.preventDefault();
    const formattedPhoneNumber = removeSpaces(phoneNumber);
    setIsRequestingUnlock(true);
    try {
      const {
        data: {id, command}
      } = await httpsCallable(functions(), "box-requestUnlock")({
        type: "INSTANT_RECEPTION",
        phoneNumber: formattedPhoneNumber,
        boxId,
        message
      });
      setUnlockRequestId(id);
      const bleDevice = await findBleDeviceNearby({id: boxId});
      const bleBox = BleBox(bleDevice);
      bleBox.transaction(async () => {
        await bleBox.unlockWithOnlineKey(command);
        setIsUnlocked(true);
      });
    } catch (err) {
      onError(err);
    } finally {
      setIsRequestingUnlock(false);
    }
  };
  const handleFinishRequest = async (event) => {
    event.preventDefault();
    setIsConfirming(true);
    try {
      await httpsCallable(functions(), "delivery-finishInstantReception")({
        unlockRequestId
      });
      onComplete();
    } catch (err) {
      onError(err);
    } finally {
      setIsConfirming(false);
    }
  };
  if (isUnlocked) {
    return /* @__PURE__ */ React.createElement("form", {
      className: "finish-instant-reception-modal__container",
      onSubmit: handleFinishRequest
    }, /* @__PURE__ */ React.createElement("h1", null, t("box:opened")), /* @__PURE__ */ React.createElement("p", {
      className: "mb16"
    }, t("box:openingRequest.closeBoxAndCheckPackageInserted")), /* @__PURE__ */ React.createElement(Button, {
      disabled: isConfirming,
      type: "submit"
    }, t("confirm")));
  }
  return /* @__PURE__ */ React.createElement("form", {
    className: "finish-instant-reception-modal__container",
    onSubmit: handleRequestUnlock
  }, /* @__PURE__ */ React.createElement("h1", null, t("box:openingRequest.doYouKnowOwner")), /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, t("box:openingRequest.enterBoxOwnersPhone")), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("phoneNumber")
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "phoneNumber",
    type: "tel",
    value: phoneNumber,
    onChange: (event) => {
      setPhoneNumber(event.target.value);
    },
    required: true,
    minLength: 13
  })), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("box:openingRequest.messageForRecipient")
  }, /* @__PURE__ */ React.createElement(FormTextarea, {
    name: "message",
    placeholder: t("box:openingRequest.messageText"),
    value: message,
    onChange: (event) => {
      setMessage(event.target.value);
    }
  })), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: isRequestingUnlock || !isPhoneValid(phoneNumber)
  }, t("box:unlock")));
}
