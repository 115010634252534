import useCollection from "./useCollection.js";

export default function useBoxBattery(boxId, { onError = console.error } = {}) {
  const [data, isLoading, error] = useCollection(`/boxes/${boxId}/battery`, {
    limit: 1,
    orderBy: ["updatedAt", "desc"],
    skip: !boxId || boxId?.length !== 6,
    onError,
  });

  return {
    battery: data ? data[0] : data,
    isLoading,
    error,
  };
}
