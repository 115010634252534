import React from "../../_snowpack/pkg/react.js";
import "./ActionsModal.css";
const ActionsModal = ({title, subtitle, actionButtons}) => /* @__PURE__ */ React.createElement("div", {
  className: "ActionsModal-container"
}, /* @__PURE__ */ React.createElement("h1", {
  className: "ActionsModal-title"
}, title), subtitle && /* @__PURE__ */ React.createElement("p", {
  className: "mb8"
}, subtitle), actionButtons);
export default ActionsModal;
