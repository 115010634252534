import React, {useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import Button from "../../components/Button.js";
import Loader from "../../components/Loader.js";
const ModalBoxUnlockButton = ({onUnlock}) => {
  const [unlocking, setUnlocking] = useState(false);
  const {t} = useTranslation();
  const handleUnlock = (...args) => {
    setUnlocking(true);
    onUnlock(...args);
  };
  return /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    loader: true,
    className: "mt16",
    onClick: handleUnlock
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("box:opening"),
    className: "color-primary"
  }) : t("box:open"));
};
export default ModalBoxUnlockButton;
