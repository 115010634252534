import * as __SNOWPACK_ENV__ from './_snowpack/env.js';

import { initializeApp } from "./_snowpack/pkg/firebase/app.js";
import { Capacitor } from "./_snowpack/pkg/@capacitor/core.js";
import {
  getFirestore,
  enableIndexedDbPersistence,
  initializeFirestore,
} from "./_snowpack/pkg/firebase/firestore.js";
import { getRemoteConfig, fetchAndActivate } from "./_snowpack/pkg/firebase/remote-config.js";
import {
  getAuth,
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence,
} from "./_snowpack/pkg/firebase/auth.js";
import { getFunctions, connectFunctionsEmulator } from "./_snowpack/pkg/firebase/functions.js";
import { getAnalytics } from "./_snowpack/pkg/firebase/analytics.js";
import { getMessaging } from "./_snowpack/pkg/firebase/messaging.js";

import config from "./firebase.config.js";

const envis = (env) => ["true", "yes", "ano", "da"].includes(env);
const {
  SNOWPACK_PUBLIC_DISABLE_RECAPTCHA,
  DISABLE_RECAPTCHA,
  EMULATED_FIRESTORE,
  EMULATED_FUNCTIONS,
  EMULATED_AUTH,
  OFFLINE_PERSISTENCE,
} = __SNOWPACK_ENV__;

const emulatorLink = (port) =>
  `${location.protocol}//${location.hostname}:${port}`;

const app = initializeApp(config);
export const analytics = getAnalytics(app);

export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
fetchAndActivate(remoteConfig).then((alreadyActivated) =>
  console.debug("Remote config loaded, activated = ", alreadyActivated)
);

const createFirestore = (firebaseApp) => {
  if (envis(EMULATED_FIRESTORE)) {
    return initializeFirestore(firebaseApp, {
      host: `localhost:8081`,
      ssl: false,
      //for cypress to work with real data
      experimentalForceLongPolling: true,
    });
  }

  return getFirestore(firebaseApp);
};

const db = createFirestore(app);

if (envis(OFFLINE_PERSISTENCE)) {
  enableIndexedDbPersistence(db, { forceOwnership: true }).then(() =>
    console.debug("Enabled offline persistence")
  );
}

export const firestore = () => {
  return db;
};

const fn = getFunctions(app, "europe-west1");
if (envis(EMULATED_FUNCTIONS)) {
  connectFunctionsEmulator(fn, location.hostname, 5001);
}
export const functions = () => {
  return fn;
};

export const messaging = () => {
  return getMessaging(app);
};

export const auth = () => {
  let authInstance;
  if (Capacitor.isNativePlatform()) {
    authInstance = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    authInstance = getAuth();
  }

  authInstance.settings.appVerificationDisabledForTesting =
    envis(DISABLE_RECAPTCHA) ||
    (envis(SNOWPACK_PUBLIC_DISABLE_RECAPTCHA) &&
      location.protocol.startsWith("https") === false);

  return authInstance;
};

if (envis(EMULATED_AUTH)) {
  connectAuthEmulator(auth(), emulatorLink(9099));
}
