import React from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import IconSymbol from "../images/icon-postcube.js";
import "./Loader.css";
export const CenteredLoader = (pass) => /* @__PURE__ */ React.createElement("div", {
  className: "centered-loader-component"
}, /* @__PURE__ */ React.createElement(Loader, {
  ...pass
}));
export default function Loader({className, text, mini, light, ghost}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: cs(className, "loader-component delayed-visibility", {
      "loader-component--mini": mini,
      "loader-component--light": light,
      "loader-component--ghost": ghost
    })
  }, /* @__PURE__ */ React.createElement(IconSymbol, {
    width: "24px",
    className: "loader-component__symbol mr8"
  }), mini ? null : text || t("loading"));
}
