import { c as createCommonjsModule } from '../common/_commonjsHelpers-0597c316.js';
import { t as taggedTemplateLiteral, a as AutosizeInput_1, e as emotionReact_browser_esm, _ as _extends_1, b as toConsumableArray, m as memoizeOne } from '../common/taggedTemplateLiteral-d9c6e788.js';
import { o as objectWithoutProperties, d as _typeof_1, e as defineProperty } from '../common/unsupportedIterableToArray-4f4f72e9.js';
import { c as classCallCheck, b as createClass } from '../common/createClass-ab3070f6.js';
import { r as react } from '../common/index-395f2387.js';
import { r as reactDom } from '../common/index-f7668ee4.js';
import '../common/extends-7477639a.js';
import '../common/index-2252d729.js';

var setPrototypeOf = createCommonjsModule(function (module) {
function _setPrototypeOf(o, p) {
  module.exports = _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) {
    o.__proto__ = p;
    return o;
  }, module.exports.__esModule = true, module.exports["default"] = module.exports;
  return _setPrototypeOf(o, p);
}

module.exports = _setPrototypeOf, module.exports.__esModule = true, module.exports["default"] = module.exports;
});

var inherits = createCommonjsModule(function (module) {
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function");
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      writable: true,
      configurable: true
    }
  });
  Object.defineProperty(subClass, "prototype", {
    writable: false
  });
  if (superClass) setPrototypeOf(subClass, superClass);
}

module.exports = _inherits, module.exports.__esModule = true, module.exports["default"] = module.exports;
});

var indexEa9e225d_cjs_prod = createCommonjsModule(function (module, exports) {



function _interopDefault(e) {
  return e && e.__esModule ? e : {
    default: e
  };
}

var _extends__default = _interopDefault(_extends_1), _taggedTemplateLiteral__default = _interopDefault(taggedTemplateLiteral), _objectWithoutProperties__default = _interopDefault(objectWithoutProperties), _typeof__default = _interopDefault(_typeof_1), AutosizeInput__default = _interopDefault(AutosizeInput_1), _classCallCheck__default = _interopDefault(classCallCheck), _createClass__default = _interopDefault(createClass), _inherits__default = _interopDefault(inherits), _defineProperty__default = _interopDefault(defineProperty);

function _defineProperty(obj, key, value) {
  return key in obj ? Object.defineProperty(obj, key, {
    value: value,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : obj[key] = value, obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter((function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    }))), keys.push.apply(keys, symbols);
  }
  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach((function(key) {
      _defineProperty(target, key, source[key]);
    })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach((function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    }));
  }
  return target;
}

function _getPrototypeOf(o) {
  return (_getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function(o) {
    return o.__proto__ || Object.getPrototypeOf(o);
  })(o);
}

function _isNativeReflectConstruct() {
  if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
  if (Reflect.construct.sham) return !1;
  if ("function" == typeof Proxy) return !0;
  try {
    return Date.prototype.toString.call(Reflect.construct(Date, [], (function() {}))), 
    !0;
  } catch (e) {
    return !1;
  }
}

function _assertThisInitialized(self) {
  if (void 0 === self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return self;
}

function _possibleConstructorReturn(self, call) {
  return !call || "object" != typeof call && "function" != typeof call ? _assertThisInitialized(self) : call;
}

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function() {
    var result, Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = Reflect.construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}

var noop = function() {};

function applyPrefixToName(prefix, name) {
  return name ? "-" === name[0] ? prefix + name : prefix + "__" + name : prefix;
}

function classNames(prefix, state, className) {
  var arr = [ className ];
  if (state && prefix) for (var key in state) state.hasOwnProperty(key) && state[key] && arr.push("".concat(applyPrefixToName(prefix, key)));
  return arr.filter((function(i) {
    return i;
  })).map((function(i) {
    return String(i).trim();
  })).join(" ");
}

var cleanValue = function(value) {
  return Array.isArray(value) ? value.filter(Boolean) : "object" === _typeof__default.default(value) && null !== value ? [ value ] : [];
}, cleanCommonProps = function(props) {
  return props.className, props.clearValue, props.cx, props.getStyles, props.getValue, 
  props.hasValue, props.isMulti, props.isRtl, props.options, props.selectOption, props.selectProps, 
  props.setValue, props.theme, _objectSpread2({}, _objectWithoutProperties__default.default(props, [ "className", "clearValue", "cx", "getStyles", "getValue", "hasValue", "isMulti", "isRtl", "options", "selectOption", "selectProps", "setValue", "theme" ]));
};

function handleInputChange(inputValue, actionMeta, onInputChange) {
  if (onInputChange) {
    var newValue = onInputChange(inputValue, actionMeta);
    if ("string" == typeof newValue) return newValue;
  }
  return inputValue;
}

function isDocumentElement(el) {
  return [ document.documentElement, document.body, window ].indexOf(el) > -1;
}

function getScrollTop(el) {
  return isDocumentElement(el) ? window.pageYOffset : el.scrollTop;
}

function scrollTo(el, top) {
  isDocumentElement(el) ? window.scrollTo(0, top) : el.scrollTop = top;
}

function getScrollParent(element) {
  var style = getComputedStyle(element), excludeStaticParent = "absolute" === style.position, overflowRx = /(auto|scroll)/, docEl = document.documentElement;
  if ("fixed" === style.position) return docEl;
  for (var parent = element; parent = parent.parentElement; ) if (style = getComputedStyle(parent), 
  (!excludeStaticParent || "static" !== style.position) && overflowRx.test(style.overflow + style.overflowY + style.overflowX)) return parent;
  return docEl;
}

function easeOutCubic(t, b, c, d) {
  return c * ((t = t / d - 1) * t * t + 1) + b;
}

function animatedScrollTo(element, to) {
  var duration = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 200, callback = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : noop, start = getScrollTop(element), change = to - start, increment = 10, currentTime = 0;
  function animateScroll() {
    var val = easeOutCubic(currentTime += increment, start, change, duration);
    scrollTo(element, val), currentTime < duration ? window.requestAnimationFrame(animateScroll) : callback(element);
  }
  animateScroll();
}

function scrollIntoView(menuEl, focusedEl) {
  var menuRect = menuEl.getBoundingClientRect(), focusedRect = focusedEl.getBoundingClientRect(), overScroll = focusedEl.offsetHeight / 3;
  focusedRect.bottom + overScroll > menuRect.bottom ? scrollTo(menuEl, Math.min(focusedEl.offsetTop + focusedEl.clientHeight - menuEl.offsetHeight + overScroll, menuEl.scrollHeight)) : focusedRect.top - overScroll < menuRect.top && scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
}

function getBoundingClientObj(element) {
  var rect = element.getBoundingClientRect();
  return {
    bottom: rect.bottom,
    height: rect.height,
    left: rect.left,
    right: rect.right,
    top: rect.top,
    width: rect.width
  };
}

function isTouchCapable() {
  try {
    return document.createEvent("TouchEvent"), !0;
  } catch (e) {
    return !1;
  }
}

function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  } catch (e) {
    return !1;
  }
}

var passiveOptionAccessed = !1, options = {
  get passive() {
    return passiveOptionAccessed = !0;
  }
}, w = "undefined" != typeof window ? window : {};

w.addEventListener && w.removeEventListener && (w.addEventListener("p", noop, options), 
w.removeEventListener("p", noop, !1));

var supportsPassiveEvents = passiveOptionAccessed;

function getMenuPlacement(_ref) {
  var maxHeight = _ref.maxHeight, menuEl = _ref.menuEl, minHeight = _ref.minHeight, placement = _ref.placement, shouldScroll = _ref.shouldScroll, isFixedPosition = _ref.isFixedPosition, spacing = _ref.theme.spacing, scrollParent = getScrollParent(menuEl), defaultState = {
    placement: "bottom",
    maxHeight: maxHeight
  };
  if (!menuEl || !menuEl.offsetParent) return defaultState;
  var scrollHeight = scrollParent.getBoundingClientRect().height, _menuEl$getBoundingCl = menuEl.getBoundingClientRect(), menuBottom = _menuEl$getBoundingCl.bottom, menuHeight = _menuEl$getBoundingCl.height, menuTop = _menuEl$getBoundingCl.top, containerTop = menuEl.offsetParent.getBoundingClientRect().top, viewHeight = window.innerHeight, scrollTop = getScrollTop(scrollParent), marginBottom = parseInt(getComputedStyle(menuEl).marginBottom, 10), marginTop = parseInt(getComputedStyle(menuEl).marginTop, 10), viewSpaceAbove = containerTop - marginTop, viewSpaceBelow = viewHeight - menuTop, scrollSpaceAbove = viewSpaceAbove + scrollTop, scrollSpaceBelow = scrollHeight - scrollTop - menuTop, scrollDown = menuBottom - viewHeight + scrollTop + marginBottom, scrollUp = scrollTop + menuTop - marginTop;
  switch (placement) {
   case "auto":
   case "bottom":
    if (viewSpaceBelow >= menuHeight) return {
      placement: "bottom",
      maxHeight: maxHeight
    };
    if (scrollSpaceBelow >= menuHeight && !isFixedPosition) return shouldScroll && animatedScrollTo(scrollParent, scrollDown, 160), 
    {
      placement: "bottom",
      maxHeight: maxHeight
    };
    if (!isFixedPosition && scrollSpaceBelow >= minHeight || isFixedPosition && viewSpaceBelow >= minHeight) return shouldScroll && animatedScrollTo(scrollParent, scrollDown, 160), 
    {
      placement: "bottom",
      maxHeight: isFixedPosition ? viewSpaceBelow - marginBottom : scrollSpaceBelow - marginBottom
    };
    if ("auto" === placement || isFixedPosition) {
      var _constrainedHeight = maxHeight, spaceAbove = isFixedPosition ? viewSpaceAbove : scrollSpaceAbove;
      return spaceAbove >= minHeight && (_constrainedHeight = Math.min(spaceAbove - marginBottom - spacing.controlHeight, maxHeight)), 
      {
        placement: "top",
        maxHeight: _constrainedHeight
      };
    }
    if ("bottom" === placement) return shouldScroll && scrollTo(scrollParent, scrollDown), 
    {
      placement: "bottom",
      maxHeight: maxHeight
    };
    break;

   case "top":
    if (viewSpaceAbove >= menuHeight) return {
      placement: "top",
      maxHeight: maxHeight
    };
    if (scrollSpaceAbove >= menuHeight && !isFixedPosition) return shouldScroll && animatedScrollTo(scrollParent, scrollUp, 160), 
    {
      placement: "top",
      maxHeight: maxHeight
    };
    if (!isFixedPosition && scrollSpaceAbove >= minHeight || isFixedPosition && viewSpaceAbove >= minHeight) {
      var _constrainedHeight2 = maxHeight;
      return (!isFixedPosition && scrollSpaceAbove >= minHeight || isFixedPosition && viewSpaceAbove >= minHeight) && (_constrainedHeight2 = isFixedPosition ? viewSpaceAbove - marginTop : scrollSpaceAbove - marginTop), 
      shouldScroll && animatedScrollTo(scrollParent, scrollUp, 160), {
        placement: "top",
        maxHeight: _constrainedHeight2
      };
    }
    return {
      placement: "bottom",
      maxHeight: maxHeight
    };

   default:
    throw new Error('Invalid placement provided "'.concat(placement, '".'));
  }
  return defaultState;
}

function alignToControl(placement) {
  return placement ? {
    bottom: "top",
    top: "bottom"
  }[placement] : "bottom";
}

var coercePlacement = function(p) {
  return "auto" === p ? "bottom" : p;
}, menuCSS = function(_ref2) {
  var _ref3, placement = _ref2.placement, _ref2$theme = _ref2.theme, borderRadius = _ref2$theme.borderRadius, spacing = _ref2$theme.spacing, colors = _ref2$theme.colors;
  return _ref3 = {
    label: "menu"
  }, _defineProperty__default.default(_ref3, alignToControl(placement), "100%"), _defineProperty__default.default(_ref3, "backgroundColor", colors.neutral0), 
  _defineProperty__default.default(_ref3, "borderRadius", borderRadius), _defineProperty__default.default(_ref3, "boxShadow", "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)"), 
  _defineProperty__default.default(_ref3, "marginBottom", spacing.menuGutter), _defineProperty__default.default(_ref3, "marginTop", spacing.menuGutter), 
  _defineProperty__default.default(_ref3, "position", "absolute"), _defineProperty__default.default(_ref3, "width", "100%"), 
  _defineProperty__default.default(_ref3, "zIndex", 1), _ref3;
}, PortalPlacementContext = react.createContext({
  getPortalPlacement: null
}), MenuPlacer = function(_Component) {
  _inherits__default.default(MenuPlacer, _Component);
  var _super = _createSuper(MenuPlacer);
  function MenuPlacer() {
    var _this;
    _classCallCheck__default.default(this, MenuPlacer);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return (_this = _super.call.apply(_super, [ this ].concat(args))).state = {
      maxHeight: _this.props.maxMenuHeight,
      placement: null
    }, _this.getPlacement = function(ref) {
      var _this$props = _this.props, minMenuHeight = _this$props.minMenuHeight, maxMenuHeight = _this$props.maxMenuHeight, menuPlacement = _this$props.menuPlacement, menuPosition = _this$props.menuPosition, menuShouldScrollIntoView = _this$props.menuShouldScrollIntoView, theme = _this$props.theme;
      if (ref) {
        var isFixedPosition = "fixed" === menuPosition, state = getMenuPlacement({
          maxHeight: maxMenuHeight,
          menuEl: ref,
          minHeight: minMenuHeight,
          placement: menuPlacement,
          shouldScroll: menuShouldScrollIntoView && !isFixedPosition,
          isFixedPosition: isFixedPosition,
          theme: theme
        }), getPortalPlacement = _this.context.getPortalPlacement;
        getPortalPlacement && getPortalPlacement(state), _this.setState(state);
      }
    }, _this.getUpdatedProps = function() {
      var menuPlacement = _this.props.menuPlacement, placement = _this.state.placement || coercePlacement(menuPlacement);
      return _objectSpread2(_objectSpread2({}, _this.props), {}, {
        placement: placement,
        maxHeight: _this.state.maxHeight
      });
    }, _this;
  }
  return _createClass__default.default(MenuPlacer, [ {
    key: "render",
    value: function() {
      return (0, this.props.children)({
        ref: this.getPlacement,
        placerProps: this.getUpdatedProps()
      });
    }
  } ]), MenuPlacer;
}(react.Component);

MenuPlacer.contextType = PortalPlacementContext;

var Menu = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerRef = props.innerRef, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("menu", props),
    className: cx({
      menu: !0
    }, className),
    ref: innerRef
  }, innerProps), children);
}, menuListCSS = function(_ref4) {
  var maxHeight = _ref4.maxHeight, baseUnit = _ref4.theme.spacing.baseUnit;
  return {
    maxHeight: maxHeight,
    overflowY: "auto",
    paddingBottom: baseUnit,
    paddingTop: baseUnit,
    position: "relative",
    WebkitOverflowScrolling: "touch"
  };
}, MenuList = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps, innerRef = props.innerRef, isMulti = props.isMulti;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("menuList", props),
    className: cx({
      "menu-list": !0,
      "menu-list--is-multi": isMulti
    }, className),
    ref: innerRef
  }, innerProps), children);
}, noticeCSS = function(_ref5) {
  var _ref5$theme = _ref5.theme, baseUnit = _ref5$theme.spacing.baseUnit;
  return {
    color: _ref5$theme.colors.neutral40,
    padding: "".concat(2 * baseUnit, "px ").concat(3 * baseUnit, "px"),
    textAlign: "center"
  };
}, noOptionsMessageCSS = noticeCSS, loadingMessageCSS = noticeCSS, NoOptionsMessage = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("noOptionsMessage", props),
    className: cx({
      "menu-notice": !0,
      "menu-notice--no-options": !0
    }, className)
  }, innerProps), children);
};

NoOptionsMessage.defaultProps = {
  children: "No options"
};

var LoadingMessage = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("loadingMessage", props),
    className: cx({
      "menu-notice": !0,
      "menu-notice--loading": !0
    }, className)
  }, innerProps), children);
};

LoadingMessage.defaultProps = {
  children: "Loading..."
};

var _templateObject, menuPortalCSS = function(_ref6) {
  var rect = _ref6.rect, offset = _ref6.offset, position = _ref6.position;
  return {
    left: rect.left,
    position: position,
    top: offset,
    width: rect.width,
    zIndex: 1
  };
}, MenuPortal = function(_Component2) {
  _inherits__default.default(MenuPortal, _Component2);
  var _super2 = _createSuper(MenuPortal);
  function MenuPortal() {
    var _this2;
    _classCallCheck__default.default(this, MenuPortal);
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) args[_key2] = arguments[_key2];
    return (_this2 = _super2.call.apply(_super2, [ this ].concat(args))).state = {
      placement: null
    }, _this2.getPortalPlacement = function(_ref7) {
      var placement = _ref7.placement;
      placement !== coercePlacement(_this2.props.menuPlacement) && _this2.setState({
        placement: placement
      });
    }, _this2;
  }
  return _createClass__default.default(MenuPortal, [ {
    key: "render",
    value: function() {
      var _this$props2 = this.props, appendTo = _this$props2.appendTo, children = _this$props2.children, className = _this$props2.className, controlElement = _this$props2.controlElement, cx = _this$props2.cx, innerProps = _this$props2.innerProps, menuPlacement = _this$props2.menuPlacement, position = _this$props2.menuPosition, getStyles = _this$props2.getStyles, isFixed = "fixed" === position;
      if (!appendTo && !isFixed || !controlElement) return null;
      var placement = this.state.placement || coercePlacement(menuPlacement), rect = getBoundingClientObj(controlElement), scrollDistance = isFixed ? 0 : window.pageYOffset, state = {
        offset: rect[placement] + scrollDistance,
        position: position,
        rect: rect
      }, menuWrapper = emotionReact_browser_esm.jsx("div", _extends__default.default({
        css: getStyles("menuPortal", state),
        className: cx({
          "menu-portal": !0
        }, className)
      }, innerProps), children);
      return emotionReact_browser_esm.jsx(PortalPlacementContext.Provider, {
        value: {
          getPortalPlacement: this.getPortalPlacement
        }
      }, appendTo ? reactDom.createPortal(menuWrapper, appendTo) : menuWrapper);
    }
  } ]), MenuPortal;
}(react.Component), containerCSS = function(_ref) {
  var isDisabled = _ref.isDisabled;
  return {
    label: "container",
    direction: _ref.isRtl ? "rtl" : null,
    pointerEvents: isDisabled ? "none" : null,
    position: "relative"
  };
}, SelectContainer = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps, isDisabled = props.isDisabled, isRtl = props.isRtl;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("container", props),
    className: cx({
      "--is-disabled": isDisabled,
      "--is-rtl": isRtl
    }, className)
  }, innerProps), children);
}, valueContainerCSS = function(_ref2) {
  var spacing = _ref2.theme.spacing;
  return {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    padding: "".concat(spacing.baseUnit / 2, "px ").concat(2 * spacing.baseUnit, "px"),
    WebkitOverflowScrolling: "touch",
    position: "relative",
    overflow: "hidden"
  };
}, ValueContainer = function(props) {
  var children = props.children, className = props.className, cx = props.cx, innerProps = props.innerProps, isMulti = props.isMulti, getStyles = props.getStyles, hasValue = props.hasValue;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("valueContainer", props),
    className: cx({
      "value-container": !0,
      "value-container--is-multi": isMulti,
      "value-container--has-value": hasValue
    }, className)
  }, innerProps), children);
}, indicatorsContainerCSS = function() {
  return {
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexShrink: 0
  };
}, IndicatorsContainer = function(props) {
  var children = props.children, className = props.className, cx = props.cx, innerProps = props.innerProps, getStyles = props.getStyles;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("indicatorsContainer", props),
    className: cx({
      indicators: !0
    }, className)
  }, innerProps), children);
};

var _ref2 = {
  name: "8mmkcg",
  styles: "display:inline-block;fill:currentColor;line-height:1;stroke:currentColor;stroke-width:0"
}, Svg = function(_ref) {
  var size = _ref.size, props = _objectWithoutProperties__default.default(_ref, [ "size" ]);
  return emotionReact_browser_esm.jsx("svg", _extends__default.default({
    height: size,
    width: size,
    viewBox: "0 0 20 20",
    "aria-hidden": "true",
    focusable: "false",
    css: _ref2
  }, props));
}, CrossIcon = function(props) {
  return emotionReact_browser_esm.jsx(Svg, _extends__default.default({
    size: 20
  }, props), emotionReact_browser_esm.jsx("path", {
    d: "M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
  }));
}, DownChevron = function(props) {
  return emotionReact_browser_esm.jsx(Svg, _extends__default.default({
    size: 20
  }, props), emotionReact_browser_esm.jsx("path", {
    d: "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
  }));
}, baseCSS = function(_ref3) {
  var isFocused = _ref3.isFocused, _ref3$theme = _ref3.theme, baseUnit = _ref3$theme.spacing.baseUnit, colors = _ref3$theme.colors;
  return {
    label: "indicatorContainer",
    color: isFocused ? colors.neutral60 : colors.neutral20,
    display: "flex",
    padding: 2 * baseUnit,
    transition: "color 150ms",
    ":hover": {
      color: isFocused ? colors.neutral80 : colors.neutral40
    }
  };
}, dropdownIndicatorCSS = baseCSS, DropdownIndicator = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("dropdownIndicator", props),
    className: cx({
      indicator: !0,
      "dropdown-indicator": !0
    }, className)
  }, innerProps), children || emotionReact_browser_esm.jsx(DownChevron, null));
}, clearIndicatorCSS = baseCSS, ClearIndicator = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("clearIndicator", props),
    className: cx({
      indicator: !0,
      "clear-indicator": !0
    }, className)
  }, innerProps), children || emotionReact_browser_esm.jsx(CrossIcon, null));
}, indicatorSeparatorCSS = function(_ref4) {
  var isDisabled = _ref4.isDisabled, _ref4$theme = _ref4.theme, baseUnit = _ref4$theme.spacing.baseUnit, colors = _ref4$theme.colors;
  return {
    label: "indicatorSeparator",
    alignSelf: "stretch",
    backgroundColor: isDisabled ? colors.neutral10 : colors.neutral20,
    marginBottom: 2 * baseUnit,
    marginTop: 2 * baseUnit,
    width: 1
  };
}, IndicatorSeparator = function(props) {
  var className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("span", _extends__default.default({}, innerProps, {
    css: getStyles("indicatorSeparator", props),
    className: cx({
      "indicator-separator": !0
    }, className)
  }));
}, loadingDotAnimations = emotionReact_browser_esm.keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral__default.default([ "\n  0%, 80%, 100% { opacity: 0; }\n  40% { opacity: 1; }\n" ]))), loadingIndicatorCSS = function(_ref5) {
  var isFocused = _ref5.isFocused, size = _ref5.size, _ref5$theme = _ref5.theme, colors = _ref5$theme.colors, baseUnit = _ref5$theme.spacing.baseUnit;
  return {
    label: "loadingIndicator",
    color: isFocused ? colors.neutral60 : colors.neutral20,
    display: "flex",
    padding: 2 * baseUnit,
    transition: "color 150ms",
    alignSelf: "center",
    fontSize: size,
    lineHeight: 1,
    marginRight: size,
    textAlign: "center",
    verticalAlign: "middle"
  };
}, LoadingDot = function(_ref6) {
  var delay = _ref6.delay, offset = _ref6.offset;
  return emotionReact_browser_esm.jsx("span", {
    css: emotionReact_browser_esm.css({
      animation: "".concat(loadingDotAnimations, " 1s ease-in-out ").concat(delay, "ms infinite;"),
      backgroundColor: "currentColor",
      borderRadius: "1em",
      display: "inline-block",
      marginLeft: offset ? "1em" : null,
      height: "1em",
      verticalAlign: "top",
      width: "1em"
    }, "", "")
  });
}, LoadingIndicator = function(props) {
  var className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps, isRtl = props.isRtl;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("loadingIndicator", props),
    className: cx({
      indicator: !0,
      "loading-indicator": !0
    }, className)
  }, innerProps), emotionReact_browser_esm.jsx(LoadingDot, {
    delay: 0,
    offset: isRtl
  }), emotionReact_browser_esm.jsx(LoadingDot, {
    delay: 160,
    offset: !0
  }), emotionReact_browser_esm.jsx(LoadingDot, {
    delay: 320,
    offset: !isRtl
  }));
};

LoadingIndicator.defaultProps = {
  size: 4
};

var css = function(_ref) {
  var isDisabled = _ref.isDisabled, isFocused = _ref.isFocused, _ref$theme = _ref.theme, colors = _ref$theme.colors, borderRadius = _ref$theme.borderRadius, spacing = _ref$theme.spacing;
  return {
    label: "control",
    alignItems: "center",
    backgroundColor: isDisabled ? colors.neutral5 : colors.neutral0,
    borderColor: isDisabled ? colors.neutral10 : isFocused ? colors.primary : colors.neutral20,
    borderRadius: borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: isFocused ? "0 0 0 1px ".concat(colors.primary) : null,
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: spacing.controlHeight,
    outline: "0 !important",
    position: "relative",
    transition: "all 100ms",
    "&:hover": {
      borderColor: isFocused ? colors.primary : colors.neutral30
    }
  };
}, Control = function(props) {
  var children = props.children, cx = props.cx, getStyles = props.getStyles, className = props.className, isDisabled = props.isDisabled, isFocused = props.isFocused, innerRef = props.innerRef, innerProps = props.innerProps, menuIsOpen = props.menuIsOpen;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    ref: innerRef,
    css: getStyles("control", props),
    className: cx({
      control: !0,
      "control--is-disabled": isDisabled,
      "control--is-focused": isFocused,
      "control--menu-is-open": menuIsOpen
    }, className)
  }, innerProps), children);
}, groupCSS = function(_ref) {
  var spacing = _ref.theme.spacing;
  return {
    paddingBottom: 2 * spacing.baseUnit,
    paddingTop: 2 * spacing.baseUnit
  };
}, Group = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, Heading = props.Heading, headingProps = props.headingProps, innerProps = props.innerProps, label = props.label, theme = props.theme, selectProps = props.selectProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("group", props),
    className: cx({
      group: !0
    }, className)
  }, innerProps), emotionReact_browser_esm.jsx(Heading, _extends__default.default({}, headingProps, {
    selectProps: selectProps,
    theme: theme,
    getStyles: getStyles,
    cx: cx
  }), label), emotionReact_browser_esm.jsx("div", null, children));
}, groupHeadingCSS = function(_ref2) {
  var spacing = _ref2.theme.spacing;
  return {
    label: "group",
    color: "#999",
    cursor: "default",
    display: "block",
    fontSize: "75%",
    fontWeight: "500",
    marginBottom: "0.25em",
    paddingLeft: 3 * spacing.baseUnit,
    paddingRight: 3 * spacing.baseUnit,
    textTransform: "uppercase"
  };
}, GroupHeading = function(props) {
  var getStyles = props.getStyles, cx = props.cx, className = props.className, _cleanCommonProps = cleanCommonProps(props);
  _cleanCommonProps.data;
  var innerProps = _objectWithoutProperties__default.default(_cleanCommonProps, [ "data" ]);
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("groupHeading", props),
    className: cx({
      "group-heading": !0
    }, className)
  }, innerProps));
}, inputCSS = function(_ref) {
  var isDisabled = _ref.isDisabled, _ref$theme = _ref.theme, spacing = _ref$theme.spacing, colors = _ref$theme.colors;
  return {
    margin: spacing.baseUnit / 2,
    paddingBottom: spacing.baseUnit / 2,
    paddingTop: spacing.baseUnit / 2,
    visibility: isDisabled ? "hidden" : "visible",
    color: colors.neutral80
  };
}, inputStyle = function(isHidden) {
  return {
    label: "input",
    background: 0,
    border: 0,
    fontSize: "inherit",
    opacity: isHidden ? 0 : 1,
    outline: 0,
    padding: 0,
    color: "inherit"
  };
}, Input = function(props) {
  var className = props.className, cx = props.cx, getStyles = props.getStyles, _cleanCommonProps = cleanCommonProps(props), innerRef = _cleanCommonProps.innerRef, isDisabled = _cleanCommonProps.isDisabled, isHidden = _cleanCommonProps.isHidden, innerProps = _objectWithoutProperties__default.default(_cleanCommonProps, [ "innerRef", "isDisabled", "isHidden" ]);
  return emotionReact_browser_esm.jsx("div", {
    css: getStyles("input", props)
  }, emotionReact_browser_esm.jsx(AutosizeInput__default.default, _extends__default.default({
    className: cx({
      input: !0
    }, className),
    inputRef: innerRef,
    inputStyle: inputStyle(isHidden),
    disabled: isDisabled
  }, innerProps)));
}, multiValueCSS = function(_ref) {
  var _ref$theme = _ref.theme, spacing = _ref$theme.spacing, borderRadius = _ref$theme.borderRadius;
  return {
    label: "multiValue",
    backgroundColor: _ref$theme.colors.neutral10,
    borderRadius: borderRadius / 2,
    display: "flex",
    margin: spacing.baseUnit / 2,
    minWidth: 0
  };
}, multiValueLabelCSS = function(_ref2) {
  var _ref2$theme = _ref2.theme, borderRadius = _ref2$theme.borderRadius, colors = _ref2$theme.colors, cropWithEllipsis = _ref2.cropWithEllipsis;
  return {
    borderRadius: borderRadius / 2,
    color: colors.neutral80,
    fontSize: "85%",
    overflow: "hidden",
    padding: 3,
    paddingLeft: 6,
    textOverflow: cropWithEllipsis ? "ellipsis" : null,
    whiteSpace: "nowrap"
  };
}, multiValueRemoveCSS = function(_ref3) {
  var _ref3$theme = _ref3.theme, spacing = _ref3$theme.spacing, borderRadius = _ref3$theme.borderRadius, colors = _ref3$theme.colors;
  return {
    alignItems: "center",
    borderRadius: borderRadius / 2,
    backgroundColor: _ref3.isFocused && colors.dangerLight,
    display: "flex",
    paddingLeft: spacing.baseUnit,
    paddingRight: spacing.baseUnit,
    ":hover": {
      backgroundColor: colors.dangerLight,
      color: colors.danger
    }
  };
}, MultiValueGeneric = function(_ref4) {
  var children = _ref4.children, innerProps = _ref4.innerProps;
  return emotionReact_browser_esm.jsx("div", innerProps, children);
}, MultiValueContainer = MultiValueGeneric, MultiValueLabel = MultiValueGeneric;

function MultiValueRemove(_ref5) {
  var children = _ref5.children, innerProps = _ref5.innerProps;
  return emotionReact_browser_esm.jsx("div", innerProps, children || emotionReact_browser_esm.jsx(CrossIcon, {
    size: 14
  }));
}

var MultiValue = function(props) {
  var children = props.children, className = props.className, components = props.components, cx = props.cx, data = props.data, getStyles = props.getStyles, innerProps = props.innerProps, isDisabled = props.isDisabled, removeProps = props.removeProps, selectProps = props.selectProps, Container = components.Container, Label = components.Label, Remove = components.Remove;
  return emotionReact_browser_esm.jsx(emotionReact_browser_esm.ClassNames, null, (function(_ref6) {
    var css = _ref6.css, emotionCx = _ref6.cx;
    return emotionReact_browser_esm.jsx(Container, {
      data: data,
      innerProps: _objectSpread2({
        className: emotionCx(css(getStyles("multiValue", props)), cx({
          "multi-value": !0,
          "multi-value--is-disabled": isDisabled
        }, className))
      }, innerProps),
      selectProps: selectProps
    }, emotionReact_browser_esm.jsx(Label, {
      data: data,
      innerProps: {
        className: emotionCx(css(getStyles("multiValueLabel", props)), cx({
          "multi-value__label": !0
        }, className))
      },
      selectProps: selectProps
    }, children), emotionReact_browser_esm.jsx(Remove, {
      data: data,
      innerProps: _objectSpread2({
        className: emotionCx(css(getStyles("multiValueRemove", props)), cx({
          "multi-value__remove": !0
        }, className))
      }, removeProps),
      selectProps: selectProps
    }));
  }));
};

MultiValue.defaultProps = {
  cropWithEllipsis: !0
};

var optionCSS = function(_ref) {
  var isDisabled = _ref.isDisabled, isFocused = _ref.isFocused, isSelected = _ref.isSelected, _ref$theme = _ref.theme, spacing = _ref$theme.spacing, colors = _ref$theme.colors;
  return {
    label: "option",
    backgroundColor: isSelected ? colors.primary : isFocused ? colors.primary25 : "transparent",
    color: isDisabled ? colors.neutral20 : isSelected ? colors.neutral0 : "inherit",
    cursor: "default",
    display: "block",
    fontSize: "inherit",
    padding: "".concat(2 * spacing.baseUnit, "px ").concat(3 * spacing.baseUnit, "px"),
    width: "100%",
    userSelect: "none",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    ":active": {
      backgroundColor: !isDisabled && (isSelected ? colors.primary : colors.primary50)
    }
  };
}, Option = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, isDisabled = props.isDisabled, isFocused = props.isFocused, isSelected = props.isSelected, innerRef = props.innerRef, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("option", props),
    className: cx({
      option: !0,
      "option--is-disabled": isDisabled,
      "option--is-focused": isFocused,
      "option--is-selected": isSelected
    }, className),
    ref: innerRef
  }, innerProps), children);
}, placeholderCSS = function(_ref) {
  var _ref$theme = _ref.theme, spacing = _ref$theme.spacing;
  return {
    label: "placeholder",
    color: _ref$theme.colors.neutral50,
    marginLeft: spacing.baseUnit / 2,
    marginRight: spacing.baseUnit / 2,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  };
}, Placeholder = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("placeholder", props),
    className: cx({
      placeholder: !0
    }, className)
  }, innerProps), children);
}, css$1 = function(_ref) {
  var isDisabled = _ref.isDisabled, _ref$theme = _ref.theme, spacing = _ref$theme.spacing, colors = _ref$theme.colors;
  return {
    label: "singleValue",
    color: isDisabled ? colors.neutral40 : colors.neutral80,
    marginLeft: spacing.baseUnit / 2,
    marginRight: spacing.baseUnit / 2,
    maxWidth: "calc(100% - ".concat(2 * spacing.baseUnit, "px)"),
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    top: "50%",
    transform: "translateY(-50%)"
  };
}, SingleValue = function(props) {
  var children = props.children, className = props.className, cx = props.cx, getStyles = props.getStyles, isDisabled = props.isDisabled, innerProps = props.innerProps;
  return emotionReact_browser_esm.jsx("div", _extends__default.default({
    css: getStyles("singleValue", props),
    className: cx({
      "single-value": !0,
      "single-value--is-disabled": isDisabled
    }, className)
  }, innerProps), children);
}, components = {
  ClearIndicator: ClearIndicator,
  Control: Control,
  DropdownIndicator: DropdownIndicator,
  DownChevron: DownChevron,
  CrossIcon: CrossIcon,
  Group: Group,
  GroupHeading: GroupHeading,
  IndicatorsContainer: IndicatorsContainer,
  IndicatorSeparator: IndicatorSeparator,
  Input: Input,
  LoadingIndicator: LoadingIndicator,
  Menu: Menu,
  MenuList: MenuList,
  MenuPortal: MenuPortal,
  LoadingMessage: LoadingMessage,
  NoOptionsMessage: NoOptionsMessage,
  MultiValue: MultiValue,
  MultiValueContainer: MultiValueContainer,
  MultiValueLabel: MultiValueLabel,
  MultiValueRemove: MultiValueRemove,
  Option: Option,
  Placeholder: Placeholder,
  SelectContainer: SelectContainer,
  SingleValue: SingleValue,
  ValueContainer: ValueContainer
}, defaultComponents = function(props) {
  return _objectSpread2(_objectSpread2({}, components), props.components);
};

exports.MenuPlacer = MenuPlacer, exports._createSuper = _createSuper, exports._objectSpread2 = _objectSpread2, 
exports.classNames = classNames, exports.cleanValue = cleanValue, exports.clearIndicatorCSS = clearIndicatorCSS, 
exports.components = components, exports.containerCSS = containerCSS, exports.css = css, 
exports.css$1 = css$1, exports.defaultComponents = defaultComponents, exports.dropdownIndicatorCSS = dropdownIndicatorCSS, 
exports.groupCSS = groupCSS, exports.groupHeadingCSS = groupHeadingCSS, exports.handleInputChange = handleInputChange, 
exports.indicatorSeparatorCSS = indicatorSeparatorCSS, exports.indicatorsContainerCSS = indicatorsContainerCSS, 
exports.inputCSS = inputCSS, exports.isDocumentElement = isDocumentElement, exports.isMobileDevice = isMobileDevice, 
exports.isTouchCapable = isTouchCapable, exports.loadingIndicatorCSS = loadingIndicatorCSS, 
exports.loadingMessageCSS = loadingMessageCSS, exports.menuCSS = menuCSS, exports.menuListCSS = menuListCSS, 
exports.menuPortalCSS = menuPortalCSS, exports.multiValueCSS = multiValueCSS, exports.multiValueLabelCSS = multiValueLabelCSS, 
exports.multiValueRemoveCSS = multiValueRemoveCSS, exports.noOptionsMessageCSS = noOptionsMessageCSS, 
exports.noop = noop, exports.optionCSS = optionCSS, exports.placeholderCSS = placeholderCSS, 
exports.scrollIntoView = scrollIntoView, exports.supportsPassiveEvents = supportsPassiveEvents, 
exports.valueContainerCSS = valueContainerCSS;
});

var SelectFd7cb895_cjs_prod = createCommonjsModule(function (module, exports) {



function _interopDefault(e) {
  return e && e.__esModule ? e : {
    default: e
  };
}

var _extends__default = _interopDefault(_extends_1), _classCallCheck__default = _interopDefault(classCallCheck), _createClass__default = _interopDefault(createClass), _inherits__default = _interopDefault(inherits), _toConsumableArray__default = _interopDefault(toConsumableArray), React__default = _interopDefault(react), memoizeOne__default = _interopDefault(memoizeOne), _objectWithoutProperties__default = _interopDefault(objectWithoutProperties);

for (var _ref = {
  name: "7pg0cj-a11yText",
  styles: "label:a11yText;z-index:9999;border:0;clip:rect(1px, 1px, 1px, 1px);height:1px;width:1px;position:absolute;overflow:hidden;padding:0;white-space:nowrap"
}, A11yText = function(props) {
  return emotionReact_browser_esm.jsx("span", _extends__default.default({
    css: _ref
  }, props));
}, defaultAriaLiveMessages = {
  guidance: function(props) {
    var isSearchable = props.isSearchable, isMulti = props.isMulti, isDisabled = props.isDisabled, tabSelectsValue = props.tabSelectsValue;
    switch (props.context) {
     case "menu":
      return "Use Up and Down to choose options".concat(isDisabled ? "" : ", press Enter to select the currently focused option", ", press Escape to exit the menu").concat(tabSelectsValue ? ", press Tab to select the option and exit the menu" : "", ".");

     case "input":
      return "".concat(props["aria-label"] || "Select", " is focused ").concat(isSearchable ? ",type to refine list" : "", ", press Down to open the menu, ").concat(isMulti ? " press left to focus selected values" : "");

     case "value":
      return "Use left and right to toggle between focused values, press Backspace to remove the currently focused value";

     default:
      return "";
    }
  },
  onChange: function(props) {
    var action = props.action, _props$label = props.label, label = void 0 === _props$label ? "" : _props$label, isDisabled = props.isDisabled;
    switch (action) {
     case "deselect-option":
     case "pop-value":
     case "remove-value":
      return "option ".concat(label, ", deselected.");

     case "select-option":
      return "option ".concat(label, isDisabled ? " is disabled. Select another option." : ", selected.");

     default:
      return "";
    }
  },
  onFocus: function(props) {
    var context = props.context, _props$focused = props.focused, focused = void 0 === _props$focused ? {} : _props$focused, options = props.options, _props$label2 = props.label, label = void 0 === _props$label2 ? "" : _props$label2, selectValue = props.selectValue, isDisabled = props.isDisabled, isSelected = props.isSelected, getArrayIndex = function(arr, item) {
      return arr && arr.length ? "".concat(arr.indexOf(item) + 1, " of ").concat(arr.length) : "";
    };
    if ("value" === context && selectValue) return "value ".concat(label, " focused, ").concat(getArrayIndex(selectValue, focused), ".");
    if ("menu" === context) {
      var disabled = isDisabled ? " disabled" : "", status = "".concat(isSelected ? "selected" : "focused").concat(disabled);
      return "option ".concat(label, " ").concat(status, ", ").concat(getArrayIndex(options, focused), ".");
    }
    return "";
  },
  onFilter: function(props) {
    var inputValue = props.inputValue, resultsMessage = props.resultsMessage;
    return "".concat(resultsMessage).concat(inputValue ? " for search term " + inputValue : "", ".");
  }
}, LiveRegion = function(props) {
  var ariaSelection = props.ariaSelection, focusedOption = props.focusedOption, focusedValue = props.focusedValue, focusableOptions = props.focusableOptions, isFocused = props.isFocused, selectValue = props.selectValue, selectProps = props.selectProps, ariaLiveMessages = selectProps.ariaLiveMessages, getOptionLabel = selectProps.getOptionLabel, inputValue = selectProps.inputValue, isMulti = selectProps.isMulti, isOptionDisabled = selectProps.isOptionDisabled, isSearchable = selectProps.isSearchable, menuIsOpen = selectProps.menuIsOpen, options = selectProps.options, screenReaderStatus = selectProps.screenReaderStatus, tabSelectsValue = selectProps.tabSelectsValue, ariaLabel = selectProps["aria-label"], ariaLive = selectProps["aria-live"], messages = react.useMemo((function() {
    return indexEa9e225d_cjs_prod._objectSpread2(indexEa9e225d_cjs_prod._objectSpread2({}, defaultAriaLiveMessages), ariaLiveMessages || {});
  }), [ ariaLiveMessages ]), ariaSelected = react.useMemo((function() {
    var val, message = "";
    if (ariaSelection && messages.onChange) {
      var option = ariaSelection.option, removedValue = ariaSelection.removedValue, value = ariaSelection.value, selected = removedValue || option || (val = value, 
      Array.isArray(val) ? null : val), onChangeProps = indexEa9e225d_cjs_prod._objectSpread2({
        isDisabled: selected && isOptionDisabled(selected),
        label: selected ? getOptionLabel(selected) : ""
      }, ariaSelection);
      message = messages.onChange(onChangeProps);
    }
    return message;
  }), [ ariaSelection, isOptionDisabled, getOptionLabel, messages ]), ariaFocused = react.useMemo((function() {
    var focusMsg = "", focused = focusedOption || focusedValue, isSelected = !!(focusedOption && selectValue && selectValue.includes(focusedOption));
    if (focused && messages.onFocus) {
      var onFocusProps = {
        focused: focused,
        label: getOptionLabel(focused),
        isDisabled: isOptionDisabled(focused),
        isSelected: isSelected,
        options: options,
        context: focused === focusedOption ? "menu" : "value",
        selectValue: selectValue
      };
      focusMsg = messages.onFocus(onFocusProps);
    }
    return focusMsg;
  }), [ focusedOption, focusedValue, getOptionLabel, isOptionDisabled, messages, options, selectValue ]), ariaResults = react.useMemo((function() {
    var resultsMsg = "";
    if (menuIsOpen && options.length && messages.onFilter) {
      var resultsMessage = screenReaderStatus({
        count: focusableOptions.length
      });
      resultsMsg = messages.onFilter({
        inputValue: inputValue,
        resultsMessage: resultsMessage
      });
    }
    return resultsMsg;
  }), [ focusableOptions, inputValue, menuIsOpen, messages, options, screenReaderStatus ]), ariaGuidance = react.useMemo((function() {
    var guidanceMsg = "";
    if (messages.guidance) {
      var context = focusedValue ? "value" : menuIsOpen ? "menu" : "input";
      guidanceMsg = messages.guidance({
        "aria-label": ariaLabel,
        context: context,
        isDisabled: focusedOption && isOptionDisabled(focusedOption),
        isMulti: isMulti,
        isSearchable: isSearchable,
        tabSelectsValue: tabSelectsValue
      });
    }
    return guidanceMsg;
  }), [ ariaLabel, focusedOption, focusedValue, isMulti, isOptionDisabled, isSearchable, menuIsOpen, messages, tabSelectsValue ]), ariaContext = "".concat(ariaFocused, " ").concat(ariaResults, " ").concat(ariaGuidance);
  return emotionReact_browser_esm.jsx(A11yText, {
    "aria-live": ariaLive,
    "aria-atomic": "false",
    "aria-relevant": "additions text"
  }, isFocused && emotionReact_browser_esm.jsx(React__default.default.Fragment, null, emotionReact_browser_esm.jsx("span", {
    id: "aria-selection"
  }, ariaSelected), emotionReact_browser_esm.jsx("span", {
    id: "aria-context"
  }, ariaContext)));
}, diacritics = [ {
  base: "A",
  letters: "AⒶＡÀÁÂẦẤẪẨÃĀĂẰẮẴẲȦǠÄǞẢÅǺǍȀȂẠẬẶḀĄȺⱯ"
}, {
  base: "AA",
  letters: "Ꜳ"
}, {
  base: "AE",
  letters: "ÆǼǢ"
}, {
  base: "AO",
  letters: "Ꜵ"
}, {
  base: "AU",
  letters: "Ꜷ"
}, {
  base: "AV",
  letters: "ꜸꜺ"
}, {
  base: "AY",
  letters: "Ꜽ"
}, {
  base: "B",
  letters: "BⒷＢḂḄḆɃƂƁ"
}, {
  base: "C",
  letters: "CⒸＣĆĈĊČÇḈƇȻꜾ"
}, {
  base: "D",
  letters: "DⒹＤḊĎḌḐḒḎĐƋƊƉꝹ"
}, {
  base: "DZ",
  letters: "ǱǄ"
}, {
  base: "Dz",
  letters: "ǲǅ"
}, {
  base: "E",
  letters: "EⒺＥÈÉÊỀẾỄỂẼĒḔḖĔĖËẺĚȄȆẸỆȨḜĘḘḚƐƎ"
}, {
  base: "F",
  letters: "FⒻＦḞƑꝻ"
}, {
  base: "G",
  letters: "GⒼＧǴĜḠĞĠǦĢǤƓꞠꝽꝾ"
}, {
  base: "H",
  letters: "HⒽＨĤḢḦȞḤḨḪĦⱧⱵꞍ"
}, {
  base: "I",
  letters: "IⒾＩÌÍÎĨĪĬİÏḮỈǏȈȊỊĮḬƗ"
}, {
  base: "J",
  letters: "JⒿＪĴɈ"
}, {
  base: "K",
  letters: "KⓀＫḰǨḲĶḴƘⱩꝀꝂꝄꞢ"
}, {
  base: "L",
  letters: "LⓁＬĿĹĽḶḸĻḼḺŁȽⱢⱠꝈꝆꞀ"
}, {
  base: "LJ",
  letters: "Ǉ"
}, {
  base: "Lj",
  letters: "ǈ"
}, {
  base: "M",
  letters: "MⓂＭḾṀṂⱮƜ"
}, {
  base: "N",
  letters: "NⓃＮǸŃÑṄŇṆŅṊṈȠƝꞐꞤ"
}, {
  base: "NJ",
  letters: "Ǌ"
}, {
  base: "Nj",
  letters: "ǋ"
}, {
  base: "O",
  letters: "OⓄＯÒÓÔỒỐỖỔÕṌȬṎŌṐṒŎȮȰÖȪỎŐǑȌȎƠỜỚỠỞỢỌỘǪǬØǾƆƟꝊꝌ"
}, {
  base: "OI",
  letters: "Ƣ"
}, {
  base: "OO",
  letters: "Ꝏ"
}, {
  base: "OU",
  letters: "Ȣ"
}, {
  base: "P",
  letters: "PⓅＰṔṖƤⱣꝐꝒꝔ"
}, {
  base: "Q",
  letters: "QⓆＱꝖꝘɊ"
}, {
  base: "R",
  letters: "RⓇＲŔṘŘȐȒṚṜŖṞɌⱤꝚꞦꞂ"
}, {
  base: "S",
  letters: "SⓈＳẞŚṤŜṠŠṦṢṨȘŞⱾꞨꞄ"
}, {
  base: "T",
  letters: "TⓉＴṪŤṬȚŢṰṮŦƬƮȾꞆ"
}, {
  base: "TZ",
  letters: "Ꜩ"
}, {
  base: "U",
  letters: "UⓊＵÙÚÛŨṸŪṺŬÜǛǗǕǙỦŮŰǓȔȖƯỪỨỮỬỰỤṲŲṶṴɄ"
}, {
  base: "V",
  letters: "VⓋＶṼṾƲꝞɅ"
}, {
  base: "VY",
  letters: "Ꝡ"
}, {
  base: "W",
  letters: "WⓌＷẀẂŴẆẄẈⱲ"
}, {
  base: "X",
  letters: "XⓍＸẊẌ"
}, {
  base: "Y",
  letters: "YⓎＹỲÝŶỸȲẎŸỶỴƳɎỾ"
}, {
  base: "Z",
  letters: "ZⓏＺŹẐŻŽẒẔƵȤⱿⱫꝢ"
}, {
  base: "a",
  letters: "aⓐａẚàáâầấẫẩãāăằắẵẳȧǡäǟảåǻǎȁȃạậặḁąⱥɐ"
}, {
  base: "aa",
  letters: "ꜳ"
}, {
  base: "ae",
  letters: "æǽǣ"
}, {
  base: "ao",
  letters: "ꜵ"
}, {
  base: "au",
  letters: "ꜷ"
}, {
  base: "av",
  letters: "ꜹꜻ"
}, {
  base: "ay",
  letters: "ꜽ"
}, {
  base: "b",
  letters: "bⓑｂḃḅḇƀƃɓ"
}, {
  base: "c",
  letters: "cⓒｃćĉċčçḉƈȼꜿↄ"
}, {
  base: "d",
  letters: "dⓓｄḋďḍḑḓḏđƌɖɗꝺ"
}, {
  base: "dz",
  letters: "ǳǆ"
}, {
  base: "e",
  letters: "eⓔｅèéêềếễểẽēḕḗĕėëẻěȅȇẹệȩḝęḙḛɇɛǝ"
}, {
  base: "f",
  letters: "fⓕｆḟƒꝼ"
}, {
  base: "g",
  letters: "gⓖｇǵĝḡğġǧģǥɠꞡᵹꝿ"
}, {
  base: "h",
  letters: "hⓗｈĥḣḧȟḥḩḫẖħⱨⱶɥ"
}, {
  base: "hv",
  letters: "ƕ"
}, {
  base: "i",
  letters: "iⓘｉìíîĩīĭïḯỉǐȉȋịįḭɨı"
}, {
  base: "j",
  letters: "jⓙｊĵǰɉ"
}, {
  base: "k",
  letters: "kⓚｋḱǩḳķḵƙⱪꝁꝃꝅꞣ"
}, {
  base: "l",
  letters: "lⓛｌŀĺľḷḹļḽḻſłƚɫⱡꝉꞁꝇ"
}, {
  base: "lj",
  letters: "ǉ"
}, {
  base: "m",
  letters: "mⓜｍḿṁṃɱɯ"
}, {
  base: "n",
  letters: "nⓝｎǹńñṅňṇņṋṉƞɲŉꞑꞥ"
}, {
  base: "nj",
  letters: "ǌ"
}, {
  base: "o",
  letters: "oⓞｏòóôồốỗổõṍȭṏōṑṓŏȯȱöȫỏőǒȍȏơờớỡởợọộǫǭøǿɔꝋꝍɵ"
}, {
  base: "oi",
  letters: "ƣ"
}, {
  base: "ou",
  letters: "ȣ"
}, {
  base: "oo",
  letters: "ꝏ"
}, {
  base: "p",
  letters: "pⓟｐṕṗƥᵽꝑꝓꝕ"
}, {
  base: "q",
  letters: "qⓠｑɋꝗꝙ"
}, {
  base: "r",
  letters: "rⓡｒŕṙřȑȓṛṝŗṟɍɽꝛꞧꞃ"
}, {
  base: "s",
  letters: "sⓢｓßśṥŝṡšṧṣṩșşȿꞩꞅẛ"
}, {
  base: "t",
  letters: "tⓣｔṫẗťṭțţṱṯŧƭʈⱦꞇ"
}, {
  base: "tz",
  letters: "ꜩ"
}, {
  base: "u",
  letters: "uⓤｕùúûũṹūṻŭüǜǘǖǚủůűǔȕȗưừứữửựụṳųṷṵʉ"
}, {
  base: "v",
  letters: "vⓥｖṽṿʋꝟʌ"
}, {
  base: "vy",
  letters: "ꝡ"
}, {
  base: "w",
  letters: "wⓦｗẁẃŵẇẅẘẉⱳ"
}, {
  base: "x",
  letters: "xⓧｘẋẍ"
}, {
  base: "y",
  letters: "yⓨｙỳýŷỹȳẏÿỷẙỵƴɏỿ"
}, {
  base: "z",
  letters: "zⓩｚźẑżžẓẕƶȥɀⱬꝣ"
} ], anyDiacritic = new RegExp("[" + diacritics.map((function(d) {
  return d.letters;
})).join("") + "]", "g"), diacriticToBase = {}, i = 0; i < diacritics.length; i++) for (var diacritic = diacritics[i], j = 0; j < diacritic.letters.length; j++) diacriticToBase[diacritic.letters[j]] = diacritic.base;

var stripDiacritics = function(str) {
  return str.replace(anyDiacritic, (function(match) {
    return diacriticToBase[match];
  }));
}, memoizedStripDiacriticsForInput = memoizeOne__default.default(stripDiacritics), trimString = function(str) {
  return str.replace(/^\s+|\s+$/g, "");
}, defaultStringify = function(option) {
  return "".concat(option.label, " ").concat(option.value);
}, createFilter = function(config) {
  return function(option, rawInput) {
    var _ignoreCase$ignoreAcc = indexEa9e225d_cjs_prod._objectSpread2({
      ignoreCase: !0,
      ignoreAccents: !0,
      stringify: defaultStringify,
      trim: !0,
      matchFrom: "any"
    }, config), ignoreCase = _ignoreCase$ignoreAcc.ignoreCase, ignoreAccents = _ignoreCase$ignoreAcc.ignoreAccents, stringify = _ignoreCase$ignoreAcc.stringify, trim = _ignoreCase$ignoreAcc.trim, matchFrom = _ignoreCase$ignoreAcc.matchFrom, input = trim ? trimString(rawInput) : rawInput, candidate = trim ? trimString(stringify(option)) : stringify(option);
    return ignoreCase && (input = input.toLowerCase(), candidate = candidate.toLowerCase()), 
    ignoreAccents && (input = memoizedStripDiacriticsForInput(input), candidate = stripDiacritics(candidate)), 
    "start" === matchFrom ? candidate.substr(0, input.length) === input : candidate.indexOf(input) > -1;
  };
};

function DummyInput(_ref) {
  _ref.in, _ref.out, _ref.onExited, _ref.appear, _ref.enter, _ref.exit;
  var innerRef = _ref.innerRef;
  _ref.emotion;
  var props = _objectWithoutProperties__default.default(_ref, [ "in", "out", "onExited", "appear", "enter", "exit", "innerRef", "emotion" ]);
  return emotionReact_browser_esm.jsx("input", _extends__default.default({
    ref: innerRef
  }, props, {
    css: emotionReact_browser_esm.css({
      label: "dummyInput",
      background: 0,
      border: 0,
      fontSize: "inherit",
      outline: 0,
      padding: 0,
      width: 1,
      color: "transparent",
      left: -100,
      opacity: 0,
      position: "relative",
      transform: "scale(0)"
    }, "", "")
  }));
}

var cancelScroll = function(event) {
  event.preventDefault(), event.stopPropagation();
};

function useScrollCapture(_ref) {
  var isEnabled = _ref.isEnabled, onBottomArrive = _ref.onBottomArrive, onBottomLeave = _ref.onBottomLeave, onTopArrive = _ref.onTopArrive, onTopLeave = _ref.onTopLeave, isBottom = react.useRef(!1), isTop = react.useRef(!1), touchStart = react.useRef(0), scrollTarget = react.useRef(null), handleEventDelta = react.useCallback((function(event, delta) {
    if (null !== scrollTarget.current) {
      var _scrollTarget$current = scrollTarget.current, scrollTop = _scrollTarget$current.scrollTop, scrollHeight = _scrollTarget$current.scrollHeight, clientHeight = _scrollTarget$current.clientHeight, target = scrollTarget.current, isDeltaPositive = delta > 0, availableScroll = scrollHeight - clientHeight - scrollTop, shouldCancelScroll = !1;
      availableScroll > delta && isBottom.current && (onBottomLeave && onBottomLeave(event), 
      isBottom.current = !1), isDeltaPositive && isTop.current && (onTopLeave && onTopLeave(event), 
      isTop.current = !1), isDeltaPositive && delta > availableScroll ? (onBottomArrive && !isBottom.current && onBottomArrive(event), 
      target.scrollTop = scrollHeight, shouldCancelScroll = !0, isBottom.current = !0) : !isDeltaPositive && -delta > scrollTop && (onTopArrive && !isTop.current && onTopArrive(event), 
      target.scrollTop = 0, shouldCancelScroll = !0, isTop.current = !0), shouldCancelScroll && cancelScroll(event);
    }
  }), []), onWheel = react.useCallback((function(event) {
    handleEventDelta(event, event.deltaY);
  }), [ handleEventDelta ]), onTouchStart = react.useCallback((function(event) {
    touchStart.current = event.changedTouches[0].clientY;
  }), []), onTouchMove = react.useCallback((function(event) {
    var deltaY = touchStart.current - event.changedTouches[0].clientY;
    handleEventDelta(event, deltaY);
  }), [ handleEventDelta ]), startListening = react.useCallback((function(el) {
    if (el) {
      var notPassive = !!indexEa9e225d_cjs_prod.supportsPassiveEvents && {
        passive: !1
      };
      "function" == typeof el.addEventListener && el.addEventListener("wheel", onWheel, notPassive), 
      "function" == typeof el.addEventListener && el.addEventListener("touchstart", onTouchStart, notPassive), 
      "function" == typeof el.addEventListener && el.addEventListener("touchmove", onTouchMove, notPassive);
    }
  }), [ onTouchMove, onTouchStart, onWheel ]), stopListening = react.useCallback((function(el) {
    el && ("function" == typeof el.removeEventListener && el.removeEventListener("wheel", onWheel, !1), 
    "function" == typeof el.removeEventListener && el.removeEventListener("touchstart", onTouchStart, !1), 
    "function" == typeof el.removeEventListener && el.removeEventListener("touchmove", onTouchMove, !1));
  }), [ onTouchMove, onTouchStart, onWheel ]);
  return react.useEffect((function() {
    if (isEnabled) {
      var element = scrollTarget.current;
      return startListening(element), function() {
        stopListening(element);
      };
    }
  }), [ isEnabled, startListening, stopListening ]), function(element) {
    scrollTarget.current = element;
  };
}

var STYLE_KEYS = [ "boxSizing", "height", "overflow", "paddingRight", "position" ], LOCK_STYLES = {
  boxSizing: "border-box",
  overflow: "hidden",
  position: "relative",
  height: "100%"
};

function preventTouchMove(e) {
  e.preventDefault();
}

function allowTouchMove(e) {
  e.stopPropagation();
}

function preventInertiaScroll() {
  var top = this.scrollTop, totalScroll = this.scrollHeight, currentScroll = top + this.offsetHeight;
  0 === top ? this.scrollTop = 1 : currentScroll === totalScroll && (this.scrollTop = top - 1);
}

function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints;
}

var canUseDOM = !("undefined" == typeof window || !window.document || !window.document.createElement), activeScrollLocks = 0, listenerOptions = {
  capture: !1,
  passive: !1
};

function useScrollLock(_ref) {
  var isEnabled = _ref.isEnabled, _ref$accountForScroll = _ref.accountForScrollbars, accountForScrollbars = void 0 === _ref$accountForScroll || _ref$accountForScroll, originalStyles = react.useRef({}), scrollTarget = react.useRef(null), addScrollLock = react.useCallback((function(touchScrollTarget) {
    if (canUseDOM) {
      var target = document.body, targetStyle = target && target.style;
      if (accountForScrollbars && STYLE_KEYS.forEach((function(key) {
        var val = targetStyle && targetStyle[key];
        originalStyles.current[key] = val;
      })), accountForScrollbars && activeScrollLocks < 1) {
        var currentPadding = parseInt(originalStyles.current.paddingRight, 10) || 0, clientWidth = document.body ? document.body.clientWidth : 0, adjustedPadding = window.innerWidth - clientWidth + currentPadding || 0;
        Object.keys(LOCK_STYLES).forEach((function(key) {
          var val = LOCK_STYLES[key];
          targetStyle && (targetStyle[key] = val);
        })), targetStyle && (targetStyle.paddingRight = "".concat(adjustedPadding, "px"));
      }
      target && isTouchDevice() && (target.addEventListener("touchmove", preventTouchMove, listenerOptions), 
      touchScrollTarget && (touchScrollTarget.addEventListener("touchstart", preventInertiaScroll, listenerOptions), 
      touchScrollTarget.addEventListener("touchmove", allowTouchMove, listenerOptions))), 
      activeScrollLocks += 1;
    }
  }), []), removeScrollLock = react.useCallback((function(touchScrollTarget) {
    if (canUseDOM) {
      var target = document.body, targetStyle = target && target.style;
      activeScrollLocks = Math.max(activeScrollLocks - 1, 0), accountForScrollbars && activeScrollLocks < 1 && STYLE_KEYS.forEach((function(key) {
        var val = originalStyles.current[key];
        targetStyle && (targetStyle[key] = val);
      })), target && isTouchDevice() && (target.removeEventListener("touchmove", preventTouchMove, listenerOptions), 
      touchScrollTarget && (touchScrollTarget.removeEventListener("touchstart", preventInertiaScroll, listenerOptions), 
      touchScrollTarget.removeEventListener("touchmove", allowTouchMove, listenerOptions)));
    }
  }), []);
  return react.useEffect((function() {
    if (isEnabled) {
      var element = scrollTarget.current;
      return addScrollLock(element), function() {
        removeScrollLock(element);
      };
    }
  }), [ isEnabled, addScrollLock, removeScrollLock ]), function(element) {
    scrollTarget.current = element;
  };
}

var blurSelectInput = function() {
  return document.activeElement && document.activeElement.blur();
}, _ref2 = {
  name: "1kfdb0e",
  styles: "position:fixed;left:0;bottom:0;right:0;top:0"
};

function ScrollManager(_ref) {
  var children = _ref.children, lockEnabled = _ref.lockEnabled, _ref$captureEnabled = _ref.captureEnabled, setScrollCaptureTarget = useScrollCapture({
    isEnabled: void 0 === _ref$captureEnabled || _ref$captureEnabled,
    onBottomArrive: _ref.onBottomArrive,
    onBottomLeave: _ref.onBottomLeave,
    onTopArrive: _ref.onTopArrive,
    onTopLeave: _ref.onTopLeave
  }), setScrollLockTarget = useScrollLock({
    isEnabled: lockEnabled
  });
  return emotionReact_browser_esm.jsx(React__default.default.Fragment, null, lockEnabled && emotionReact_browser_esm.jsx("div", {
    onClick: blurSelectInput,
    css: _ref2
  }), children((function(element) {
    setScrollCaptureTarget(element), setScrollLockTarget(element);
  })));
}

var formatGroupLabel = function(group) {
  return group.label;
}, getOptionLabel = function(option) {
  return option.label;
}, getOptionValue = function(option) {
  return option.value;
}, isOptionDisabled = function(option) {
  return !!option.isDisabled;
}, defaultStyles = {
  clearIndicator: indexEa9e225d_cjs_prod.clearIndicatorCSS,
  container: indexEa9e225d_cjs_prod.containerCSS,
  control: indexEa9e225d_cjs_prod.css,
  dropdownIndicator: indexEa9e225d_cjs_prod.dropdownIndicatorCSS,
  group: indexEa9e225d_cjs_prod.groupCSS,
  groupHeading: indexEa9e225d_cjs_prod.groupHeadingCSS,
  indicatorsContainer: indexEa9e225d_cjs_prod.indicatorsContainerCSS,
  indicatorSeparator: indexEa9e225d_cjs_prod.indicatorSeparatorCSS,
  input: indexEa9e225d_cjs_prod.inputCSS,
  loadingIndicator: indexEa9e225d_cjs_prod.loadingIndicatorCSS,
  loadingMessage: indexEa9e225d_cjs_prod.loadingMessageCSS,
  menu: indexEa9e225d_cjs_prod.menuCSS,
  menuList: indexEa9e225d_cjs_prod.menuListCSS,
  menuPortal: indexEa9e225d_cjs_prod.menuPortalCSS,
  multiValue: indexEa9e225d_cjs_prod.multiValueCSS,
  multiValueLabel: indexEa9e225d_cjs_prod.multiValueLabelCSS,
  multiValueRemove: indexEa9e225d_cjs_prod.multiValueRemoveCSS,
  noOptionsMessage: indexEa9e225d_cjs_prod.noOptionsMessageCSS,
  option: indexEa9e225d_cjs_prod.optionCSS,
  placeholder: indexEa9e225d_cjs_prod.placeholderCSS,
  singleValue: indexEa9e225d_cjs_prod.css$1,
  valueContainer: indexEa9e225d_cjs_prod.valueContainerCSS
};

function mergeStyles(source) {
  var target = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {}, styles = indexEa9e225d_cjs_prod._objectSpread2({}, source);
  return Object.keys(target).forEach((function(key) {
    source[key] ? styles[key] = function(rsCss, props) {
      return target[key](source[key](rsCss, props), props);
    } : styles[key] = target[key];
  })), styles;
}

var colors = {
  primary: "#2684FF",
  primary75: "#4C9AFF",
  primary50: "#B2D4FF",
  primary25: "#DEEBFF",
  danger: "#DE350B",
  dangerLight: "#FFBDAD",
  neutral0: "hsl(0, 0%, 100%)",
  neutral5: "hsl(0, 0%, 95%)",
  neutral10: "hsl(0, 0%, 90%)",
  neutral20: "hsl(0, 0%, 80%)",
  neutral30: "hsl(0, 0%, 70%)",
  neutral40: "hsl(0, 0%, 60%)",
  neutral50: "hsl(0, 0%, 50%)",
  neutral60: "hsl(0, 0%, 40%)",
  neutral70: "hsl(0, 0%, 30%)",
  neutral80: "hsl(0, 0%, 20%)",
  neutral90: "hsl(0, 0%, 10%)"
}, borderRadius = 4, baseUnit = 4, controlHeight = 38, menuGutter = 2 * baseUnit, spacing = {
  baseUnit: baseUnit,
  controlHeight: controlHeight,
  menuGutter: menuGutter
}, defaultTheme = {
  borderRadius: borderRadius,
  colors: colors,
  spacing: spacing
}, defaultProps = {
  "aria-live": "polite",
  backspaceRemovesValue: !0,
  blurInputOnSelect: indexEa9e225d_cjs_prod.isTouchCapable(),
  captureMenuScroll: !indexEa9e225d_cjs_prod.isTouchCapable(),
  closeMenuOnSelect: !0,
  closeMenuOnScroll: !1,
  components: {},
  controlShouldRenderValue: !0,
  escapeClearsValue: !1,
  filterOption: createFilter(),
  formatGroupLabel: formatGroupLabel,
  getOptionLabel: getOptionLabel,
  getOptionValue: getOptionValue,
  isDisabled: !1,
  isLoading: !1,
  isMulti: !1,
  isRtl: !1,
  isSearchable: !0,
  isOptionDisabled: isOptionDisabled,
  loadingMessage: function() {
    return "Loading...";
  },
  maxMenuHeight: 300,
  minMenuHeight: 140,
  menuIsOpen: !1,
  menuPlacement: "bottom",
  menuPosition: "absolute",
  menuShouldBlockScroll: !1,
  menuShouldScrollIntoView: !indexEa9e225d_cjs_prod.isMobileDevice(),
  noOptionsMessage: function() {
    return "No options";
  },
  openMenuOnFocus: !1,
  openMenuOnClick: !0,
  options: [],
  pageSize: 5,
  placeholder: "Select...",
  screenReaderStatus: function(_ref) {
    var count = _ref.count;
    return "".concat(count, " result").concat(1 !== count ? "s" : "", " available");
  },
  styles: {},
  tabIndex: "0",
  tabSelectsValue: !0
};

function toCategorizedOption(props, option, selectValue, index) {
  return {
    type: "option",
    data: option,
    isDisabled: _isOptionDisabled(props, option, selectValue),
    isSelected: _isOptionSelected(props, option, selectValue),
    label: getOptionLabel$1(props, option),
    value: getOptionValue$1(props, option),
    index: index
  };
}

function buildCategorizedOptions(props, selectValue) {
  return props.options.map((function(groupOrOption, groupOrOptionIndex) {
    if (groupOrOption.options) {
      var categorizedOptions = groupOrOption.options.map((function(option, optionIndex) {
        return toCategorizedOption(props, option, selectValue, optionIndex);
      })).filter((function(categorizedOption) {
        return isFocusable(props, categorizedOption);
      }));
      return categorizedOptions.length > 0 ? {
        type: "group",
        data: groupOrOption,
        options: categorizedOptions,
        index: groupOrOptionIndex
      } : void 0;
    }
    var categorizedOption = toCategorizedOption(props, groupOrOption, selectValue, groupOrOptionIndex);
    return isFocusable(props, categorizedOption) ? categorizedOption : void 0;
  })).filter((function(categorizedOption) {
    return !!categorizedOption;
  }));
}

function buildFocusableOptionsFromCategorizedOptions(categorizedOptions) {
  return categorizedOptions.reduce((function(optionsAccumulator, categorizedOption) {
    return "group" === categorizedOption.type ? optionsAccumulator.push.apply(optionsAccumulator, _toConsumableArray__default.default(categorizedOption.options.map((function(option) {
      return option.data;
    })))) : optionsAccumulator.push(categorizedOption.data), optionsAccumulator;
  }), []);
}

function buildFocusableOptions(props, selectValue) {
  return buildFocusableOptionsFromCategorizedOptions(buildCategorizedOptions(props, selectValue));
}

function isFocusable(props, categorizedOption) {
  var _props$inputValue = props.inputValue, inputValue = void 0 === _props$inputValue ? "" : _props$inputValue, data = categorizedOption.data, isSelected = categorizedOption.isSelected, label = categorizedOption.label, value = categorizedOption.value;
  return (!shouldHideSelectedOptions(props) || !isSelected) && _filterOption(props, {
    label: label,
    value: value,
    data: data
  }, inputValue);
}

function getNextFocusedValue(state, nextSelectValue) {
  var focusedValue = state.focusedValue, lastFocusedIndex = state.selectValue.indexOf(focusedValue);
  if (lastFocusedIndex > -1) {
    if (nextSelectValue.indexOf(focusedValue) > -1) return focusedValue;
    if (lastFocusedIndex < nextSelectValue.length) return nextSelectValue[lastFocusedIndex];
  }
  return null;
}

function getNextFocusedOption(state, options) {
  var lastFocusedOption = state.focusedOption;
  return lastFocusedOption && options.indexOf(lastFocusedOption) > -1 ? lastFocusedOption : options[0];
}

var getOptionLabel$1 = function(props, data) {
  return props.getOptionLabel(data);
}, getOptionValue$1 = function(props, data) {
  return props.getOptionValue(data);
};

function _isOptionDisabled(props, option, selectValue) {
  return "function" == typeof props.isOptionDisabled && props.isOptionDisabled(option, selectValue);
}

function _isOptionSelected(props, option, selectValue) {
  if (selectValue.indexOf(option) > -1) return !0;
  if ("function" == typeof props.isOptionSelected) return props.isOptionSelected(option, selectValue);
  var candidate = getOptionValue$1(props, option);
  return selectValue.some((function(i) {
    return getOptionValue$1(props, i) === candidate;
  }));
}

function _filterOption(props, option, inputValue) {
  return !props.filterOption || props.filterOption(option, inputValue);
}

var shouldHideSelectedOptions = function(props) {
  var hideSelectedOptions = props.hideSelectedOptions, isMulti = props.isMulti;
  return void 0 === hideSelectedOptions ? isMulti : hideSelectedOptions;
}, instanceId = 1, Select = function(_Component) {
  _inherits__default.default(Select, _Component);
  var _super = indexEa9e225d_cjs_prod._createSuper(Select);
  function Select(_props) {
    var _this;
    return _classCallCheck__default.default(this, Select), (_this = _super.call(this, _props)).state = {
      ariaSelection: null,
      focusedOption: null,
      focusedValue: null,
      inputIsHidden: !1,
      isFocused: !1,
      selectValue: [],
      clearFocusValueOnUpdate: !1,
      inputIsHiddenAfterUpdate: void 0,
      prevProps: void 0
    }, _this.blockOptionHover = !1, _this.isComposing = !1, _this.commonProps = void 0, 
    _this.initialTouchX = 0, _this.initialTouchY = 0, _this.instancePrefix = "", _this.openAfterFocus = !1, 
    _this.scrollToFocusedOptionOnUpdate = !1, _this.userIsDragging = void 0, _this.controlRef = null, 
    _this.getControlRef = function(ref) {
      _this.controlRef = ref;
    }, _this.focusedOptionRef = null, _this.getFocusedOptionRef = function(ref) {
      _this.focusedOptionRef = ref;
    }, _this.menuListRef = null, _this.getMenuListRef = function(ref) {
      _this.menuListRef = ref;
    }, _this.inputRef = null, _this.getInputRef = function(ref) {
      _this.inputRef = ref;
    }, _this.focus = _this.focusInput, _this.blur = _this.blurInput, _this.onChange = function(newValue, actionMeta) {
      var _this$props = _this.props, onChange = _this$props.onChange, name = _this$props.name;
      actionMeta.name = name, _this.ariaOnChange(newValue, actionMeta), onChange(newValue, actionMeta);
    }, _this.setValue = function(newValue) {
      var action = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "set-value", option = arguments.length > 2 ? arguments[2] : void 0, _this$props2 = _this.props, closeMenuOnSelect = _this$props2.closeMenuOnSelect, isMulti = _this$props2.isMulti;
      _this.onInputChange("", {
        action: "set-value"
      }), closeMenuOnSelect && (_this.setState({
        inputIsHiddenAfterUpdate: !isMulti
      }), _this.onMenuClose()), _this.setState({
        clearFocusValueOnUpdate: !0
      }), _this.onChange(newValue, {
        action: action,
        option: option
      });
    }, _this.selectOption = function(newValue) {
      var _this$props3 = _this.props, blurInputOnSelect = _this$props3.blurInputOnSelect, isMulti = _this$props3.isMulti, name = _this$props3.name, selectValue = _this.state.selectValue, deselected = isMulti && _this.isOptionSelected(newValue, selectValue), isDisabled = _this.isOptionDisabled(newValue, selectValue);
      if (deselected) {
        var candidate = _this.getOptionValue(newValue);
        _this.setValue(selectValue.filter((function(i) {
          return _this.getOptionValue(i) !== candidate;
        })), "deselect-option", newValue);
      } else {
        if (isDisabled) return void _this.ariaOnChange(newValue, {
          action: "select-option",
          name: name
        });
        isMulti ? _this.setValue([].concat(_toConsumableArray__default.default(selectValue), [ newValue ]), "select-option", newValue) : _this.setValue(newValue, "select-option");
      }
      blurInputOnSelect && _this.blurInput();
    }, _this.removeValue = function(removedValue) {
      var isMulti = _this.props.isMulti, selectValue = _this.state.selectValue, candidate = _this.getOptionValue(removedValue), newValueArray = selectValue.filter((function(i) {
        return _this.getOptionValue(i) !== candidate;
      })), newValue = isMulti ? newValueArray : newValueArray[0] || null;
      _this.onChange(newValue, {
        action: "remove-value",
        removedValue: removedValue
      }), _this.focusInput();
    }, _this.clearValue = function() {
      var selectValue = _this.state.selectValue;
      _this.onChange(_this.props.isMulti ? [] : null, {
        action: "clear",
        removedValues: selectValue
      });
    }, _this.popValue = function() {
      var isMulti = _this.props.isMulti, selectValue = _this.state.selectValue, lastSelectedValue = selectValue[selectValue.length - 1], newValueArray = selectValue.slice(0, selectValue.length - 1), newValue = isMulti ? newValueArray : newValueArray[0] || null;
      _this.onChange(newValue, {
        action: "pop-value",
        removedValue: lastSelectedValue
      });
    }, _this.getValue = function() {
      return _this.state.selectValue;
    }, _this.cx = function() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      return indexEa9e225d_cjs_prod.classNames.apply(void 0, [ _this.props.classNamePrefix ].concat(args));
    }, _this.getOptionLabel = function(data) {
      return getOptionLabel$1(_this.props, data);
    }, _this.getOptionValue = function(data) {
      return getOptionValue$1(_this.props, data);
    }, _this.getStyles = function(key, props) {
      var base = defaultStyles[key](props);
      base.boxSizing = "border-box";
      var custom = _this.props.styles[key];
      return custom ? custom(base, props) : base;
    }, _this.getElementId = function(element) {
      return "".concat(_this.instancePrefix, "-").concat(element);
    }, _this.getComponents = function() {
      return indexEa9e225d_cjs_prod.defaultComponents(_this.props);
    }, _this.buildCategorizedOptions = function() {
      return buildCategorizedOptions(_this.props, _this.state.selectValue);
    }, _this.getCategorizedOptions = function() {
      return _this.props.menuIsOpen ? _this.buildCategorizedOptions() : [];
    }, _this.buildFocusableOptions = function() {
      return buildFocusableOptionsFromCategorizedOptions(_this.buildCategorizedOptions());
    }, _this.getFocusableOptions = function() {
      return _this.props.menuIsOpen ? _this.buildFocusableOptions() : [];
    }, _this.ariaOnChange = function(value, actionMeta) {
      _this.setState({
        ariaSelection: indexEa9e225d_cjs_prod._objectSpread2({
          value: value
        }, actionMeta)
      });
    }, _this.onMenuMouseDown = function(event) {
      0 === event.button && (event.stopPropagation(), event.preventDefault(), _this.focusInput());
    }, _this.onMenuMouseMove = function(event) {
      _this.blockOptionHover = !1;
    }, _this.onControlMouseDown = function(event) {
      var openMenuOnClick = _this.props.openMenuOnClick;
      _this.state.isFocused ? _this.props.menuIsOpen ? "INPUT" !== event.target.tagName && "TEXTAREA" !== event.target.tagName && _this.onMenuClose() : openMenuOnClick && _this.openMenu("first") : (openMenuOnClick && (_this.openAfterFocus = !0), 
      _this.focusInput()), "INPUT" !== event.target.tagName && "TEXTAREA" !== event.target.tagName && event.preventDefault();
    }, _this.onDropdownIndicatorMouseDown = function(event) {
      if (!(event && "mousedown" === event.type && 0 !== event.button || _this.props.isDisabled)) {
        var _this$props4 = _this.props, isMulti = _this$props4.isMulti, menuIsOpen = _this$props4.menuIsOpen;
        _this.focusInput(), menuIsOpen ? (_this.setState({
          inputIsHiddenAfterUpdate: !isMulti
        }), _this.onMenuClose()) : _this.openMenu("first"), event.preventDefault(), event.stopPropagation();
      }
    }, _this.onClearIndicatorMouseDown = function(event) {
      event && "mousedown" === event.type && 0 !== event.button || (_this.clearValue(), 
      event.stopPropagation(), _this.openAfterFocus = !1, "touchend" === event.type ? _this.focusInput() : setTimeout((function() {
        return _this.focusInput();
      })));
    }, _this.onScroll = function(event) {
      "boolean" == typeof _this.props.closeMenuOnScroll ? event.target instanceof HTMLElement && indexEa9e225d_cjs_prod.isDocumentElement(event.target) && _this.props.onMenuClose() : "function" == typeof _this.props.closeMenuOnScroll && _this.props.closeMenuOnScroll(event) && _this.props.onMenuClose();
    }, _this.onCompositionStart = function() {
      _this.isComposing = !0;
    }, _this.onCompositionEnd = function() {
      _this.isComposing = !1;
    }, _this.onTouchStart = function(_ref2) {
      var touches = _ref2.touches, touch = touches && touches.item(0);
      touch && (_this.initialTouchX = touch.clientX, _this.initialTouchY = touch.clientY, 
      _this.userIsDragging = !1);
    }, _this.onTouchMove = function(_ref3) {
      var touches = _ref3.touches, touch = touches && touches.item(0);
      if (touch) {
        var deltaX = Math.abs(touch.clientX - _this.initialTouchX), deltaY = Math.abs(touch.clientY - _this.initialTouchY);
        _this.userIsDragging = deltaX > 5 || deltaY > 5;
      }
    }, _this.onTouchEnd = function(event) {
      _this.userIsDragging || (_this.controlRef && !_this.controlRef.contains(event.target) && _this.menuListRef && !_this.menuListRef.contains(event.target) && _this.blurInput(), 
      _this.initialTouchX = 0, _this.initialTouchY = 0);
    }, _this.onControlTouchEnd = function(event) {
      _this.userIsDragging || _this.onControlMouseDown(event);
    }, _this.onClearIndicatorTouchEnd = function(event) {
      _this.userIsDragging || _this.onClearIndicatorMouseDown(event);
    }, _this.onDropdownIndicatorTouchEnd = function(event) {
      _this.userIsDragging || _this.onDropdownIndicatorMouseDown(event);
    }, _this.handleInputChange = function(event) {
      var inputValue = event.currentTarget.value;
      _this.setState({
        inputIsHiddenAfterUpdate: !1
      }), _this.onInputChange(inputValue, {
        action: "input-change"
      }), _this.props.menuIsOpen || _this.onMenuOpen();
    }, _this.onInputFocus = function(event) {
      _this.props.onFocus && _this.props.onFocus(event), _this.setState({
        inputIsHiddenAfterUpdate: !1,
        isFocused: !0
      }), (_this.openAfterFocus || _this.props.openMenuOnFocus) && _this.openMenu("first"), 
      _this.openAfterFocus = !1;
    }, _this.onInputBlur = function(event) {
      _this.menuListRef && _this.menuListRef.contains(document.activeElement) ? _this.inputRef.focus() : (_this.props.onBlur && _this.props.onBlur(event), 
      _this.onInputChange("", {
        action: "input-blur"
      }), _this.onMenuClose(), _this.setState({
        focusedValue: null,
        isFocused: !1
      }));
    }, _this.onOptionHover = function(focusedOption) {
      _this.blockOptionHover || _this.state.focusedOption === focusedOption || _this.setState({
        focusedOption: focusedOption
      });
    }, _this.shouldHideSelectedOptions = function() {
      return shouldHideSelectedOptions(_this.props);
    }, _this.onKeyDown = function(event) {
      var _this$props5 = _this.props, isMulti = _this$props5.isMulti, backspaceRemovesValue = _this$props5.backspaceRemovesValue, escapeClearsValue = _this$props5.escapeClearsValue, inputValue = _this$props5.inputValue, isClearable = _this$props5.isClearable, isDisabled = _this$props5.isDisabled, menuIsOpen = _this$props5.menuIsOpen, onKeyDown = _this$props5.onKeyDown, tabSelectsValue = _this$props5.tabSelectsValue, openMenuOnFocus = _this$props5.openMenuOnFocus, _this$state = _this.state, focusedOption = _this$state.focusedOption, focusedValue = _this$state.focusedValue, selectValue = _this$state.selectValue;
      if (!(isDisabled || "function" == typeof onKeyDown && (onKeyDown(event), event.defaultPrevented))) {
        switch (_this.blockOptionHover = !0, event.key) {
         case "ArrowLeft":
          if (!isMulti || inputValue) return;
          _this.focusValue("previous");
          break;

         case "ArrowRight":
          if (!isMulti || inputValue) return;
          _this.focusValue("next");
          break;

         case "Delete":
         case "Backspace":
          if (inputValue) return;
          if (focusedValue) _this.removeValue(focusedValue); else {
            if (!backspaceRemovesValue) return;
            isMulti ? _this.popValue() : isClearable && _this.clearValue();
          }
          break;

         case "Tab":
          if (_this.isComposing) return;
          if (event.shiftKey || !menuIsOpen || !tabSelectsValue || !focusedOption || openMenuOnFocus && _this.isOptionSelected(focusedOption, selectValue)) return;
          _this.selectOption(focusedOption);
          break;

         case "Enter":
          if (229 === event.keyCode) break;
          if (menuIsOpen) {
            if (!focusedOption) return;
            if (_this.isComposing) return;
            _this.selectOption(focusedOption);
            break;
          }
          return;

         case "Escape":
          menuIsOpen ? (_this.setState({
            inputIsHiddenAfterUpdate: !1
          }), _this.onInputChange("", {
            action: "menu-close"
          }), _this.onMenuClose()) : isClearable && escapeClearsValue && _this.clearValue();
          break;

         case " ":
          if (inputValue) return;
          if (!menuIsOpen) {
            _this.openMenu("first");
            break;
          }
          if (!focusedOption) return;
          _this.selectOption(focusedOption);
          break;

         case "ArrowUp":
          menuIsOpen ? _this.focusOption("up") : _this.openMenu("last");
          break;

         case "ArrowDown":
          menuIsOpen ? _this.focusOption("down") : _this.openMenu("first");
          break;

         case "PageUp":
          if (!menuIsOpen) return;
          _this.focusOption("pageup");
          break;

         case "PageDown":
          if (!menuIsOpen) return;
          _this.focusOption("pagedown");
          break;

         case "Home":
          if (!menuIsOpen) return;
          _this.focusOption("first");
          break;

         case "End":
          if (!menuIsOpen) return;
          _this.focusOption("last");
          break;

         default:
          return;
        }
        event.preventDefault();
      }
    }, _this.instancePrefix = "react-select-" + (_this.props.instanceId || ++instanceId), 
    _this.state.selectValue = indexEa9e225d_cjs_prod.cleanValue(_props.value), _this;
  }
  return _createClass__default.default(Select, [ {
    key: "componentDidMount",
    value: function() {
      this.startListeningComposition(), this.startListeningToTouch(), this.props.closeMenuOnScroll && document && document.addEventListener && document.addEventListener("scroll", this.onScroll, !0), 
      this.props.autoFocus && this.focusInput();
    }
  }, {
    key: "componentDidUpdate",
    value: function(prevProps) {
      var _this$props6 = this.props, isDisabled = _this$props6.isDisabled, menuIsOpen = _this$props6.menuIsOpen, isFocused = this.state.isFocused;
      (isFocused && !isDisabled && prevProps.isDisabled || isFocused && menuIsOpen && !prevProps.menuIsOpen) && this.focusInput(), 
      isFocused && isDisabled && !prevProps.isDisabled && this.setState({
        isFocused: !1
      }, this.onMenuClose), this.menuListRef && this.focusedOptionRef && this.scrollToFocusedOptionOnUpdate && (indexEa9e225d_cjs_prod.scrollIntoView(this.menuListRef, this.focusedOptionRef), 
      this.scrollToFocusedOptionOnUpdate = !1);
    }
  }, {
    key: "componentWillUnmount",
    value: function() {
      this.stopListeningComposition(), this.stopListeningToTouch(), document.removeEventListener("scroll", this.onScroll, !0);
    }
  }, {
    key: "onMenuOpen",
    value: function() {
      this.props.onMenuOpen();
    }
  }, {
    key: "onMenuClose",
    value: function() {
      this.onInputChange("", {
        action: "menu-close"
      }), this.props.onMenuClose();
    }
  }, {
    key: "onInputChange",
    value: function(newValue, actionMeta) {
      this.props.onInputChange(newValue, actionMeta);
    }
  }, {
    key: "focusInput",
    value: function() {
      this.inputRef && this.inputRef.focus();
    }
  }, {
    key: "blurInput",
    value: function() {
      this.inputRef && this.inputRef.blur();
    }
  }, {
    key: "openMenu",
    value: function(focusOption) {
      var _this2 = this, _this$state2 = this.state, selectValue = _this$state2.selectValue, isFocused = _this$state2.isFocused, focusableOptions = this.buildFocusableOptions(), openAtIndex = "first" === focusOption ? 0 : focusableOptions.length - 1;
      if (!this.props.isMulti) {
        var selectedIndex = focusableOptions.indexOf(selectValue[0]);
        selectedIndex > -1 && (openAtIndex = selectedIndex);
      }
      this.scrollToFocusedOptionOnUpdate = !(isFocused && this.menuListRef), this.setState({
        inputIsHiddenAfterUpdate: !1,
        focusedValue: null,
        focusedOption: focusableOptions[openAtIndex]
      }, (function() {
        return _this2.onMenuOpen();
      }));
    }
  }, {
    key: "focusValue",
    value: function(direction) {
      var _this$state3 = this.state, selectValue = _this$state3.selectValue, focusedValue = _this$state3.focusedValue;
      if (this.props.isMulti) {
        this.setState({
          focusedOption: null
        });
        var focusedIndex = selectValue.indexOf(focusedValue);
        focusedValue || (focusedIndex = -1);
        var lastIndex = selectValue.length - 1, nextFocus = -1;
        if (selectValue.length) {
          switch (direction) {
           case "previous":
            nextFocus = 0 === focusedIndex ? 0 : -1 === focusedIndex ? lastIndex : focusedIndex - 1;
            break;

           case "next":
            focusedIndex > -1 && focusedIndex < lastIndex && (nextFocus = focusedIndex + 1);
          }
          this.setState({
            inputIsHidden: -1 !== nextFocus,
            focusedValue: selectValue[nextFocus]
          });
        }
      }
    }
  }, {
    key: "focusOption",
    value: function() {
      var direction = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "first", pageSize = this.props.pageSize, focusedOption = this.state.focusedOption, options = this.getFocusableOptions();
      if (options.length) {
        var nextFocus = 0, focusedIndex = options.indexOf(focusedOption);
        focusedOption || (focusedIndex = -1), "up" === direction ? nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1 : "down" === direction ? nextFocus = (focusedIndex + 1) % options.length : "pageup" === direction ? (nextFocus = focusedIndex - pageSize) < 0 && (nextFocus = 0) : "pagedown" === direction ? (nextFocus = focusedIndex + pageSize) > options.length - 1 && (nextFocus = options.length - 1) : "last" === direction && (nextFocus = options.length - 1), 
        this.scrollToFocusedOptionOnUpdate = !0, this.setState({
          focusedOption: options[nextFocus],
          focusedValue: null
        });
      }
    }
  }, {
    key: "getTheme",
    value: function() {
      return this.props.theme ? "function" == typeof this.props.theme ? this.props.theme(defaultTheme) : indexEa9e225d_cjs_prod._objectSpread2(indexEa9e225d_cjs_prod._objectSpread2({}, defaultTheme), this.props.theme) : defaultTheme;
    }
  }, {
    key: "getCommonProps",
    value: function() {
      var clearValue = this.clearValue, cx = this.cx, getStyles = this.getStyles, getValue = this.getValue, selectOption = this.selectOption, setValue = this.setValue, props = this.props, isMulti = props.isMulti, isRtl = props.isRtl, options = props.options;
      return {
        clearValue: clearValue,
        cx: cx,
        getStyles: getStyles,
        getValue: getValue,
        hasValue: this.hasValue(),
        isMulti: isMulti,
        isRtl: isRtl,
        options: options,
        selectOption: selectOption,
        selectProps: props,
        setValue: setValue,
        theme: this.getTheme()
      };
    }
  }, {
    key: "hasValue",
    value: function() {
      return this.state.selectValue.length > 0;
    }
  }, {
    key: "hasOptions",
    value: function() {
      return !!this.getFocusableOptions().length;
    }
  }, {
    key: "isClearable",
    value: function() {
      var _this$props7 = this.props, isClearable = _this$props7.isClearable, isMulti = _this$props7.isMulti;
      return void 0 === isClearable ? isMulti : isClearable;
    }
  }, {
    key: "isOptionDisabled",
    value: function(option, selectValue) {
      return _isOptionDisabled(this.props, option, selectValue);
    }
  }, {
    key: "isOptionSelected",
    value: function(option, selectValue) {
      return _isOptionSelected(this.props, option, selectValue);
    }
  }, {
    key: "filterOption",
    value: function(option, inputValue) {
      return _filterOption(this.props, option, inputValue);
    }
  }, {
    key: "formatOptionLabel",
    value: function(data, context) {
      if ("function" == typeof this.props.formatOptionLabel) {
        var inputValue = this.props.inputValue, selectValue = this.state.selectValue;
        return this.props.formatOptionLabel(data, {
          context: context,
          inputValue: inputValue,
          selectValue: selectValue
        });
      }
      return this.getOptionLabel(data);
    }
  }, {
    key: "formatGroupLabel",
    value: function(data) {
      return this.props.formatGroupLabel(data);
    }
  }, {
    key: "startListeningComposition",
    value: function() {
      document && document.addEventListener && (document.addEventListener("compositionstart", this.onCompositionStart, !1), 
      document.addEventListener("compositionend", this.onCompositionEnd, !1));
    }
  }, {
    key: "stopListeningComposition",
    value: function() {
      document && document.removeEventListener && (document.removeEventListener("compositionstart", this.onCompositionStart), 
      document.removeEventListener("compositionend", this.onCompositionEnd));
    }
  }, {
    key: "startListeningToTouch",
    value: function() {
      document && document.addEventListener && (document.addEventListener("touchstart", this.onTouchStart, !1), 
      document.addEventListener("touchmove", this.onTouchMove, !1), document.addEventListener("touchend", this.onTouchEnd, !1));
    }
  }, {
    key: "stopListeningToTouch",
    value: function() {
      document && document.removeEventListener && (document.removeEventListener("touchstart", this.onTouchStart), 
      document.removeEventListener("touchmove", this.onTouchMove), document.removeEventListener("touchend", this.onTouchEnd));
    }
  }, {
    key: "renderInput",
    value: function() {
      var _this$props8 = this.props, isDisabled = _this$props8.isDisabled, isSearchable = _this$props8.isSearchable, inputId = _this$props8.inputId, inputValue = _this$props8.inputValue, tabIndex = _this$props8.tabIndex, form = _this$props8.form, Input = this.getComponents().Input, inputIsHidden = this.state.inputIsHidden, commonProps = this.commonProps, id = inputId || this.getElementId("input"), ariaAttributes = {
        "aria-autocomplete": "list",
        "aria-label": this.props["aria-label"],
        "aria-labelledby": this.props["aria-labelledby"]
      };
      return isSearchable ? React__default.default.createElement(Input, _extends__default.default({}, commonProps, {
        autoCapitalize: "none",
        autoComplete: "off",
        autoCorrect: "off",
        id: id,
        innerRef: this.getInputRef,
        isDisabled: isDisabled,
        isHidden: inputIsHidden,
        onBlur: this.onInputBlur,
        onChange: this.handleInputChange,
        onFocus: this.onInputFocus,
        spellCheck: "false",
        tabIndex: tabIndex,
        form: form,
        type: "text",
        value: inputValue
      }, ariaAttributes)) : React__default.default.createElement(DummyInput, _extends__default.default({
        id: id,
        innerRef: this.getInputRef,
        onBlur: this.onInputBlur,
        onChange: indexEa9e225d_cjs_prod.noop,
        onFocus: this.onInputFocus,
        readOnly: !0,
        disabled: isDisabled,
        tabIndex: tabIndex,
        form: form,
        value: ""
      }, ariaAttributes));
    }
  }, {
    key: "renderPlaceholderOrValue",
    value: function() {
      var _this3 = this, _this$getComponents2 = this.getComponents(), MultiValue = _this$getComponents2.MultiValue, MultiValueContainer = _this$getComponents2.MultiValueContainer, MultiValueLabel = _this$getComponents2.MultiValueLabel, MultiValueRemove = _this$getComponents2.MultiValueRemove, SingleValue = _this$getComponents2.SingleValue, Placeholder = _this$getComponents2.Placeholder, commonProps = this.commonProps, _this$props9 = this.props, controlShouldRenderValue = _this$props9.controlShouldRenderValue, isDisabled = _this$props9.isDisabled, isMulti = _this$props9.isMulti, inputValue = _this$props9.inputValue, placeholder = _this$props9.placeholder, _this$state4 = this.state, selectValue = _this$state4.selectValue, focusedValue = _this$state4.focusedValue, isFocused = _this$state4.isFocused;
      if (!this.hasValue() || !controlShouldRenderValue) return inputValue ? null : React__default.default.createElement(Placeholder, _extends__default.default({}, commonProps, {
        key: "placeholder",
        isDisabled: isDisabled,
        isFocused: isFocused
      }), placeholder);
      if (isMulti) return selectValue.map((function(opt, index) {
        var isOptionFocused = opt === focusedValue;
        return React__default.default.createElement(MultiValue, _extends__default.default({}, commonProps, {
          components: {
            Container: MultiValueContainer,
            Label: MultiValueLabel,
            Remove: MultiValueRemove
          },
          isFocused: isOptionFocused,
          isDisabled: isDisabled,
          key: "".concat(_this3.getOptionValue(opt)).concat(index),
          index: index,
          removeProps: {
            onClick: function() {
              return _this3.removeValue(opt);
            },
            onTouchEnd: function() {
              return _this3.removeValue(opt);
            },
            onMouseDown: function(e) {
              e.preventDefault(), e.stopPropagation();
            }
          },
          data: opt
        }), _this3.formatOptionLabel(opt, "value"));
      }));
      if (inputValue) return null;
      var singleValue = selectValue[0];
      return React__default.default.createElement(SingleValue, _extends__default.default({}, commonProps, {
        data: singleValue,
        isDisabled: isDisabled
      }), this.formatOptionLabel(singleValue, "value"));
    }
  }, {
    key: "renderClearIndicator",
    value: function() {
      var ClearIndicator = this.getComponents().ClearIndicator, commonProps = this.commonProps, _this$props10 = this.props, isDisabled = _this$props10.isDisabled, isLoading = _this$props10.isLoading, isFocused = this.state.isFocused;
      if (!this.isClearable() || !ClearIndicator || isDisabled || !this.hasValue() || isLoading) return null;
      var innerProps = {
        onMouseDown: this.onClearIndicatorMouseDown,
        onTouchEnd: this.onClearIndicatorTouchEnd,
        "aria-hidden": "true"
      };
      return React__default.default.createElement(ClearIndicator, _extends__default.default({}, commonProps, {
        innerProps: innerProps,
        isFocused: isFocused
      }));
    }
  }, {
    key: "renderLoadingIndicator",
    value: function() {
      var LoadingIndicator = this.getComponents().LoadingIndicator, commonProps = this.commonProps, _this$props11 = this.props, isDisabled = _this$props11.isDisabled, isLoading = _this$props11.isLoading, isFocused = this.state.isFocused;
      if (!LoadingIndicator || !isLoading) return null;
      return React__default.default.createElement(LoadingIndicator, _extends__default.default({}, commonProps, {
        innerProps: {
          "aria-hidden": "true"
        },
        isDisabled: isDisabled,
        isFocused: isFocused
      }));
    }
  }, {
    key: "renderIndicatorSeparator",
    value: function() {
      var _this$getComponents5 = this.getComponents(), DropdownIndicator = _this$getComponents5.DropdownIndicator, IndicatorSeparator = _this$getComponents5.IndicatorSeparator;
      if (!DropdownIndicator || !IndicatorSeparator) return null;
      var commonProps = this.commonProps, isDisabled = this.props.isDisabled, isFocused = this.state.isFocused;
      return React__default.default.createElement(IndicatorSeparator, _extends__default.default({}, commonProps, {
        isDisabled: isDisabled,
        isFocused: isFocused
      }));
    }
  }, {
    key: "renderDropdownIndicator",
    value: function() {
      var DropdownIndicator = this.getComponents().DropdownIndicator;
      if (!DropdownIndicator) return null;
      var commonProps = this.commonProps, isDisabled = this.props.isDisabled, isFocused = this.state.isFocused, innerProps = {
        onMouseDown: this.onDropdownIndicatorMouseDown,
        onTouchEnd: this.onDropdownIndicatorTouchEnd,
        "aria-hidden": "true"
      };
      return React__default.default.createElement(DropdownIndicator, _extends__default.default({}, commonProps, {
        innerProps: innerProps,
        isDisabled: isDisabled,
        isFocused: isFocused
      }));
    }
  }, {
    key: "renderMenu",
    value: function() {
      var _this4 = this, _this$getComponents7 = this.getComponents(), Group = _this$getComponents7.Group, GroupHeading = _this$getComponents7.GroupHeading, Menu = _this$getComponents7.Menu, MenuList = _this$getComponents7.MenuList, MenuPortal = _this$getComponents7.MenuPortal, LoadingMessage = _this$getComponents7.LoadingMessage, NoOptionsMessage = _this$getComponents7.NoOptionsMessage, Option = _this$getComponents7.Option, commonProps = this.commonProps, focusedOption = this.state.focusedOption, _this$props12 = this.props, captureMenuScroll = _this$props12.captureMenuScroll, inputValue = _this$props12.inputValue, isLoading = _this$props12.isLoading, loadingMessage = _this$props12.loadingMessage, minMenuHeight = _this$props12.minMenuHeight, maxMenuHeight = _this$props12.maxMenuHeight, menuIsOpen = _this$props12.menuIsOpen, menuPlacement = _this$props12.menuPlacement, menuPosition = _this$props12.menuPosition, menuPortalTarget = _this$props12.menuPortalTarget, menuShouldBlockScroll = _this$props12.menuShouldBlockScroll, menuShouldScrollIntoView = _this$props12.menuShouldScrollIntoView, noOptionsMessage = _this$props12.noOptionsMessage, onMenuScrollToTop = _this$props12.onMenuScrollToTop, onMenuScrollToBottom = _this$props12.onMenuScrollToBottom;
      if (!menuIsOpen) return null;
      var menuUI, render = function(props, id) {
        var type = props.type, data = props.data, isDisabled = props.isDisabled, isSelected = props.isSelected, label = props.label, value = props.value, isFocused = focusedOption === data, onHover = isDisabled ? void 0 : function() {
          return _this4.onOptionHover(data);
        }, onSelect = isDisabled ? void 0 : function() {
          return _this4.selectOption(data);
        }, optionId = "".concat(_this4.getElementId("option"), "-").concat(id), innerProps = {
          id: optionId,
          onClick: onSelect,
          onMouseMove: onHover,
          onMouseOver: onHover,
          tabIndex: -1
        };
        return React__default.default.createElement(Option, _extends__default.default({}, commonProps, {
          innerProps: innerProps,
          data: data,
          isDisabled: isDisabled,
          isSelected: isSelected,
          key: optionId,
          label: label,
          type: type,
          value: value,
          isFocused: isFocused,
          innerRef: isFocused ? _this4.getFocusedOptionRef : void 0
        }), _this4.formatOptionLabel(props.data, "menu"));
      };
      if (this.hasOptions()) menuUI = this.getCategorizedOptions().map((function(item) {
        if ("group" === item.type) {
          var data = item.data, options = item.options, groupIndex = item.index, groupId = "".concat(_this4.getElementId("group"), "-").concat(groupIndex), headingId = "".concat(groupId, "-heading");
          return React__default.default.createElement(Group, _extends__default.default({}, commonProps, {
            key: groupId,
            data: data,
            options: options,
            Heading: GroupHeading,
            headingProps: {
              id: headingId,
              data: item.data
            },
            label: _this4.formatGroupLabel(item.data)
          }), item.options.map((function(option) {
            return render(option, "".concat(groupIndex, "-").concat(option.index));
          })));
        }
        if ("option" === item.type) return render(item, "".concat(item.index));
      })); else if (isLoading) {
        var message = loadingMessage({
          inputValue: inputValue
        });
        if (null === message) return null;
        menuUI = React__default.default.createElement(LoadingMessage, commonProps, message);
      } else {
        var _message = noOptionsMessage({
          inputValue: inputValue
        });
        if (null === _message) return null;
        menuUI = React__default.default.createElement(NoOptionsMessage, commonProps, _message);
      }
      var menuPlacementProps = {
        minMenuHeight: minMenuHeight,
        maxMenuHeight: maxMenuHeight,
        menuPlacement: menuPlacement,
        menuPosition: menuPosition,
        menuShouldScrollIntoView: menuShouldScrollIntoView
      }, menuElement = React__default.default.createElement(indexEa9e225d_cjs_prod.MenuPlacer, _extends__default.default({}, commonProps, menuPlacementProps), (function(_ref4) {
        var ref = _ref4.ref, _ref4$placerProps = _ref4.placerProps, placement = _ref4$placerProps.placement, maxHeight = _ref4$placerProps.maxHeight;
        return React__default.default.createElement(Menu, _extends__default.default({}, commonProps, menuPlacementProps, {
          innerRef: ref,
          innerProps: {
            onMouseDown: _this4.onMenuMouseDown,
            onMouseMove: _this4.onMenuMouseMove
          },
          isLoading: isLoading,
          placement: placement
        }), React__default.default.createElement(ScrollManager, {
          captureEnabled: captureMenuScroll,
          onTopArrive: onMenuScrollToTop,
          onBottomArrive: onMenuScrollToBottom,
          lockEnabled: menuShouldBlockScroll
        }, (function(scrollTargetRef) {
          return React__default.default.createElement(MenuList, _extends__default.default({}, commonProps, {
            innerRef: function(instance) {
              _this4.getMenuListRef(instance), scrollTargetRef(instance);
            },
            isLoading: isLoading,
            maxHeight: maxHeight,
            focusedOption: focusedOption
          }), menuUI);
        })));
      }));
      return menuPortalTarget || "fixed" === menuPosition ? React__default.default.createElement(MenuPortal, _extends__default.default({}, commonProps, {
        appendTo: menuPortalTarget,
        controlElement: this.controlRef,
        menuPlacement: menuPlacement,
        menuPosition: menuPosition
      }), menuElement) : menuElement;
    }
  }, {
    key: "renderFormField",
    value: function() {
      var _this5 = this, _this$props13 = this.props, delimiter = _this$props13.delimiter, isDisabled = _this$props13.isDisabled, isMulti = _this$props13.isMulti, name = _this$props13.name, selectValue = this.state.selectValue;
      if (name && !isDisabled) {
        if (isMulti) {
          if (delimiter) {
            var value = selectValue.map((function(opt) {
              return _this5.getOptionValue(opt);
            })).join(delimiter);
            return React__default.default.createElement("input", {
              name: name,
              type: "hidden",
              value: value
            });
          }
          var input = selectValue.length > 0 ? selectValue.map((function(opt, i) {
            return React__default.default.createElement("input", {
              key: "i-".concat(i),
              name: name,
              type: "hidden",
              value: _this5.getOptionValue(opt)
            });
          })) : React__default.default.createElement("input", {
            name: name,
            type: "hidden"
          });
          return React__default.default.createElement("div", null, input);
        }
        var _value = selectValue[0] ? this.getOptionValue(selectValue[0]) : "";
        return React__default.default.createElement("input", {
          name: name,
          type: "hidden",
          value: _value
        });
      }
    }
  }, {
    key: "renderLiveRegion",
    value: function() {
      var commonProps = this.commonProps, _this$state5 = this.state, ariaSelection = _this$state5.ariaSelection, focusedOption = _this$state5.focusedOption, focusedValue = _this$state5.focusedValue, isFocused = _this$state5.isFocused, selectValue = _this$state5.selectValue, focusableOptions = this.getFocusableOptions();
      return React__default.default.createElement(LiveRegion, _extends__default.default({}, commonProps, {
        ariaSelection: ariaSelection,
        focusedOption: focusedOption,
        focusedValue: focusedValue,
        isFocused: isFocused,
        selectValue: selectValue,
        focusableOptions: focusableOptions
      }));
    }
  }, {
    key: "render",
    value: function() {
      var _this$getComponents8 = this.getComponents(), Control = _this$getComponents8.Control, IndicatorsContainer = _this$getComponents8.IndicatorsContainer, SelectContainer = _this$getComponents8.SelectContainer, ValueContainer = _this$getComponents8.ValueContainer, _this$props14 = this.props, className = _this$props14.className, id = _this$props14.id, isDisabled = _this$props14.isDisabled, menuIsOpen = _this$props14.menuIsOpen, isFocused = this.state.isFocused, commonProps = this.commonProps = this.getCommonProps();
      return React__default.default.createElement(SelectContainer, _extends__default.default({}, commonProps, {
        className: className,
        innerProps: {
          id: id,
          onKeyDown: this.onKeyDown
        },
        isDisabled: isDisabled,
        isFocused: isFocused
      }), this.renderLiveRegion(), React__default.default.createElement(Control, _extends__default.default({}, commonProps, {
        innerRef: this.getControlRef,
        innerProps: {
          onMouseDown: this.onControlMouseDown,
          onTouchEnd: this.onControlTouchEnd
        },
        isDisabled: isDisabled,
        isFocused: isFocused,
        menuIsOpen: menuIsOpen
      }), React__default.default.createElement(ValueContainer, _extends__default.default({}, commonProps, {
        isDisabled: isDisabled
      }), this.renderPlaceholderOrValue(), this.renderInput()), React__default.default.createElement(IndicatorsContainer, _extends__default.default({}, commonProps, {
        isDisabled: isDisabled
      }), this.renderClearIndicator(), this.renderLoadingIndicator(), this.renderIndicatorSeparator(), this.renderDropdownIndicator())), this.renderMenu(), this.renderFormField());
    }
  } ], [ {
    key: "getDerivedStateFromProps",
    value: function(props, state) {
      var prevProps = state.prevProps, clearFocusValueOnUpdate = state.clearFocusValueOnUpdate, inputIsHiddenAfterUpdate = state.inputIsHiddenAfterUpdate, options = props.options, value = props.value, menuIsOpen = props.menuIsOpen, inputValue = props.inputValue, newMenuOptionsState = {};
      if (prevProps && (value !== prevProps.value || options !== prevProps.options || menuIsOpen !== prevProps.menuIsOpen || inputValue !== prevProps.inputValue)) {
        var selectValue = indexEa9e225d_cjs_prod.cleanValue(value), focusableOptions = menuIsOpen ? buildFocusableOptions(props, selectValue) : [], focusedValue = clearFocusValueOnUpdate ? getNextFocusedValue(state, selectValue) : null;
        newMenuOptionsState = {
          selectValue: selectValue,
          focusedOption: getNextFocusedOption(state, focusableOptions),
          focusedValue: focusedValue,
          clearFocusValueOnUpdate: !1
        };
      }
      var newInputIsHiddenState = null != inputIsHiddenAfterUpdate && props !== prevProps ? {
        inputIsHidden: inputIsHiddenAfterUpdate,
        inputIsHiddenAfterUpdate: void 0
      } : {};
      return indexEa9e225d_cjs_prod._objectSpread2(indexEa9e225d_cjs_prod._objectSpread2(indexEa9e225d_cjs_prod._objectSpread2({}, newMenuOptionsState), newInputIsHiddenState), {}, {
        prevProps: props
      });
    }
  } ]), Select;
}(react.Component);

Select.defaultProps = defaultProps, exports.Select = Select, exports.createFilter = createFilter, 
exports.defaultProps = defaultProps, exports.defaultTheme = defaultTheme, exports.getOptionLabel = getOptionLabel, 
exports.getOptionValue = getOptionValue, exports.mergeStyles = mergeStyles;
});

var stateManager799f6a0f_cjs_prod = createCommonjsModule(function (module, exports) {



function _interopDefault(e) {
  return e && e.__esModule ? e : {
    default: e
  };
}

var _extends__default = _interopDefault(_extends_1), _objectWithoutProperties__default = _interopDefault(objectWithoutProperties), _classCallCheck__default = _interopDefault(classCallCheck), _createClass__default = _interopDefault(createClass), _inherits__default = _interopDefault(inherits), React__default = _interopDefault(react), defaultProps = {
  defaultInputValue: "",
  defaultMenuIsOpen: !1,
  defaultValue: null
}, manageState = function(SelectComponent) {
  var _class, _temp;
  return _temp = _class = function(_Component) {
    _inherits__default.default(StateManager, _Component);
    var _super = indexEa9e225d_cjs_prod._createSuper(StateManager);
    function StateManager() {
      var _this;
      _classCallCheck__default.default(this, StateManager);
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      return (_this = _super.call.apply(_super, [ this ].concat(args))).select = void 0, 
      _this.state = {
        inputValue: void 0 !== _this.props.inputValue ? _this.props.inputValue : _this.props.defaultInputValue,
        menuIsOpen: void 0 !== _this.props.menuIsOpen ? _this.props.menuIsOpen : _this.props.defaultMenuIsOpen,
        value: void 0 !== _this.props.value ? _this.props.value : _this.props.defaultValue
      }, _this.onChange = function(value, actionMeta) {
        _this.callProp("onChange", value, actionMeta), _this.setState({
          value: value
        });
      }, _this.onInputChange = function(value, actionMeta) {
        var newValue = _this.callProp("onInputChange", value, actionMeta);
        _this.setState({
          inputValue: void 0 !== newValue ? newValue : value
        });
      }, _this.onMenuOpen = function() {
        _this.callProp("onMenuOpen"), _this.setState({
          menuIsOpen: !0
        });
      }, _this.onMenuClose = function() {
        _this.callProp("onMenuClose"), _this.setState({
          menuIsOpen: !1
        });
      }, _this;
    }
    return _createClass__default.default(StateManager, [ {
      key: "focus",
      value: function() {
        this.select.focus();
      }
    }, {
      key: "blur",
      value: function() {
        this.select.blur();
      }
    }, {
      key: "getProp",
      value: function(key) {
        return void 0 !== this.props[key] ? this.props[key] : this.state[key];
      }
    }, {
      key: "callProp",
      value: function(name) {
        if ("function" == typeof this.props[name]) {
          for (var _this$props, _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) args[_key2 - 1] = arguments[_key2];
          return (_this$props = this.props)[name].apply(_this$props, args);
        }
      }
    }, {
      key: "render",
      value: function() {
        var _this2 = this, _this$props2 = this.props;
        _this$props2.defaultInputValue, _this$props2.defaultMenuIsOpen, _this$props2.defaultValue;
        var props = _objectWithoutProperties__default.default(_this$props2, [ "defaultInputValue", "defaultMenuIsOpen", "defaultValue" ]);
        return React__default.default.createElement(SelectComponent, _extends__default.default({}, props, {
          ref: function(_ref) {
            _this2.select = _ref;
          },
          inputValue: this.getProp("inputValue"),
          menuIsOpen: this.getProp("menuIsOpen"),
          onChange: this.onChange,
          onInputChange: this.onInputChange,
          onMenuClose: this.onMenuClose,
          onMenuOpen: this.onMenuOpen,
          value: this.getProp("value")
        }));
      }
    } ]), StateManager;
  }(react.Component), _class.defaultProps = defaultProps, _temp;
};

exports.manageState = manageState;
});

var reactSelect_cjs_prod = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: !0
});



function _interopDefault(e) {
  return e && e.__esModule ? e : {
    default: e
  };
}

var _extends__default = _interopDefault(_extends_1), _objectWithoutProperties__default = _interopDefault(objectWithoutProperties), _defineProperty__default = _interopDefault(defineProperty), _classCallCheck__default = _interopDefault(classCallCheck), _createClass__default = _interopDefault(createClass), _inherits__default = _interopDefault(inherits), React__default = _interopDefault(react), defaultProps = {
  cacheOptions: !1,
  defaultOptions: !1,
  filterOption: null,
  isLoading: !1
}, makeAsyncSelect = function(SelectComponent) {
  var _class, _temp;
  return _temp = _class = function(_Component) {
    _inherits__default.default(Async, _Component);
    var _super = indexEa9e225d_cjs_prod._createSuper(Async);
    function Async(props) {
      var _this;
      return _classCallCheck__default.default(this, Async), (_this = _super.call(this)).select = void 0, 
      _this.lastRequest = void 0, _this.mounted = !1, _this.handleInputChange = function(newValue, actionMeta) {
        var _this$props = _this.props, cacheOptions = _this$props.cacheOptions, onInputChange = _this$props.onInputChange, inputValue = indexEa9e225d_cjs_prod.handleInputChange(newValue, actionMeta, onInputChange);
        if (!inputValue) return delete _this.lastRequest, void _this.setState({
          inputValue: "",
          loadedInputValue: "",
          loadedOptions: [],
          isLoading: !1,
          passEmptyOptions: !1
        });
        if (cacheOptions && _this.state.optionsCache[inputValue]) _this.setState({
          inputValue: inputValue,
          loadedInputValue: inputValue,
          loadedOptions: _this.state.optionsCache[inputValue],
          isLoading: !1,
          passEmptyOptions: !1
        }); else {
          var request = _this.lastRequest = {};
          _this.setState({
            inputValue: inputValue,
            isLoading: !0,
            passEmptyOptions: !_this.state.loadedInputValue
          }, (function() {
            _this.loadOptions(inputValue, (function(options) {
              _this.mounted && request === _this.lastRequest && (delete _this.lastRequest, _this.setState((function(state) {
                return {
                  isLoading: !1,
                  loadedInputValue: inputValue,
                  loadedOptions: options || [],
                  passEmptyOptions: !1,
                  optionsCache: options ? indexEa9e225d_cjs_prod._objectSpread2(indexEa9e225d_cjs_prod._objectSpread2({}, state.optionsCache), {}, _defineProperty__default.default({}, inputValue, options)) : state.optionsCache
                };
              })));
            }));
          }));
        }
        return inputValue;
      }, _this.state = {
        defaultOptions: Array.isArray(props.defaultOptions) ? props.defaultOptions : void 0,
        inputValue: void 0 !== props.inputValue ? props.inputValue : "",
        isLoading: !0 === props.defaultOptions,
        loadedOptions: [],
        passEmptyOptions: !1,
        optionsCache: {},
        prevDefaultOptions: void 0,
        prevCacheOptions: void 0
      }, _this;
    }
    return _createClass__default.default(Async, [ {
      key: "componentDidMount",
      value: function() {
        var _this2 = this;
        this.mounted = !0;
        var defaultOptions = this.props.defaultOptions, inputValue = this.state.inputValue;
        !0 === defaultOptions && this.loadOptions(inputValue, (function(options) {
          if (_this2.mounted) {
            var isLoading = !!_this2.lastRequest;
            _this2.setState({
              defaultOptions: options || [],
              isLoading: isLoading
            });
          }
        }));
      }
    }, {
      key: "componentWillUnmount",
      value: function() {
        this.mounted = !1;
      }
    }, {
      key: "focus",
      value: function() {
        this.select.focus();
      }
    }, {
      key: "blur",
      value: function() {
        this.select.blur();
      }
    }, {
      key: "loadOptions",
      value: function(inputValue, callback) {
        var loadOptions = this.props.loadOptions;
        if (!loadOptions) return callback();
        var loader = loadOptions(inputValue, callback);
        loader && "function" == typeof loader.then && loader.then(callback, (function() {
          return callback();
        }));
      }
    }, {
      key: "render",
      value: function() {
        var _this3 = this, _this$props2 = this.props;
        _this$props2.loadOptions;
        var isLoadingProp = _this$props2.isLoading, props = _objectWithoutProperties__default.default(_this$props2, [ "loadOptions", "isLoading" ]), _this$state = this.state, defaultOptions = _this$state.defaultOptions, inputValue = _this$state.inputValue, isLoading = _this$state.isLoading, loadedInputValue = _this$state.loadedInputValue, loadedOptions = _this$state.loadedOptions, options = _this$state.passEmptyOptions ? [] : inputValue && loadedInputValue ? loadedOptions : defaultOptions || [];
        return React__default.default.createElement(SelectComponent, _extends__default.default({}, props, {
          ref: function(_ref) {
            _this3.select = _ref;
          },
          options: options,
          isLoading: isLoading || isLoadingProp,
          onInputChange: this.handleInputChange
        }));
      }
    } ], [ {
      key: "getDerivedStateFromProps",
      value: function(props, state) {
        var newCacheOptionsState = props.cacheOptions !== state.prevCacheOptions ? {
          prevCacheOptions: props.cacheOptions,
          optionsCache: {}
        } : {}, newDefaultOptionsState = props.defaultOptions !== state.prevDefaultOptions ? {
          prevDefaultOptions: props.defaultOptions,
          defaultOptions: Array.isArray(props.defaultOptions) ? props.defaultOptions : void 0
        } : {};
        return indexEa9e225d_cjs_prod._objectSpread2(indexEa9e225d_cjs_prod._objectSpread2({}, newCacheOptionsState), newDefaultOptionsState);
      }
    } ]), Async;
  }(react.Component), _class.defaultProps = defaultProps, _temp;
}, SelectState = stateManager799f6a0f_cjs_prod.manageState(SelectFd7cb895_cjs_prod.Select), Async = makeAsyncSelect(SelectState);

exports.default = Async, exports.defaultProps = defaultProps, exports.makeAsyncSelect = makeAsyncSelect;
});

var reactSelect_cjs = createCommonjsModule(function (module) {

{
  module.exports = reactSelect_cjs_prod;
}
});

export default reactSelect_cjs;
