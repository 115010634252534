import React, {useState} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import Button from "../components/Button.js";
import Loader from "../components/Loader.js";
import useBoxBattery from "../hooks/useBoxBattery.js";
import "./MyBoxActions.css";
export default function MyBoxActions({
  boxId,
  boxName,
  isBoxPaired,
  onNavigate,
  onUnlock
}) {
  const [unlocking, setUnlocking] = useState(false);
  const {battery} = useBoxBattery(boxId);
  const {t} = useTranslation();
  const handleUnlock = (...args) => {
    setUnlocking(true);
    onUnlock(...args);
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "my-box-actions__container"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "my-box-actions__title"
  }, boxId), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, /* @__PURE__ */ React.createElement("strong", null, boxName)), !isBoxPaired && /* @__PURE__ */ React.createElement("p", {
    className: "color-warn"
  }, t("box:notPaired")), battery && /* @__PURE__ */ React.createElement("p", {
    className: "color-grey mb16 pb16"
  }, t("box:batteryPercentageUpdatedAt", {
    percentage: battery.batteryLevel,
    dateTime: battery.updatedAt?.toDate()?.toLocaleString()
  })), !isBoxPaired && /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: onNavigate(`/boxes/pairing?id=${boxId}`)
  }, t("box:pair")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    loader: unlocking,
    onClick: handleUnlock,
    disabled: !isBoxPaired
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("box:opening"),
    className: "color-primary"
  }) : t("box:open")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: onNavigate(`/boxes/${boxId}/edit`)
  }, t("box:edit")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: onNavigate(`/boxes/${boxId}/permissions`)
  }, t("box:editPermissions")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: onNavigate(`/boxes/${boxId}/keys`)
  }, t("box:keys")));
}
