import React from "../../_snowpack/pkg/react.js";
import "./UserItem.css";
export default function UserItem({user, onClick}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "wombat-user-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "wombat-user-item__card  mb8 pl16 pr16",
    onClick,
    "data-boxid": user.phoneNumber
  }, /* @__PURE__ */ React.createElement("h1", null, user.phoneNumber), /* @__PURE__ */ React.createElement("p", null, user.email), /* @__PURE__ */ React.createElement("h3", null, "Vytvoření účtu:"), /* @__PURE__ */ React.createElement("p", null, user.metadata.creationTime), /* @__PURE__ */ React.createElement("h3", null, "Poslední přihlášení:"), /* @__PURE__ */ React.createElement("p", null, user.metadata.lastSignInTime)));
}
