import React, {useState} from "../../_snowpack/pkg/react.js";
import {doc, getDoc} from "../../_snowpack/pkg/firebase/firestore.js";
import "./LockersScreen.css";
import useAlert from "../../hooks/useAlert.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import Button from "../../components/Button.js";
import useBleDevicesNearby from "../../hooks/useBleDevicesNearby.js";
import Loader from "../../components/Loader.js";
import {firestore, functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import {Capacitor} from "../../_snowpack/pkg/@capacitor/core.js";
import ListSelect from "../../components/ListSelect.js";
import Warning from "../../components/Warning.js";
import HeaderBack from "../../components/HeaderBack.js";
import BleBox from "../../library/BleBox.js";
const isNative = Capacitor.isNativePlatform();
async function getBoxInfo(boxId) {
  const response = await httpsCallable(functions(), "locker-serviceUnlock")({
    boxId
  });
  if (!response.data) {
    throw new Error("Unregistered box");
  }
  return response.data;
}
export default function HomeUnlockScreen() {
  const [unlocking, setUnlocking] = useState(false);
  const alert = useAlert();
  const {
    devices: bleDevicesNearby,
    findNearby: findBleDeviceNearby,
    pickedDevice: selectedBleDevice,
    setPickedDevice: setSelectedBleDevice,
    error: bleDevicesNearbyError
  } = useBleDevicesNearby();
  const handleSetKey = async () => {
    if (unlocking)
      return;
    try {
      setUnlocking(true);
      const bleDevice = selectedBleDevice || await findBleDeviceNearby();
      if (!bleDevice)
        return;
      const boxId = bleDevice.id;
      const bleBox = new BleBox(bleDevice);
      const boxDoc = await getDoc(doc(firestore(), "locker-boxes"));
      if (!boxDoc.exists)
        throw new Error(`Locker ${boxId} box not found`);
      const {accountPublicKey, secretCode} = boxDoc.data();
      await bleBox.transaction(async () => await bleBox.saveAccountKey(accountPublicKey, secretCode));
      alert.success("Klíč nahrán");
    } catch (err) {
      alert.error(err);
    } finally {
      setUnlocking(false);
      setSelectedBleDevice(null);
    }
  };
  const handleUnlockLocker = async () => {
    if (unlocking)
      return;
    try {
      setUnlocking(true);
      const bleDevice = selectedBleDevice || await findBleDeviceNearby();
      if (!bleDevice)
        return;
      const boxId = bleDevice.id;
      const {cmdId, unlockCommand} = await getBoxInfo(boxId);
      const bleBox = new BleBox(bleDevice);
      await bleBox.transaction(async () => await bleBox.unlockWithOnlineKey(unlockCommand));
      alert.success(`Locker otevřen; cmdId: ${cmdId}`);
    } catch (err) {
      alert.error(err);
    } finally {
      setUnlocking(false);
      setSelectedBleDevice(null);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "servicer-unlock-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null), null), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, bleDevicesNearbyError && /* @__PURE__ */ React.createElement(Warning, {
    className: "mt8 mb8",
    ...bleDevicesNearbyError,
    text: bleDevicesNearbyError.message
  }), /* @__PURE__ */ React.createElement("div", {
    className: "servicer-unlock-screen__view"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "mb16 "
  }, "Nahrát account klíč do boxu"), isNative && /* @__PURE__ */ React.createElement("div", {
    className: "servicer-unlock-screen__devices-list"
  }, /* @__PURE__ */ React.createElement(ListSelect, {
    data: bleDevicesNearby,
    render: (item) => /* @__PURE__ */ React.createElement("p", {
      className: "ml16"
    }, item.name),
    selected: selectedBleDevice,
    onChange: setSelectedBleDevice
  }), !bleDevicesNearby.length && /* @__PURE__ */ React.createElement("p", {
    className: "center-text"
  }, "Žádný box v dosahu")), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16",
    onClick: handleSetKey,
    disabled: unlocking || isNative && !selectedBleDevice
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    text: "Nahrávám"
  }) : "Nahrát klíč"), /* @__PURE__ */ React.createElement("p", {
    className: "mb16 "
  }, "Otevřít locker"), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16",
    onClick: handleUnlockLocker,
    disabled: unlocking || isNative && !selectedBleDevice
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    text: "Otevírám"
  }) : "Otevřít"))));
}
