import React from "../_snowpack/pkg/react.js";
import {t} from "../_snowpack/pkg/i18next.js";
import {compose, mount, redirect, route, withState} from "../_snowpack/pkg/navi.js";
import {Router, View, NotFoundBoundary} from "../_snowpack/pkg/react-navi.js";
import * as ReactAlert from "../_snowpack/pkg/react-alert.js";
import ReactAlertTemplate, {
  reactAlertOptions
} from "../components/ReactAlertTemplate.js";
import FallbackPath from "../screens/FallbackScreen.js";
import MessengerDeliveryScreen from "./screens/Screen.js";
import MessengerDeliveryHelpScreen from "./screens/HelpScreen.js";
import "../i18n.js";
export const messengerRoutes = compose(withState(({state}) => state), mount({
  "/": redirect("help"),
  "/:id": route((request) => {
    return {
      title: t("delivery:clearance"),
      view: /* @__PURE__ */ React.createElement(MessengerDeliveryScreen, {
        deliveryId: request.params.id
      })
    };
  }),
  "/help": route((request) => {
    return {
      title: t("hint"),
      view: /* @__PURE__ */ React.createElement(MessengerDeliveryHelpScreen, {
        deliveryId: request.params.id
      })
    };
  })
}));
const allAppRoutes = mount({
  "/messenger": messengerRoutes
});
export default function MessengerApp() {
  return /* @__PURE__ */ React.createElement(Router, {
    routes: allAppRoutes
  }, /* @__PURE__ */ React.createElement(ReactAlert.Provider, {
    template: ReactAlertTemplate,
    ...reactAlertOptions
  }, /* @__PURE__ */ React.createElement(NotFoundBoundary, {
    render: () => /* @__PURE__ */ React.createElement(FallbackPath, null)
  }, /* @__PURE__ */ React.createElement(View, null))));
}
