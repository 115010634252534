import React, {useCallback} from "../../_snowpack/pkg/react.js";
import "./PublicDeliveryScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderLogo from "../../components/HeaderLogo.js";
import {CenteredLoader} from "../../components/Loader.js";
import Button from "../../components/Button.js";
import useAlert from "../../hooks/useAlert.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import useBoxPublicState from "../../hooks/useBoxPublicState.js";
import FinishInstantReceptionModal from "../../modals/FinishInstantReceptionModal.js";
import FinishRequestReceptionModal from "../../modals/FinishRequestReceptionModal.js";
export default function PublicDeliveryScreen({boxId}) {
  const alert = useAlert();
  const {openModal, closeModal, modal} = useMobileModal();
  const handleScreenError = useCallback((error) => {
    alert.error(error);
  }, [alert]);
  const {publicState, isLoading} = useBoxPublicState(boxId, {
    onError: handleScreenError
  });
  const handleInstantReception = useCallback(() => {
    openModal(/* @__PURE__ */ React.createElement(FinishInstantReceptionModal, {
      boxId,
      onComplete: closeModal,
      onError: handleScreenError
    }));
  }, [openModal, boxId, closeModal, handleScreenError]);
  const handleRequestReception = useCallback(() => {
    openModal(/* @__PURE__ */ React.createElement(FinishRequestReceptionModal, {
      boxId,
      onComplete: closeModal,
      onError: handleScreenError
    }));
  }, [openModal, boxId, closeModal, handleScreenError]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-public-delivery"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, "Vložit zásilku do schránky"), /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, "Číslo schránky: ", /* @__PURE__ */ React.createElement("strong", null, boxId)), isLoading && /* @__PURE__ */ React.createElement(CenteredLoader, {
    className: "screen-delivery__loader",
    text: "zjišťuji oprávnění..."
  }), publicState?.isOccupied && /* @__PURE__ */ React.createElement("h2", null, "Tato schránka je momentálně plně obsazená"), publicState?.allowInstantReception && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", null, "Schránka je aktuálně připravena přijmout zásilku"), /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleInstantReception,
    disabled: publicState?.isOccupied
  }, "Vložit zásilku")), !publicState?.allowInstantReception && publicState?.allowRequestReception && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", null, "Schránka je připravena přijmout zásilku. Vyžaduje to ale souhlas jejího majitele."), /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleRequestReception,
    disabled: publicState?.isOccupied
  }, "Požádat majitele o otevření"))), modal);
}
