import React, {useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Card from "../../components/Card.js";
import HeaderBack from "../../components/HeaderBack.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import useAlert from "../../hooks/useAlert.js";
import useBoxEditForm from "../../hooks/useBoxEditForm.js";
import useMultibox from "../hooks/useMultibox.js";
const MultiboxEditScreen = ({multiboxId}) => {
  const {multibox} = useMultibox(multiboxId);
  const navigation = useNavigation();
  const alert = useAlert();
  const {t} = useTranslation();
  const onEditSuccess = useCallback(() => {
    navigation.goBack();
    alert.success(t("multibox:editSuccess"));
  }, [navigation, alert, t]);
  const [form] = useBoxEditForm({
    box: multibox,
    onSuccess: onEditSuccess
  });
  return /* @__PURE__ */ React.createElement("div", {
    className: "has-header"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("multibox:labelWithIdNumber", {multiboxId})), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt16"
  }, form)));
};
export default MultiboxEditScreen;
