import React from "../_snowpack/pkg/react.js";
import IconBatteryUndefined from "../images/icon-battery-undefined.js";
import "./BatteryIcon.css";
const resolveBatteryLevelClassName = (level) => {
  if (!level) {
    return "";
  }
  if (level >= 100) {
    return "BatteryIcon--level100";
  }
  if (level >= 75) {
    return "BatteryIcon--level75";
  }
  if (level >= 50) {
    return "BatteryIcon--level50";
  }
  if (level >= 25) {
    return "BatteryIcon--level25";
  }
};
const BatteryIcon = ({level, className, width, ...others}) => {
  if (!level) {
    return /* @__PURE__ */ React.createElement(IconBatteryUndefined, {
      className,
      width
    });
  }
  return /* @__PURE__ */ React.createElement("svg", {
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    x: "0px",
    y: "0px",
    viewBox: "0 0 24 24",
    xmlSpace: "preserve",
    className: `BatteryIcon ${resolveBatteryLevelClassName(level)} ${className}`,
    width,
    ...others
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M0,6v12h21V6H0z M19,16H2V8h17V16z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M22,15h0.8c0.7,0,1.2-0.6,1.2-1.2v-3.5C24,9.6,23.4,9,22.8,9H22V15z"
  }), level >= 25 && /* @__PURE__ */ React.createElement("rect", {
    x: "3",
    y: "9",
    width: "3",
    height: "6"
  }), level >= 50 && /* @__PURE__ */ React.createElement("rect", {
    x: "7",
    y: "9",
    width: "3",
    height: "6"
  }), level >= 75 && /* @__PURE__ */ React.createElement("rect", {
    x: "11",
    y: "9",
    width: "3",
    height: "6"
  }), level >= 100 && /* @__PURE__ */ React.createElement("rect", {
    x: "15",
    y: "9",
    width: "3",
    height: "6"
  }));
};
export default BatteryIcon;
