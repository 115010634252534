import { useEffect, useState, useCallback } from "../../_snowpack/pkg/react.js";
import { isArray } from "../../_snowpack/pkg/lodash.js";
import { functions } from "../../firebase.js";
import { httpsCallable } from "../../_snowpack/pkg/firebase/functions.js";

export default function useApps({ onError }) {
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchApps = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await httpsCallable(
        functions(),
        "organisation-apps"
      )({});

      if (isArray(response?.data)) {
        setApps(response.data);
        setIsLoaded(true);
      }
    } catch (err) {
      onError(err);
    } finally {
      setIsLoading(false);
    }
  }, [onError]);

  useEffect(() => {
    if (!isLoaded) fetchApps();
  }, [fetchApps, isLoaded]);

  return {
    apps,
    isLoading,
    reloadApps: useCallback(() => {
      setIsLoaded(false);
    }, []),
  };
}
