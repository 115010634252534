import React, {useEffect, useState, useMemo, useCallback} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import Button from "../components/Button.js";
import FormItem from "../components/FormItem.js";
import FormTextInput from "../components/FormTextInput.js";
import FormTextarea from "../components/FormTextarea.js";
import useBleDevicesNearby from "../hooks/useBleDevicesNearby.js";
import BleBox from "../library/BleBox.js";
import {removeSpaces} from "../library/utils/functions.js";
import {isPhoneValid} from "../library/validation.js";
import Warning from "../components/Warning.js";
import "./FinishRequestReceptionModal.css";
export default function FinishRequestReceptionModal({
  boxId,
  onError = console.error,
  onComplete = () => {
  }
}) {
  const [isRequestingUnlock, setIsRequestingUnlock] = useState(false);
  const [isAwaitingApprove, setIsAwaitingApprove] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isFinishingRequest, setIsFinishingRequest] = useState(false);
  const [unlockRequestId, setUnlockRequestId] = useState();
  const [phoneNumber, setPhoneNumber] = useState("+420");
  const [message, setMessage] = useState(t("box:openingRequest.messagePlaceholder"));
  const [pollTimeout, setPollTimeout] = useState();
  const [command, setCommand] = useState();
  const {t} = useTranslation();
  const {findNearby: findBleDeviceNearby, error: bleError} = useBleDevicesNearby();
  const handleRequestUnlock = useCallback(async (event) => {
    event.preventDefault();
    const formattedPhoneNumber = removeSpaces(phoneNumber);
    setIsRequestingUnlock(true);
    try {
      const {
        data: {id}
      } = await httpsCallable(functions(), "box-requestUnlock")({
        type: "REQUEST_RECEPTION",
        phoneNumber: formattedPhoneNumber,
        boxId,
        message
      });
      setUnlockRequestId(id);
      setIsAwaitingApprove(true);
    } catch (err) {
      onError(err);
    } finally {
      setIsRequestingUnlock(false);
    }
  }, [boxId, message, onError, phoneNumber]);
  const handleCheckUnlockRequestState = useCallback(async () => {
    let repeat = true;
    if (pollTimeout) {
      clearTimeout(pollTimeout);
    }
    try {
      const {
        data: {state, command: command2}
      } = await httpsCallable(functions(), "box-requestUnlock")({
        unlockRequestId
      });
      if (state === "REJECTED") {
        setIsAwaitingApprove(false);
        setIsRejected(true);
      } else if (state === "APPROVED") {
        setIsAwaitingApprove(false);
        setIsApproved(true);
        setCommand(command2);
        repeat = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (repeat) {
        setPollTimeout(setTimeout(handleCheckUnlockRequestState, 1e3 * 10));
      }
    }
  }, [pollTimeout, unlockRequestId]);
  useEffect(() => {
    if (isAwaitingApprove && unlockRequestId) {
      handleCheckUnlockRequestState();
    }
    return () => {
      if (pollTimeout) {
        clearTimeout(pollTimeout);
      }
    };
  }, [
    handleCheckUnlockRequestState,
    isAwaitingApprove,
    pollTimeout,
    unlockRequestId
  ]);
  const handleOpenBox = useCallback(async (event) => {
    event.preventDefault();
    setIsOpening(true);
    const bleDevice = await findBleDeviceNearby({id: boxId});
    if (!bleDevice) {
      setIsOpening(false);
      return;
    }
    const bleBox = BleBox(bleDevice);
    bleBox.transaction(async () => {
      await bleBox.unlockWithOnlineKey(command).catch((error) => {
        setIsOpening(false);
        onError(error);
      });
      setIsOpening(false);
      setIsOpened(true);
    });
  }, [boxId, command, findBleDeviceNearby, onError]);
  const handleFinishRequest = useCallback(async (event) => {
    event.preventDefault();
    setIsFinishingRequest(true);
    try {
      await httpsCallable(functions(), "delivery-finishRequestReception")({
        unlockRequestId
      });
      onComplete();
    } catch (err) {
      onError(err);
    } finally {
      setIsFinishingRequest(false);
    }
  }, [onError, onComplete, unlockRequestId]);
  const content = useMemo(() => {
    if (isOpened) {
      return /* @__PURE__ */ React.createElement("form", {
        onSubmit: handleFinishRequest
      }, /* @__PURE__ */ React.createElement("h1", null, t("box:isOpen")), /* @__PURE__ */ React.createElement("p", {
        className: "mb16"
      }, t("box:openingRequest.closeBoxAndCheckPackageInserted")), /* @__PURE__ */ React.createElement(Button, {
        type: "submit",
        disabled: isFinishingRequest
      }, t("confirm")));
    }
    if (isRejected) {
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, t("box:openingRequest.wasRefused")), /* @__PURE__ */ React.createElement("p", {
        className: "mb16"
      }, t("box:openingRequest.cantOpenBox")), /* @__PURE__ */ React.createElement(Button, {
        secondary: true,
        onClick: onComplete
      }, t("close")));
    }
    if (isApproved) {
      return /* @__PURE__ */ React.createElement("form", {
        onSubmit: handleOpenBox
      }, /* @__PURE__ */ React.createElement("h1", null, t("box:openingRequest.wasApproved")), /* @__PURE__ */ React.createElement("p", {
        className: "mb16"
      }, t("box:openingRequest.nowOpenTheBox")), bleError && /* @__PURE__ */ React.createElement(Warning, {
        ...bleError,
        text: bleError.message || bleError.code || bleError,
        className: "mb16"
      }), /* @__PURE__ */ React.createElement(Button, {
        type: "submit",
        disabled: isOpening
      }, t("box:open")));
    }
    if (isAwaitingApprove) {
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, t("box:openingRequest.wasSent")), /* @__PURE__ */ React.createElement("p", null, t("box:openingRequest.waitForResponse")));
    }
    return /* @__PURE__ */ React.createElement("form", {
      onSubmit: handleRequestUnlock
    }, /* @__PURE__ */ React.createElement("h1", null, t("box:openingRequest.labelShort")), /* @__PURE__ */ React.createElement("p", {
      className: "mb16"
    }, t("box:openingRequest.enterBoxOwnersPhone")), /* @__PURE__ */ React.createElement(FormItem, {
      label: t("phoneNumber")
    }, /* @__PURE__ */ React.createElement(FormTextInput, {
      name: "phoneNumber",
      type: "tel",
      value: phoneNumber,
      onChange: (event) => {
        setPhoneNumber(event.target.value);
      },
      required: true
    })), /* @__PURE__ */ React.createElement(FormItem, {
      label: t("box:openingRequest.messageForRecipient")
    }, /* @__PURE__ */ React.createElement(FormTextarea, {
      name: "message",
      placeholder: t("box:openingRequest.messageText"),
      value: message,
      onChange: (event) => {
        setMessage(event.target.value);
      }
    })), /* @__PURE__ */ React.createElement(Button, {
      type: "submit",
      disabled: !isPhoneValid(phoneNumber) || isRequestingUnlock
    }, t("box:openingRequest.send")));
  }, [
    isOpened,
    isRejected,
    isApproved,
    isAwaitingApprove,
    handleRequestUnlock,
    phoneNumber,
    message,
    isRequestingUnlock,
    handleFinishRequest,
    isFinishingRequest,
    onComplete,
    handleOpenBox,
    bleError,
    isOpening,
    t
  ]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "finish-request-reception-modal__container p16"
  }, content);
}
