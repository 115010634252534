import React from "../../_snowpack/pkg/react.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import BoxStateRow from "./BoxStateRow.js";
const MultiboxState = ({partitions}) => {
  const {openModal, modal} = useMobileModal();
  return /* @__PURE__ */ React.createElement("div", {
    className: "SingleBoxesStateRows"
  }, partitions.map((partition, index) => /* @__PURE__ */ React.createElement(BoxStateRow, {
    key: `${index}-${partition?.id}`,
    box: partition,
    openModal
  })), modal);
};
export default MultiboxState;
