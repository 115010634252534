import * as __SNOWPACK_ENV__ from './_snowpack/env.js';

import React from "./_snowpack/pkg/react.js";
import ReactDOM from "./_snowpack/pkg/react-dom.js";
import App from "./App.js";
import {SplashScreen} from "./_snowpack/pkg/@capacitor/splash-screen.js";
import {Capacitor} from "./_snowpack/pkg/@capacitor/core.js";
import InsallPrompt from "./components/InstallPrompt.js";
import Rollbar from "./_snowpack/pkg/rollbar.js";
const {ROLLBAR, MODE, SNOWPACK_PUBLIC_CIRCLE_SHA1} = __SNOWPACK_ENV__;
const enabled = MODE === "production";
const rollbar = new Rollbar({
  accessToken: ROLLBAR,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled,
  payload: {
    environment: MODE,
    version: SNOWPACK_PUBLIC_CIRCLE_SHA1
  }
});
console.log(SNOWPACK_PUBLIC_CIRCLE_SHA1 || "HEAD", {MODE});
const isNative = Capacitor.isNativePlatform();
const ios = /iphone|ipod|ipad/.test(navigator.userAgent.toLocaleLowerCase());
const osx = /mac/.test(navigator.userAgent.toLocaleLowerCase());
let highState = {
  forceWebVersion: false,
  offline: !navigator.onLine
};
function reRender() {
  const {forceWebVersion, offline} = highState;
  const showInstallPrompt = (ios || osx) && !isNative && !forceWebVersion && (!localStorage || localStorage.getItem("isAnnoying") !== "false");
  ReactDOM.render(showInstallPrompt ? /* @__PURE__ */ React.createElement(InsallPrompt, {
    ios,
    onClose: () => {
      highState.forceWebVersion = true;
      reRender();
    }
  }) : /* @__PURE__ */ React.createElement(App, {
    ...{rollbar, offline}
  }), document.querySelector("#app-root"), () => {
    SplashScreen.hide();
  });
}
setTimeout(reRender, 0);
const handlerOnline = () => {
  document.body.classList.remove("offline");
  highState.offline = false;
  reRender();
};
const handlerOffline = () => {
  document.body.classList.add("offline");
  highState.offline = true;
  reRender();
};
window.addEventListener("online", handlerOnline);
window.addEventListener("offline", handlerOffline);
