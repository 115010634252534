import React, {memo, useRef} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import Button from "./Button.js";
import Divider from "./Divider.js";
import FormTextarea from "./FormTextarea.js";
import FormTextInput from "./FormTextInput.js";
import GoogleAddressInput from "./GoogleAddressInput.js";
import Loader from "./Loader.js";
import {updateState} from "../library/forms.js";
const BoxEditForm = ({
  submitButtonLabel,
  submitDisabled,
  formValues,
  submitting,
  onSubmit,
  setFormValues
}) => {
  const {t} = useTranslation();
  const streetNumberInputRef = useRef();
  return /* @__PURE__ */ React.createElement("form", {
    onSubmit: (e) => onSubmit(e, formValues)
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "name",
    label: t("addressee"),
    value: formValues.name,
    onChange: updateState(setFormValues),
    required: true
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(GoogleAddressInput, {
    value: formValues.addressStreet,
    onSelect: ({
      street = "",
      landRegistryNo = "",
      city = "",
      zipcode = "",
      country = ""
    }) => {
      setFormValues({
        ...formValues,
        addressStreet: street,
        addressStreetNumber: landRegistryNo,
        addressCity: city,
        addressPSC: zipcode,
        addressCountry: country
      });
      streetNumberInputRef.current.focus();
    },
    onChange: (value) => {
      setFormValues({...formValues, addressStreet: value});
    }
  }, (props) => /* @__PURE__ */ React.createElement(FormTextInput, {
    ...props,
    name: "addressStreet",
    label: t("address.street"),
    required: true
  })), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressStreetNumber",
    label: t("address.houseNo"),
    value: formValues.addressStreetNumber,
    required: true,
    onChange: updateState(setFormValues),
    customRef: streetNumberInputRef
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressCity",
    label: t("address.city"),
    value: formValues.addressCity,
    required: true,
    onChange: updateState(setFormValues)
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressPSC",
    label: t("address.zip"),
    value: formValues.addressPSC,
    required: true,
    onChange: updateState(setFormValues)
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(FormTextarea, {
    name: "description",
    label: t("box:locationDesc"),
    value: formValues.description,
    onChange: updateState(setFormValues)
  }), submitting ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("saving")
  }) : /* @__PURE__ */ React.createElement(Button, {
    className: "mt32 mb8",
    type: "submit",
    disabled: submitDisabled || submitting,
    name: "save-box"
  }, submitButtonLabel || t("saveChanges")));
};
export default memo(BoxEditForm);
