import { c as createChunksMatcher, j as joinPaths, a as createURLDescriptor, b as createChunk } from './common/MemoryNavigation-f23c5caa.js';
export { d as compose, e as lazy, e as map, m as mount, r as route, w as withData, f as withState, g as withView } from './common/MemoryNavigation-f23c5caa.js';
import './common/extends-7477639a.js';

function redirect(maybeResolvableTo, _a) {
    var _b = (_a === void 0 ? {} : _a).exact, exact = _b === void 0 ? true : _b;
    return createChunksMatcher(maybeResolvableTo, undefined, function (to, request) {
        var toHref;
        if (typeof to === 'string') {
            toHref = to[0] === '/' ? to : joinPaths('/', request.mountpath, to);
        }
        else if (to) {
            toHref = createURLDescriptor(to).href;
        }
        return toHref ? [createChunk('redirect', request, { to: toHref })] : [];
    }, exact, true // proccess during crawl
    );
}

export { redirect };
