import {useState, useMemo, useCallback, useEffect} from "../_snowpack/pkg/react.js";
import {unionBy} from "../_snowpack/pkg/lodash.js";
import {
  doc,
  collection,
  getDoc,
  setDoc,
  addDoc,
  runTransaction
} from "../_snowpack/pkg/firebase/firestore.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {
  generateKeyPair,
  removePrefixFromPublicKey
} from "../library/utils/crypto.js";
import {firestore, functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import BleBox, {
  getDeviceKeyPair,
  hasDeviceBoxKey,
  saveDeviceKeyIndex,
  saveDeviceKeyPair,
  SUCCESSFUL_BOX_RESPONSE,
  boxResponseToMessage
} from "../library/BleBox.js";
import useCollection from "./useCollection.js";
const PAIRED_DEVICE_COUNT_LIMIT = 4;
export const PAIRING_STATUS_TYPES = {
  NEED_SECRET_CODE: 1,
  LIMIT_REACHED: 2,
  PAIRED: 3
};
export const KEY_DEVICE_LABEL = "device-key-label";
export const getDeviceLabel = () => localStorage.getItem(KEY_DEVICE_LABEL);
export const saveDeviceLabel = (s, onSuccess) => {
  localStorage.setItem(KEY_DEVICE_LABEL, s);
  onSuccess && onSuccess();
};
async function saveBatteryStatus(box, battery) {
  const batteryCol = await collection(firestore(), `/boxes/${box.id}/battery`);
  return addDoc(batteryCol, {
    batteryLevel: battery,
    updatedAt: new Date()
  }).catch((e) => console.warn("could not save battery status", e));
}
async function pairingProcedure({bleBox, bleDevice, secretCode, userId, userDeviceToReplace}, setStatusText = () => {
}, t) {
  setStatusText(t("checking"));
  const boxRef = await doc(firestore(), `boxes/${bleDevice.id}`);
  const [boxExists, boxDevicePublicKeys, boxDeviceOwnerId] = await getDoc(boxRef).then((b) => [
    b.exists(),
    b.get("devicePublicKeys") || [],
    b.get("ownerId")
  ]);
  if (boxDeviceOwnerId && boxDeviceOwnerId !== userId) {
    throw new Error(t("box:alreadyPaired"));
  }
  if (!boxExists && !secretCode) {
    return [PAIRING_STATUS_TYPES.NEED_SECRET_CODE];
  }
  setStatusText(t("pairing"));
  if (!boxExists) {
    const userRef = await getDoc(doc(firestore(), `users/${userId}`));
    const userPublicKey = await userRef.get("publicKey");
    const response = await bleBox.saveAccountKey(userPublicKey, secretCode);
    if (response !== SUCCESSFUL_BOX_RESPONSE) {
      throw new Error(`${t("error:pair")}: ${boxResponseToMessage(response)}`);
    }
  }
  if (boxExists && hasDeviceBoxKey(boxDevicePublicKeys)) {
    return [PAIRING_STATUS_TYPES.PAIRED, bleBox];
  }
  if (boxDevicePublicKeys.length >= PAIRED_DEVICE_COUNT_LIMIT && !userDeviceToReplace?.createdAt) {
    return [PAIRING_STATUS_TYPES.LIMIT_REACHED, boxDevicePublicKeys];
  }
  setStatusText(t("box:uploadingDeviceKey"));
  const equalUserDevice = ({createdAt}) => createdAt === userDeviceToReplace.createdAt;
  const keyIndex = (userDeviceToReplace ? boxDevicePublicKeys.findIndex(equalUserDevice) : boxDevicePublicKeys.length) + 1;
  const devicePublicKey = removePrefixFromPublicKey(getDeviceKeyPair().publicKey);
  {
    const {
      data: {command}
    } = await httpsCallable(functions(), "box-saveDeviceKey")({
      boxId: bleBox.id,
      devicePublicKey,
      keyIndex
    });
    const response = await bleBox.saveDeviceKey(command);
    if (response !== SUCCESSFUL_BOX_RESPONSE) {
      throw new Error(`${t("error:saveKey")}: ${boxResponseToMessage(response)}`);
    }
  }
  const userDevice = {
    label: getDeviceLabel(),
    publicKey: devicePublicKey,
    createdAt: Date.now()
  };
  if (boxExists) {
    await runTransaction(firestore(), (t2) => t2.get(boxRef).then((box) => {
      const devicePublicKeys = box.get("devicePublicKeys") || [];
      devicePublicKeys[keyIndex - 1] = userDevice;
      return t2.update(boxRef, {devicePublicKeys});
    }));
  } else {
    await setDoc(boxRef, {
      devicePublicKeys: [userDevice],
      ownerId: userId,
      id: bleBox.id
    });
  }
  saveDeviceKeyIndex(keyIndex, bleBox.id);
  return [PAIRING_STATUS_TYPES.PAIRED, bleBox];
}
export default function useMyBoxes(user, {onError = console.error} = {}) {
  const {t} = useTranslation();
  const [boxesOwner = [], loadingOwner, boxesOwnerError] = useCollection(`/boxes`, {
    skip: !user,
    where: [["ownerId", "==", user?.uid]],
    onError
  });
  const [boxesFullAccess = [], loadingFullAccess, boxesFullAccessError] = useCollection(`/boxes`, {
    skip: !user,
    where: [["permissions.fullAccess", "array-contains", user?.uid]],
    onError
  });
  const boxes = useMemo(() => unionBy([...boxesOwner, ...boxesFullAccess].filter((box) => !box.isPartition).map((box) => ({
    ...box,
    isPaired: box.virtual || hasDeviceBoxKey(box.devicePublicKeys)
  })), (box) => box.id), [boxesOwner, boxesFullAccess]);
  useEffect(() => {
    if (!user)
      return;
    try {
      const {publicKey, privateKey} = getDeviceKeyPair();
      if (privateKey && publicKey)
        return;
      const keyPair = generateKeyPair();
      saveDeviceKeyPair(keyPair);
    } catch (err) {
      onError(err);
    }
  }, [onError, user]);
  useEffect(() => {
    if (!user || getDeviceLabel())
      return;
    const chunks = (navigator.userAgent.match(/\(.*?\)/)[0] || "").slice(1, -1).split(";");
    if (chunks.length > 2) {
      saveDeviceLabel(chunks[2].split("Build")[0]);
    } else {
      saveDeviceLabel(chunks[0]);
    }
  }, [user]);
  const [pairing, setPairing] = useState(false);
  const [pairingStatusText, setPairingStatusText] = useState("");
  const pair = useCallback(async (bleDevice, secretCode, userDeviceToReplace) => {
    if (!user)
      return;
    setPairing(true);
    const bleBox = BleBox(bleDevice);
    try {
      return await bleBox.transaction(async () => {
        const result = await pairingProcedure({
          bleBox,
          bleDevice,
          secretCode,
          userDeviceToReplace,
          userId: user.uid
        }, setPairingStatusText, t);
        if (result[0] == PAIRING_STATUS_TYPES.PAIRED) {
          const battery = await bleBox.readBattery();
          if (battery)
            await saveBatteryStatus({id: bleDevice.id}, battery);
        }
        return result;
      });
    } finally {
      setPairing(false);
    }
  }, [user, t]);
  const unlock = useCallback(async (bleDevice) => {
    const bleBox = BleBox(bleDevice);
    const box = boxes.find(({id}) => id === bleBox.id);
    if (!box)
      throw new Error(t("box:unknown"));
    await bleBox.transaction(async () => {
      await bleBox.syncTime(box);
      const response = await bleBox.unlockWithOfflineKey(box);
      if (response !== SUCCESSFUL_BOX_RESPONSE) {
        throw new Error(`${t("error:unlock")}: ${boxResponseToMessage(response)}`);
      }
      const battery = await bleBox.readBattery();
      saveBatteryStatus(box, battery);
    });
  }, [boxes, t]);
  const unpair = useCallback(async (box) => {
    if (!box)
      throw new Error(t("box:unknown"));
    await httpsCallable(functions(), "box-delete")({
      boxId: box.id
    });
  }, [t]);
  const loading = loadingOwner || loadingFullAccess;
  const boxesError = boxesOwnerError || boxesFullAccessError;
  return {
    boxes,
    loading,
    error: boxesError,
    pair,
    pairing,
    pairingStatusText,
    unlock,
    unpair
  };
}
