import React, {useCallback} from "../_snowpack/pkg/react.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderBack from "../components/HeaderBack.js";
import ItemDelivery from "../components/ItemDelivery.js";
import {CenteredLoader} from "../components/Loader.js";
import HeaderTabs from "../components/HeaderTabs.js";
import {
  DELIVERY_STATE_VISIBILITY,
  DELIVERY_DIRECTION
} from "../_snowpack/pkg/@topmonks/postcube.js";
import useAlert from "../hooks/useAlert.js";
import useCollection from "../hooks/useCollection.js";
import "./HistoryScreen.css";
const HISTORY_DELIVERIES_LIMIT = 50;
export default function HistoryScreen({
  route: {
    data: {user}
  },
  incoming
}) {
  const {t} = useTranslation();
  const alert = useAlert();
  const navigation = useNavigation();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const [deliveries = [], deliveriesLoading] = useCollection(`/deliveries`, {
    where: incoming ? [
      ["recipient.ownerId", "==", user.uid],
      ["state", "in", DELIVERY_STATE_VISIBILITY.HISTORY]
    ] : [
      ["sender.ownerId", "==", user.uid],
      ["state", "in", DELIVERY_STATE_VISIBILITY.HISTORY]
    ],
    orderBy: ["updatedAt", "desc"],
    limit: HISTORY_DELIVERIES_LIMIT,
    onError: handleScreenError
  });
  const direction = incoming ? DELIVERY_DIRECTION.INCOMING : DELIVERY_DIRECTION.SENDING;
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-history"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-history__header-tabs"
  }, /* @__PURE__ */ React.createElement(HeaderTabs, {
    activeTab: direction,
    onTabChange: (newTab) => {
      navigation.navigate(newTab, {replace: true});
    }
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, deliveries?.map((d) => /* @__PURE__ */ React.createElement(ItemDelivery, {
    name: incoming ? d.sender?.name : d.recipient?.name,
    id: d.id,
    key: d.id,
    status: d.state,
    direction,
    onClick: (id) => {
      navigation.navigate(`/deliveries/${direction}/${id}`);
    }
  })), deliveriesLoading && /* @__PURE__ */ React.createElement(CenteredLoader, {
    className: "screen-delivery__loader",
    text: t("loadingData")
  }), !deliveriesLoading && deliveries?.length === 0 && /* @__PURE__ */ React.createElement("p", {
    className: "screen-history__empty mb16 mt16"
  }, incoming ? t("delivery:noneReceived") : t("delivery:noneSent"))));
}
