import React, {useCallback, useState} from "../_snowpack/pkg/react.js";
import {BottomSheet} from "../_snowpack/pkg/react-spring-bottom-sheet.js";
import "../library/react-spring-bottom-sheet.js";
const DELAY_BEFORE_OPENING = 500;
const noop = () => {
};
export default function useMobileModal({
  onClose = noop,
  ...customModalProps
} = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);
  const openModal = useCallback((content2) => {
    setIsOpen(false);
    setTimeout(() => {
      setContent(content2);
      setIsOpen(true);
    }, DELAY_BEFORE_OPENING);
  }, []);
  const modal = /* @__PURE__ */ React.createElement(BottomSheet, {
    open: isOpen,
    className: "mobile-modal",
    snapPoints: ({minHeight, maxHeight, height}) => [
      minHeight,
      height && height < maxHeight ? height : maxHeight - 44
    ],
    onDismiss: closeModal,
    initialFocusRef: false,
    ...customModalProps
  }, content);
  return {openModal, closeModal, modal};
}
