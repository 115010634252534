import { r as registerPlugin } from './index-67482630.js';

var ApplePayEventsEnum;
(function (ApplePayEventsEnum) {
    ApplePayEventsEnum["Loaded"] = "applePayLoaded";
    ApplePayEventsEnum["FailedToLoad"] = "applePayFailedToLoad";
    ApplePayEventsEnum["Completed"] = "applePayCompleted";
    ApplePayEventsEnum["Canceled"] = "applePayCanceled";
    ApplePayEventsEnum["Failed"] = "applePayFailed";
})(ApplePayEventsEnum || (ApplePayEventsEnum = {}));

var GooglePayEventsEnum;
(function (GooglePayEventsEnum) {
    GooglePayEventsEnum["Loaded"] = "googlePayLoaded";
    GooglePayEventsEnum["FailedToLoad"] = "googlePayFailedToLoad";
    GooglePayEventsEnum["Completed"] = "googlePayCompleted";
    GooglePayEventsEnum["Canceled"] = "googlePayCanceled";
    GooglePayEventsEnum["Failed"] = "googlePayFailed";
})(GooglePayEventsEnum || (GooglePayEventsEnum = {}));

var PaymentFlowEventsEnum;
(function (PaymentFlowEventsEnum) {
    PaymentFlowEventsEnum["Loaded"] = "paymentFlowLoaded";
    PaymentFlowEventsEnum["FailedToLoad"] = "paymentFlowFailedToLoad";
    PaymentFlowEventsEnum["Opened"] = "paymentFlowOpened";
    PaymentFlowEventsEnum["Created"] = "paymentFlowCreated";
    PaymentFlowEventsEnum["Completed"] = "paymentFlowCompleted";
    PaymentFlowEventsEnum["Canceled"] = "paymentFlowCanceled";
    PaymentFlowEventsEnum["Failed"] = "paymentFlowFailed";
})(PaymentFlowEventsEnum || (PaymentFlowEventsEnum = {}));

var PaymentSheetEventsEnum;
(function (PaymentSheetEventsEnum) {
    PaymentSheetEventsEnum["Loaded"] = "paymentSheetLoaded";
    PaymentSheetEventsEnum["FailedToLoad"] = "paymentSheetFailedToLoad";
    PaymentSheetEventsEnum["Completed"] = "paymentSheetCompleted";
    PaymentSheetEventsEnum["Canceled"] = "paymentSheetCanceled";
    PaymentSheetEventsEnum["Failed"] = "paymentSheetFailed";
})(PaymentSheetEventsEnum || (PaymentSheetEventsEnum = {}));

const Stripe = registerPlugin('Stripe', {
    web: () => import('./web-01b5de14.js').then(m => new m.StripeWeb()),
});

export { ApplePayEventsEnum as A, PaymentSheetEventsEnum as P, Stripe as S, PaymentFlowEventsEnum as a };
