import React, {useState} from "../../_snowpack/pkg/react.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import Button from "../../components/Button.js";
import ListSelect from "../../components/ListSelect.js";
import HeaderBack from "../../components/HeaderBack.js";
import Tabs, {Tab} from "../components/Tabs.js";
import FormTextInput from "../../components/FormTextInput.js";
import "./Proto2Screen.css";
import {
  Platform,
  PostCubeBLE
} from "../../_snowpack/pkg/@topmonks/postcube.js";
PostCubeBLE.platform = Platform.web;
export default function Proto2Screen() {
  const [selectedCube, setSelectedCube] = useState();
  const handleScan = () => {
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "servicer-proto2-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("h1", {
    className: "mt32 pl16 pr16"
  }, "Protocol v2"), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "servicer-proto2-screen__view"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "mb16 "
  }, "Multibox"), /* @__PURE__ */ React.createElement("div", {
    className: "servicer-proto2-screen__devices-list"
  }, /* @__PURE__ */ React.createElement(ListSelect, {
    render: (item) => /* @__PURE__ */ React.createElement("p", {
      className: "ml16"
    }, item.name),
    data: [],
    selected: selectedCube,
    onChange: setSelectedCube
  })), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16",
    onClick: handleScan
  }))), /* @__PURE__ */ React.createElement("h2", {
    className: "pl16 pr16"
  }, "Encrypted Packet"), /* @__PURE__ */ React.createElement(Tabs, {
    className: "mb32"
  }, /* @__PURE__ */ React.createElement(Tab, {
    label: "key"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "encryptedKeyId",
    placeholder: "134567584392",
    className: "mb0"
  })), /* @__PURE__ */ React.createElement(Tab, {
    label: "hash"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "grid grid__2cols gap16 mb16"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "boxId",
    placeholder: "134567584392"
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "Secret",
    placeholder: "134567584392"
  })), /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "Hash",
    placeholder: "134567584392",
    className: "mb0"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "mb32 pl16 pr16"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "mb16"
  }, "Packet"), /* @__PURE__ */ React.createElement("div", {
    className: "grid grid__2cols gap16"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "CommandId",
    placeholder: "134567584392"
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "ExpireAt",
    placeholder: "134567584392"
  }))), /* @__PURE__ */ React.createElement("h3", {
    className: "mb16 pl16 pr16"
  }, "Command"), /* @__PURE__ */ React.createElement(Tabs, {
    className: "mb32"
  }, /* @__PURE__ */ React.createElement(Tab, {
    label: "Set key"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "key id",
    placeholder: "134567584392"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "grid grid__2cols3_1 gap16 alignItemsEnd"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "Public Key",
    placeholder: "134567584392"
  }), /* @__PURE__ */ React.createElement(Button, null, "NEW"))), /* @__PURE__ */ React.createElement(Tab, {
    label: "Upload"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "LickId",
    placeholder: "134567584392",
    className: "mb0"
  })), /* @__PURE__ */ React.createElement(Tab, {
    label: "Sync"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "grid grid__2cols3_1 gap16 alignItemsEnd"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: "Timestamp",
    placeholder: "134567584392"
  }), /* @__PURE__ */ React.createElement(Button, null, "NOW"))), /* @__PURE__ */ React.createElement(Tab, {
    label: "Nuke"
  }, "Nude command selected")), /* @__PURE__ */ React.createElement(Button, {
    className: "servicer-proto2-screen__actionButton ml16 mr16"
  }, "ACTION"));
}
