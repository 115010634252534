import web from "./BleAdapter.web.js";
import cordova from "./BleAdapter.cordova.js";
import { Capacitor } from "../_snowpack/pkg/@capacitor/core.js";

export function parseDeviceId({ name }) {
  return name.slice(-6);
}

export function splitCommand(buf, chunkSize = 20) {
  let offset = 0;
  const length = buf.length;
  const result = [];

  while (offset < length) {
    result.push(buf.subarray(offset, offset + chunkSize));
    offset += chunkSize;
  }

  return result;
}

export const ERROR_BLUETOOTH_DISABLED = "bluetooth-disabled";
export const ERROR_BLUETOOTH_UNAUTHORIZED = "bluetooth-unauthorized";
export const ERROR_BLUETOOTH_UNSUPPORTED = "bluetooth-unsupported";
export const ERROR_BLUETOOTH_RESSETING = "bluetooth-resseting";

const isNative = Capacitor.isNativePlatform();

export default isNative ? cordova : web;
