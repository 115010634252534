export default {
  label_one: "Shipment",
  label_other: "Shipments",
  number: "Shipment number",
  sent: "Sent",
  sentDeliveries: "Shipments sent",
  received: "Received",
  receivedDeliveries: "Shipments received",
  detail: "Shipment detail",
  receiving: "RECEIVING",
  sending: "SENDING",

  recipient: "Recipient",
  sender: "Sender",

  notPossible:
    "Sorry, there is no transport option between these addresses yet.",
  notFound: "Shipment not found",

  // messenger
  collect: "Pick up the shipment",
  collectDeliveryFromBox: "Pick up the shipment from the box",
  collectFromBox: "Pick up from the box",
  collectFromSeller: "Pick up the shipment from the seller",
  collectAndCloseBox: "Pick up the shipment and close the box",

  deliverToBox: "Deliver to box",
  type: "Delivery type",
  messageFromInserter: "Message from inserter:",
  instantInsert: "Instant insertion",
  confirmedInsert: "Insertion approved by you",
  send: "Send shipment",
  sendToBox: "Send the shipment to a box",
  sendToAddress: "Send the parcel to an address",
  pay: "Pay shipment",
  note: "Shipment note",

  insert: "Insert the shipment",
  insertToBox: "Insert the shipment to the box",
  insertToBox2: "Insert the shipment to the box",
  insertAndCloseBox: "Insert the shipment and close the box",
  linkForCarrier: "Link for the carrier",

  clearance: "Shipment clearance",
  cancel: "Cancel shipment",
  checkPlease: "Please check your shipment",
  check: "Check shipment",
  makeSureItsOkay: "Make sure the shipment is OK",
  markLegibly: "Mark the shipment legibly",
  markLegiblyInfo:
    "In order for the courier to recognize the shipment safely, write this code legibly on the shipment.",

  recipientBoxNotSelected: "Recipient box not selected",
  senderBoxNotSelected: "Sender box not selected",
  noneReceived: "You have no received shipment.",
  noneSent: "You have no sent shipment.",
  noneAwaiting: "You are not expecting any shipment.",
  noneSending: "You are not sending any shipment.",

  unknownInsertedBySomeone: "Unknown shipment inserted by anyone",
  unknowInsertedWithPermission:
    "Unknown shipment inserted with your permission",

  public: "Public shipments",
  preferencesQRCodeOpening:
    "Preferences for working with shipments outside PostCube transport. Refers to opening the mailbox using a <1>QR code</1>.",

  price: "Shipment price",
  transport: "Transport",
  chooseCarrier: "Select a carrier",
  chooseCarrierPlease: "Please select a carrier",

  reallyDelete: "Are you sure you want to delete the shipment?",
  deleted: "Shipment deleted",
  confirmed: "Shipment confirmed",

  history: "Order history",
  historyReceived: "History of received shipments",
  historySent: "History of sent shipments",
  whereTo: "Where's it going?",
  fromWhichBox: "From which box?",

  packageCode: "Package code",
  packageCode2: "Shipment marking code",

  collection: "Shipment received",
  create: "Create a shipment",
  creation: "Shipment created",
  new: "New shipment",
  whereToSend: "Where do you want to send your shipment?",
  toBox: "To a box",
  toAddress: "To an address",

  state: "Shipment status",
  status: {
    messenger: {
      collect: "Pick up the parcel from the box",
      deliver: "Deliver the collected parcel",
      noStatus: "No status",
    },
    unfinished: "Unfinished",
    insertToBox: "Insert into box",
    inPreparation: "In preparation",
    lookingForCourier: "We are looking for a courier",
    courierOnTheWay: "Courier on the way",
    beingCollectedByCourier: "Picked up by courier",
    beingDelivered: "Being delivered",
    noStatus: "No status",
    waitingForApproval: "Awaiting approval",

    forCollection: "Shipment for pick up",
    inTransit: "Shipment on its way",

    delivered: "Shipment delivered",
    collected: "Shipment picked up",
    takenProperly: "Shipment received properly",

    beingPrepared: "The shipment is being prepared",
    readyToShip: "Shipment ready to ship",
    readyToInsert: "Shipment ready to be placed in the box",
    courierOnTheWayForDelivery: "Courier on the way for your shipment",
    courierIsAtBox: "The courier is at your box",

    goingToYou: "Shipment is coming to you",
    goingToDestination: "The shipment is on its way to its destination",
  },
};
