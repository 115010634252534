import { BOX_STATES } from "../_snowpack/pkg/@topmonks/postcube.js";
import useBox from "./useBox.js";
import useBoxBattery from "./useBoxBattery.js";

/**
 * @type {BoxAvailability}
 * @property {string} boxId
 * @property {boolean} isLoading
 * @property {boolean} isAvailable
 * @property {number} battery
 * @property {"IDLE"|"OFFLINE"|"BLOCKED_BY_DELIVERY"} boxState
 */

/**
 * @returns {BoxAvailability}
 */
export default function useBoxAvailability(
  boxId,
  { onError = console.error } = {}
) {
  const { box, isLoading } = useBox(boxId, { onError });
  const { battery } = useBoxBattery(boxId);

  const boxState = box?.state || BOX_STATES.IDLE;

  return {
    boxId,
    boxState,
    isLoading: isLoading,
    isAvailable: boxState === BOX_STATES.IDLE,
    battery: battery?.batteryLevel,
  };
}
