export default {
  ok: "OK",
  cancel: "Cancel",
  change: "Change",
  back: "Back",
  delivered: "Delivered",
  collected: "Collected",
  unknown: "Unknown",
  loading: "Loading ...",
  loadingData: "Loading data ...",
  loadingApp: "Loading application ...",
  loadingUsers: "Loading user ...",
  saving: "Saving ...",
  checking: "Checking ...",
  confirming: "Confirming ...",
  confirm: "Confirm",
  pairing: "Pairing ...",
  help: "Help",
  add: "Add",
  create: "Create",
  creating: "Creating ...",
  processing: "Processing ...",
  note: "Note",
  open: "Open",
  understood: "Understood",
  lost: "I am lost",
  notSpecified: "Not specified",
  hint: "Hint",
  finish: "Finish",
  saveChanges: "Save Changes",
  cancelChanges: "Discard changes?",
  leave: "Leave",
  logout: "Logout",
  login: "Login",
  login2: "Login",
  toHome: "Home",
  goHome: "go home",
  goStepBack: "Go one step back",
  labelName: "Name",
  changeLabelName: "Change name",
  continue: "Continue",
  choose: "Select",
  general: "General",
  advanced: "Advanced",
  remove: "Remove",
  options: "Options",
  listEmpty: "The list is empty.",
  pageNotFound: "Page not found",
  skip: "Skip",
  begin: "Begin",
  battery: "Battery",
  verification: "Verification",
  registration: "Registration",
  welcome: "Welcome",
  close: "Close",
  copiedToClipboard: "Copied to clipboard.",

  CIN: "VAT ID",
  addressee: "Name of the addressee, company ...",
  addresseePhone: "Telephone number of the addressee",
  phoneNumber: "Telephone number",
  phone: "Phone",
  email: "E-mail (optional)",

  address: {
    label: "Address",
    labelLine2: "Address (line 2)",
    notFound: "Address not found",
    enteredNotFound: "The address you entered was not found",
    city: "City",
    street: "Street",
    houseNo: "House number",
    zip: "Zip code",
  },
};
