import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
const MultiboxStatesInfo = () => {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGridStatesInfo"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGridStatesInfo-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box MultiboxGrid-box--free"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box-label"
  }, t("box:state.free"))), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGridStatesInfo-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box MultiboxGrid-box--disabled"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box-label"
  }, t("box:state.paused"))), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGridStatesInfo-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box MultiboxGrid-box--sending"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box-label"
  }, t("box:state.sending"))), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGridStatesInfo-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box MultiboxGrid-box--receiving"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxGrid-box-label"
  }, t("box:state.receiving"))));
};
export default MultiboxStatesInfo;
