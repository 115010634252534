import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import {useEffect, useState} from "../_snowpack/pkg/react.js";
import {Loader} from "../_snowpack/pkg/google-maps.js";
const apiKey = __SNOWPACK_ENV__.GOOGLE_MAPS_APIKEY;
const enable = apiKey !== "null";
const loader = new Loader(apiKey, {
  language: "cs",
  libraries: ["places"]
});
export const gmapsLoader = () => loader.load();
export default function useGoogleMaps() {
  const [google, setGoogle] = useState();
  useEffect(() => {
    if (enable)
      gmapsLoader().then(setGoogle);
  }, []);
  return enable ? google : void 0;
}
