import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import { messaging } from "../firebase.js";
import { onMessage, getToken } from "../_snowpack/pkg/firebase/messaging.js";

// https://firebase.google.com/docs/reference/js/firebase.messaging.Messaging
const vapidKey = __SNOWPACK_ENV__.VAPID_KEY;

const retrieveRegistrationToken = async () => {
  const currentToken = await getToken(messaging(), { vapidKey });

  if (currentToken) {
    return currentToken;
  }
};

export function subscribeToPushNotifications({
  onTokenReceived,
  onErrorOccurred,
  onMessageReceived,
}) {
  if (!("Notification" in window && navigator.serviceWorker)) {
    console.warn("Push notifications are not supported");
    return;
  }

  retrieveRegistrationToken().then(onTokenReceived).catch(onErrorOccurred);
  onMessage(messaging(), onMessageReceived);
}
