import React from "../_snowpack/pkg/react.js";
import {compose, mount, route, withState} from "../_snowpack/pkg/navi.js";
import {Router, View, NotFoundBoundary} from "../_snowpack/pkg/react-navi.js";
import * as ReactAlert from "../_snowpack/pkg/react-alert.js";
import ReactAlertTemplate, {
  reactAlertOptions
} from "../components/ReactAlertTemplate.js";
import FallbackPath from "../screens/FallbackScreen.js";
import PublicDeliveryScreen from "./screens/PublicDeliveryScreen.js";
export const boxQrRoutes = compose(withState(({state}) => state), mount({
  "/:id": route(({params: {id: boxId}}) => ({
    title: `PostCube ${boxId} QR`,
    view: (pass) => /* @__PURE__ */ React.createElement(PublicDeliveryScreen, {
      boxId,
      ...pass
    })
  }))
}));
const allAppRoutes = mount({
  "/qrbox": boxQrRoutes
});
export default function QrBoxApp() {
  return /* @__PURE__ */ React.createElement(Router, {
    routes: allAppRoutes
  }, /* @__PURE__ */ React.createElement(ReactAlert.Provider, {
    template: ReactAlertTemplate,
    ...reactAlertOptions
  }, /* @__PURE__ */ React.createElement(NotFoundBoundary, {
    render: ({pathname = "/"}) => /* @__PURE__ */ React.createElement(FallbackPath, {
      type: pathname.split("/").reverse()[0]
    })
  }, /* @__PURE__ */ React.createElement(View, null))));
}
