import React, {useRef} from "../_snowpack/pkg/react.js";
import PlacesAutocomplete from "../_snowpack/pkg/react-places-autocomplete.js";
import useGoogleMaps from "../hooks/useGoogleMaps.js";
import "./GoogleAddressInput.css";
const SEARCH_OPTIONS = {
  componentRestrictions: {country: "cz"},
  types: ["address"]
};
export default function GoogleAddressInput({
  children: renderInput,
  value,
  onChange,
  onSelect
}) {
  const google = useGoogleMaps();
  const mapRef = useRef();
  const handleSelect = (addressString = "", placeId) => {
    console.warn("Not used variable", {addressString});
    const placesService = new google.maps.places.PlacesService(mapRef.current);
    placesService.getDetails({placeId, fields: ["address_components"]}, ({address_components}) => {
      const details = address_components.reduce((d, {types, short_name}) => {
        if (types.includes("country"))
          return {...d, country: short_name};
        if (types.includes("route"))
          return {...d, street: short_name};
        if (types.includes("postal_code"))
          return {...d, zipcode: short_name};
        if (types.includes("locality") || types.includes("sublocality_level_1"))
          return {...d, city: short_name};
        if (types.includes("street_number") || types.includes("premise"))
          return {...d, landRegistryNo: short_name};
        return d;
      }, {});
      onSelect(details);
    });
  };
  if (!google)
    return renderInput({
      value,
      onChange: ({target: {value: value2}}) => {
        onChange(value2);
      }
    });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PlacesAutocomplete, {
    debounce: 450,
    searchOptions: SEARCH_OPTIONS,
    onChange,
    onSelect: handleSelect,
    value,
    highlightFirstSuggestion: true
  }, ({getInputProps, suggestions, getSuggestionItemProps}) => /* @__PURE__ */ React.createElement("div", {
    className: "google-address__container"
  }, /* @__PURE__ */ React.createElement("div", null, renderInput(getInputProps())), !!suggestions.length && /* @__PURE__ */ React.createElement("div", {
    className: "google-address__dropdown"
  }, suggestions.map((suggestion) => /* @__PURE__ */ React.createElement("div", {
    ...getSuggestionItemProps(suggestion, {
      className: suggestion.active ? "google-address__item google-address__item--active" : "google-address__item"
    }),
    key: suggestion.placeId
  }, /* @__PURE__ */ React.createElement("span", null, suggestion.description)))))), /* @__PURE__ */ React.createElement("div", {
    ref: mapRef
  }));
}
