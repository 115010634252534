import React, {useEffect, useState, Suspense} from "./_snowpack/pkg/react.js";
import {
  compose,
  mount,
  redirect,
  map,
  route,
  withData,
  withState,
  withView,
  lazy
} from "./_snowpack/pkg/navi.js";
import {Router, View, NotFoundBoundary} from "./_snowpack/pkg/react-navi.js";
import * as ReactAlert from "./_snowpack/pkg/react-alert.js";
import ReactAlertTemplate, {
  reactAlertOptions
} from "./components/ReactAlertTemplate.js";
import AuthLoginScreen from "./screens/Auth/LoginScreen.js";
import ErrorBoundary from "./components/ErrorBoundary.js";
import AuthRegistrationScreen from "./screens/Auth/RegistrationScreen.js";
import AuthVerificationScreen from "./screens/Auth/VerificationScreen.js";
import BoxPairingScreen from "./screens/Box/PairingScreen.js";
import BoxPairedScreen from "./screens/Box/PairedScreen.js";
import KeysListScreen from "./screens/Box/KeysListScreen.js";
import PermissionsScreen from "./screens/Box/PermissionsScreen.js";
import ScreenHome from "./screens/HomeScreen.js";
import Step1Screen from "./screens/Send/Step1Screen.js";
import Step1ScreenAddress from "./screens/Send/Step1ScreenAddress.js";
import Step2Screen from "./screens/Send/Step2Screen.js";
import Step3PaymentSuccessScreen from "./screens/Send/Step3PaymentSuccessScreen.js";
import HistoryScreen from "./screens/HistoryScreen.js";
import MyBoxesScreen from "./screens/MyBoxesScreen.js";
import SettingsScreen from "./screens/SettingsScreen.js";
import DeliveryConfirmScreen from "./screens/Delivery/ConfirmScreen.js";
import DeliverySenderScreen from "./screens/Delivery/SenderScreen.js";
import DeliveryRecipientScreen from "./screens/Delivery/RecipientScreen.js";
import {DELIVERY_DIRECTION} from "./_snowpack/pkg/@topmonks/postcube.js";
import useSetupAuthUser from "./hooks/useSetupAuthUser.js";
import useOfflineAbilities from "./hooks/useOfflineAbilities.js";
import {auth} from "./firebase.js";
import {onAuthStateChanged, signOut} from "./_snowpack/pkg/firebase/auth.js";
import usePushNotifications from "./hooks/usePushNotifications.js";
import DeepLinkTrapper from "./components/DeepLinkTrapper.js";
import FallbackPath from "./screens/FallbackScreen.js";
import WelcomeScreen from "./screens/WelcomeScreen.js";
import Loader from "./components/Loader.js";
import SandboxScreen from "./screens/SandboxScreen.js";
import StripeProvider from "./components/StripeProvider.js";
import "./i18n.js";
import {t} from "./_snowpack/pkg/i18next.js";
const authenticatedRoutes = {
  "/": redirect("/home"),
  "/home": route({
    title: t("toHome"),
    view: ScreenHome
  }),
  "/boxes": compose(withState(({state}) => state), mount({
    "/pairing": route({
      title: t("box:pairing"),
      view: BoxPairingScreen
    }),
    "/:boxId/paired": route((req) => ({
      title: t("box:paired"),
      view: (pass) => /* @__PURE__ */ React.createElement(BoxPairedScreen, {
        boxId: req.params.boxId,
        create: true,
        ...pass
      })
    })),
    "/:boxId/edit": route((req) => ({
      title: t("box:edit2"),
      view: (pass) => /* @__PURE__ */ React.createElement(BoxPairedScreen, {
        boxId: req.params.boxId,
        ...pass
      })
    })),
    "/:boxId/keys": route((req) => ({
      title: t("box:key.generation"),
      view: (pass) => /* @__PURE__ */ React.createElement(KeysListScreen, {
        boxId: req.params.boxId,
        ...pass
      })
    })),
    "/:boxId/permissions": route((req) => ({
      title: t("box:permissionsToUse"),
      view: (pass) => /* @__PURE__ */ React.createElement(PermissionsScreen, {
        boxId: req.params.boxId,
        ...pass
      })
    }))
  })),
  "/send": compose(withState(({state}) => state), withView(() => /* @__PURE__ */ React.createElement(StripeProvider, null, /* @__PURE__ */ React.createElement(View, null))), mount({
    "/": redirect("1"),
    "/1": route({
      title: t("delivery:sendToBox"),
      view: Step1Screen
    }),
    "/1/address": route({
      title: t("delivery:sendToAddress"),
      view: Step1ScreenAddress
    }),
    "/2": route({
      title: t("delivery:send"),
      view: Step2Screen
    }),
    "/3": mount({
      "/payment-success": route({
        title: t("delivery:pay"),
        view: Step3PaymentSuccessScreen
      })
    })
  })),
  "/history": mount({
    "/": redirect("incoming"),
    "/incoming": route({
      title: t("delivery:historyReceived"),
      view: (pass) => /* @__PURE__ */ React.createElement(HistoryScreen, {
        incoming: true,
        ...pass
      })
    }),
    "/sending": route({
      title: t("delivery:historySent"),
      view: (pass) => /* @__PURE__ */ React.createElement(HistoryScreen, {
        ...pass
      })
    })
  }),
  "/myboxes": route({
    title: t("box:label", {count: 5}),
    view: MyBoxesScreen
  }),
  "/settings": route({
    title: t("settings:label"),
    view: SettingsScreen
  }),
  "/deliveries": compose(withState(({state}) => state), mount({
    [`/${DELIVERY_DIRECTION.SENDING}/:id`]: route((req) => ({
      title: t("delivery:label", {count: 1}),
      view: (pass) => /* @__PURE__ */ React.createElement(DeliverySenderScreen, {
        deliveryId: req.params.id,
        ...pass
      })
    })),
    [`/${DELIVERY_DIRECTION.INCOMING}/:id`]: route((req) => ({
      title: t("delivery:label", {count: 1}),
      view: (pass) => /* @__PURE__ */ React.createElement(DeliveryRecipientScreen, {
        deliveryId: req.params.id,
        ...pass
      })
    })),
    [`/${DELIVERY_DIRECTION.INCOMING}/:id/confirm`]: route((req) => ({
      title: t("delivery:check"),
      view: (pass) => /* @__PURE__ */ React.createElement(DeliveryConfirmScreen, {
        deliveryId: req.params.id,
        ...pass
      })
    }))
  })),
  "/wombat": lazy(() => import("./wombat/routes.js").then(({default: routes}) => routes)),
  "/servicer": lazy(() => import("./servicer/routes.js").then(({default: routes}) => routes)),
  "/multibox": lazy(() => import("./multibox/routes.js").then(({default: routes}) => routes)),
  "/organisation": lazy(() => import("./organisation/routes.js").then(({default: routes}) => routes))
};
const allRoutes = mount({
  "/404/:type": route((request) => {
    return {
      title: "fallback",
      view: (pass) => /* @__PURE__ */ React.createElement(FallbackPath, {
        type: request.params.type,
        ...pass
      })
    };
  }),
  "/welcome": route({
    title: t("welcome"),
    view: WelcomeScreen
  }),
  "/register": route({
    title: t("registration"),
    view: AuthRegistrationScreen
  }),
  "/login": route({
    title: t("login2"),
    view: AuthLoginScreen
  }),
  "/logout": redirect(() => {
    signOut(auth());
    return "/login";
  }),
  "/verify": compose(withState(({state}) => state), route({
    title: t("verification"),
    view: AuthVerificationScreen
  })),
  "/qrbox": lazy(() => import("./qrbox/QrBoxApp.js").then(({boxQrRoutes}) => boxQrRoutes)),
  "/keys": lazy(() => import("./openlinks/OpenLinksApp.js").then(({openLinksRoutes}) => openLinksRoutes)),
  "/messenger": lazy(() => import("./messenger/MessengerApp.js").then(({messengerRoutes}) => messengerRoutes)),
  "/sandbox": route({
    title: "Sandbox",
    view: SandboxScreen
  }),
  "*": compose(withData(async (route2, context) => ({
    user: context.user,
    userToken: context.userToken,
    rollbar: context.rollbar
  })), withView((request, {user}) => !user ? /* @__PURE__ */ React.createElement("div", {
    className: "app-loading"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "app-loading__text"
  }, t("loadingUsers"))) : /* @__PURE__ */ React.createElement(View, null)), map((request, {user, userLoaded}) => !user && userLoaded ? redirect("/login?redirectTo=" + encodeURIComponent(request.originalUrl), {exact: false}) : mount(authenticatedRoutes)))
});
export default function App({rollbar}) {
  const [user, setUser] = useState();
  const [userLoaded, setUserLoaded] = useState(false);
  const [userToken, setUserToken] = useState();
  useOfflineAbilities();
  usePushNotifications({user});
  useEffect(() => onAuthStateChanged(auth(), (user2) => {
    setUser(user2);
    setUserLoaded(true);
    if (user2) {
      user2.getIdTokenResult().then(setUserToken);
    }
  }), []);
  useSetupAuthUser(user, rollbar);
  return /* @__PURE__ */ React.createElement(ErrorBoundary, {
    logger: rollbar
  }, /* @__PURE__ */ React.createElement(Router, {
    routes: allRoutes,
    context: {user, userLoaded, userToken, rollbar}
  }, /* @__PURE__ */ React.createElement(DeepLinkTrapper, {
    rollbar
  }), /* @__PURE__ */ React.createElement(ReactAlert.Provider, {
    template: ReactAlertTemplate,
    ...reactAlertOptions
  }, /* @__PURE__ */ React.createElement(NotFoundBoundary, {
    render: () => /* @__PURE__ */ React.createElement(FallbackPath, null)
  }, /* @__PURE__ */ React.createElement(Suspense, {
    fallback: /* @__PURE__ */ React.createElement(Loader, null)
  }, /* @__PURE__ */ React.createElement(View, null))))));
}
