import React from "../../_snowpack/pkg/react.js";
import "./BoxItem.css";
import BatteryIcon from "../../components/BatteryIcon.js";
import useBoxBattery from "../../hooks/useBoxBattery.js";
export default function BoxItem({box, onClick}) {
  const {battery} = useBoxBattery(box.id);
  let title = [box.id];
  if (box.isMultibox) {
    title.push(" ", /* @__PURE__ */ React.createElement("i", {
      key: "type",
      style: {fontSize: "71%", opacity: 0.61}
    }, "(multibox)"));
  } else if (box.isPartition) {
    title.push(" ", /* @__PURE__ */ React.createElement("i", {
      key: "type",
      style: {fontSize: "71%", opacity: 0.61}
    }, "(schránka multiboxu)"));
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "wombat-box-item"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "wombat-box-item__card mb8 pl16 pr16",
    onClick,
    "data-boxid": box.id
  }, /* @__PURE__ */ React.createElement("div", {
    className: "wombat-box-item__battery"
  }, /* @__PURE__ */ React.createElement("b", null, battery?.batteryLevel || "?", " %"), /* @__PURE__ */ React.createElement(BatteryIcon, {
    level: battery?.batteryLevel,
    width: "24px",
    className: "ml8"
  })), /* @__PURE__ */ React.createElement("h1", null, title), /* @__PURE__ */ React.createElement("p", null, box.name), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("span", null, box.address)), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("span", null, "Key: ", box.secretCode?.map((s) => s.toString(16).padStart(2, "0"))))));
}
