import React from "../_snowpack/pkg/react.js";
import Logo from "../images/logo-inline.js";
import "./HeaderLogo.css";
export default function HeaderLogo({link}) {
  const logo = /* @__PURE__ */ React.createElement(Logo, {
    height: "24px",
    className: "header-logo-component header-logo-component--white"
  });
  if (link) {
    return /* @__PURE__ */ React.createElement("a", {
      href: link,
      title: "Logo",
      className: "header-logo-link"
    }, logo);
  }
  return logo;
}
