import React, {useCallback, useMemo} from "../../_snowpack/pkg/react.js";
import {Trans, useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Divider from "../../components/Divider.js";
import ActionsModal from "../../modals/ActionsModal.js";
import AddUserModal from "../../modals/AddUserModal.js";
import useAlert from "../../hooks/useAlert.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import useBox from "../../hooks/useBox.js";
import useBoxPermissions from "../../hooks/useBoxPermissions.js";
import IconAdd from "../../images/icon-add-circle.js";
import Button from "../../components/Button.js";
import "./PermissionsScreen.css";
const capitalize = (value = "") => !value ? "" : `${value[0].toUpperCase()}${value.substring(1, value.length)}`;
export function BoxPermissionsList({
  title,
  listKey,
  onItemClick,
  onAddItemClick,
  items = [],
  disabled
}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "box-permissions-screen__list"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "box-permissions-screen__list-title"
  }, title), items.map((item) => /* @__PURE__ */ React.createElement("div", {
    key: item.uid,
    className: "box-permissions-screen__item",
    onClick: () => {
      onItemClick(listKey, item);
    }
  }, /* @__PURE__ */ React.createElement("p", {
    className: "box-permissions-screen__item-display-name"
  }, item.displayName), /* @__PURE__ */ React.createElement("p", {
    className: "box-permissions-screen__item-phone-number"
  }, item.phoneNumber))), !items.length && /* @__PURE__ */ React.createElement("p", {
    className: "p16 center-text small-text"
  }, /* @__PURE__ */ React.createElement("em", null, t("listEmpty"))), /* @__PURE__ */ React.createElement(Button, {
    type: "secondary",
    onClick: (event) => {
      event.preventDefault();
      onAddItemClick(listKey);
    },
    disabled
  }, /* @__PURE__ */ React.createElement(IconAdd, {
    className: "box-permissions-screen__list-add-icon"
  }), " ", t("add")));
}
export default function PermissionsScreen({
  boxId
}) {
  const {t} = useTranslation();
  const alert = useAlert();
  const handleScreenError = useCallback((err) => {
    if (err.code === "user-not-found") {
      alert.info(t("permissions:noUserExistsWithThisPhone"));
    } else {
      alert.error(t("error:oopsSomethingWentWrong"), err);
    }
  }, [alert, t]);
  const {openModal, closeModal, modal} = useMobileModal();
  const {isLoading: isLoadingBox} = useBox(boxId, {
    onError: handleScreenError
  });
  const {
    boxPermissions,
    isLoading: isLoadingBoxPermissions,
    reload: reloadBoxPermissions
  } = useBoxPermissions(boxId, {onError: handleScreenError});
  const isLoading = isLoadingBox || isLoadingBoxPermissions;
  const generalPermissions = useMemo(() => [
    {listKey: "fullAccess", title: t("box:fullAccess")},
    {listKey: "denyAccess", title: t("box:denyAccess")}
  ], [t]);
  const granularPermissions = useMemo(() => [
    {listKey: "allowSendFrom", title: t("box:allowSendFrom")},
    {listKey: "allowSendTo", title: t("box:allowSendTo")},
    {
      listKey: "allowReceiveTo",
      title: t("box:allowReceiveTo")
    }
  ], [t]);
  const handleAllowInstantReceptionChange = async (event) => {
    try {
      await httpsCallable(functions(), "box-updatePermissions")({
        boxId,
        allowInstantReception: !!event?.target?.checked
      });
      await reloadBoxPermissions();
    } catch (err) {
      handleScreenError(err);
    }
  };
  const handleAllowRequestReceptionChange = async (event) => {
    try {
      await httpsCallable(functions(), "box-updatePermissions")({
        boxId,
        allowRequestReception: !!event?.target?.checked
      });
      await reloadBoxPermissions();
    } catch (err) {
      handleScreenError(err);
    }
  };
  const handleAddUser = async (listKey, phoneNumber) => {
    try {
      await httpsCallable(functions(), "box-updatePermissions")({
        boxId,
        [`add${capitalize(listKey)}`]: [phoneNumber]
      });
      await reloadBoxPermissions();
    } catch (err) {
      handleScreenError(err);
    }
  };
  const handleRemoveUser = async (listKey, user) => {
    try {
      await httpsCallable(functions(), "box-updatePermissions")({
        boxId,
        [`remove${capitalize(listKey)}`]: [user.uid]
      });
      await reloadBoxPermissions();
    } catch (err) {
      handleScreenError(err);
    }
  };
  const handleItemClick = (listKey, item) => {
    openModal(/* @__PURE__ */ React.createElement(ActionsModal, {
      title: t("options"),
      actions: [
        {
          text: t("remove"),
          onClick: () => handleRemoveUser(listKey, item).then(closeModal)
        }
      ],
      descriptions: [
        /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("strong", null, item?.displayName), ", ", item?.phoneNumber)
      ]
    }));
  };
  const handleAddItemClick = (listKey) => {
    openModal(/* @__PURE__ */ React.createElement(AddUserModal, {
      onError: handleScreenError,
      onSave: (phoneNumber) => handleAddUser(listKey, phoneNumber).then(closeModal)
    }));
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "box-permissions-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("permissions:label")), /* @__PURE__ */ React.createElement("p", null, t("box:permissionsSettingsHere"), " #", boxId, "."), /* @__PURE__ */ React.createElement("div", {
    className: "box-permissions-screen__checkbox"
  }, /* @__PURE__ */ React.createElement("h2", null, t("delivery:public")), /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "delivery:preferencesQRCodeOpening",
    t
  }, "Předvolby pro práci se zásilkami mimo PostCube dopravou. Týká se otevření schrány pomocí ", /* @__PURE__ */ React.createElement("strong", null, "QR kódu"), ".")), /* @__PURE__ */ React.createElement("label", {
    className: "checkbox-container"
  }, /* @__PURE__ */ React.createElement("input", {
    disabled: isLoading,
    className: "checkbox",
    type: "checkbox",
    checked: boxPermissions?.allowInstantReception || false,
    onChange: handleAllowInstantReceptionChange
  }), /* @__PURE__ */ React.createElement("span", {
    className: "checkbox-label"
  }, t("box:allowAnyoneToInsertDelivery")))), /* @__PURE__ */ React.createElement("div", {
    className: "box-permissions-screen__checkbox"
  }, /* @__PURE__ */ React.createElement("label", {
    className: "checkbox-container"
  }, /* @__PURE__ */ React.createElement("input", {
    disabled: isLoading,
    className: "checkbox mr8",
    type: "checkbox",
    checked: boxPermissions?.allowRequestReception || false,
    onChange: handleAllowRequestReceptionChange
  }), /* @__PURE__ */ React.createElement("span", {
    className: "checkbox-label"
  }, t("box:allowInsertDeliveryAfterConfirm")))), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("general")), generalPermissions.map((props, index) => /* @__PURE__ */ React.createElement(BoxPermissionsList, {
    key: index,
    ...props,
    items: boxPermissions?.[props.listKey],
    onItemClick: handleItemClick,
    onAddItemClick: handleAddItemClick,
    disabled: isLoading
  })), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("advanced")), granularPermissions.map((props, index) => /* @__PURE__ */ React.createElement(BoxPermissionsList, {
    key: index,
    ...props,
    items: boxPermissions?.[props.listKey],
    onItemClick: handleItemClick,
    onAddItemClick: handleAddItemClick,
    disabled: isLoading
  }))), modal);
}
