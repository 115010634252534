import React from "../_snowpack/pkg/react.js";
import {compose, mount, redirect, route, map, withState} from "../_snowpack/pkg/navi.js";
import DeliveriesScreen from "./screens/DeliveriesScreen.js";
import DeliveryScreen from "./screens/DeliveryScreen.js";
import DeliveryMapScreen from "./screens/DeliveryMapScreen.js";
import BoxesScreen from "./screens/BoxesScreen.js";
import BoxScreen from "./screens/BoxScreen.js";
import BoxesMapScreen from "./screens/BoxesMapScreen.js";
import UsersScreen from "./screens/UsersScreen.js";
import UserScreen from "./screens/UserScreen.js";
import LogsScreen from "./screens/LogsScreen.js";
import MoreScreen from "./screens/MoreScreen.js";
import LockersScreen from "./screens/LockersScreen.js";
import BoxActivationScreen from "./screens/BoxActivationScreen.js";
import Proto2Screen from "./screens/Proto2Screen.js";
const routes = {
  "/": redirect("deliveries"),
  "/deliveries": route(() => ({
    title: "All deliveries",
    view: /* @__PURE__ */ React.createElement(DeliveriesScreen, null)
  })),
  "/delivery/:id": compose(withState(({state}) => state), route(({params}) => ({
    title: "Delivery",
    view: (pass) => /* @__PURE__ */ React.createElement(DeliveryScreen, {
      id: params.id,
      ...pass
    })
  }))),
  "/delivery/:id/map": compose(withState(({state}) => state), route(({params}) => ({
    title: "Delivery map",
    view: (pass) => /* @__PURE__ */ React.createElement(DeliveryMapScreen, {
      id: params.id,
      ...pass
    })
  }))),
  "/boxes": route(() => ({
    title: "All boxes",
    view: /* @__PURE__ */ React.createElement(BoxesScreen, null)
  })),
  "/boxes/map": compose(withState(({state}) => state), route(() => ({
    title: "Boxes map",
    view: (pass) => /* @__PURE__ */ React.createElement(BoxesMapScreen, {
      ...pass
    })
  }))),
  "/box/:id": compose(withState(({state}) => state), route(({params}) => ({
    title: `Box #${params.id}`,
    view: (pass) => /* @__PURE__ */ React.createElement(BoxScreen, {
      id: params.id,
      ...pass
    })
  }))),
  "/box/:id/activation": compose(withState(({state}) => state), route(({params}) => ({
    title: `Aktivace boxu`,
    view: (pass) => /* @__PURE__ */ React.createElement(BoxActivationScreen, {
      id: params.id,
      ...pass
    })
  }))),
  "/box/:id/map": compose(withState(({state}) => state), route(({params}) => ({
    title: "Box map",
    view: (pass) => /* @__PURE__ */ React.createElement(BoxesMapScreen, {
      id: params.id,
      ...pass
    })
  }))),
  "/users": route(() => ({
    title: "All users",
    view: /* @__PURE__ */ React.createElement(UsersScreen, null)
  })),
  "/user/:id": compose(withState(({state}) => state), route(({params}) => ({
    title: "User",
    view: (pass) => /* @__PURE__ */ React.createElement(UserScreen, {
      id: params.id,
      ...pass
    })
  }))),
  "/logs": route(() => ({
    title: "Logs",
    view: /* @__PURE__ */ React.createElement(LogsScreen, null)
  })),
  "/more": route(() => ({
    title: "More",
    view: /* @__PURE__ */ React.createElement(MoreScreen, null)
  })),
  "/more/lockers": route(() => ({
    title: "Lockers",
    view: /* @__PURE__ */ React.createElement(LockersScreen, null)
  })),
  "/more/proto2": route(() => ({
    title: "Multibox Protocol",
    view: /* @__PURE__ */ React.createElement(Proto2Screen, null)
  }))
};
export default mount({
  "*": map((request, {userToken}) => userToken.claims.wombat ? mount(routes) : route(() => ({
    title: "404 Error",
    view: () => /* @__PURE__ */ React.createElement("div", {
      style: {height: "100vh"},
      className: "center-flex center-text"
    }, "Co sem lezeš⁉️⁉️⁉️ ", /* @__PURE__ */ React.createElement("br", null), "😠👊😠👊👊🚷🚓")
  })))
});
