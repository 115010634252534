import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import "./DeliveriesScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import TabbarWombat from "../components/TabbarWombat.js";
import HeaderBack from "../../components/HeaderBack.js";
import DeliveryItem from "../components/DeliveryItem.js";
import Loader from "../../components/Loader.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import useAlert from "../../hooks/useAlert.js";
import useCollection from "../../hooks/useCollection.js";
export default function DeliveriesScreen() {
  const navigation = useNavigation();
  const alert = useAlert();
  const [showEshopDeliveries, setShowEshopDeliveries] = useState(false);
  const [searchText, setSearchText] = useState("");
  const applySearch = (data) => !searchText || data?.code?.startsWith(searchText.toUpperCase()) || data?.sender?.id?.startsWith(searchText) || data?.recipient?.id?.startsWith(searchText) || data?.sender?.orderNumber?.startsWith(searchText) || data?.sender?.name.toUpperCase().startsWith(searchText.toUpperCase());
  const handleSearch = (text) => {
    setSearchText(text);
  };
  const handleChange = () => {
    setShowEshopDeliveries(!showEshopDeliveries);
  };
  const [allDeliveries = [], allDeliveriesLoading, allErrors] = useCollection(`/deliveries`, {
    orderBy: ["updatedAt", "desc"],
    limit: 100
  });
  const [eshopDeliveries = [], eshopDeliveriesLoading, eshopErrors] = useCollection(`/deliveries`, {
    where: ["sender.type", "==", "eshop"],
    orderBy: ["updatedAt", "desc"],
    limit: 100
  });
  useEffect(() => {
    const dataError = allErrors || eshopErrors;
    if (dataError)
      alert.error(dataError);
  }, [alert, allErrors, eshopErrors]);
  const deliveries = (!showEshopDeliveries ? allDeliveries : eshopDeliveries).map((box) => ({
    ...box,
    ...(showEshopDeliveries ? eshopDeliveries : allDeliveries).find((b) => b.id === box.id)
  }));
  return /* @__PURE__ */ React.createElement("div", {
    className: "deliveries-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    path: "/settings",
    text: "PostCube"
  }), null), /* @__PURE__ */ React.createElement("div", {
    className: "deliveries-wombat-screen__boxes"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "deliveries-wombat-screen__header"
  }, /* @__PURE__ */ React.createElement("h1", null, "Zásilky"), /* @__PURE__ */ React.createElement("div", {
    className: "deliveries-wombat-screen__menu"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "deliveries-wombat-screen__input",
    type: "text",
    placeholder: "Zásilka",
    value: searchText,
    onChange: (event) => handleSearch(event.target.value)
  }), /* @__PURE__ */ React.createElement("div", {
    className: "deliveries-wombat-screen__filters"
  }, /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("input", {
    className: "mr8",
    type: "checkbox",
    checked: showEshopDeliveries,
    onChange: handleChange
  }), /* @__PURE__ */ React.createElement("span", null, "Pouze z eshopů"))))), (allDeliveriesLoading || eshopDeliveriesLoading) && /* @__PURE__ */ React.createElement(Loader, null), deliveries.filter(applySearch).map((delivery) => /* @__PURE__ */ React.createElement(DeliveryItem, {
    key: delivery.id,
    delivery,
    onClick: () => {
      navigation.navigate(`delivery/${delivery.id}`);
    }
  }))), /* @__PURE__ */ React.createElement(TabbarWombat, {
    deliveries: true
  }));
}
