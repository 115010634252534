import React from "../_snowpack/pkg/react.js";
import {compose, mount, route, withState, withView} from "../_snowpack/pkg/navi.js";
import {View} from "../_snowpack/pkg/react-navi.js";
import OrganisationScreen from "./screens/OrganisationScreen.js";
import AppScreen from "./screens/AppScreen.js";
import {t} from "../_snowpack/pkg/i18next.js";
const routes = {
  "/": compose(withState(({state}) => state), withView((request, {userToken}) => {
    return !userToken?.claims?.organisation ? /* @__PURE__ */ React.createElement("div", {
      className: "app-loading"
    }, /* @__PURE__ */ React.createElement("p", {
      className: "app-loading__text"
    }, t("auth:sectionAccessDenied"))) : /* @__PURE__ */ React.createElement(View, null);
  }), route((request, {}) => ({
    title: `Postcube Organisation`,
    view: (pass) => /* @__PURE__ */ React.createElement(OrganisationScreen, {
      ...pass
    })
  }))),
  "/app/:appId": compose(withState(({state}) => state), withView((request, {userToken}) => {
    return !userToken?.claims?.organisation ? /* @__PURE__ */ React.createElement("div", {
      className: "app-loading"
    }, /* @__PURE__ */ React.createElement("p", {
      className: "app-loading__text"
    }, t("auth:sectionAccessDenied"))) : /* @__PURE__ */ React.createElement(View, null);
  }), route((request, {}) => ({
    title: `App`,
    view: (pass) => /* @__PURE__ */ React.createElement(AppScreen, {
      appId: request.params.appId,
      ...pass
    })
  })))
};
export default mount(routes);
