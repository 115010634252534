import {useCallback} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import BleBox, {
  SUCCESSFUL_BOX_RESPONSE,
  boxResponseToMessage
} from "../library/BleBox.js";
async function getBoxInfo(boxId) {
  const response = await httpsCallable(functions(), "box-serviceUnlock")({
    boxId
  });
  if (!response.data) {
    throw new Error("Unregistered box");
  }
  return response.data;
}
async function updateBoxUnlockCounter(boxId) {
  const response = await httpsCallable(functions(), "box-updateUnlockCounter")({
    boxId
  });
  if (!response.data) {
    throw new Error("Box not found");
  }
  if (!response.data.hasBeenUnlocked) {
    throw new Error("Unlock counter failed to update");
  }
  return response.data;
}
export default function useServiceBoxes() {
  const {t} = useTranslation();
  const unlock = useCallback(async (bleDevice) => {
    const bleBox = BleBox(bleDevice);
    const {publicKey, secretCode, unlockCommand} = await getBoxInfo(bleDevice.id);
    return await bleBox.transaction(async () => {
      let response = await bleBox.saveAccountKey(publicKey, secretCode);
      if (response !== SUCCESSFUL_BOX_RESPONSE) {
        throw new Error(`${t("error:unlock")}: ${boxResponseToMessage(response)}`);
      }
      response = await bleBox.unlockWithOnlineKey(unlockCommand);
      if (response !== SUCCESSFUL_BOX_RESPONSE) {
        throw new Error(`${t("error:unlock")}: ${boxResponseToMessage(response)}`);
      }
      await updateBoxUnlockCounter(bleDevice.id);
      const battery = await bleBox.readBattery();
      return battery;
    });
  }, [t]);
  return {
    unlock
  };
}
