import React, {useCallback, useEffect, useState} from "../_snowpack/pkg/react.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import BoxEditForm from "../components/BoxEditForm.js";
import {defaultBox} from "../screens/Box/PairedScreen.js";
import usePreviousValue from "./usePreviousValue.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
export default function useBoxEditForm({
  onSuccess = console.log,
  onError = console.error,
  box,
  disabled,
  submitButtonLabel
}) {
  const {t} = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(defaultBox);
  const prevBox = usePreviousValue(box);
  useEffect(() => {
    if (!box || disabled || prevBox === box) {
      return;
    }
    setFormValues(Object.keys(defaultBox).reduce((data, key) => ({
      ...data,
      [key]: data[key] || box[key] || defaultBox[key]
    }), formValues));
  }, [box, prevBox, formValues, disabled]);
  const handleEditBox = useCallback(async (e, formValues2) => {
    e.preventDefault();
    if (submitting)
      return;
    setSubmitting(true);
    try {
      const createAddressResponse = await httpsCallable(functions(), "address-create")({
        scope: "box",
        street: formValues2.addressStreet,
        streetNumber: formValues2.addressStreetNumber,
        city: formValues2.addressCity,
        psc: formValues2.addressPSC,
        country: formValues2.addressCountry
      });
      if (!createAddressResponse?.data?.isValid) {
        throw new Error(t("address.enteredNotFound"));
      }
      const {addressTokenId, address} = createAddressResponse.data;
      console.warn("Never used variable", {address});
      await httpsCallable(functions(), "box-edit")({
        ...formValues2,
        addressTokenId
      });
      onSuccess();
    } catch (err) {
      onError(err);
    } finally {
      setSubmitting(false);
      console.timeEnd("submit");
    }
  }, [submitting, onSuccess, onError, t]);
  const form = /* @__PURE__ */ React.createElement(BoxEditForm, {
    onSubmit: handleEditBox,
    submitButtonLabel,
    submitting,
    submitDisabled: disabled,
    formValues,
    setFormValues
  });
  return [form, submitting];
}
