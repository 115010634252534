import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import "./UsersScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import {functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import Tabbar from "../components/TabbarWombat.js";
import HeaderBack from "../../components/HeaderBack.js";
import UserItem from "../components/UserItem.js";
import {removeSpaces} from "../../library/utils/functions.js";
import Loader from "../../components/Loader.js";
import useAlert from "../../hooks/useAlert.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
export default function UsersScreen() {
  const navigation = useNavigation();
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [nextPage, setNextPage] = useState();
  const alert = useAlert();
  const applySearch = (data) => data.phoneNumber.includes(removeSpaces(searchText));
  const handleSearch = (text) => {
    setSearchText(text);
  };
  const fetchUsers = async () => {
    if (users.length > 0 && !nextPage) {
      return;
    }
    try {
      const {data} = await httpsCallable(functions(), "allUsers-listAllUsers")({
        limit: 100,
        page: nextPage
      });
      setNextPage(data.nextPage);
      setUsers([...users, ...data.users]);
    } catch (err) {
      alert.error(err);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "users-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    path: "/settings",
    text: "PostCube"
  }), null), /* @__PURE__ */ React.createElement("div", {
    className: "users-wombat-screen__boxes"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "users-wombat-screen__header"
  }, /* @__PURE__ */ React.createElement("h1", null, "Users"), /* @__PURE__ */ React.createElement("div", {
    className: "users-wombat-screen__menu"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "users-wombat-screen__input",
    type: "text",
    placeholder: "Telefon",
    value: searchText,
    onChange: (event) => handleSearch(event.target.value)
  }))), users.length == 0 && /* @__PURE__ */ React.createElement(Loader, null), users.filter(applySearch).map((user) => /* @__PURE__ */ React.createElement(UserItem, {
    key: user.phoneNumber,
    user,
    onClick: () => navigation.navigate(`/wombat/user/${user.uid}`, {
      state: {user}
    })
  }))), /* @__PURE__ */ React.createElement(Tabbar, {
    users: true
  }));
}
