import React, {useMemo, useState, useEffect, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Button from "../../components/Button.js";
import Stepper from "../../components/Stepper.js";
import Divider from "../../components/Divider.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import ListSelectTransportProviders from "../../components/ListSelectTransportProviders.js";
import useAlert from "../../hooks/useAlert.js";
import useTransportProviders from "../../hooks/useTransportProviders.js";
import useDelivery from "../../hooks/useDelivery.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import useUserPaymentMethods from "../../hooks/useUserPaymentMethods.js";
import Loader from "../../components/Loader.js";
import {DELIVERY_TRANSITION_NAME} from "../../_snowpack/pkg/@topmonks/postcube.js";
import PaymentUseCardModal, {
  APPLE_PAY_METHOD
} from "../../modals/PaymentUseCardModal.js";
import PaymentAddCardModal from "../../modals/PaymentAddCardModal.js";
import {functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import {DELIVERY_STATE_NAMES} from "../../_snowpack/pkg/@topmonks/postcube.js";
import {Capacitor} from "../../_snowpack/pkg/@capacitor/core.js";
import {Stripe as NativeStripe} from "../../_snowpack/pkg/@capacitor-community/stripe.js";
import {boolifyPromise} from "../../library/async.js";
const fetchDeliveryPaymentIntent = async (deliveryId) => {
  const {data: paymentIntent} = await httpsCallable(functions(), "delivery-payment")({
    deliveryId
  });
  return paymentIntent;
};
const defaultFormValues = {
  transportProvider: {}
};
export default function Step2Screen({
  route: {
    state: {deliveryId, deliveryPreview = {}}
  }
}) {
  const navigation = useNavigation();
  const alert = useAlert();
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [submitting, setSubmitting] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    if (!deliveryId)
      navigation.navigate("/", {replace: true});
  }, [deliveryId, navigation]);
  const {openModal, closeModal, modal} = useMobileModal({
    onClose: useCallback(() => {
      setSubmitting(false);
    }, [])
  });
  const handleScreenError = useCallback((err = new Error("unknown")) => {
    setSubmitting(false);
    let message = t("error:oopsSomethingWentWrong");
    if (err.code) {
      message = err.message;
    }
    alert.error(message, err);
    console.error(err);
  }, [alert, t]);
  const {delivery = deliveryPreview, updateDelivery} = useDelivery(deliveryId, {onError: handleScreenError});
  const transportProviders = useTransportProviders(deliveryId, {
    onError: handleScreenError
  });
  const paymentMethods = useUserPaymentMethods({onError: handleScreenError});
  const totalPrice = useMemo(() => formValues.transportProvider.price, [formValues]);
  const openAddCardModal = useCallback(({paymentIntent, hasSavedCards, hasApplePay}) => {
    openModal(/* @__PURE__ */ React.createElement(PaymentAddCardModal, {
      deliveryId,
      submitLabel: t("payment:payAmount", {
        amount: totalPrice
      }),
      hasOtherPaymentMethods: hasSavedCards || hasApplePay,
      clientSecret: paymentIntent.client_secret,
      onUseCard: () => {
        closeModal();
        openUseCardModal({paymentIntent, hasApplePay, hasSavedCards});
      },
      onError: handleScreenError
    }));
  }, [openModal, deliveryId, totalPrice, handleScreenError]);
  const openUseCardModal = useCallback(({paymentIntent, hasSavedCards, hasApplePay}) => {
    openModal(/* @__PURE__ */ React.createElement(PaymentUseCardModal, {
      deliveryId,
      order: {
        price: totalPrice,
        label: `${t("delivery:transport")} ${formValues.transportProvider.name}`,
        currency: "CZK"
      },
      paymentMethods: [
        ...paymentMethods,
        hasApplePay && APPLE_PAY_METHOD
      ].filter(Boolean),
      clientSecret: paymentIntent.client_secret,
      onAddCard: () => {
        closeModal();
        openAddCardModal({paymentIntent, hasSavedCards, hasApplePay});
      },
      onError: handleScreenError,
      onSuccess: closeModal
    }));
  }, [
    closeModal,
    deliveryId,
    formValues.transportProvider.name,
    handleScreenError,
    openAddCardModal,
    openModal,
    paymentMethods,
    totalPrice,
    t
  ]);
  useEffect(() => {
    if (delivery.state === DELIVERY_STATE_NAMES.PAID) {
      closeModal();
      navigation.navigate("/send/3/payment-success", {
        state: {deliveryId: delivery.id}
      });
    }
  }, [closeModal, delivery, navigation]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const {transportProvider} = formValues;
    setSubmitting(true);
    try {
      await updateDelivery({transportProvider}, DELIVERY_TRANSITION_NAME.UPDATE_FORM);
      const paymentIntent = await fetchDeliveryPaymentIntent(deliveryId);
      const hasSavedCards = paymentMethods.length > 0;
      const hasApplePay = Capacitor.isNativePlatform() && await boolifyPromise(NativeStripe.isApplePayAvailable());
      if (paymentMethods.length || hasApplePay) {
        openUseCardModal({paymentIntent, hasApplePay, hasSavedCards});
      } else {
        openAddCardModal({paymentIntent});
      }
    } catch (error) {
      handleScreenError(error);
    }
  };
  const isSubmitDisabled = !formValues.transportProvider.id || submitting || delivery.state === DELIVERY_STATE_NAMES.PAID;
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-send"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    text: t("delivery:recipient"),
    onBack: async (nav) => {
      await nav.goBack();
      await nav.navigate(`/send/1/${delivery.recipient.type || ""}`, {
        replace: true,
        state: {deliveryId}
      });
    }
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement(Stepper, {
    step: "2"
  }), /* @__PURE__ */ React.createElement("h1", null, t("delivery:chooseCarrier")), /* @__PURE__ */ React.createElement(ListSelectTransportProviders, {
    transportProviders,
    selected: formValues.transportProvider,
    onChange: (transportProvider) => {
      setFormValues({transportProvider});
    }
  }), transportProviders?.length > 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Divider, null), totalPrice && /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, t("payment:totalPrice", {total: totalPrice})), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: isSubmitDisabled
  }, submitting && /* @__PURE__ */ React.createElement(Loader, {
    text: t("processing")
  }), !submitting && (formValues.transportProvider.id ? t("payment:goTo") : t("delivery:chooseCarrierPlease")))))), modal);
}
