import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import Divider from "../../components/Divider.js";
import Logo from "../../images/logo-inline.js";
import IconAlert from "../../images/icon-alert.js";
import "./Screen.css";
export default function MessengerDeliveryHelpScreen() {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__header"
  }, /* @__PURE__ */ React.createElement(Logo, {
    fill: "var(--color-white)"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__view"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "mt16 mb16"
  }, t("hint")), t("help:text"), /* @__PURE__ */ React.createElement("div", {
    className: "screen-help__container"
  }, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("help:1.heading")), /* @__PURE__ */ React.createElement("p", null, t("help:1.text")), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help1.png",
    className: "mt16"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-help__container"
  }, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("help:2.heading")), /* @__PURE__ */ React.createElement("p", null, t("help:2.text")), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help2.png",
    className: "mt16"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-help__container"
  }, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("help:3.heading")), /* @__PURE__ */ React.createElement("p", {
    className: "mb8"
  }, t("help:3.text")), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement(IconAlert, {
    width: "16px",
    className: "mr8",
    fill: "var(--color-alert)"
  }), t("help:3.extra")), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help3.png",
    className: "mt16"
  }), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help4.png",
    className: "mt16"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-help__container"
  }, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("help:5.heading")), /* @__PURE__ */ React.createElement("p", null, t("help:4.text")), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help5.png",
    className: "mt16"
  }), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help6.png",
    className: "mt16"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-help__container"
  }, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h2", null, t("help:5.heading")), /* @__PURE__ */ React.createElement("p", null, t("help:5.text")), /* @__PURE__ */ React.createElement("img", {
    src: "../../images/help/help8.png",
    className: "mt16"
  }))));
}
