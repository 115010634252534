import { DELIVERY_STATE_VISIBILITY } from "../../_snowpack/pkg/@topmonks/postcube.js";
import { unionBy } from "../../_snowpack/pkg/lodash.js";
import useCollection from "../../hooks/useCollection.js";

const HISTORY_DELIVERIES_LIMIT = 50;
const historyStateFilter = ["state", "in", DELIVERY_STATE_VISIBILITY.HISTORY];
const orderBy = ["updatedAt", "desc"];

const useDeliveriesForBox = (boxId) => {
  const [sentDeliveries = [], sentDeliveriesLoading, sentDeliveriesError] =
    useCollection(`/deliveries`, {
      where: [["sender.id", "==", boxId], historyStateFilter],
      orderBy,
      limit: HISTORY_DELIVERIES_LIMIT,
    });
  const [
    receivedDeliveries = [],
    receivedDeliveriesLoading,
    receivedDeliveriesError,
  ] = useCollection(`/deliveries`, {
    where: [["recipient.id", "==", boxId], historyStateFilter],
    orderBy,
    limit: HISTORY_DELIVERIES_LIMIT,
  });

  const deliveries = unionBy(
    [...sentDeliveries, ...receivedDeliveries],
    (delivery) => delivery?.id
  );

  return [
    deliveries,
    sentDeliveriesLoading || receivedDeliveriesLoading,
    sentDeliveriesError || receivedDeliveriesError,
  ];
};

export default useDeliveriesForBox;
