export function parseSecretCode(secretCode) {
  if (typeof secretCode !== "string") return secretCode;
  return secretCode.match(/.{1,2}/g).map((n) => parseInt(n, "16"));
}

export function ulongToArray(value) {
  return [
    (value >> 24) & 0xff,
    (value >> 16) & 0xff,
    (value >> 8) & 0xff,
    value & 0xff,
  ];
}
