import React, {useEffect} from "../_snowpack/pkg/react.js";
import Button from "./Button.js";
import Logo from "../images/logo-inline.js";
import DownloadBadge from "../images/download-appstore-cs.js";
import "./InstallPrompt.css";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
export default function InsallPrompt({onClose, ios}) {
  const {t} = useTranslation();
  useEffect(() => {
    document.body.style.backgroundColor = "var(--color-primary)";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "install-prompt"
  }, /* @__PURE__ */ React.createElement(Logo, {
    fill: "var(--color-white)",
    width: "80%",
    className: "mt64 mb32",
    title: "Logo Postcube"
  }), /* @__PURE__ */ React.createElement("h1", null, t("install:heading")), /* @__PURE__ */ React.createElement("a", {
    href: "https://apps.apple.com/cz/app/postcube/id1537386836?l=cs",
    title: t("install:download")
  }, /* @__PURE__ */ React.createElement(DownloadBadge, {
    width: "50%"
  })), !ios && /* @__PURE__ */ React.createElement("p", {
    className: "mt32 mb16 small-text"
  }, t("install:or")), /* @__PURE__ */ React.createElement(Button, {
    onClick: onClose,
    className: cs("install-prompt__close-button", ios && "install-prompt__close-button--ios")
  }, t("install:goToWebApp"), " ", ios && /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("br", null), t("install:withoutLockFuncs"))));
}
