import React, {useState, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {
  BOX_STATES,
  DELIVERY_TRANSITION_NAME,
  DELIVERY_STATE_NAMES
} from "../../_snowpack/pkg/@topmonks/postcube.js";
import "./DeliveryAction.css";
import Button from "../../components/Button.js";
import IconSend from "../../images/icon-arrow-recipient.js";
import DeliveryTimeline from "../../components/DeliveryTimeline.js";
import Warning from "../../components/Warning.js";
import IconBox from "../../images/icon-box.js";
import useMyBoxes from "../../hooks/useMyBoxes.js";
import useBleDevicesNearby from "../../hooks/useBleDevicesNearby.js";
import useBoxAvailability from "../../hooks/useBoxAvailability.js";
export default function SenderDeliveryAction({
  delivery,
  updateDelivery,
  user,
  onError,
  onSuccess
}) {
  const {t} = useTranslation();
  const {unlock: unlockMyBox} = useMyBoxes(user, {onError});
  const {devices: bleDevicesNearby, findNearby: findBleDeviceNearby} = useBleDevicesNearby({onError});
  const [unlocking, setUnlocking] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    isLoading: isLoadingBoxAvailability,
    boxState,
    battery
  } = useBoxAvailability(delivery?.sender?.id, {onError});
  const handleUnlockBox = async () => {
    setUnlocking(true);
    try {
      const box = delivery.sender;
      let bleDevice = bleDevicesNearby.find(({id}) => id === box.id);
      if (!bleDevice) {
        bleDevice = await findBleDeviceNearby(box);
      }
      await unlockMyBox(bleDevice);
      onSuccess(t("box:opened"));
      await updateDelivery(null, DELIVERY_TRANSITION_NAME.SENDER_OPENS_BOX);
    } catch (err) {
      onError(err);
    } finally {
      setUnlocking(false);
    }
  };
  const handleConfirmDeliveryTagged = useCallback(async () => {
    try {
      setSubmitting(true);
      await updateDelivery(null, DELIVERY_TRANSITION_NAME.PACKAGE_TAGGED);
    } finally {
      setSubmitting(false);
    }
  }, [updateDelivery]);
  const handleConfirmDelivery = useCallback(async () => {
    if (!confirm(t("delivery:makeSureItsOkay"))) {
      return;
    }
    try {
      setSubmitting(true);
      await updateDelivery(null, DELIVERY_TRANSITION_NAME.SENDER_CONFIRMS_DELIVERY);
    } finally {
      setSubmitting(false);
    }
  }, [updateDelivery, t]);
  if (delivery.state === DELIVERY_STATE_NAMES.PAID) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action delivery-action--"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__banner"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__hero-code"
    }, delivery.code), /* @__PURE__ */ React.createElement("h2", {
      className: "delivery-action__headline"
    }, t("delivery:markLegibly")), /* @__PURE__ */ React.createElement("p", {
      className: "delivery-action__instruction"
    }, t("delivery:markLegiblyInfo"))), /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__buttons"
    }, boxState === BOX_STATES.OFFLINE && /* @__PURE__ */ React.createElement(Warning, {
      type: "error",
      headline: t("box:warning.outOfBattery.headlineRecipient"),
      text: t("box:warning.outOfBattery.textWithPercent", {
        count: battery
      }),
      className: "mt16 mb16"
    }), /* @__PURE__ */ React.createElement(Button, {
      onClick: handleConfirmDeliveryTagged,
      disabled: submitting || boxState === BOX_STATES.OFFLINE,
      name: "ready2box"
    }, t("delivery:readyToInsert"))));
  }
  if ([
    DELIVERY_STATE_NAMES.READY_FOR_BOX,
    DELIVERY_STATE_NAMES.PACKAGE_IN_SENDER_BOX
  ].includes(delivery.state)) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action delivery-action--"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__banner"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__box-animation"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__box-animation-package"
    }), /* @__PURE__ */ React.createElement(IconBox, {
      width: "64px",
      height: "64px"
    })), /* @__PURE__ */ React.createElement("h2", {
      className: "delivery-action__headline"
    }, t("delivery:insertToBox2")), /* @__PURE__ */ React.createElement("p", {
      className: "delivery-action__instruction"
    }, t("box:checkIfCloseProperly"))), /* @__PURE__ */ React.createElement("div", {
      className: "delivery-action__buttons"
    }, boxState === BOX_STATES.OFFLINE && /* @__PURE__ */ React.createElement(Warning, {
      type: "error",
      headline: t("box:warning.outOfBattery.headlineRecipient"),
      text: t("box:warning.outOfBattery.textWithPercent", {
        count: battery
      }),
      className: "mt16 mb16"
    }), /* @__PURE__ */ React.createElement(Button, {
      secondary: true,
      onClick: handleUnlockBox,
      disabled: unlocking || isLoadingBoxAvailability || boxState === BOX_STATES.OFFLINE,
      className: "mb16",
      name: "open-box"
    }, t("box:open")), /* @__PURE__ */ React.createElement(Button, {
      className: "screen-delivery__button-send",
      onClick: handleConfirmDelivery,
      disabled: submitting || isLoadingBoxAvailability || boxState === BOX_STATES.OFFLINE,
      type: "submit",
      name: "send-delivery"
    }, /* @__PURE__ */ React.createElement(IconSend, {
      width: "24px",
      className: "mr8"
    }), t("delivery:send"))));
  }
  const showTimeline = ![
    DELIVERY_STATE_NAMES.FORM_IN_PROGRESS,
    DELIVERY_STATE_NAMES.PAYMENT_IN_PROGRESS
  ].includes(delivery.state);
  return /* @__PURE__ */ React.createElement("div", {
    className: "delivery-action delivery-action--timeline"
  }, showTimeline && /* @__PURE__ */ React.createElement(DeliveryTimeline, {
    state: delivery.state
  }));
}
