import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import IconBack from "../images/icon-back-white.js";
import "./HeaderBack.css";
export default function HeaderBack({onBack, text, path}) {
  const navigation = useNavigation();
  const {t} = useTranslation();
  const defaultOnBack = () => path ? navigation.navigate(path, {replace: true}) : navigation.goBack();
  return /* @__PURE__ */ React.createElement("div", {
    className: "header-back-component",
    onClick: () => onBack ? onBack(navigation) : defaultOnBack()
  }, /* @__PURE__ */ React.createElement(IconBack, {
    className: "mr4",
    alt: "back",
    height: "19px"
  }), /* @__PURE__ */ React.createElement("p", null, text || t("back")));
}
