import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import React, {useState, useEffect} from "../_snowpack/pkg/react.js";
import {Trans, useTranslation} from "../_snowpack/pkg/react-i18next.js";
import useBleDevicesNearby from "../hooks/useBleDevicesNearby.js";
import BleBox, {
  boxResponseToMessage,
  SUCCESSFUL_BOX_RESPONSE
} from "../library/BleBox.js";
import {expirationTime} from "../library/format.js";
import useAlert from "../hooks/useAlert.js";
import Button from "../components/Button.js";
import Loader from "../components/Loader.js";
import Divider from "../components/Divider.js";
import IconKey from "../images/icon-key.js";
import IconBox from "../images/icon-box.js";
import Logo from "../images/logo-inline.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import {StatusBar, Style} from "../_snowpack/pkg/@capacitor/status-bar.js";
import {Capacitor} from "../_snowpack/pkg/@capacitor/core.js";
import Warning from "../components/Warning.js";
import useDoc from "../hooks/useDoc.js";
import "./OpenLinkScreen.css";
const version = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CIRCLE_SHA1 || "dev";
export default function OpenLinksApp({
  linkId,
  route: {
    state: {deeplink}
  }
}) {
  const {t} = useTranslation();
  const [unlocking, setUnlocking] = useState(false);
  const navigation = useNavigation();
  const alert = useAlert();
  const [data, loading] = useDoc(`/open-links/${linkId}`, {
    skip: !linkId
  });
  useEffect(() => {
    if (!Capacitor.isNativePlatform())
      return;
    StatusBar.setStyle({style: Style.Light});
    return () => {
      StatusBar.setStyle({style: Style.Dark});
    };
  }, []);
  const {findNearby: findBleDeviceNearby, error: bleDevicesNearbyError} = useBleDevicesNearby();
  useEffect(() => {
    if (!loading && !data?.expireAt) {
      navigation.navigate("/404");
    }
    if (!loading && data?.expireAt < Date.now() / 1e3) {
      navigation.navigate("/404/invalid-key");
    }
  }, [data?.expireAt, loading, navigation]);
  const handleUnlockBox = async () => {
    if (unlocking || !data)
      return;
    try {
      const bleDevice = await findBleDeviceNearby({id: data.boxId});
      setUnlocking(true);
      const bleBox = BleBox(bleDevice);
      await bleBox.transaction(async () => {
        const response = await bleBox.unlockWithOnlineKey(data.command);
        if (response !== SUCCESSFUL_BOX_RESPONSE) {
          throw new Error(`${t("error:unlock")}: ${boxResponseToMessage(response)}`);
        }
      });
      alert.success(t("box:opened"));
    } catch (err) {
      alert.error(err);
    } finally {
      setUnlocking(false);
    }
  };
  if (loading) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "app-loading"
    }, /* @__PURE__ */ React.createElement("p", {
      className: "app-loading__text"
    }, t("loadingApp")));
  }
  const screenError = bleDevicesNearbyError;
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-open-keys__hero"
  }, /* @__PURE__ */ React.createElement(Logo, {
    height: "32px",
    className: "color-primary mt32"
  }), /* @__PURE__ */ React.createElement(IconKey, {
    width: "64px",
    className: "color-grey mb16"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "screen-open-keys__box-id"
  }, /* @__PURE__ */ React.createElement(IconBox, {
    width: "16px",
    className: "color-primary mr8"
  }), /* @__PURE__ */ React.createElement("h2", null, data?.boxId)), screenError && /* @__PURE__ */ React.createElement("div", {
    className: "screen-settings__error m16"
  }, /* @__PURE__ */ React.createElement(Warning, {
    ...screenError,
    text: screenError.message || screenError.code || screenError
  })), t("box:key.expiresIn", {time: expirationTime(data?.expireAt)}), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("div", {
    className: "screen-open-keys__help"
  }, /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "box:key.help.start",
    t
  }, "Začněte kliknutím na ", /* @__PURE__ */ React.createElement("b", null, "Najít schránku"))), /* @__PURE__ */ React.createElement("li", null, t("box:key.help.choose")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "box:key.help.pair",
    t
  }, "Klikněte na ", /* @__PURE__ */ React.createElement("b", null, "Spárovat")))), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16 screen-open-keys__button--unlock",
    loader: true,
    onClick: handleUnlockBox,
    disabled: unlocking,
    type: "submit",
    large: true
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    ghost: true,
    text: t("box:opening")
  }) : t("box:find"))), deeplink && /* @__PURE__ */ React.createElement("a", {
    href: "/",
    className: "screen-open-keys__link mt32",
    onClick: (event) => {
      event.preventDefault();
      navigation.navigate("/", {replace: true});
    }
  }, t("goHome")), /* @__PURE__ */ React.createElement("p", {
    className: "screen-open-keys__version"
  }, version));
}
