import React, {useState, useEffect, useMemo, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {unionBy} from "../../_snowpack/pkg/lodash.js";
import {BOX_STATES, DELIVERY_STATE_NAMES} from "../../_snowpack/pkg/@topmonks/postcube.js";
import AutosuggestTextInput from "../../components/AutosuggestTextInput.js";
import Select, {components as ReactSelectComponents} from "../../_snowpack/pkg/react-select.js";
import IconDelete from "../../images/icon-delete-circle.js";
import Button from "../../components/Button.js";
import FormTextarea from "../../components/FormTextarea.js";
import Divider from "../../components/Divider.js";
import DeliveryAdress from "../../components/DeliveryAddress.js";
import FormItem from "../../components/FormItem.js";
import {generateDeliveryCode, updateState} from "../../library/forms.js";
import Stepper from "../../components/Stepper.js";
import useAlert from "../../hooks/useAlert.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import useDelivery from "../../hooks/useDelivery.js";
import useBoxAvailability from "../../hooks/useBoxAvailability.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Loader from "../../components/Loader.js";
import Warning from "../../components/Warning.js";
import useMyBoxes from "../../hooks/useMyBoxes.js";
import {isValidBox} from "../../library/validation.js";
import {analytics, remoteConfig} from "../../firebase.js";
import {getBoolean} from "../../_snowpack/pkg/firebase/remote-config.js";
import {logEvent} from "../../_snowpack/pkg/firebase/analytics.js";
import useScanAndSelectSender from "../../hooks/useScanAndSelectSender.js";
import useDoc from "../../hooks/useDoc.js";
import useCollection from "../../hooks/useCollection.js";
import "./Step1Screen.css";
const ReactSelectSingleValue = (props) => /* @__PURE__ */ React.createElement(ReactSelectComponents.SingleValue, {
  ...props
}, /* @__PURE__ */ React.createElement("strong", null, props.data.id), " ", props.data.name, props.data.isMultibox && " (multibox)");
const ReactSelectOption = (props) => /* @__PURE__ */ React.createElement(ReactSelectComponents.Option, {
  ...props,
  isSelected: false
}, /* @__PURE__ */ React.createElement("strong", null, props.data.id), " ", props.data.name, props.data.isMultibox && " (multibox)");
const defaultFormValues = {
  recipientBoxId: "",
  note: ""
};
export default function Step1Screen({
  route: {
    data: {user},
    state: {deliveryId}
  }
}) {
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [suggestedRecipients, setSuggestedRecipients] = useState([]);
  const hasFeatureBoxBleSearch = useMemo(() => getBoolean(remoteConfig, "send_search_ble_box_nearby"), []);
  const [deliveries = []] = useCollection("/deliveries", {
    skip: !user,
    where: ["sender.ownerId", "==", user.uid],
    orderBy: ["updatedAt", "desc"],
    limit: 10,
    onError: handleScreenError
  });
  const {
    isAvailable: isRecipientBoxAvailable,
    boxState: recipientBoxState
  } = useBoxAvailability(formValues?.recipientBoxId, {
    onError: handleScreenError
  });
  const lastRecipients = useMemo(() => {
    const deduplicate = ({id}, index, list) => !list.slice(0, index).find((r) => r.id === id);
    return deliveries.map(({recipient: {id, name}}) => ({id, name})).filter(deduplicate);
  }, [deliveries]);
  const [recipient] = useDoc(`/boxes/${formValues.recipientBoxId}`, {
    skip: formValues.recipientBoxId?.length !== 6
  });
  const {boxes: myBoxes, loading: myBoxesLoading} = useMyBoxes(user, {
    onError: handleScreenError
  });
  const {selectedNearbyBox, scanAndSelect: handleScanAndSelect} = useScanAndSelectSender({
    onError: handleScreenError,
    scan: hasFeatureBoxBleSearch
  });
  const boxes = unionBy([...myBoxes, selectedNearbyBox].filter((box) => !!box), (box) => box.id);
  const [senderBoxId, setSenderBoxId] = useState();
  const sender = useMemo(() => boxes.find(({id}) => id === senderBoxId), [boxes, senderBoxId]);
  useEffect(() => {
    if (selectedNearbyBox && selectedNearbyBox.id) {
      setSenderBoxId(selectedNearbyBox.id);
    }
  }, [selectedNearbyBox]);
  const {
    isLoading: isLoadingSenderBoxAvailability,
    isAvailable: isSenderBoxAvailable,
    boxState: senderBoxState
  } = useBoxAvailability(senderBoxId, {onError: handleScreenError});
  useEffect(() => {
    if (boxes.length && !senderBoxId) {
      setSenderBoxId(boxes[0].id);
    }
  }, [boxes, senderBoxId]);
  const {delivery, updateDelivery, deleteDelivery} = useDelivery(deliveryId, {onError: handleScreenError}, ({note, code, recipient: recipient2, sender: _sender} = {}) => {
    if (recipient2?.id) {
      setFormValues({recipientBoxId: recipient2.id, note, code});
    }
    if (_sender?.id) {
      setSenderBoxId(_sender.id);
    }
  });
  const navigation = useNavigation();
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    logEvent(analytics, "create_delivery");
    setSubmitting(true);
    if (!isValidBox(recipient) || !isValidBox(sender)) {
      setSubmitting(false);
      if (!isValidBox(recipient))
        return handleScreenError(new Error(t("delivery:recipientBoxNotSelected")));
      if (!isValidBox(sender))
        return handleScreenError(new Error(t("delivery:senderBoxNotSelected")));
    }
    const completed = {
      note: formValues.note,
      code: generateDeliveryCode(),
      recipient,
      sender
    };
    updateDelivery(completed).then(({data: deliveryId2} = {}) => {
      setSubmitting(false);
      if (deliveryId2) {
        navigation.navigate(`/send/2`, {
          state: {deliveryId: deliveryId2, deliveryPreview: completed}
        });
      }
    });
  };
  const canBeDeleted = [
    DELIVERY_STATE_NAMES.FORM_IN_PROGRESS,
    DELIVERY_STATE_NAMES.PAYMENT_IN_PROGRESS
  ].includes(delivery?.state);
  const handleDelete = () => {
    if (!confirm(t("delivery:reallyDelete"))) {
      return;
    }
    if (!delivery) {
      navigation.goBack();
      return;
    }
    deleteDelivery().then(() => {
      alert.info(t("delivery:deleted"));
      navigation.goBack();
    });
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-send"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, {
    title: t("delivery:toBox")
  }, /* @__PURE__ */ React.createElement(HeaderBack, {
    text: t("toHome"),
    onBack: (nav) => {
      if (!delivery && formValues !== defaultFormValues) {
        if (confirm(t("cancelChanges"))) {
          nav.goBack();
        }
        return;
      }
      nav.goBack();
    }
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement(Stepper, {
    step: "1"
  }), /* @__PURE__ */ React.createElement("h1", null, t("delivery:whereTo")), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement(AutosuggestTextInput, {
    suggestions: suggestedRecipients,
    onSuggestionsFetchRequested: ({value, reason}) => {
      if (reason === "suggestion-selected") {
        return setSuggestedRecipients([]);
      }
      setSuggestedRecipients(lastRecipients.filter(({id, type}) => id && id.toString().includes(value) && !type));
    },
    onSuggestionsClearRequested: () => {
      setSuggestedRecipients([]);
    },
    inputProps: {
      value: formValues.recipientBoxId || "",
      label: t("box:insertId"),
      name: "recipientBoxId",
      onChange: (event, {newValue: recipientBoxId}) => {
        if (!recipientBoxId)
          recipientBoxId = null;
        setFormValues({...formValues, recipientBoxId});
      }
    }
  }), recipient && !isValidBox(recipient) && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.noAddress.headlineRecipient"),
    text: t("box:warning.noAddress.textRecipient"),
    className: "mt16 mb16"
  }), recipientBoxState === BOX_STATES.OFFLINE && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.outOfBattery.headlineRecipient"),
    text: t("box:warning.outOfBattery.textRecipient"),
    className: "mt16 mb16"
  }), recipientBoxState === BOX_STATES.BLOCKED_BY_DELIVERY && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.unavailable.headline"),
    text: t("box:warning.unavailable.textRecipient"),
    className: "mt16 mb16"
  }), recipientBoxState === BOX_STATES.OUT_OF_PARTITIONS && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.occupied.headline"),
    text: t("box:warning.occupied.text"),
    className: "mt16 mb16"
  }), /* @__PURE__ */ React.createElement(DeliveryAdress, {
    recipient: recipient || {},
    className: "mb16"
  }), /* @__PURE__ */ React.createElement(FormTextarea, {
    label: t("delivery:note"),
    value: formValues.note,
    name: "note",
    onChange: updateState(setFormValues)
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("h1", null, t("delivery:fromWhichBox")), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("box:chooseSenderBox")
  }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Select, {
    options: boxes,
    onChange: ({id}) => {
      setSenderBoxId(id);
    },
    value: sender,
    placeholder: t("choose"),
    isLoading: myBoxesLoading || isLoadingSenderBoxAvailability,
    components: {
      SingleValue: ReactSelectSingleValue,
      Option: ReactSelectOption
    }
  }))), sender && !sender.name && /* @__PURE__ */ React.createElement(Warning, {
    type: "alert",
    headline: t("box:warning.noName.headline"),
    text: t("box:warning.noName.text"),
    className: "mt16 mb16",
    link: `/boxes/${sender.id}/edit`
  }), sender && !isValidBox(sender) && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.noAddress.headline"),
    text: t("box:warning.noAddress.text"),
    className: "mt16 mb16",
    link: `/boxes/${sender.id}/edit`
  }), senderBoxState === BOX_STATES.OFFLINE && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.outOfBattery.headline"),
    text: t("box:warning.outOfBattery.text"),
    className: "mt16 mb16"
  }), senderBoxState === BOX_STATES.BLOCKED_BY_DELIVERY && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.unavailable.headline"),
    text: t("box:warning.unavailable.text"),
    className: "mt16 mb16"
  }), senderBoxState === BOX_STATES.OUT_OF_PARTITIONS && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.occupied.headline"),
    text: t("box:warning.occupied.text"),
    className: "mt16 mb16"
  }), hasFeatureBoxBleSearch && /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleScanAndSelect
  }, t("box:searchAround")), /* @__PURE__ */ React.createElement(DeliveryAdress, {
    sender: sender || {}
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: submitting || !isValidBox(recipient) || !isValidBox(sender) || !isRecipientBoxAvailable || !isSenderBoxAvailable,
    name: "continue2transport"
  }, submitting ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("creating")
  }) : t("continue"))), canBeDeleted && /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    onClick: handleDelete,
    className: "screen-delivery__button-delete mt32",
    disabled: submitting
  }, /* @__PURE__ */ React.createElement(IconDelete, {
    width: "24px",
    className: "mr8"
  }), " ", t("delivery:cancel"))));
}
