import React, {Fragment, useState, useCallback} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import Card from "../components/Card.js";
import StripeAddCard from "../components/StripeAddCard.js";
import Button from "../components/Button.js";
import Divider from "../components/Divider.js";
import "./CardModalContent.css";
import {
  CardNumberElement,
  useElements as useStripeElements,
  useStripe
} from "../_snowpack/pkg/@stripe/react-stripe-js.js";
const defaultFormValues = {
  newCardName: ""
};
export default function PaymentAddCardModal({
  clientSecret,
  hasOtherPaymentMethods = false,
  onUseCard,
  submitLabel,
  onError,
  onSuccess
}) {
  const [paying, setPaying] = useState(false);
  const [formValues, setStepForm] = useState(defaultFormValues);
  const stripe = useStripe();
  const stripeElements = useStripeElements();
  const {t} = useTranslation();
  const handlePaySubmit = useCallback((event) => {
    event.preventDefault();
    if (!stripe || !stripeElements) {
      onError(new Error("Stripe not loaded"));
      return;
    }
    setPaying(true);
    const cardElement = stripeElements.getElement(CardNumberElement);
    stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement
      }
    }).then(({error, paymentIntent: {status, error: intentError} = {}}) => {
      if (status === "succeeded") {
        onSuccess();
      } else {
        onError(intentError || error);
        setPaying(false);
      }
    }).catch((error) => {
      console.warn('TODO, některé errory si zaslouží víc než "Jejda"..');
      onError(error);
      setPaying(false);
    });
  }, [clientSecret, onError, onSuccess, stripe, stripeElements]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "CardModalContent-header"
  }, /* @__PURE__ */ React.createElement("h1", null, t("payment:newCardPayment"))), /* @__PURE__ */ React.createElement(Card, null, /* @__PURE__ */ React.createElement(StripeAddCard, {
    submitLabel: submitLabel || t("payment:pay"),
    onSubmit: handlePaySubmit,
    cardName: formValues.newCardName,
    onCardNameChange: (newCardName) => {
      setStepForm({...formValues, newCardName});
    },
    payInProgress: paying
  })), hasOtherPaymentMethods && /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    disabled: paying,
    onClick: onUseCard
  }, t("payment:selectSavedCard")))));
}
