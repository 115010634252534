import { auth } from "../../firebase.js";
import useDoc from "../../hooks/useDoc.js";

export default function useOrganisation({ onError = console.error } = {}) {
  const [organisation, isLoading, error] = useDoc(
    `/organisations/${auth().currentUser.uid}`,
    { onError }
  );

  return {
    organisation: organisation || {},
    isLoading,
    error,
  };
}
