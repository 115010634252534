import React from "../../_snowpack/pkg/react.js";
import {Trans, useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import useAlert from "../../hooks/useAlert.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import FormTextInput from "../../components/FormTextInput.js";
import Card from "../../components/Card.js";
import Loader from "../../components/Loader.js";
import Button from "../../components/Button.js";
import useOrganisation from "../hooks/useOrganisation.js";
import useApp from "../hooks/useApp.js";
import {APP_TYPE_LABEL} from "../constants.js";
import "./AppScreen.css";
export default function AppScreen({appId}) {
  const {t} = useTranslation();
  const alert = useAlert();
  const {organisation} = useOrganisation();
  const {
    app,
    reloadApp,
    isLoading: isLoadingApp
  } = useApp(appId, {alert}, true);
  const handleRotateCredentials = async () => {
    try {
      await httpsCallable(functions(), "organisation-rotateAppCredentials")({
        appId
      });
      reloadApp();
    } catch (err) {
      if (alert) {
        alert.error(err);
      }
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "AppScreen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, {
    title: `${organisation.name} - ${app.name}`
  }), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, isLoadingApp && /* @__PURE__ */ React.createElement(Loader, {
    text: t("loadingApp")
  }), /* @__PURE__ */ React.createElement(Card, {
    className: "p16"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    label: t("labelName"),
    value: app.name
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mb32"
  }, /* @__PURE__ */ React.createElement("strong", null, APP_TYPE_LABEL[app?.type])), /* @__PURE__ */ React.createElement("p", {
    className: "mt32 mb16"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "organisation:integrationInfo",
    t
  }, "Pro více informací o integraci s Postcube se prosím podívejte na", /* @__PURE__ */ React.createElement("a", {
    href: "https://sdk.postcube.cz",
    target: "_blank",
    rel: "noreferrer"
  }, "https://sdk.postcube.cz"), ", kde najdete veřejnou dokumentaci Postcube API.")), /* @__PURE__ */ React.createElement("h3", null, "Client ID"), /* @__PURE__ */ React.createElement("pre", {
    className: "credentials-value"
  }, app?.credentials?.id), /* @__PURE__ */ React.createElement("h3", null, "Client Secret"), /* @__PURE__ */ React.createElement("pre", {
    className: "credentials-value"
  }, app?.credentials?.secret), /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleRotateCredentials
  }, t("organisation:rotateCredentials")))));
}
