import React from "../_snowpack/pkg/react.js";
import {mount, redirect, route} from "../_snowpack/pkg/navi.js";
import UnlockScreen from "./UnlockScreen.js";
import {t} from "../_snowpack/pkg/i18next.js";
export default mount({
  "/": redirect("/servicer/unlock"),
  "/home": redirect("/servicer/unlock"),
  "/unlock": route(() => ({
    title: t("settings:serviceOpening"),
    view: /* @__PURE__ */ React.createElement(UnlockScreen, null)
  })),
  "/unlock/:boxId": route(({params}) => {
    return {
      title: t("settings:serviceOpening"),
      view: /* @__PURE__ */ React.createElement(UnlockScreen, {
        id: params.boxId
      })
    };
  })
});
