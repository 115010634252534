import React, {useCallback, useEffect, useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {auth} from "../../firebase.js";
import {PhoneAuthProvider, signInWithCredential} from "../../_snowpack/pkg/firebase/auth.js";
import useAlert from "../../hooks/useAlert.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Card from "../../components/Card.js";
import FormTextInput from "../../components/FormTextInput.js";
import Button from "../../components/Button.js";
import "./VerificationScreen.css";
const isValidCode = (code = 0) => {
  if (code.toString().length !== 6)
    return false;
  const number = Number(code);
  return !isNaN(number);
};
export default function AuthVerificationScreen({
  route: {
    state: {email, verificationId, registration, redirectTo = "/home"}
  }
}) {
  const navigation = useNavigation();
  useEffect(() => {
    if (!verificationId)
      navigation.navigate("/", {replace: true});
  }, [navigation, verificationId]);
  const [code, setCode] = useState();
  const [submitting, setSubmitting] = useState(false);
  const isLogin = !registration;
  const alert = useAlert();
  const {t} = useTranslation();
  const handleConfirm = useCallback((event) => {
    event.preventDefault();
    setSubmitting(true);
    const credentials = PhoneAuthProvider.credential(verificationId, code);
    signInWithCredential(auth(), credentials).then(async ({user}) => {
      if (email)
        await user.updateEmail(email);
      await navigation.setContext({user, userLoaded: true});
      return navigation.navigate(isLogin ? redirectTo : "/boxes/pairing", {
        state: {justLoggedIn: true}
      });
    }).catch((error) => {
      setSubmitting(false);
      console.error(error);
      alert.error(error.message);
    });
  }, [alert, code, email, isLogin, navigation, redirectTo, verificationId]);
  const onCodeInput = useCallback((event) => {
    event.preventDefault();
    setCode(event.target.value);
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-verification"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, isLogin ? t("auth:loginWithSMSCode") : t("auth:verifyWithSMSCode")), /* @__PURE__ */ React.createElement(Card, {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, isLogin ? t("auth:confirmLoginWithSMSCode") : t("auth:activateAccountWithSMSCode")), /* @__PURE__ */ React.createElement("form", null, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "verifyCode",
    label: t("auth:verifyCode"),
    type: "number",
    className: "mt16",
    value: code || "",
    onChange: onCodeInput,
    autoComplete: "one-time-code"
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    className: "mb8",
    onClick: handleConfirm,
    disabled: !isValidCode(code) || submitting,
    name: "verify-account"
  }, isLogin ? t("auth:confirmLogin") : t("auth:activateAccount"))))));
}
