export const boxStates = {
  SENDING: "SENDING",
  RECEIVING: "RECEIVING",
  FREE: "FREE",
  DISABLED: "DISABLED",
};

export const deliveryStates = {
  READY_FOR_COLLECTION: "READY_FOR_COLLECTION",
  IN_TRANSIT: "IN_TRANSIT",
  DELIVERED: "DELIVERED",
};

export const boxSizes = {
  DEFAULT: "DEFAULT",
  HALF: "HALF",
  DOUBLE: "DOUBLE",
};

export const getDeliveryStatesLabels = (t) => ({
  [deliveryStates.READY_FOR_COLLECTION]: t("delivery:forCollection"),
  [deliveryStates.IN_TRANSIT]: t("delivery:inTransit"),
});
