import { useEffect, useState } from "../../_snowpack/pkg/react.js";
import { useTranslation } from "../../_snowpack/pkg/react-i18next.js";
import { functions } from "../../firebase.js";
import { httpsCallable } from "../../_snowpack/pkg/firebase/functions.js";

const useApp = (appId, { alert }, includeCredentials = false) => {
  const [app, setApp] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchApp = async () => {
    setIsLoading(true);

    try {
      const response = await httpsCallable(
        functions(),
        "organisation-app"
      )({
        appId,
        includeCredentials,
      });

      if (response?.data) {
        setApp(response.data);
      }
    } catch (err) {
      console.error(err);
      if (alert) {
        alert.error(t("error:loadApp"), err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApp();
  }, []);

  return {
    app,
    isLoading,
    reloadApp: fetchApp,
  };
};

export default useApp;
