import { useEffect, useRef } from "../_snowpack/pkg/react.js";
import { analytics } from "../firebase.js";
import { setUserId } from "../_snowpack/pkg/firebase/analytics.js";

// todo: remove/rename this file?

export default function useSetupAuthUser(user, rollbar) {
  const init = useRef(false);

  useEffect(() => {
    if (!user || init.current) {
      return;
    }

    setUserId(analytics, user.uid);

    rollbar.configure({
      payload: {
        person: {
          id: user.uid,
          username: user.phoneNumber,
          email: user.email,
        },
      },
    });
    init.current = true;
  }, [rollbar, user]);
}
