import React, {useState, useCallback} from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {LOW_BATTERY_THRESHOLD_CENT} from "../_snowpack/pkg/@topmonks/postcube.js";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderLogo from "../components/HeaderLogo.js";
import Tabbar from "../components/Tabbar.js";
import Warning from "../components/Warning.js";
import IconAdd from "../images/icon-add-circle.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import useAlert from "../hooks/useAlert.js";
import {Capacitor} from "../_snowpack/pkg/@capacitor/core.js";
import useBleDevicesNearby from "../hooks/useBleDevicesNearby.js";
import MyBoxActions from "../modals/MyBoxActions.js";
import {analytics} from "../firebase.js";
import {logEvent} from "../_snowpack/pkg/firebase/analytics.js";
import useMobileModal from "../hooks/useMobileModal.js";
import BatteryIcon from "../components/BatteryIcon.js";
import useMyBoxes from "../hooks/useMyBoxes.js";
import useBoxBattery from "../hooks/useBoxBattery.js";
import "./MyBoxesScreen.css";
const isNative = Capacitor.isNativePlatform();
const SingleBoxCard = ({box, onClick}) => {
  const {battery} = useBoxBattery(box.id);
  return /* @__PURE__ */ React.createElement("div", {
    key: `box-${box.id}`,
    className: cs("screen-boxes__box-container", !box.isPaired && "screen-boxes__box-container--disabled"),
    "aria-disabled": !box.isPaired
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-boxes__box-card mb8 pl16 pr16",
    onClick: () => onClick(box),
    "data-boxid": box.id
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-boxes__box-battery"
  }, /* @__PURE__ */ React.createElement("strong", null, battery?.batteryLevel || "?", " %"), /* @__PURE__ */ React.createElement(BatteryIcon, {
    level: battery?.batteryLevel,
    width: "24px",
    className: "ml8"
  })), /* @__PURE__ */ React.createElement("h1", null, box.isPaired ? box.id : /* @__PURE__ */ React.createElement("strike", null, box.id), box.isMultibox && /* @__PURE__ */ React.createElement("em", {
    className: "color-grey"
  }, " (multibox)")), /* @__PURE__ */ React.createElement("p", null, box.name), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("span", null, box.address))));
};
const BatteryWarning = ({boxId}) => {
  const {battery} = useBoxBattery(boxId);
  const {t} = useTranslation();
  return battery?.batteryLevel < LOW_BATTERY_THRESHOLD_CENT && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    headline: t("box:warning.outOfBatteryMyBox.headline", {boxId}),
    text: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, t("box:warning.outOfBatteryMyBox.text1", {boxId})), /* @__PURE__ */ React.createElement("div", null, t("box:warning.outOfBatteryMyBox.text2"))),
    className: "mb16"
  });
};
export default function MyBoxesScreen({
  route: {
    data: {user}
  }
}) {
  const navigation = useNavigation();
  const alert = useAlert();
  const [unlocking, setUnlocking] = useState(false);
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const {
    boxes: myBoxes,
    loading: myBoxesLoading,
    unlock: unlockMyBox
  } = useMyBoxes(user, {onError: handleScreenError});
  const {openModal, closeModal, modal} = useMobileModal();
  const {
    devices: bleDevicesNearby,
    findNearby: findBleDeviceNearby,
    error: bleError
  } = useBleDevicesNearby();
  const handlePairing = () => {
    navigation.navigate("/boxes/pairing");
    logEvent(analytics, "pairing_attempt");
  };
  const handleBoxCardClick = (box) => {
    if (box.isMultibox) {
      navigation.navigate(`/multibox/${box.id}`, {
        state: {backLink: "/myboxes"}
      });
    } else {
      openModal(/* @__PURE__ */ React.createElement(MyBoxActions, {
        onNavigate: (link) => () => {
          closeModal();
          navigation.navigate(link);
        },
        onUnlock: (event) => {
          logEvent(analytics, "opening_attempt");
          const unlock = isNative ? handleNativeUnlock(box)(event) : handleWebUnlock(box);
          unlock.then(closeModal);
        },
        boxId: box.id,
        boxName: box.name,
        isBoxPaired: box.isPaired
      }));
    }
  };
  const handleWebUnlock = async (box) => {
    if (unlocking)
      return;
    try {
      setUnlocking(true);
      const bleDevice = await findBleDeviceNearby(box);
      await unlockMyBox(bleDevice);
      alert.success(t("box:opened"));
    } catch (err) {
      console.error(err);
      alert.error(t("error:unlockBox"), err);
    } finally {
      setUnlocking(false);
    }
  };
  const handleNativeUnlock = (box) => async () => {
    if (unlocking)
      return;
    try {
      setUnlocking(box.id);
      const bleDevice = bleDevicesNearby.find(({id}) => id === box.id);
      if (bleDevice || box.virtual) {
        if (!box.virtual)
          await unlockMyBox(bleDevice);
        alert.success(t("box:opened"));
      }
    } catch (err) {
      console.error(err);
      alert.error(t("error:unlockBox"), err);
    } finally {
      setUnlocking(false);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-boxes"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, !myBoxesLoading && !myBoxes.length && /* @__PURE__ */ React.createElement(Warning, {
    type: "alert",
    headline: t("box:warning.nonePaired.headline2"),
    text: t("box:warning.nonePaired.text2"),
    className: "mb16",
    link: "/boxes/pairing"
  }), myBoxes.map(({id, name, address, isMultibox}) => address && name ? null : /* @__PURE__ */ React.createElement(Warning, {
    key: id,
    type: "alert",
    headline: t("box:warning.missingDetails.headline", {id}),
    text: t("box:warning.missingDetails.text"),
    className: "mb16",
    link: isMultibox ? `/multibox/${id}/edit` : `/boxes/${id}/edit`
  })), bleError && /* @__PURE__ */ React.createElement(Warning, {
    ...bleError,
    text: bleError.message || bleError.code || bleError,
    className: "mb16"
  }), myBoxes.map((box) => /* @__PURE__ */ React.createElement(BatteryWarning, {
    boxId: box.id,
    key: `batterywarning-${box.id}`
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-boxes__header mb16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("box:label", {count: 5})), /* @__PURE__ */ React.createElement("button", {
    className: "screen-boxes__add-box",
    onClick: handlePairing
  }, /* @__PURE__ */ React.createElement(IconAdd, {
    width: "24px",
    className: cs("ml8 mr8", !myBoxes.length && "pulsing")
  }), /* @__PURE__ */ React.createElement("h3", null, t("add")))), myBoxes.map((box) => /* @__PURE__ */ React.createElement(SingleBoxCard, {
    key: box.id,
    box,
    onClick: handleBoxCardClick
  }))), !myBoxes.length && /* @__PURE__ */ React.createElement("div", {
    className: "ml16 mr16"
  }, myBoxesLoading ? "\r	" : /* @__PURE__ */ React.createElement(Warning, {
    type: "alert",
    headline: t("box:warning.nonePaired.headline3"),
    text: t("box:warning.nonePaired.text3"),
    link: "/boxes/pairing"
  })), /* @__PURE__ */ React.createElement(Tabbar, {
    myboxes: true
  }), modal);
}
