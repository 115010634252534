export default {
  label_one: "Schránka",
  label_other: "Schránky",
  labelWithId: "Schránka {{boxId}}",
  labelWithIdNumber: "Schránka č. {{boxId}}",
  unlocking: "Otevírání",
  locationDesc: "Popis umístění boxu",
  locationDesc2: "Popis umístění schránky",
  locationDescShort: "Popis umístění",

  unknown: "Neznámý box",
  noName: "Bez názvu",
  isEmpty: "Box je prázdný",

  uploadingDeviceKey: "Nahrávám klíč zařízení...",
  alreadyPaired: "Box je již už spárovaný s jiným účtem",
  withIdPaired: "Box č. {{boxId}} spárován.",
  paired: "Box spárován",
  pairSuccess: "Box úspěšně spárován",
  notPaired: "Schránka není spárována s tímto zařízením.",
  pairing: "Párování boxu",
  pairing2: "Párování schránky",

  doesNotExist: "Tento box neexistuje",
  userBlocked: "Uživatel vašeho účtu je na tomto boxu blokován",
  notPermittedToSendFrom: "Z tohoto boxu nemáte oprávnění odesílat zásilky",

  resumeUse: "Obnovit užívání schránky",
  pauseUse: "Pozastavit užívání schránky",
  callOwner: "Zavolat uživateli schránky",

  closeCarefully: "Pečlivě zavřete schránku",
  checkIfCloseProperly: "Zkontrolujte, zda je box se zásilkou pečlivě uzavřen.",

  idForCollection: "Číslo schránky pro vyzvednutí",
  idForDelivery: "Číslo schránky pro doručení",

  find: "Najít schránku",
  findAnother: "Najít jinou schránku",
  findAround: "Najít schránku v okolí",
  searchAround: "Vyhledat box v okolí",
  chosenWithId: "Vybrán box <1>{{id}}</1>",

  chooseSenderBox: "Vyberte box pro odeslání",
  insertId: "Zadejte kód boxu adresáta (6 číslic)",

  details: "Údaje o vašem boxu.",
  edit: "Upravit údaje o schránce",
  edit2: "Editace boxu",
  editSuccess: "Box úspěšně upraven",

  permissions: "Přístupy ke schránce",
  editPermissions: "Upravit přístupy ke schránce",
  permissionsSettingsHere:
    "Zde najdete nastavení oprávnění k vaší PostCube schránce",
  permissionsToUse: "Oprávnění používat box",

  openingRequest: {
    label: "ŽÁDOST O OTEVŘENÍ BOXU",
    label2: "Žádost o otevření schránky",
    labelShort: "Žádost o otevření",
    refused: "Žádost o otevření byla úspěšně zamítnuta",
    approved: "Žádost o otevření byla úspěšně schválena",
    wasRefused: "Vaše žádost byla zamítnuta",
    wasApproved: "Vaše žádost byla schválena",
    cantOpenBox: "Box není možné otevřít.",
    nowOpenTheBox: "Nyní otevřete schránku, do které vložte zásilku.",
    send: "Odeslat žádost",
    wasSent: "Žádost o otevření je odeslaná",
    waitForResponse: "Čekejte prosím, než se majitel schránky vyjádří.",
    messageFromRequester: "Zpráva od žadatele:",
    approve: "Schválit žádost",
    refuse: "Odmítnout žádost",
    closeBoxAndCheckPackageInserted:
      "Zavřete prosím box a potvrďte, že je zásilka bezpečně uložena.",
    messageForRecipient: "Zpráva pro příjemce",
    messageText: "Text zprávy",
    enterBoxOwnersPhone: "Zadejte telefonní číslo majitele schránky.",
    doYouKnowOwner: "Znáte majitele?",
    messagePlaceholder: "Ahoj, tady Jméno, mám pro tebe tu Zásilku. Děkuji.",
  },

  fullAccess: "Povolit plný přístup",
  denyAccess: "Blokovat přístup k boxu",
  allowSendFrom: "Povolit pouze odesílání z boxu",
  allowSendTo: "Povolit pouze odesílání do boxu",
  allowReceiveTo: "Povolit pouze doručení do boxu",
  allowAnyoneToInsertDelivery: "Povolit komukoliv vložit zásilku do boxu",
  allowInsertDeliveryAfterConfirm:
    "Povolit vložení zásilky do boxu po schválení",

  batteryPercentageUpdatedAt:
    "{{percentage}}% baterie, aktualizováno {{dateTime}}",
  pair: "Spárovat schránku",
  opening: "Otevírám ...",
  opened: "Box otevřen",
  open: "Otevřít schránku",
  unlock: "Odemknout schránku",
  isOpen: "Schránka je otevřená",

  filter: "Filtr zámků (pouze zámky s tímto prefixem)",
  filterHelp:
    "Aplikace vyhledá schránky v okolí. Vyberte schránku a stisknutím <1>spárovat</1> ho otevřete.",
  noneAround: "Žádný box v dosahu",
  noSuchAround: "Žádný takový box v dosahu",

  keys: "Klíče k otevření boxu",
  key: {
    label: "Klíče",
    loading: "Načítám klíče ...",
    none: "Tady nic",
    generationFailed: "Failed to create open link: No Data",
    generateNew: "Generovat nový klíč",
    generateInfo:
      "Vygenerovaný klíč může použít kdokoliv, komu pošlete odkaz k otevření schránky. <1>Klíč je jednorázový a platí 24 hodin.</1>",
    generating: "Generuji...",
    generate: "Generovat klíč",
    generation: "Generování klíčů",

    openInfo:
      "Klíčem může schránku otevřít kdokoliv, komu ho pošlete. <1> Klíč je jednorázový a platí 24 hodin.</1>",
    copyToClipboard: "Kopírovat odkaz do schránky",
    deactivate: "Deaktivovat odkaz",
    toOpen: "Klíč k otevření",
    createToOpen: "Vytvořit klíč k otevření",
    expiresIn: "Klíč vyprší za {{time}}",
    expired: "Klíč vypršel",
    expired2: "Platnost klíče vypršela",

    help: {
      start: "Začněte kliknutím na <1>Najít schránku</1>",
      choose: "V nabídce vyberte schránku k otevření",
      pair: "Klikněte na <1>Spárovat</1>",
    },
  },

  pairTutorialText: 'V dalším označte schránku a klikněte na "Spárovat"',

  delete: {
    dangerous: "Nebezpečné",
    idConfirmLabel: "Napište číslo schránky pro potvrzení",
    info: "Zámek zde můžete ze svého účtu úplně odstranit. Nezapomeňte ale také <1>vyresetovat fyzický zámek</1>, aby jej bylo možné v budoucnu znovu přidat.",
    completely: "Úplně smazat schránku",
    areYouSure:
      "Opravdu chcete vymazat vaši schránku? Fyzický zámek bude poté pro další použití také nutné vynulovat.",
    successful: "Schránka úspěšně smazána.",
  },

  state: {
    free: "Volná",
    paused: "Pozastavená",
    sending: "Odesílá",
    receiving: "Přijímá",
  },

  warning: {
    missingDetails: {
      headline: "U boxu {{id}} chybí údaje",
      text: "Je třeba vyplnit jméno a adresu schránky, jinak vás kurýr nenajde.",
    },
    nonePaired: {
      headline: "Nemáte spárovanou žádnou schránku",
      text: "Schránku je před použitím nutné spárovat.",
      headline2: "Nemáte spárovaný žádný box",
      text2: "Box je před použitím nutné spárovat.",
      headline3: "Přidejte váš první box",
      text3: "S vaším účtem není spárován žádný box. Začněte přidáním boxu.",
    },
    noName: {
      headline: "Není vyplněné jméno boxu",
      text: "Je třeba vyplnit jméno boxu, jinak vás příjemce nepozná.",
    },
    noAddress: {
      headline: "Není vyplněná adresa",
      text: "V nastavení boxu je třeba vyplnit adresu boxu, jinak vás kurýr nenajde.",
      headlineRecipient: "Box příjemce nemá úplnou adresu",
      textRecipient:
        "Majitel boxu nejprve musí doplnit potřebné údaje, jinak mu nebude možné zaslat zásilku.",
    },
    outOfBattery: {
      headline: "Hladový box",
      text: "Vámi zvolený box je vybitý. Nakrmte prosím svůj box a dokončete odesílání zásilky.",
      headlineRecipient: "Vybitý box",
      textRecipient:
        "Box příjemce je vybitý. Vyčkejte prosím až si příjemce všimne vybitých baterií, vzpomene a zakoupí při 28. cestě do obchodu, najde šroubovák a vymění baterie, a pak dokončete odesílání zásilky.",
      textWithPercent_one:
        "Vašemu boxu zbývá {{count}}% baterie. Vyměňte prosím baterie ve svém boxu a dokončete odesílání zásilky.",
      textWithPercent_few:
        "Vašemu boxu zbývají {{count}}% baterie. Vyměňte prosím baterie ve svém boxu a dokončete odesílání zásilky.",
      textWithPercent_other:
        "Vašemu boxu zbývá {{count}}% baterie. Vyměňte prosím baterie ve svém boxu a dokončete odesílání zásilky.",
    },
    outOfBatteryMyBox: {
      headline: "Box {{boxId}} je téměř vybitý",
      text1:
        "U boxu {{boxId}} byl dočasně pozastaven příjem a odesílání zásilek z důvodu docházející baterie.",
      text2:
        "Vyměňte prosím baterie v tomto boxu. Pro více informací se poraďte s uživatelskou příručkou.",
    },
    unavailable: {
      headline: "Box dočasně nedostupný",
      text: "Vámi zvolený box je dočasně nedostupný z důvodu odesílání / příjmu jiné zásilky. Zkusete prosím odesílání zásilky dokončit později, nebo zvolte jiný box.",
      textRecipient:
        "Box příjemce je dočasně nedostupný z důvodu odesílání / příjmu jiné zásilky. Zkusete prosím odesílání zásilky dokončit později, nebo zvolte jiný box příjemce.",
    },
    occupied: {
      headline: "Multibox je plně obsazený",
      text: "Ve vámi zvoleném multiboxu jsou všechny schránky obsazené. Zkusete prosím odesílání zásilky dokončit později, nebo zvolte jiný box.",
    },
    addressNotComplete: {
      headline: "Není zadaná kompletní adresa",
      text: "Je potřeba doplnit nezbytné údaje, jinak nebude možné zásilku doručit.",
    },
  },
};
