import { PushNotifications } from "../_snowpack/pkg/@capacitor/push-notifications.js";

export function subscribeToPushNotifications({
  onTokenReceived,
  onErrorOccurred,
  onMessageReceived,
}) {
  PushNotifications.requestPermissions().then((result) => {
    console.log("PushNotifications.requestPermissions result", result);

    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
      return;
    }

    // Show some error
    console.error("You scum!! You rejected the notifications!");
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener("registration", (token) => {
    console.log("Push registration success, token: " + token.value);
    onTokenReceived(token.value);
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener("registrationError", (error) => {
    console.log("Error on registration: " + JSON.stringify(error));
    onErrorOccurred(error);
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    console.log("Push received: " + JSON.stringify(notification));
  });

  // Method called when tapping on a notification
  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    onMessageReceived
  );
}
