import React, {useEffect, useCallback} from "../../_snowpack/pkg/react.js";
import "./UserScreen.css";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import BoxItem from "../components/BoxItem.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Button from "../../components/Button.js";
import UserItem from "../components/UserItem.js";
import Loader from "../../components/Loader.js";
import useAlert from "../../hooks/useAlert.js";
import useCollection from "../../hooks/useCollection.js";
export default function UserScreen({
  id,
  route: {
    state: {user}
  }
}) {
  const navigation = useNavigation();
  const alert = useAlert();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const [boxes = [], boxesLoading] = useCollection("/boxes", {
    skip: !id,
    where: ["ownerId", "==", id],
    onError: handleScreenError
  });
  useEffect(() => {
    if (!user) {
      navigation.navigate("/wombat/users");
    }
  }, [navigation, user]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "user-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    path: "/wombat/users"
  }), null), /* @__PURE__ */ React.createElement("div", {
    className: "user-wombat-screen__container"
  }, user && /* @__PURE__ */ React.createElement(UserItem, {
    key: user.phoneNumber,
    user
  }), /* @__PURE__ */ React.createElement("h2", null, "Boxes"), boxes.map((box) => box && /* @__PURE__ */ React.createElement(BoxItem, {
    key: box.id,
    box,
    onClick: () => {
      navigation.navigate();
    }
  })), boxesLoading && /* @__PURE__ */ React.createElement(Loader, null), !boxesLoading && !boxes.length && /* @__PURE__ */ React.createElement("p", null, "none"), /* @__PURE__ */ React.createElement("h2", null, "Actions"), /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    link: `tel:${user.phoneNumber}`
  }, "Call user")));
}
