import React from "../_snowpack/pkg/react.js";
import "./FormTextarea.css";
import cs from "../_snowpack/pkg/classnames.js";
export default function FormTextarea({
  label = "",
  name = "default",
  type = "text",
  className,
  onChange,
  value,
  autocomplete,
  customRef,
  ...rest
}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("form-textarea-component", className)
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: name
  }, label), /* @__PURE__ */ React.createElement("textarea", {
    type,
    name,
    onChange,
    value,
    autoComplete: autocomplete,
    ref: customRef,
    ...rest
  }));
}
