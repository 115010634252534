import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import "./RegistrationScreen.css";
import {auth} from "../../firebase.js";
import {
  signInWithPhoneNumber,
  useDeviceLanguage,
  RecaptchaVerifier
} from "../../_snowpack/pkg/firebase/auth.js";
import useAlert from "../../hooks/useAlert.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderLogo from "../../components/HeaderLogo.js";
import {useNavigation, Link} from "../../_snowpack/pkg/react-navi.js";
import Card from "../../components/Card.js";
import FormTextInput from "../../components/FormTextInput.js";
import Button from "../../components/Button.js";
import {isPhoneValid} from "../../library/validation.js";
import {updateState} from "../../library/forms.js";
import {Capacitor} from "../../_snowpack/pkg/@capacitor/core.js";
import {removeSpaces} from "../../library/utils/functions.js";
import {Trans, useTranslation} from "../../_snowpack/pkg/react-i18next.js";
export default function AuthRegistrationScreen() {
  const [userForm, setUserForm] = useState({
    phoneNumber: "+420",
    email: ""
  });
  const navigation = useNavigation();
  const alert = useAlert();
  const {t} = useTranslation();
  useEffect(() => {
    useDeviceLanguage(auth());
    if (!Capacitor.isNativePlatform()) {
      window.recaptchaVerifier = new RecaptchaVerifier("captcha-button", {size: "invisible"}, auth());
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    let verificationId;
    try {
      const formattedPhone = removeSpaces(userForm.phoneNumber);
      if (Capacitor.isNativePlatform()) {
        verificationId = await window.cordova.plugins.firebase.auth.verifyPhoneNumber(formattedPhone, 3e4);
      } else {
        const result = await signInWithPhoneNumber(auth(), formattedPhone, window.recaptchaVerifier);
        verificationId = result.verificationId;
      }
    } catch (err) {
      console.error(err);
      alert.error("Něco se pokazilo. Zkuste to, prosím, znovu.", err);
    }
    if (verificationId) {
      navigation.navigate(`/verify`, {
        state: {
          email: userForm.email,
          verificationId,
          registration: true
        }
      });
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-registration"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("other:welcomeByPostCube")), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "auth:alreadyHaveAccountLogin",
    t
  }, "Již mám účet,", /* @__PURE__ */ React.createElement(Link, {
    className: "signin-link",
    href: "/login"
  }, "přihlásit se"), ".")), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt32"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "pt8 pb32"
  }, t("auth:registerPostCube")), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit,
    action: "#"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "phoneNumber",
    label: t("phone"),
    type: "tel",
    value: userForm.phoneNumber,
    onChange: updateState(setUserForm)
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "email",
    type: "email",
    label: t("email"),
    value: userForm.email,
    onChange: updateState(setUserForm)
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    id: "captcha-button",
    disabled: !isPhoneValid(userForm.phoneNumber),
    name: "register"
  }, t("continue"))))));
}
