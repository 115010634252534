import { useCallback, useMemo } from "../_snowpack/pkg/react.js";
import { useAlert as useAlertOriginal } from "../_snowpack/pkg/react-alert.js";
import { useTranslation } from "../_snowpack/pkg/react-i18next.js";

export default function useAlert(options) {
  const alert = useAlertOriginal(options);
  const { t } = useTranslation();
  const handleError = useCallback(
    (errorMessage, originalError) => {
      if (typeof errorMessage === "object") {
        errorMessage =
          errorMessage.message || errorMessage.msg || t("error:unknown");
      }

      try {
        // Will report error to Rollbar
        if (!originalError) {
          window.onerror(errorMessage);
        } else {
          window.onerror(originalError);
        }
      } catch (err) {
        console.error("Unreportable error", err);
      }

      return alert.error(errorMessage, {
        timeout: 10000,
      });
    },
    [alert, t]
  );

  const api = useMemo(
    () => ({
      ...alert,
      error: handleError,
    }),
    [alert, handleError]
  );

  return api;
}
