import React from "../../_snowpack/pkg/react.js";
import cs from "../../_snowpack/pkg/classnames.js";
import IconVehicle from "../../images/icon-vehicle.js";
import IconBoxColour from "../../images/icon-package-colour.js";
import {deliveryStates} from "../constants.js";
import "./Delivery.css";
const deliveryStatesIcons = {
  [deliveryStates.DELIVERED]: /* @__PURE__ */ React.createElement(IconBoxColour, null),
  [deliveryStates.READY_FOR_COLLECTION]: /* @__PURE__ */ React.createElement(IconBoxColour, null),
  [deliveryStates.IN_TRANSIT]: /* @__PURE__ */ React.createElement(IconVehicle, null)
};
const Delivery = ({
  state = deliveryStates.READY_FOR_COLLECTION,
  sender,
  lastUpdate
}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("Delivery")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("Delivery-icon", {
      "--addCheckIcon": state === deliveryStates.READY_FOR_COLLECTION
    })
  }, deliveryStatesIcons[state]), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "Delivery-sender"
  }, sender), /* @__PURE__ */ React.createElement("div", {
    className: "Delivery-lastUpdate"
  }, lastUpdate)));
};
export default Delivery;
