import { useEffect } from "../_snowpack/pkg/react.js";
import { Capacitor } from "../_snowpack/pkg/@capacitor/core.js";
import { functions } from "../firebase.js";
import { httpsCallable } from "../_snowpack/pkg/firebase/functions.js";

const isNative = Capacitor.isNativePlatform();

const registerNotificationToken = async (token) => {
  let type = "web";
  if (isNative) {
    type = "native";
  }

  try {
    await httpsCallable(
      functions(),
      "notifications-subscribe"
    )({ token, type });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export default function usePushNotifications({ user }) {
  useEffect(() => {
    if (!user) {
      return;
    }

    const run = ({ subscribeToPushNotifications }) =>
      subscribeToPushNotifications({
        onTokenReceived: registerNotificationToken,
        onErrorOccurred: console.error,
        onMessageReceived: console.log,
      });

    if (isNative) {
      import("../library/notifications.capacitor.js").then(run);
    } else {
      import("../library/notifications.web.js").then(run);
    }
  }, [user]);
}
