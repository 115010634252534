import React, {useCallback, useEffect} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderLogo from "../../components/HeaderLogo.js";
import Button from "../../components/Button.js";
import Divider from "../../components/Divider.js";
import IconCheck from "../../images/icon-check-circle.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Stepper from "../../components/Stepper.js";
import "./Step3PaymentScreen.css";
export default function Step3PaymentSuccessScreen({
  route: {
    state: {deliveryId}
  }
}) {
  const {t} = useTranslation();
  const navigation = useNavigation();
  useEffect(() => {
    if (!deliveryId) {
      navigation.navigate("/", {replace: true});
    }
  }, [deliveryId, navigation]);
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    navigation.navigate(`/deliveries/sending/${deliveryId}`, {
      state: {backPath: "/home"}
    });
  }, [navigation, deliveryId]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-payment screen-payment--success"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement(Stepper, {
    step: "3"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "screen-payment__success mb32"
  }, /* @__PURE__ */ React.createElement(IconCheck, {
    width: "128px",
    className: "mb32 mt32"
  }), /* @__PURE__ */ React.createElement("p", null, t("payment:wasSuccessful"))), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    name: "continue2Delivery"
  }, t("continue")))));
}
