export const SNOWPACK_PUBLIC_CIRCLE_SHA1 = "🤪";
export const STRIPE = "pk_live_51HgVvNGb1zq8zl5W5yrOm4D3uHWEyFF07PXx8rJrsXxngHOyPxdM37aOToSP5GcuoNUsuOtQXqADHRd3NNKQkwfO00CChAaIjd";
export const ROLLBAR = "c8fa8c4f5fdf474391eb7265b8eb14dd";
export const ROLLBAR_WOMBAT = "55c6c0dd66a6409ab11dc54f65024523";
export const GOOGLE_MAPS_APIKEY = "AIzaSyCRuxX6g1daDNglQGu2IkHETyUL8KEr5_8";
export const BACKEND = "https://europe-west1-chytrybox.cloudfunctions.net/";
export const OFFLINE_PERSISTENCE = "true";
export const DISABLE_RECAPTCHA = "false";
export const DISPLAY_META = "false";
export const EMULATED_FIRESTORE = "noooo";
export const EMULATED_FUNCTIONS = "nope";
export const EMULATED_AUTH = "hellnoo";
export const VAPID_KEY = "BA844xU9bLP03XK3ybZYhOnhiIFJw9_2JyXC8mwKiPFKFMvxQBza_i9Q5Dv17-6c9aDZ0flieyfpwMY4YPz7Ozc";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;