import React, {useEffect, useState, useCallback} from "../../_snowpack/pkg/react.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import useAlert from "../../hooks/useAlert.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import FormTextInput from "../../components/FormTextInput.js";
import Card from "../../components/Card.js";
import Loader from "../../components/Loader.js";
import Button from "../../components/Button.js";
import useOrganisation from "../hooks/useOrganisation.js";
import useApps from "../hooks/useApps.js";
import AddAppModal from "../modals/AddAppModal.js";
import {APP_TYPE_LABEL} from "../constants.js";
import "./OrganisationScreen.css";
const OrganisationScreen = () => {
  const {navigate} = useNavigation();
  const alert = useAlert();
  const {openModal, closeModal, modal} = useMobileModal();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const {organisation: loadedOrganisation, isLoading: isLoadingOrganisation} = useOrganisation({onError: handleScreenError});
  const {
    apps,
    reloadApps,
    isLoading: isLoadingApps
  } = useApps({onError: handleScreenError});
  const [organisation, setOrganisation] = useState(loadedOrganisation);
  const [hasChanged, setHasChanged] = useState(false);
  useEffect(() => {
    setOrganisation(loadedOrganisation);
    setHasChanged(false);
  }, [loadedOrganisation]);
  const setProperties = (changes = {}) => {
    setHasChanged(true);
    setOrganisation({
      ...organisation,
      ...changes
    });
  };
  const handleSave = async () => {
    setHasChanged(false);
    setOrganisation(loadedOrganisation);
  };
  const handleAddApp = () => {
    openModal(/* @__PURE__ */ React.createElement(AddAppModal, {
      onCancel: closeModal,
      onSave: async (request) => {
        closeModal();
        await httpsCallable(functions(), "organisation-createApp")(request).catch(handleScreenError);
        await reloadApps();
      }
    }));
  };
  const renderApp = (app, index) => {
    return /* @__PURE__ */ React.createElement("div", {
      key: `${index}-${app.id}`
    }, /* @__PURE__ */ React.createElement(Button, {
      className: "mb16",
      onClick: () => navigate(`/organisation/app/${app.id}`),
      secondary: true
    }, app.name, /* @__PURE__ */ React.createElement("strong", null, APP_TYPE_LABEL[app.type])));
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "OrganisationScreen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, {
    title: t("organisation:mineLabel")
  }), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, isLoadingOrganisation && /* @__PURE__ */ React.createElement(Loader, {
    className: "OrganisationScreen-loader",
    text: t("organisation:loading")
  }), /* @__PURE__ */ React.createElement("h1", null, organisation?.name), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt16"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "name",
    label: t("organisation:name"),
    value: organisation?.name,
    onChange: (event) => setProperties({
      name: event.target.value
    }),
    required: true
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "ico",
    label: t("CIN"),
    value: organisation?.ico,
    onChange: (event) => setProperties({
      ico: event.target.value
    }),
    required: true
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressLine1",
    label: t("address.label"),
    value: organisation?.addressLine1,
    onChange: (event) => setProperties({
      addressLine1: event.target.value
    }),
    required: true
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "addressLine2",
    label: t("address.labelLine2"),
    value: organisation?.addressLine2,
    onChange: (event) => setProperties({
      addressLine2: event.target.value
    }),
    required: true
  }), hasChanged && /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleSave
  }, t("saveChanges"))), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt32"
  }, /* @__PURE__ */ React.createElement("h1", null, t("organisation:myApps")), isLoadingApps && /* @__PURE__ */ React.createElement(Loader, {
    className: "OrganisationScreen-loader",
    text: t("organisation:loadingApps")
  }), !isLoadingApps && apps.length < 1 && /* @__PURE__ */ React.createElement("h2", null, t("organisation:noApps")), apps.map(renderApp), /* @__PURE__ */ React.createElement(Button, {
    className: "mt16",
    onClick: handleAddApp
  }, t("organisation:addApp")))), modal);
};
export default OrganisationScreen;
