import React, {useCallback} from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import Icon1 from "../images/icon-package.js";
import Icon3 from "../images/icon-box.js";
import Icon4 from "../images/icon-settings.js";
import "./Tabbar.css";
export default function Tabbar({home, myboxes, settings, disableActiveTab}) {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const navigateOnClick = useCallback((tab, path) => {
    if (tab && disableActiveTab) {
      return;
    }
    navigation.navigate(path);
  }, [navigation, disableActiveTab]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "tabbar-component"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-component__menu-item",
    onClick: () => navigateOnClick(home, "/home")
  }, /* @__PURE__ */ React.createElement("div", {
    className: "tabbar-component__menu-icon"
  }, /* @__PURE__ */ React.createElement(Icon1, {
    className: cs("tabbar-component__menu-image", home && "tabbar-component__menu-image--focus")
  })), /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-component__menu-text", home && "tabbar-component__menu-text--focus")
  }, t("delivery:label", {count: 10}))), /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-component__menu-item",
    onClick: () => navigateOnClick(myboxes, "/myboxes")
  }, /* @__PURE__ */ React.createElement("div", {
    className: "tabbar-component__menu-icon"
  }, /* @__PURE__ */ React.createElement(Icon3, {
    className: cs("tabbar-component__menu-image", myboxes && "tabbar-component__menu-image--focus")
  })), /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-component__menu-text", myboxes && "tabbar-component__menu-text--focus")
  }, t("box:label", {count: 10}))), /* @__PURE__ */ React.createElement("button", {
    className: "tabbar-component__menu-item",
    onClick: () => navigateOnClick(settings, "/settings")
  }, /* @__PURE__ */ React.createElement("div", {
    className: "tabbar-component__menu-icon"
  }, /* @__PURE__ */ React.createElement(Icon4, {
    className: cs("tabbar-component__menu-image", settings && "tabbar-component__menu-image--focus")
  })), /* @__PURE__ */ React.createElement("div", {
    className: cs("tabbar-component__menu-text", settings && "tabbar-component__menu-text--focus")
  }, t("settings:label"))));
}
