import useDoc from "./useDoc.js";

export default function useBox(boxId, { onError = console.error } = {}) {
  const [data, isLoading, error] = useDoc(`/boxes/${boxId}`, {
    skip: !boxId || boxId?.length !== 6,
    onError,
  });

  return {
    box: data,
    isLoading,
    error,
  };
}
