import React from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import "./Stepper.css";
export default function Stepper({className = "", step}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: cs(className, "stepper-component")
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs(step == 1 && "stepper-component__circle", step == 1 && "stepper-component__circle--active", step > 1 && "stepper-component__circle--smol", step > 1 && "stepper-component__circle--smol--active")
  }, step == 1 && "1"), /* @__PURE__ */ React.createElement("div", {
    className: "stepper-component__line"
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("stepper-component__line", step > 1 && "stepper-component__line--active", step > 2 && "stepper-component__line--solid")
  })), /* @__PURE__ */ React.createElement("div", {
    className: cs("stepper-component__circle", step == 2 && "stepper-component__circle--smol", step == 2 && "stepper-component__circle--active", step > 2 && "stepper-component__circle--smol--active")
  }, step == 2 && "2"), /* @__PURE__ */ React.createElement("div", {
    className: "stepper-component__line"
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("stepper-component__line", step > 2 && "stepper-component__line--active")
  })), /* @__PURE__ */ React.createElement("div", {
    className: cs("stepper-component__circle", step >= 3 && "stepper-component__circle--active", step > 3 && "stepper-component__circle--smol")
  }, step == 3 && "3"));
}
