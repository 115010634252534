import { useCallback, useEffect, useState } from "../_snowpack/pkg/react.js";

export default function useIsOnline() {
  const [online, setOnline] = useState(window.navigator.onLine);

  const onlineListener = useCallback(() => setOnline(true), [setOnline]);
  const offlineListener = useCallback(() => setOnline(false), [setOnline]);

  useEffect(() => {
    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", offlineListener);

    return () => {
      window.removeEventListener("online", onlineListener);
      window.removeEventListener("offline", offlineListener);
    };
  }, [onlineListener, offlineListener]);

  return online;
}
