import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import React from "../_snowpack/pkg/react.js";
import {Elements} from "../_snowpack/pkg/@stripe/react-stripe-js.js";
import {loadStripe} from "../_snowpack/pkg/@stripe/stripe-js.js";
import {Capacitor} from "../_snowpack/pkg/@capacitor/core.js";
import {
  Stripe as NativeStripe,
  ApplePayEventsEnum
} from "../_snowpack/pkg/@capacitor-community/stripe.js";
const publishableKey = __SNOWPACK_ENV__.STRIPE;
const stripePromise = loadStripe(publishableKey, {
  apiVersion: "2020-08-27"
}).catch((error) => {
  window.onerror(error);
});
if (Capacitor.isNativePlatform()) {
  NativeStripe.initialize({
    publishableKey
  }).catch((error) => {
    window.onerror(error);
  });
}
NativeStripe.addListener(ApplePayEventsEnum.FailedToLoad, window.onerror);
NativeStripe.addListener(ApplePayEventsEnum.Failed, window.onerror);
export default function StripeProvider({children, clientSecret}) {
  return /* @__PURE__ */ React.createElement(Elements, {
    stripe: stripePromise,
    options: {locale: "cs", clientSecret}
  }, children);
}
