import React, {useCallback} from "../../_snowpack/pkg/react.js";
import MultiboxGrid from "../components/MultiboxGrid.js";
import MultiboxStatesInfo from "../components/MultiBoxStatesInfo.js";
import MultiboxHeader from "../components/MultiboxHeader.js";
import MultiBoxState from "../components/MultiboxState.js";
import useAlert from "../../hooks/useAlert.js";
import useMultibox from "../hooks/useMultibox.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import "./MultiboxScreen.css";
const MultiboxScreen = ({
  multiboxId,
  route: {
    state: {backLink}
  }
}) => {
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:loadMultibox"), err);
  }, [alert, t]);
  const {multibox, partitions} = useMultibox(multiboxId, {
    onError: handleScreenError
  });
  return /* @__PURE__ */ React.createElement("div", {
    className: "MultiboxScreen has-header"
  }, /* @__PURE__ */ React.createElement(MultiboxHeader, {
    multiboxId: multibox?.id,
    address: multibox?.address,
    backLink
  }), /* @__PURE__ */ React.createElement(MultiboxStatesInfo, null), /* @__PURE__ */ React.createElement(MultiboxGrid, {
    partitions
  }), /* @__PURE__ */ React.createElement(MultiBoxState, {
    partitions
  }));
};
export default MultiboxScreen;
