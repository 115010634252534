import React, {useState} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {functions} from "../firebase.js";
import {httpsCallable} from "../_snowpack/pkg/firebase/functions.js";
import Button from "../components/Button.js";
import FormItem from "../components/FormItem.js";
import Warning from "../components/Warning.js";
import FormTextInput from "../components/FormTextInput.js";
import {removeSpaces} from "../library/utils/functions.js";
import {isPhoneValid} from "../library/validation.js";
import "./AddUserModal.css";
export default function AddUserModal({onSave, onError}) {
  const [phoneNumber, setPhoneNumber] = useState("+420");
  const [showNotFound, setShowNotFound] = useState(false);
  const {t} = useTranslation();
  const handleSave = (phoneNumber2) => {
    if (typeof onSave === "function") {
      onSave(phoneNumber2);
    }
  };
  const handleValidatePhoneNumber = async () => {
    try {
      const formattedPhoneNumber = removeSpaces(phoneNumber);
      const {data} = await httpsCallable(functions(), "auth-userWithPhoneNumberExists")({
        phoneNumber: formattedPhoneNumber
      });
      if (!data?.exists) {
        onError({code: "user-not-found"});
      } else {
        await handleSave(formattedPhoneNumber);
      }
    } catch (err) {
      onError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "add-user-modal__container p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("phoneNumber")), showNotFound && /* @__PURE__ */ React.createElement(Warning, {
    headline: t("permissions:noUserFound"),
    type: "error",
    className: "mt8 mb8"
  }), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("permissions:insertUserPhone")
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "phoneNumber",
    type: "tel",
    value: phoneNumber,
    onChange: (event) => {
      setPhoneNumber(event.target.value);
      setShowNotFound(false);
    },
    required: true
  })), /* @__PURE__ */ React.createElement(Button, {
    disabled: !isPhoneValid(phoneNumber),
    onClick: handleValidatePhoneNumber
  }, t("confirm")));
}
