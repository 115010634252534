import React from "../_snowpack/pkg/react.js";
import "./StripeAddCard.css";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from "../_snowpack/pkg/@stripe/react-stripe-js.js";
import Button from "./Button.js";
import FormItem from "./FormItem.js";
import FormTextInput from "./FormTextInput.js";
import Loader from "./Loader.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#34374b",
      "::placeholder": {
        color: "rgb(117, 117, 117)"
      }
    },
    invalid: {
      color: "#34374b"
    }
  }
};
export default function StripeAddCard({
  onSubmit,
  cardName,
  onCardNameChange,
  payInProgress,
  submitLabel
}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("form", {
    onSubmit
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "name",
    label: t("payment:card.holder"),
    required: true,
    placeholder: "",
    value: cardName,
    onChange: ({target}) => {
      onCardNameChange(target.value);
    }
  }), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("payment:card.number")
  }, /* @__PURE__ */ React.createElement(CardNumberElement, {
    id: "cardNumber",
    options: CARD_ELEMENT_OPTIONS
  })), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("payment:card.expiry")
  }, /* @__PURE__ */ React.createElement(CardExpiryElement, {
    id: "expiry",
    options: CARD_ELEMENT_OPTIONS
  })), /* @__PURE__ */ React.createElement(FormItem, {
    label: t("payment:card.cvc")
  }, /* @__PURE__ */ React.createElement(CardCvcElement, {
    id: "cvc",
    placeholder: "foo",
    options: CARD_ELEMENT_OPTIONS
  })), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: payInProgress,
    name: "pay"
  }, payInProgress && /* @__PURE__ */ React.createElement(Loader, {
    text: t("payment:inProgress")
  }) || /* @__PURE__ */ React.createElement("p", null, submitLabel)));
}
