import React, {useState, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import cs from "../../_snowpack/pkg/classnames.js";
import {expirationTime} from "../../library/format.js";
import useAlert from "../../hooks/useAlert.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import ModalGenerateKey from "../../modals/ModalGenerateKey.js";
import ModalKeyDetail from "../../modals/ModalKeyDetail.js";
import Loader from "../../components/Loader.js";
import IconArrowBack from "../../images/icon-circle-arrow.js";
import IconAdd from "../../images/icon-add-circle.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import useCollection from "../../hooks/useCollection.js";
import "./KeysListScreen.css";
export default function KeysListScreen({
  route: {
    data: {user, rollbar}
  },
  boxId
}) {
  const navigation = useNavigation();
  const {t} = useTranslation();
  const alert = useAlert();
  const [latestKey, setLatestKey] = useState();
  const {openModal, closeModal, modal} = useMobileModal();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const [openLinks = [], linksLoading] = useCollection("/open-links", {
    where: [
      ["ownerId", "==", user?.uid],
      ["boxId", "==", boxId]
    ],
    orderBy: ["createdAt", "desc"],
    onError: handleScreenError
  });
  const handleLinkGenerated = (res) => {
    closeModal();
    setLatestKey(res.commandId);
    rollbar.info("Generated new key", res);
  };
  const handleBox = (boxId2) => {
    openModal(/* @__PURE__ */ React.createElement(ModalGenerateKey, {
      boxId: boxId2,
      onDone: handleLinkGenerated
    }));
  };
  const keyDeactivated = () => {
    closeModal();
  };
  const keyCopiedToClipboard = () => {
    closeModal();
    alert.success(t("copiedToClipboard"));
  };
  const handleKey = (boxId2, k) => {
    openModal(/* @__PURE__ */ React.createElement(ModalKeyDetail, {
      boxId: boxId2,
      openLink: k,
      onDeactivate: keyDeactivated,
      onCopyToClipboard: keyCopiedToClipboard
    }));
  };
  const isNegativeTimestamp = (expireAt) => {
    const currentTimestamp = Math.floor(new Date().getTime() / 1e3);
    const remainingSeconds = expireAt - currentTimestamp;
    return remainingSeconds < 0;
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list__view"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list__header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list__nav-button",
    onClick: () => navigation.goBack()
  }, /* @__PURE__ */ React.createElement(IconArrowBack, {
    width: "32px",
    fill: "var(--color-grey)"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list__title"
  }, /* @__PURE__ */ React.createElement("h1", null, t("box:key.label")), /* @__PURE__ */ React.createElement("b", {
    className: "color-grey"
  }, boxId)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list__nav-button",
    onClick: () => handleBox(boxId)
  }, /* @__PURE__ */ React.createElement(IconAdd, {
    width: "32px",
    fill: "var(--color-primary)"
  }))), !linksLoading && openLinks.length ? /* @__PURE__ */ React.createElement("div", null, openLinks.map((k) => /* @__PURE__ */ React.createElement("div", {
    key: k.commandId,
    className: cs("screen-keys-list__item", latestKey === k.commandId && "screen-keys-list__item--latest", isNegativeTimestamp(k.expireAt) && "screen-keys-list__item--expired"),
    onClick: () => handleKey(boxId, k)
  }, /* @__PURE__ */ React.createElement("h2", null, k.name, latestKey === k.commandId && /* @__PURE__ */ React.createElement("span", {
    className: "color-primary"
  }, " ●")), !isNegativeTimestamp(k.expireAt) ? /* @__PURE__ */ React.createElement("p", null, t("box:key.expiresIn", {
    time: expirationTime(k.expireAt)
  })) : /* @__PURE__ */ React.createElement("p", null, t("box:key.expired"))))) : /* @__PURE__ */ React.createElement("div", {
    className: "screen-keys-list__loader"
  }, linksLoading ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("box:key.loading")
  }) : /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("em", null, t("box:key.none"))))), modal);
}
