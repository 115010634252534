import { Crypto, Encoding } from "./utils/index.js";
import VirtualBox from "./VirtualBox.js";
import {
  DEPRECATED_CHAR_SAVE_ACC_UUID,
  DEPRECATED_CHAR_SET_KEY_UUID,
  DEPRECATED_CHAR_UNLOCK_UUID,
  DEPRECATED_CHAR_STATUS_UUID,
  DEPRECATED_CHAR_TIME_SYNC_UUID,
  RES_OK,
  RESPONSE_MESSAGES,
} from "../_snowpack/pkg/@topmonks/postcube.js";

export const SUCCESSFUL_BOX_RESPONSE = RES_OK;
export const boxResponseToMessage = (code) => RESPONSE_MESSAGES[code] || code;

export const LOCALSTORAGE_DEVICE_PRIVATE_KEY = "device-private-key";
export const LOCALSTORAGE_DEVICE_PUBLIC_KEY = "device-public-key";
export const LOCALSTORAGE_DEVICE_KEYINDEX = "device-key-index";

export function getDeviceKeyPair() {
  const publicKey = JSON.parse(
    window.localStorage.getItem(LOCALSTORAGE_DEVICE_PUBLIC_KEY)
  );
  const privateKey = JSON.parse(
    window.localStorage.getItem(LOCALSTORAGE_DEVICE_PRIVATE_KEY)
  );
  return { publicKey, privateKey };
}

export function saveDeviceKeyPair(keyPair) {
  window.localStorage.setItem(
    LOCALSTORAGE_DEVICE_PRIVATE_KEY,
    JSON.stringify(keyPair.getPrivate().toArray())
  );
  window.localStorage.setItem(
    LOCALSTORAGE_DEVICE_PUBLIC_KEY,
    JSON.stringify(keyPair.getPublic().encode())
  );
}

export function getDeviceKeyIndex(boxId) {
  return parseInt(
    window.localStorage.getItem(`${LOCALSTORAGE_DEVICE_KEYINDEX}:${boxId}`)
  );
}
export function saveDeviceKeyIndex(index, boxId) {
  window.localStorage.setItem(
    `${LOCALSTORAGE_DEVICE_KEYINDEX}:${boxId}`,
    index
  );
}
export function hasDeviceBoxKey(keys = []) {
  return (
    keys.findIndex(
      ({ publicKey }) =>
        JSON.stringify(publicKey) ===
        JSON.stringify(getDeviceKeyPair().publicKey.splice(1))
    ) > -1
  );
}

export default function BleBox(bleDevice) {
  if (!bleDevice) throw new Error("BLE device not defined");
  if (bleDevice.virtual) return VirtualBox(bleDevice);

  let txCount = 0;

  const bleBox = {
    id: bleDevice.id,

    async connect() {
      if (txCount > 0) return;
      await bleDevice.connect();
    },

    async disconnect() {
      if (txCount > 0) return;
      await bleDevice.disconnect();
    },

    async transaction(fn) {
      await bleBox.connect();
      txCount++;
      try {
        return await fn();
      } finally {
        txCount--;
        await bleBox.disconnect();
      }
    },

    async saveAccountKey(userPubKey = [], secretCode = "") {
      const publicKey = Crypto.removePrefixFromPublicKey(userPubKey);
      const secretCodeData = Encoding.parseSecretCode(secretCode);
      const timestamp = Encoding.ulongToArray(Math.floor(Date.now() / 1000));
      const hash = await Crypto.hash([
        ...publicKey,
        ...timestamp,
        ...secretCodeData,
      ]);
      const command = [...publicKey, ...timestamp, ...hash];

      const response = await bleDevice.send(
        DEPRECATED_CHAR_SAVE_ACC_UUID,
        command
      );

      return response === RES_OK ? SUCCESSFUL_BOX_RESPONSE : response;
    },

    async saveDeviceKey(command) {
      await bleBox.connect();
      const response = await bleDevice.send(
        DEPRECATED_CHAR_SET_KEY_UUID,
        command
      );
      await bleBox.disconnect();

      return response === RES_OK ? SUCCESSFUL_BOX_RESPONSE : response;
    },

    async unlockWithOfflineKey({ publicKey }) {
      const encrypted = await Crypto.createCommand({
        accountOfflinePrivateKey: getDeviceKeyPair().privateKey,
        boxPublicKey: publicKey,
        expireAt: Math.floor((Date.now() + 60000) / 1000),
      });

      const keyIndex = getDeviceKeyIndex(bleDevice.id);
      const command = new Uint8Array([keyIndex, ...encrypted]);

      return await bleBox.unlock(command);
    },

    async unlockWithOnlineKey(command) {
      const response = await bleBox.unlock(command);
      return response === RES_OK ? SUCCESSFUL_BOX_RESPONSE : response;
    },

    async unlock(command) {
      return await bleDevice.send(DEPRECATED_CHAR_UNLOCK_UUID, command);
    },

    async getState() {
      const state = await bleDevice.read(DEPRECATED_CHAR_STATUS_UUID);
      return state;
    },

    async readBattery() {
      await bleBox.connect();
      const state = await bleDevice.readBattery();
      await bleBox.disconnect();
      return state;
    },

    async syncTime({ publicKey }) {
      const encrypted = await Crypto.createCommand({
        accountOfflinePrivateKey: getDeviceKeyPair().privateKey,
        boxPublicKey: publicKey,
        expireAt: Math.floor(Date.now() / 1000),
      });

      const keyIndex = getDeviceKeyIndex(bleDevice.id);
      if (isNaN(keyIndex)) return;

      const command = new Uint8Array([keyIndex, ...encrypted]);
      return await bleDevice.send(DEPRECATED_CHAR_TIME_SYNC_UUID, command);
    },
  };

  return bleBox;
}
