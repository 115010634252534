import React, {useMemo} from "../_snowpack/pkg/react.js";
import {DELIVERY_STATE_NAMES} from "../_snowpack/pkg/@topmonks/postcube.js";
import "./DeliveryTimeline.css";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
const getTimelines = (t) => ({
  BOX_BOX_PUBLIC_RECEPTION: [
    {text: t("delivery:status.delivered"), bool: true},
    {text: t("delivery:status.collected"), bool: true}
  ],
  BOX_BOX_SENDER: [
    {text: t("delivery:status.lookingForCourier")},
    {text: t("delivery:status.courierOnTheWayForDelivery")},
    {text: t("delivery:status.courierIsAtBox")},
    {text: t("delivery:status.goingToDestination")},
    {text: t("delivery:status.delivered"), bool: true},
    {text: t("delivery:status.collected"), bool: true}
  ],
  BOX_BOX_RECIPIENT: [
    {text: t("delivery:status.beingPrepared")},
    {text: t("delivery:status.readyToShip")},
    {text: t("delivery:status.courierOnTheWayForDelivery")},
    {text: t("delivery:status.goingToYou")},
    {text: t("delivery:status.delivered"), bool: true},
    {text: t("delivery:status.collected"), bool: true}
  ]
});
const stateToIndex = {
  BOX_BOX_PUBLIC_RECEPTION: {
    [void 0]: -1,
    [DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX]: 0,
    [DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_RECIPIENT]: 0,
    [DELIVERY_STATE_NAMES.PACKAGE_CONFIRMED_BY_RECIPIENT]: 1
  },
  BOX_BOX_SENDER: {
    [void 0]: -1,
    [DELIVERY_STATE_NAMES.FORM_IN_PROGRESS]: -1,
    [DELIVERY_STATE_NAMES.PAYMENT_IN_PROGRESS]: -1,
    [DELIVERY_STATE_NAMES.PACKAGE_WAITING_FOR_DELIVERY]: 0,
    [DELIVERY_STATE_NAMES.DELIVERY_ACCEPTED_BY_TRANSPORT_COMPANY]: 1,
    [DELIVERY_STATE_NAMES.SENDER_BOX_OPENED_BY_TRANSPORT_COMPANY]: 2,
    [DELIVERY_STATE_NAMES.PACKAGE_TAKEOVER_BY_TRANSPORT_COMPANY]: 3,
    [DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_TRANSPORT_COMPANY]: 3,
    [DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX]: 4,
    [DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_RECIPIENT]: 4,
    [DELIVERY_STATE_NAMES.PACKAGE_CONFIRMED_BY_RECIPIENT]: 5
  },
  BOX_BOX_RECIPIENT: {
    [void 0]: 0,
    [DELIVERY_STATE_NAMES.PAID]: 0,
    [DELIVERY_STATE_NAMES.READY_FOR_BOX]: 0,
    [DELIVERY_STATE_NAMES.PACKAGE_IN_SENDER_BOX]: 1,
    [DELIVERY_STATE_NAMES.PACKAGE_WAITING_FOR_DELIVERY]: 1,
    [DELIVERY_STATE_NAMES.DELIVERY_ACCEPTED_BY_TRANSPORT_COMPANY]: 2,
    [DELIVERY_STATE_NAMES.SENDER_BOX_OPENED_BY_TRANSPORT_COMPANY]: 2,
    [DELIVERY_STATE_NAMES.PACKAGE_TAKEOVER_BY_TRANSPORT_COMPANY]: 3,
    [DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_TRANSPORT_COMPANY]: 3,
    [DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX]: 4,
    [DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_RECIPIENT]: 4,
    [DELIVERY_STATE_NAMES.PACKAGE_CONFIRMED_BY_RECIPIENT]: 5
  }
};
export default function DeliveryTimeline({state, type = "BOX_BOX_SENDER"}) {
  const {t} = useTranslation();
  const timeline = useMemo(() => getTimelines(t)[type], [t, type]);
  const activeIndex = useMemo(() => {
    const index = stateToIndex[type][state];
    return isNaN(index) ? timeline.length : index;
  }, [type, state, timeline.length]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "delivery-timeline"
  }, /* @__PURE__ */ React.createElement("h2", null, t("delivery:state")), /* @__PURE__ */ React.createElement("ul", null, timeline.map(({text, bool}, index) => /* @__PURE__ */ React.createElement("li", {
    key: text,
    className: cs(activeIndex > index && "done", activeIndex === index && "going", activeIndex === index && bool && "going-is-done")
  }, text))));
}
