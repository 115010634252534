import i18n from "../_snowpack/pkg/i18next.js";
import { initReactI18next } from "../_snowpack/pkg/react-i18next.js";
import LanguageDetector from "../_snowpack/pkg/i18next-browser-languagedetector.js";
import cs from "./cs/index.js";
import en from "./en/index.js";
import sk from "./sk/index.js";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "cs",
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      cs,
      sk,
      en,
    },
  });

export default i18n;
