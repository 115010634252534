import { useEffect } from "../_snowpack/pkg/react.js";
import useDoc from "./useDoc.js";

export default function useProductionBox(productionBoxId, { alert } = {}) {
  const [data, isLoading, error] = useDoc(
    `/production-boxes/${productionBoxId}`,
    {
      skip: !productionBoxId || productionBoxId?.length !== 6,
    }
  );

  useEffect(() => {
    let ui;
    if (alert && error) {
      ui = alert.error(error);
    }
    return () => {
      if (ui) {
        alert.remove(ui);
      }
    };
  }, [alert, error]);

  return {
    error,
    isLoading,
    productionBox: data,
  };
}
