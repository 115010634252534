import React, {useCallback} from "../../_snowpack/pkg/react.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Card from "../../components/Card.js";
import Divider from "../../components/Divider.js";
import IconCheck from "../../images/icon-check-green.js";
import useBoxEditForm from "../../hooks/useBoxEditForm.js";
import useAlert from "../../hooks/useAlert.js";
import useDoc from "../../hooks/useDoc.js";
import "./PairedScreen.css";
export const defaultBox = {
  id: null,
  name: "",
  description: "",
  address: "",
  addressStreetNumber: "",
  addressStreet: "",
  addressCity: "",
  addressPSC: "",
  addressCountry: "CZ",
  placeId: null,
  boxToDeleteId: ""
};
export default function BoxPairedScreen({
  create,
  boxId
}) {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const [box = defaultBox] = useDoc(`/boxes/${boxId}`);
  const alert = useAlert();
  const onEditSuccess = useCallback(() => {
    create ? navigation.navigate("/myboxes") : navigation.goBack();
    alert.success(create ? t("box:pairSuccess") : t("box:editSuccess"));
  }, [alert, create, navigation, t]);
  const [boxEditForm] = useBoxEditForm({
    onSuccess: onEditSuccess,
    box,
    submitButtonLabel: create ? t("finish") : t("saveChanges"),
    disabled: box === defaultBox
  });
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-box-paired"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, create ? /* @__PURE__ */ React.createElement("h1", null, t("box:details")) : /* @__PURE__ */ React.createElement("h1", null, t("box:labelWithIdNumber", {boxId})), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt16"
  }, create && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "screen-box-paired__info"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-box-paired__message"
  }, /* @__PURE__ */ React.createElement(IconCheck, {
    alt: "checkmark",
    className: "mr8"
  }), /* @__PURE__ */ React.createElement("p", null, t("box:withIdPaired", {boxId}))), /* @__PURE__ */ React.createElement("a", {
    href: "/",
    onClick: (e) => {
      e.preventDefault();
      navigation.goBack();
    }
  }, t("change"))), /* @__PURE__ */ React.createElement(Divider, null)), boxEditForm)));
}
