import React, {useEffect} from "../../_snowpack/pkg/react.js";
import BoxItem from "../components/BoxItem.js";
import "./BoxScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import useAlert from "../../hooks/useAlert.js";
import DeliveryItem from "../components/DeliveryItem.js";
import IconSettings from "../../images/icon-settings.js";
import IconBox from "../../images/icon-box.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Loader from "../../components/Loader.js";
import useDoc from "../../hooks/useDoc.js";
import useCollection from "../../hooks/useCollection.js";
export default function BoxScreen({
  id,
  route: {
    state: {box: boxPreview}
  }
}) {
  const navigation = useNavigation();
  const [box = boxPreview, boxLoading, boxError] = useDoc(`/box/${id}`, {
    skip: boxPreview
  });
  const alert = useAlert();
  const [sendedDeliveries = [], sendedDeliveriesLoading, sendedError] = useCollection(`/deliveries`, {
    where: ["recipient.id", "==", id],
    orderBy: ["updatedAt", "desc"]
  });
  const [incomingDeliveries = [], incomingDeliveriesLoading, incomingError] = useCollection(`/deliveries`, {
    where: ["sender.id", "==", id],
    orderBy: ["updatedAt", "desc"]
  });
  useEffect(() => {
    const dataError = incomingError || sendedError || boxError;
    if (dataError) {
      alert.error(dataError);
    }
  }, [alert, boxError, incomingError, sendedError]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "box-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null), /* @__PURE__ */ React.createElement(IconBox, {
    fill: "var(--color-white)",
    height: "24px",
    lenght: "24px",
    onClick: () => {
      navigation.navigate(`${id}/map`, {state: {boxes: [box]}});
    }
  })), /* @__PURE__ */ React.createElement("div", {
    className: "box-wombat-screen__container"
  }, box && /* @__PURE__ */ React.createElement(BoxItem, {
    key: box.id,
    box
  }), boxLoading && boxLoading && /* @__PURE__ */ React.createElement(Loader, null)), /* @__PURE__ */ React.createElement("div", {
    className: "box-wombat-screen__container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "box-wombat-screen__box",
    onClick: () => navigation.navigate(`/servicer/unlock/${box.id}`)
  }, /* @__PURE__ */ React.createElement(IconSettings, {
    className: "box-wombat-screen__icon"
  }), /* @__PURE__ */ React.createElement("h3", {
    className: "box-wombat-screen__text"
  }, "Otevírání")), /* @__PURE__ */ React.createElement("h1", null, "Doručené Zásilky"), sendedDeliveries.map((delivery) => /* @__PURE__ */ React.createElement(DeliveryItem, {
    key: delivery.id,
    delivery,
    onClick: () => navigation.navigate(`/wombat/delivery/${delivery.id}`)
  })), sendedDeliveriesLoading && /* @__PURE__ */ React.createElement(Loader, null), /* @__PURE__ */ React.createElement("h1", null, "Odeslané Zásilky"), incomingDeliveries.map((delivery) => /* @__PURE__ */ React.createElement(DeliveryItem, {
    key: delivery.id,
    delivery,
    onClick: () => navigation.navigate(`/wombat/delivery/${delivery.id}`)
  })), incomingDeliveriesLoading && /* @__PURE__ */ React.createElement(Loader, null)));
}
