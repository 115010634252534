import { firestore } from "../firebase.js";
import { enableNetwork, disableNetwork } from "../_snowpack/pkg/firebase/firestore.js";
import { useEffect } from "../_snowpack/pkg/react.js";
import useIsOnline from "./useIsOnline.js";

export default function useOfflineAbilities() {
  const online = useIsOnline();

  useEffect(() => {
    if (online) {
      enableNetwork(firestore()).then(() => console.debug("Network enabled"));
    } else {
      disableNetwork(firestore()).then(() => console.debug("Network disabled"));
    }
  }, [online]);
}
