import React from "../_snowpack/pkg/react.js";
import {compose, mount, route, withState} from "../_snowpack/pkg/navi.js";
import {Router, View, NotFoundBoundary} from "../_snowpack/pkg/react-navi.js";
import * as ReactAlert from "../_snowpack/pkg/react-alert.js";
import ReactAlertTemplate, {
  reactAlertOptions
} from "../components/ReactAlertTemplate.js";
import OpenLinkScreen from "./OpenLinkScreen.js";
import FallbackPath from "../screens/FallbackScreen.js";
import {t} from "../_snowpack/pkg/i18next.js";
export const openLinksRoutes = compose(withState(({state}) => state), mount({
  "/:id": route((request) => {
    return {
      title: t("box:key.toOpen"),
      view: (pass) => /* @__PURE__ */ React.createElement(OpenLinkScreen, {
        linkId: request.params.id,
        ...pass
      })
    };
  })
}));
const allAppRoutes = mount({
  "/keys": openLinksRoutes
});
export default function OpenLinksApp() {
  return /* @__PURE__ */ React.createElement(Router, {
    routes: allAppRoutes
  }, /* @__PURE__ */ React.createElement(ReactAlert.Provider, {
    template: ReactAlertTemplate,
    ...reactAlertOptions
  }, /* @__PURE__ */ React.createElement(NotFoundBoundary, {
    render: ({pathname = "/"}) => /* @__PURE__ */ React.createElement(FallbackPath, {
      type: pathname.split("/").reverse()[0]
    })
  }, /* @__PURE__ */ React.createElement(View, null))));
}
