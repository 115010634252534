import React, {useState, useMemo, useCallback} from "../../_snowpack/pkg/react.js";
import cx from "../../_snowpack/pkg/classnames.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {Trans, useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Loader from "../../components/Loader.js";
import Divider from "../../components/Divider.js";
import IconBox from "../../images/icon-box.js";
import FormTextInput from "../../components/FormTextInput.js";
import Card from "../../components/Card.js";
import Button from "../../components/Button.js";
import useAlert from "../../hooks/useAlert.js";
import ListSelect from "../../components/ListSelect.js";
import {Capacitor} from "../../_snowpack/pkg/@capacitor/core.js";
import useMyBoxes, {PAIRING_STATUS_TYPES} from "../../hooks/useMyBoxes.js";
import useBleDevicesNearby from "../../hooks/useBleDevicesNearby.js";
import Warning from "../../components/Warning.js";
import "./PairingScreen.css";
const isNative = Capacitor.isNativePlatform();
const blurAllTextFields = (container) => {
  for (let input of container.querySelectorAll('input[type="text"]')) {
    input?.blur();
  }
};
export default function BoxPairingScreen({
  route: {
    data: {user},
    state: {justLoggedIn}
  }
}) {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const alert = useAlert();
  const handleScreenError = useCallback((err) => {
    console.log("handleScreenError:", err);
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const {
    boxes,
    pair: pairBox,
    pairing,
    pairingStatusText
  } = useMyBoxes(user, {onError: handleScreenError});
  const boxIdUrlParam = useMemo(() => {
    const url = new URL(window.location.href);
    return url.searchParams.get("id");
  }, []);
  const {
    devices: bleDevicesNearby,
    findNearby: findBleDeviceNearby,
    pickedDevice: selectedBleDevice,
    setPickedDevice: setSelectedBleDevice,
    error: bleError
  } = useBleDevicesNearby();
  const selectedBox = useMemo(() => selectedBleDevice && boxes.find(({id}) => id === selectedBleDevice.id), [boxes, selectedBleDevice]);
  const [code, setCode] = useState("");
  const [pairingCodeNeeded, setPairingCodeNeeded] = useState(false);
  const [boxDeviceOverUserDevicesLimit, setBoxDeviceOverUserDevicesLimit] = useState();
  const [userDeviceToReplace, setUserDeviceToReplace] = useState();
  const handleSelectUserDeviceToReplace = (value) => {
    setUserDeviceToReplace(value);
  };
  const isSubmitDisabled = pairing || !selectedBleDevice || pairingCodeNeeded && !code || boxDeviceOverUserDevicesLimit && !userDeviceToReplace;
  const handleBleDeviceRequest = (event) => {
    event.preventDefault();
    findBleDeviceNearby(boxIdUrlParam ? {id: boxIdUrlParam} : void 0).then(() => setPairingCodeNeeded(false)).catch(handleScreenError);
  };
  const handleBleDeviceSelect = (value) => {
    setSelectedBleDevice(value);
    setPairingCodeNeeded(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitDisabled)
      return;
    blurAllTextFields(event.target.parentElement);
    try {
      const [status, payload] = await pairBox(selectedBleDevice, code, userDeviceToReplace);
      if (status === PAIRING_STATUS_TYPES.NEED_SECRET_CODE) {
        setPairingCodeNeeded(true);
        return;
      }
      if (status === PAIRING_STATUS_TYPES.LIMIT_REACHED) {
        setBoxDeviceOverUserDevicesLimit(true);
        return;
      }
      if (status === PAIRING_STATUS_TYPES.PAIRED) {
        const {id: boxId} = payload;
        await navigation.navigate(`/boxes/${boxId}/paired`);
        return;
      }
    } catch (error) {
      handleScreenError(error);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-pairing"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    path: justLoggedIn && "/"
  })), /* @__PURE__ */ React.createElement("form", {
    className: "p16",
    action: "#",
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement("h1", null, t("box:pairing2")), /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, t("pairing:pairFirst")), /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "pairing:distanceNotice",
    t
  }, /* @__PURE__ */ React.createElement("strong", null, "Důležité"), " je při tomto procesu být v bezprosřední vzdálenosti od schránky a mít ve svém zařízení povolený a zapnutý", /* @__PURE__ */ React.createElement("strong", null, "Bluetooth"), ".")), bleError && /* @__PURE__ */ React.createElement("div", {
    className: "screen-pairing__error"
  }, /* @__PURE__ */ React.createElement(Warning, {
    ...bleError,
    text: bleError.message || bleError.code || bleError
  })), /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt16"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: ""
  }, t("pairing:step1")), /* @__PURE__ */ React.createElement(Divider, {
    className: "mt8 mb16"
  }), isNative && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ListSelect, {
    data: bleDevicesNearby,
    render: (item) => /* @__PURE__ */ React.createElement("p", {
      className: "ml16"
    }, item.name),
    selected: selectedBleDevice,
    onChange: handleBleDeviceSelect
  }), !bleDevicesNearby.length && /* @__PURE__ */ React.createElement("p", null, t("pairing:searchingAround"))), !isNative && /* @__PURE__ */ React.createElement(React.Fragment, null, selectedBleDevice && /* @__PURE__ */ React.createElement("p", {
    className: "mb16",
    id: "picked-box-to-pair"
  }, /* @__PURE__ */ React.createElement(IconBox, {
    width: "16px",
    className: "color-primary box mr8"
  }), /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "box:chosenWithId",
    t
  }, "Vybrán box ", /* @__PURE__ */ React.createElement("strong", null, {id: selectedBleDevice.id}))), /* @__PURE__ */ React.createElement(Button, {
    onClick: handleBleDeviceRequest,
    className: cx({white: selectedBleDevice}),
    name: "pickDevice"
  }, selectedBleDevice ? t("box:findAnother") : t("box:findAround")))), pairingCodeNeeded && /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt16"
  }, /* @__PURE__ */ React.createElement("h2", null, t("pairing:step2")), /* @__PURE__ */ React.createElement(Divider, {
    className: "mt8 mb16"
  }), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "boxCode",
    label: t("pairing:step2Label"),
    value: code,
    onChange: ({target: {value}}) => setCode(value),
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "off",
    spellCheck: "false",
    autoFocus: true
  })), selectedBox && boxDeviceOverUserDevicesLimit && /* @__PURE__ */ React.createElement(Card, {
    className: "p16 mt16"
  }, /* @__PURE__ */ React.createElement("h3", null, t("pairing:overDevicesLimit")), /* @__PURE__ */ React.createElement(Divider, {
    className: "mt8 mb16"
  }), /* @__PURE__ */ React.createElement(ListSelect, {
    render: ({label}) => /* @__PURE__ */ React.createElement("p", {
      className: "ml16"
    }, label),
    data: selectedBox.devicePublicKeys,
    getItemId: ({createdAt}) => createdAt,
    selected: userDeviceToReplace,
    onChange: handleSelectUserDeviceToReplace
  })), selectedBleDevice && /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: isSubmitDisabled,
    className: "mt16",
    loader: pairing
  }, pairing ? /* @__PURE__ */ React.createElement(Loader, {
    ghost: true,
    text: pairingStatusText
  }) : t("continue"))));
}
