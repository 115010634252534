import React, {useState, Fragment, useCallback} from "../../_snowpack/pkg/react.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {components as ReactSelectComponents} from "../../_snowpack/pkg/react-select.js";
import SelectAsync from "../../_snowpack/pkg/react-select/async.js";
import {functions} from "../../firebase.js";
import {httpsCallable} from "../../_snowpack/pkg/firebase/functions.js";
import useAlert from "../../hooks/useAlert.js";
import useBox from "../../hooks/useBox.js";
import useProductionBox from "../../hooks/useProductionBox.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Button from "../../components/Button.js";
import FormItem from "../../components/FormItem.js";
import "./BoxActivationScreen.css";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
const ReactSelectSingleValue = (props) => /* @__PURE__ */ React.createElement(ReactSelectComponents.SingleValue, {
  ...props
}, /* @__PURE__ */ React.createElement("strong", null, props.data.displayName), ", ", props.data.phoneNumber, " (ID:", " ", props.data.uid, ")");
const ReactSelectOption = (props) => /* @__PURE__ */ React.createElement(ReactSelectComponents.Option, {
  ...props,
  isSelected: false
}, /* @__PURE__ */ React.createElement("strong", null, props.data.displayName), ", ", props.data.phoneNumber, " (ID:", " ", props.data.uid, ")");
export default function BoxActivationScreen({
  id: boxId
}) {
  const navigation = useNavigation();
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const {box: existingBox} = useBox(boxId, {onError: handleScreenError});
  const isAwaitingActivation = !existingBox;
  const {
    productionBox
  } = useProductionBox(boxId, {onError: handleScreenError});
  const [owner, setOwner] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    await httpsCallable(functions(), "box-activateBox")({
      boxId,
      ownerId: owner.uid
    }).catch(handleScreenError);
    navigation.navigate(`/wombat/box/${boxId}`);
  };
  const fetchUsers = async (searchExpr) => {
    const {data} = await httpsCallable(functions(), "users-findUsers")({
      searchExpr
    }).catch(handleScreenError);
    return data;
  };
  const boxProperties = [
    {
      title: "ID",
      value: productionBox?.id
    },
    {
      title: "Virtual",
      value: productionBox?.virtual
    },
    {
      title: "Flashed",
      value: productionBox?.flashed
    },
    {
      title: "Is multibox",
      value: productionBox?.isMultibox
    },
    {
      title: "Is partition",
      value: productionBox?.isPartition
    }
  ];
  return /* @__PURE__ */ React.createElement("div", {
    className: "box-activation-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "box-activation-wombat-screen__container"
  }, /* @__PURE__ */ React.createElement("h1", null, "Aktivace ", !!productionBox?.isMultibox && "multi", "boxu #", boxId), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, productionBox && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", null, "Detail produkčního boxu"), boxProperties.map((item, index) => /* @__PURE__ */ React.createElement("div", {
    key: `${index}-${item?.title}`
  }, /* @__PURE__ */ React.createElement("strong", null, item?.title, ":"), " ", String(item?.value)))), !isAwaitingActivation ? /* @__PURE__ */ React.createElement("h2", null, "Tento ", !!productionBox?.isMultibox && "multi", "box je již aktivovaný") : /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("h2", null, "Aktivovat"), /* @__PURE__ */ React.createElement(FormItem, {
    label: "Vyberte majitele (právně odpovědnou osobu)"
  }, /* @__PURE__ */ React.createElement(SelectAsync.default, {
    placeholder: "Hledat dle telefonu",
    components: {
      SingleValue: ReactSelectSingleValue,
      Option: ReactSelectOption
    },
    loadOptions: fetchUsers,
    value: owner,
    onChange: (user) => {
      setOwner(user);
    }
  })), /* @__PURE__ */ React.createElement(Button, {
    type: "submit",
    disabled: !isAwaitingActivation || !owner?.uid
  }, "Aktivovat ", !!productionBox?.isMultibox && "multi", "box")))));
}
