import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderLogo from "../components/HeaderLogo.js";
import Button from "../components/Button.js";
import "./FallbackScreen.css";
export default function FallbackScreen({type}) {
  const {t} = useTranslation();
  let text = t("pageNotFound");
  if (type == "invalid-key") {
    text = t("box:key.expired2");
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-fallback"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, {
    link: "/"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-fallback__content"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "screen-fallback__title"
  }, text), /* @__PURE__ */ React.createElement(Button, {
    className: "screen-fallback__button",
    link: "."
  }, t("goStepBack"))));
}
