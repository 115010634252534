import React, {forwardRef} from "../_snowpack/pkg/react.js";
import AutosuggestOriginal from "../_snowpack/pkg/react-autosuggest.js";
import FormTextInput from "./FormTextInput.js";
const AutosuggestTextInputInput = forwardRef((props, ref) => /* @__PURE__ */ React.createElement(FormTextInput, {
  ...props,
  customRef: ref
}));
AutosuggestTextInputInput.displayName = "AutosuggestTextInputInput";
const renderInputComponent = (props) => /* @__PURE__ */ React.createElement(AutosuggestTextInputInput, {
  ...props
});
const renderSuggestion = (suggestion) => /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("strong", null, suggestion.id), " ", suggestion.name);
export default function AutosuggestTextInput(props) {
  return /* @__PURE__ */ React.createElement(AutosuggestOriginal, {
    getSuggestionValue: ({id}) => id,
    alwaysRenderSuggestions: true,
    renderSuggestion,
    renderInputComponent,
    ...props
  });
}
