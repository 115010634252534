export function money(n, currency = "Kč", locale = "cs") {
  const s = n
    ?.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    ?.replace(/,00/, "");
  return s ? `${s} ${currency}` : "";
}

export function date(d, locale = "cs") {
  return d?.toLocaleString(locale, {
    day: "numeric",
    month: "short",
    year: "numeric",
    minute: "numeric",
    hour: "numeric",
    timeZone: "Europe/Prague",
  });
}

export function dateFromTimestamp(ts) {
  return date(new Date(ts?.seconds * 1000 + ts?.nanoseconds / 1000000));
}

export function expirationTime(expireAt) {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  const remainingSeconds = expireAt - currentTimestamp;
  const remainingHours = Math.floor(remainingSeconds / 3600);
  const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
  return `${remainingHours}h ${remainingMinutes}m`;
}
