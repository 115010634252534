import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import HeaderBack from "../../components/HeaderBack.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import useDeliveriesForBox from "../hooks/useDeliveriesForBox.js";
import Delivery from "../components/Delivery.js";
import Loader from "../../components/Loader.js";
const BoxHistoryScreen = ({boxId}) => {
  const {t} = useTranslation();
  const [deliveries, loading] = useDeliveriesForBox(boxId);
  return /* @__PURE__ */ React.createElement("div", {
    className: "has-header"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", null, t("delivery:history")), loading ? /* @__PURE__ */ React.createElement(Loader, null) : deliveries.length === 0 ? t("multibox:hasNoDeliveries") : deliveries.map((delivery) => /* @__PURE__ */ React.createElement(Delivery, {
    ...delivery,
    key: delivery.id
  }))));
};
export default BoxHistoryScreen;
