export default {
  ok: "OK",
  cancel: "Zrušit",
  change: "Změnit",
  back: "Zpět",
  delivered: "Doručeno",
  collected: "Vyzvednuto",
  unknown: "Neznámý",
  loading: "Načítám ...",
  loadingData: "Načítám data ...",
  loadingApp: "Načítám aplikaci ...",
  loadingUsers: "Načítám uživatele ...",
  saving: "Ukládám ...",
  checking: "Kontroluji ...",
  confirming: "Potvrzuji ...",
  confirm: "Potvrdit",
  pairing: "Pářím ...",
  help: "Pomoc",
  add: "Přidat",
  create: "Vytvořit",
  creating: "Vytvářím ...",
  processing: "Zpracovávám ...",
  note: "Poznámka",
  open: "Otevřít",
  understood: "Rozumím",
  lost: "Nevím si rady",
  notSpecified: "Neuvedeno",
  hint: "Nápověda",
  finish: "Dokončit",
  saveChanges: "Uložit změny",
  cancelChanges: "Zahodit změny?",
  leave: "Odejít",
  logout: "Odhlásit",
  login: "Přihlásit",
  login2: "Login",
  toHome: "Domů",
  goHome: "odejít domů",
  goStepBack: "Jít o krok zpět",
  labelName: "Název",
  changeLabelName: "Změnit název",
  continue: "Pokračovat",
  choose: "Vyberte",
  general: "Obecné",
  advanced: "Pokročilé",
  remove: "Odebrat",
  options: "Možnosti",
  listEmpty: "Seznam je prázdný.",
  pageNotFound: "Stránka nenalezena",
  skip: "Přeskočit",
  begin: "Začít",
  battery: "Baterie",
  verification: "Verifikace",
  registration: "Registrace",
  welcome: "Welcome",
  close: "Zavřít",
  copiedToClipboard: "Zkopírováno do schránky.",

  CIN: "IČO",
  addressee: "Jméno adresáta, firmy ...",
  addresseePhone: "Telefonní číslo adresáta",
  phoneNumber: "Telefonní číslo",
  phone: "Telefon",
  email: "E-mail (nepovinný)",

  address: {
    label: "Adresa",
    labelLine2: "Adresa (řádek 2)",
    notFound: "Adresa nenalezena",
    enteredNotFound: "Vámi zadaná adresa nebyla nalezena",
    city: "Město",
    street: "Ulice",
    houseNo: "Číslo popisné",
    zip: "PSČ",
  },
};
