import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import IconBox from "../images/icon-box.js";
import IconDelivery from "../images/icon-send.js";
import IconDeliverySearch from "../images/icon-search-messenger.js";
import IconEdit from "../images/icon-edit.js";
import IconCheck from "../images/icon-check-circle.js";
import {DELIVERY_STATE_NAMES} from "../_snowpack/pkg/@topmonks/postcube.js";
import "./DeliveryStatus.css";
const statusText = (status, direction, t) => {
  switch (status) {
    case DELIVERY_STATE_NAMES.FORM_IN_PROGRESS:
    case DELIVERY_STATE_NAMES.PAYMENT_IN_PROGRESS:
    case DELIVERY_STATE_NAMES.PAID:
      return /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconEdit, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.unfinished")));
    case DELIVERY_STATE_NAMES.READY_FOR_BOX:
    case DELIVERY_STATE_NAMES.PACKAGE_IN_SENDER_BOX:
      return direction === "sending" ? /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconBox, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.insertToBox"))) : /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconBox, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.inPreparation")));
    case DELIVERY_STATE_NAMES.PACKAGE_WAITING_FOR_DELIVERY:
      return direction === "sending" ? /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconDeliverySearch, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.lookingForCourier"))) : /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconBox, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.inPreparation")));
    case DELIVERY_STATE_NAMES.DELIVERY_ACCEPTED_BY_TRANSPORT_COMPANY:
    case DELIVERY_STATE_NAMES.SENDER_BOX_OPENED_BY_TRANSPORT_COMPANY:
      return direction === "sending" ? /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconDelivery, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.courierOnTheWay"))) : /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconDelivery, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.beingCollectedByCourier")));
    case DELIVERY_STATE_NAMES.PACKAGE_TAKEOVER_BY_TRANSPORT_COMPANY:
    case DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_TRANSPORT_COMPANY:
      return /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconDelivery, {
        width: "16px",
        className: "color-grey mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.beingDelivered")));
    case DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX:
      return /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconCheck, {
        width: "16px",
        className: "color-primary mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivered")));
    case DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_RECIPIENT:
    case DELIVERY_STATE_NAMES.PACKAGE_CONFIRMED_BY_RECIPIENT:
      return direction === "sending" ? /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconCheck, {
        width: "16px",
        className: "color-primary mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("delivered"))) : /* @__PURE__ */ React.createElement("div", {
        className: "item-delivery-component__status"
      }, /* @__PURE__ */ React.createElement(IconCheck, {
        width: "16px",
        className: "color-primary mr8"
      }), /* @__PURE__ */ React.createElement("p", null, t("collected")));
    default:
      if (direction === "AWAITING_APPROVAL") {
        return /* @__PURE__ */ React.createElement("div", {
          className: "item-delivery-component__status"
        }, /* @__PURE__ */ React.createElement(IconBox, {
          width: "16px",
          className: "color-grey mr8"
        }), /* @__PURE__ */ React.createElement("p", null, t("delivery:status.waitingForApproval")));
      }
      return /* @__PURE__ */ React.createElement("p", null, t("delivery:status.noStatus"));
  }
};
export default function DeliveryStatus({status, direction}) {
  const {t} = useTranslation();
  return statusText(status, direction, t);
}
