import React, {useCallback} from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import ScreenHeader from "../components/ScreenHeader.js";
import HeaderLogo from "../components/HeaderLogo.js";
import Tabbar from "../components/Tabbar.js";
import ItemDelivery from "../components/ItemDelivery.js";
import IconSend from "../images/icon-add-circle.js";
import {
  DELIVERY_STATE_NAMES,
  DELIVERY_STATE_VISIBILITY,
  DELIVERY_DIRECTION
} from "../_snowpack/pkg/@topmonks/postcube.js";
import {CenteredLoader} from "../components/Loader.js";
import useAlert from "../hooks/useAlert.js";
import Warning from "../components/Warning.js";
import useMyBoxes from "../hooks/useMyBoxes.js";
import ModalCreateDelivery from "../modals/ModalCreateDelivery.js";
import ApproveUnlockRequestModal from "../modals/ApproveUnlockRequestModal.js";
import useMobileModal from "../hooks/useMobileModal.js";
import useAwaitingUnlockRequests from "../hooks/useAwaitingUnlockRequests.js";
import useCollection from "../hooks/useCollection.js";
import "./HomeScreen.css";
const LAST_DELIVERIES_LIMIT = 5;
export default function HomeScreen({
  route: {
    data: {user}
  }
}) {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const alert = useAlert();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const {openModal, closeModal, modal} = useMobileModal();
  const handleCreateDelivery = () => {
    openModal(/* @__PURE__ */ React.createElement(ModalCreateDelivery, {
      onBoxSelected: (link) => () => {
        closeModal();
        navigation.navigate(link);
      },
      onAddressSelected: (link) => () => {
        closeModal();
        navigation.navigate(link);
      }
    }));
  };
  const handleApproveUnlockRequest = (unlockRequest) => {
    openModal(/* @__PURE__ */ React.createElement(ApproveUnlockRequestModal, {
      unlockRequest,
      alert,
      onClose: closeModal
    }));
  };
  const {awaitingUnlockRequests, awaitingUnlockRequestsLoading} = useAwaitingUnlockRequests(user, {onError: handleScreenError});
  const [_outgoingDeliveries = [], outgoingDeliveriesLoading] = useCollection(`/deliveries`, {
    skip: !user,
    where: [["sender.ownerId", "==", user?.uid]],
    orderBy: ["updatedAt", "desc"],
    limit: LAST_DELIVERIES_LIMIT,
    onError: handleScreenError
  });
  const outgoingDeliveries = _outgoingDeliveries.filter((delivery) => !~["INSTANT_RECEPTION", "REQUEST_RECEPTION"].indexOf(delivery?.transportProvider?.type));
  const [incomingDeliveries = [], incomingDeliveriesLoading] = useCollection(`/deliveries`, {
    skip: !user,
    where: [
      ["recipient.ownerId", "==", user?.uid],
      ["state", "in", DELIVERY_STATE_VISIBILITY.RECIPIENT]
    ],
    orderBy: ["updatedAt", "desc"],
    limit: LAST_DELIVERIES_LIMIT,
    onError: handleScreenError
  });
  const {boxes: myBoxes, loading: myBoxesLoading} = useMyBoxes(user, {
    onError: handleScreenError
  });
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-home"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderLogo, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, !myBoxesLoading && !myBoxes.length && /* @__PURE__ */ React.createElement(Warning, {
    type: "alert",
    headline: t("box:warning.nonePaired.headline"),
    text: t("box:warning.nonePaired.text"),
    className: "mt16 mb16",
    link: "/boxes/pairing"
  }), myBoxes.map(({id, name, address, isMultibox}) => address && name ? null : /* @__PURE__ */ React.createElement(Warning, {
    key: id,
    type: "alert",
    headline: t("box:warning.missingDetails.headline", {id}),
    text: t("box:warning.missingDetails.text"),
    className: "mb16",
    link: isMultibox ? `/multibox/${id}/edit` : `/boxes/${id}/edit`
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-home__header"
  }, /* @__PURE__ */ React.createElement("h1", null, t("delivery:label", {count: 5})), /* @__PURE__ */ React.createElement("button", {
    title: t("delivery:create"),
    name: "createDelivery",
    className: cs("screen-home__button-send"),
    disabled: !myBoxes.length,
    onClick: handleCreateDelivery
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("mr8", myBoxes.length && "pulsing")
  }, /* @__PURE__ */ React.createElement(IconSend, {
    className: cs("screen-home__new-delivery-button", !myBoxes.length && "screen-home__new-delivery-button--disabled")
  })))), !awaitingUnlockRequestsLoading && awaitingUnlockRequests?.length > 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", {
    className: "scree-home__label"
  }, t("box:openingRequest.label")), awaitingUnlockRequests.map((ur, index) => {
    return /* @__PURE__ */ React.createElement(ItemDelivery, {
      key: `${ur?.id}-${index}`,
      name: t("box:openingRequest.label2"),
      code: /* @__PURE__ */ React.createElement("em", null, ur?.message),
      id: ur?.id,
      direction: "AWAITING_APPROVAL",
      onClick: () => {
        handleApproveUnlockRequest(ur);
      }
    });
  })), /* @__PURE__ */ React.createElement("p", {
    className: "scree-home__label"
  }, t("delivery:receiving")), incomingDeliveries.map((d) => {
    let name = d.sender?.name;
    switch (d?.transportProvider?.type) {
      case "INSTANT_RECEPTION":
        name = t("delivery:unknownInsertedBySomeone");
        break;
      case "REQUEST_RECEPTION":
        name = t("delivery:unknowInsertedWithPermission");
        break;
    }
    return /* @__PURE__ */ React.createElement(ItemDelivery, {
      name,
      code: d.sender?.type === "eshop" && d.sender?.orderNumber,
      id: d.id,
      key: d.id,
      status: d.state,
      direction: DELIVERY_DIRECTION.INCOMING,
      onClick: () => {
        navigation.navigate(`/deliveries/${DELIVERY_DIRECTION.INCOMING}/${d.id}`);
      }
    });
  }), incomingDeliveriesLoading && !incomingDeliveries.length && /* @__PURE__ */ React.createElement(CenteredLoader, null), !incomingDeliveriesLoading && incomingDeliveries.length === 0 && /* @__PURE__ */ React.createElement("p", {
    className: "screen-home__empty mb16"
  }, t("delivery:noneAwaiting")), /* @__PURE__ */ React.createElement("p", {
    className: "scree-home__label"
  }, t("delivery:sending")), outgoingDeliveries.map((d) => {
    const isDraftDelivery = [
      DELIVERY_STATE_NAMES.FORM_IN_PROGRESS,
      DELIVERY_STATE_NAMES.PAYMENT_IN_PROGRESS
    ].includes(d.state);
    const isAddressType = d.recipient?.type === "address";
    return /* @__PURE__ */ React.createElement(ItemDelivery, {
      name: d.recipient?.name,
      type: d.recipient?.type,
      id: d.id,
      key: d.id,
      status: d.state,
      direction: DELIVERY_DIRECTION.SENDING,
      onClick: () => {
        if (isDraftDelivery) {
          if (isAddressType) {
            navigation.navigate(`/send/1/address`, {
              state: {
                deliveryId: d.id
              }
            });
          } else {
            navigation.navigate(`/send/1`, {
              state: {
                deliveryId: d.id
              }
            });
          }
        } else {
          navigation.navigate(`/deliveries/${DELIVERY_DIRECTION.SENDING}/${d.id}`);
        }
      }
    });
  }), !outgoingDeliveriesLoading && outgoingDeliveries.length === 0 && /* @__PURE__ */ React.createElement("div", {
    className: "mt16"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "screen-home__empty mb16"
  }, t("delivery:noneSending")))), /* @__PURE__ */ React.createElement(Tabbar, {
    home: true
  }), modal);
}
