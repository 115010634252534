export default {
  apiKey: "AIzaSyBE484_KyFQQBoViZUnQT6mIbHPL1Ci3ug",
  authDomain: "chytrybox.firebaseapp.com",
  databaseURL: "https://chytrybox.firebaseio.com",
  projectId: "chytrybox",
  storageBucket: "chytrybox.appspot.com",
  messagingSenderId: "363672533476",
  appId: "1:363672533476:web:ecfc5d1ac059c3a709eff1",
  measurementId: "G-EZ80W60GL0",
};
