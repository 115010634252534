import common from "./common.js";
import delivery from "./delivery.js";
import payment from "./payment.js";
import box from "./box.js";

export default {
  delivery,
  payment,
  box,
  common,

  multibox: {
    label: "Multibox",
    labelWithIdNumber: "Multibox č. {{multiboxId}}",
    fillAddress: "Vyplňte adresu multiboxu",
    editDetails: "Upravit informace o multiboxu",
    hasNoDeliveries: "Tento multibox zatím nemá žádné objednávky...",
    editSuccess: "Multibox úspěšně upraven",
  },

  organisation: {
    mineLabel: "Moje organizace",
    myApps: "Moje aplikace",
    loading: "Načítám organizaci...",
    loadingApps: "Načítám aplikace...",
    noApps: "Zatím nemáte žádnou aplikaci",
    addApp: "Přidat aplikaci",
    newApp: "Nová aplikace",
    appName: "Název aplikace",
    name: "Název / jméno",
    appMustHaveName: "Nová aplikace se přece musí nějak jmenovat!",
    cbUrlRequired: "Callback URL je povinná pro aplikaci typu SPA",

    integrationInfo: `Pro více informací o integraci s Postcube se prosím podívejte na <1>https://sdk.postcube.cz</1>, kde najdete veřejnou dokumentaci Postcube API.`,
    rotateCredentials: "Rotovat přístupové údaje",
  },

  auth: {
    notLoggedIn: "Nejste přihlášeni.",
    loginToApp: "Přihlašte se do aplikace.",
    registerPostCube: "Zaregistrujte si svůj PostCube",
    alreadyHaveAccountLogin: "Již mám účet, <1>přihlásit se</1>.",
    haveNoAccountRegister: "Nemám účet, <1>zaregistrovat se</1>.",
    loginWithSMSCode: "Přihlášení SMS kódem",
    verifyWithSMSCode: "Ověření SMS kódem",
    activateAccountWithSMSCode:
      "Aktivujte svůj účet kódem, který jsme vám poslali SMSkou.",
    confirmLoginWithSMSCode:
      "Potvrďte přihlášení kódem, který jsme vám poslali SMSkou.",
    activateAccount: "Aktivovat účet",
    confirmLogin: "Potvrdit přihlášení",
    verifyCode: "Aktivační kód z SMS",
    sendCodeAgain: "Znovu odeslat kód",
    reallyLogout: "Opravdu se chcete odhlásit?",
    sectionAccessDenied: "Tato sekce není pro vás!",
    noUserWithThisPhoneRegister:
      "Uživatel s daným telefonním číslem neexistuje. Nejdříve se prosím zaregistrujte",
  },

  permissions: {
    label: "Uživatelské přístupy",
    noUserExistsWithThisPhone: "Uživatel s tímto číslem neexistuje",
    insertUserPhone:
      "Zadejte telefonní číslo uživatele, kterému chcete přiřadit oprávnění",
    noUserFound: "Žádný uživatel nenalezen",
  },

  BT: {
    notAvailable: "Bluetooth není k dispozici",
    turnItOnPlease: "Prosím zapněte jej v nastavení telefonu.",
    missingPermission: "Chybí bluetooth oprávnění",
    allowItPlease: "Prosím povolte jej v Nastavení > PostCube > Bluetooth.",
    deviceIdNotFound: "Zařízení s ID {{id}} nenalezeno.",
    noDeviceFound: "Žádné zařízení nenalezeno.",
    turnItOnInDevice: "Zapněte Bluetooth ve vašem zařízení",
    turnItOnBeforeOpeningBox:
      "Pro otevření schránky PostCube je třeba zapnout ve vašem zařízení Bluetooth.",
    turnItOnAndClickBtn:
      "Zapněte si ve svém zařízení bluetooth a klikněte na tlačítko.",
    chooseBoxInListAndPair:
      'Vyberte v seznamu <1>PostCube {{boxId}}</1> a klikněte na "Párovat"',
    boxWillNotifyAndOpen: "Schránka vydá signál a otevře se",
  },

  pairing: {
    searchingAround: "Hledám PostCube schránky v dosahu...",
    pairFirst:
      "Než bude možné schránku použít k doručování zásilek, je ji potřeba nejprve spárovat.",
    distanceNotice:
      "<0>Důležité</0> je při tomto procesu být v bezprosřední vzdálenosti od schránky a mít ve svém zařízení povolený a zapnutý <2>Bluetooth</2>.",

    step1: "1. Vyberte box k párování",
    step2: "2. Zadejte kód z manuálu",
    step2Label: "Zadejte kód z manuálu k vašemu boxu",
    overDevicesLimit:
      "Box už je spárován se 4 zařízeními vyberte, které chcete nahradit",
  },

  settings: {
    label: "Nastavení",
    deviceName: "Jméno zařízení",
    yourDeviceName: "Jméno zařízení",
    yourEmail: "Váš e-mail",
    changeEmail: "Změnit e-mail",
    notSet: "Není nastaven",
    serviceOpening: "Servisní otevření",
    emailWrongFormat: "Zadali jste e-mail v nesprávném formátu.",
    emailSaved: "E-mail úspěšně uložen.",
  },

  error: {
    somethingWentWrong: "Něco se pokazilo",
    oopsSomethingWentWrong: "Jejda! Něco se pokazilo.",
    unknown: "Neznámá chyba",
    unlock: "Chyba odemykání",
    saveKey: "Chyba ukládání klíčů",
    pair: "Chyba párování",
    unknownBtError: "Neznámá bluetooth chyba",
    carrier: "Chyba dopravce",
    report: "Nahlásit problém",

    unlockBox: "Chyba při odemykání schránky",
    approveUnlockRequest: "Chyba při potvrzování žádosti o otevření",
    refuseUnlockRequest: "Chyba při zamítání žádosti o otevření",
    loadMultibox: "Chyba při načítání multiboxu",
    loadOrganisation: "Chyba při načítání organizace",
    loadApp: "Chyba při načítání aplikace",
    saveEmail: "Chyba při ukládání e-mailu",
    loginTryAgain: "Přihlášení se nezdařilo. Zkuste to prosím znovu.",
  },

  welcome: {
    1: {
      heading: "Klíč k vaší chytré schránce",
      text: "Otevírejte vaši PostCube pomocí mobilu propojením přes Bluetooth. V aplikaci se dozvíte i stav baterie zámku.",
    },
    2: {
      heading: "Odesílejte a přijímejte zásilky",
      text: "Připravujte balíky k odeslání přímo v aplikaci. Mějte přehled o zásilkách, které vám mají dorazit.",
    },
    3: {
      heading: "Kód k otevření pro každého kurýra",
      text: "Není váš oblíbený přepravce plně propojen s naším systémem? Pošlete kurýrovi odkaz k otevření vaší PostCube.",
    },
  },

  help: {
    text: "Otevření schránky PostCube krok za krokem.",
    1: {
      heading: "1. Otevření schránky tlačítkem",
      text: 'Začněte stisknutím tlačítka "Otevřít schránku". Otevře se dialogové okno s možností vybrat schránku k otevření.',
    },
    2: {
      heading: "2. Zapnutí Bluetooth",
      text: "Pro otevření boxu je třeba mít ve vašem mobilu zapnuté Bluetooth. V případě, že to tak není, klikněte na příslušný odkaz v dialogovém okně.",
    },
    3: {
      heading: "3. Povolení polohy vašeho internetového prohlížeče",
      text: "Aby spojení se schránku správně fungovalo, je potřeba va vašem prohlížeči povolit polohovací služby. Pokud zapnuté nejsou, klikněte na příslušný odkaz v dialogovém okně.",
      extra:
        "Toto nastavení se uloží pro všechna další otevření schránky PostCube.",
    },
    4: {
      heading: "4. Vybrání příslušné schránky",
      text: 'Kliknutím na schránku s odpovídajícím číslem boxu a následným kliknutím na tlačítko "Párovat" se váš mobil spojí se schránkou a otevře dvířka.',
    },
    5: {
      heading: "5. Zpět do vaši aplikace pro kurýra",
      text: "Po vyzvednutí zásilky pokračujte ve vaši aplikaci jak jste zvyklí.",
    },
  },

  install: {
    heading: "Stáhněte si aplikaci pro váš iPhone, iPad nebo Mac",
    download: "Stáhnout z AppStore",
    or: "nebo",
    goToWebApp: "Přejít do webové aplikace",
    withoutLockFuncs: "(bez funkcí zámku)",
  },

  other: {
    returnToMessengerAppAndContinue:
      "Vraťte se do vaši aplikace pro kurýra a pokračujte jako obvykle",
    callSupport: "Zavolat podporu PostCube",
    welcomeByPostCube: "Vítá vás PostCube!",
  },
};
