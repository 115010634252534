import React, {useState, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import IconPackage from "../../images/icon-package.js";
import IconAlert from "../../images/icon-alert.js";
import Divider from "../../components/Divider.js";
import Button from "../../components/Button.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {DELIVERY_TRANSITION_NAME} from "../../_snowpack/pkg/@topmonks/postcube.js";
import useDelivery from "../../hooks/useDelivery.js";
import useAlert from "../../hooks/useAlert.js";
import "./ConfirmScreen.css";
export default function DeliveryConfirmScreen({
  deliveryId,
  route: {
    state: {canNavGoBack}
  }
}) {
  const [confirming, setConfirming] = useState(false);
  const navigation = useNavigation();
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const {updateDelivery} = useDelivery(deliveryId, {
    onError: handleScreenError
  });
  const handleConfirm = () => {
    if (confirm(t("delivery:makeSureItsOkay"))) {
      setConfirming(true);
      updateDelivery(null, DELIVERY_TRANSITION_NAME.RECIPIENT_CONFIRMS_DELIVERY).then((result) => {
        if (result)
          alert.success(t("delivery:confirmed"));
        setConfirming(false);
      }).then(() => canNavGoBack ? navigation.goBack() : navigation.navigate("/"));
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-confirm-delivery"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    text: canNavGoBack ? t("back") : t("toHome"),
    path: canNavGoBack ? null : "/"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-confirm-delivery__package mt32 mb32"
  }, /* @__PURE__ */ React.createElement(IconPackage, {
    width: "64px",
    className: "mb16"
  }), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:checkPlease"))), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(Button, {
    className: "mb8",
    onClick: handleConfirm,
    type: "submit",
    disabled: confirming,
    name: "confirm-delivery"
  }, t("delivery:status.takenProperly")), /* @__PURE__ */ React.createElement(Button, {
    secondary: true,
    className: "screen-confirm-delivery__problem-button",
    link: "tel:+420739304189",
    name: "report-problem"
  }, /* @__PURE__ */ React.createElement(IconAlert, {
    width: "16px",
    className: "ml8 mr8"
  }), t("error:report"))));
}
