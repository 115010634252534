import React from "../../_snowpack/pkg/react.js";
import "./MoreScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import Tabbar from "../components/TabbarWombat.js";
import Icon4 from "../../images/icon-settings.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
export default function MoreScreen() {
  const navigation = useNavigation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "more-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    path: "/settings",
    text: "PostCube"
  }), null), /* @__PURE__ */ React.createElement("div", {
    className: "more-wombat-screen__container"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "more-wombat-screen__h1"
  }, "More actions"), /* @__PURE__ */ React.createElement("div", {
    className: "more-wombat-screen__box",
    onClick: () => navigation.navigate("more/lockers")
  }, /* @__PURE__ */ React.createElement(Icon4, {
    className: "more-wombat-screen__icon"
  }), /* @__PURE__ */ React.createElement("h3", {
    className: "more-wombat-screen__text"
  }, "Lockers")), /* @__PURE__ */ React.createElement("div", {
    className: "more-wombat-screen__box",
    onClick: () => navigation.navigate("more/proto2")
  }, /* @__PURE__ */ React.createElement(Icon4, {
    className: "more-wombat-screen__icon"
  }), /* @__PURE__ */ React.createElement("h3", {
    className: "more-wombat-screen__text"
  }, "Multibox Protocol"))), /* @__PURE__ */ React.createElement(Tabbar, {
    more: true
  }));
}
