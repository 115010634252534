import { useState, useEffect } from "../_snowpack/pkg/react.js";
import { functions } from "../firebase.js";
import { httpsCallable } from "../_snowpack/pkg/firebase/functions.js";

export default function useUserPaymentMethods({
  onError = console.error,
} = {}) {
  const [paymentMethods, setPaymentMethods] = useState();
  const [stateName, setStateName] = useState(null);

  useEffect(() => {
    if (["loading", "loaded", "error"].includes(stateName)) return;

    setStateName("loading");
    httpsCallable(functions(), "stripe-listPaymentMethods")()
      .then(({ data }) => {
        setStateName("loaded");
        setPaymentMethods(data);
      })
      .catch((err) => {
        setStateName("error");
        onError(err);
      });
  }, [stateName, onError]);

  return paymentMethods;
}
