/**
 * https://github.com/don/cordova-plugin-ble-central#connect
 */
import ble from "../_snowpack/pkg/cordova-plugin-ble-central/www/ble.js";
import {
  splitCommand,
  parseDeviceId,
  ERROR_BLUETOOTH_DISABLED,
  ERROR_BLUETOOTH_UNAUTHORIZED,
  ERROR_BLUETOOTH_UNSUPPORTED,
  ERROR_BLUETOOTH_RESSETING,
} from "./BleAdapter.js";
import {
  DEPRECATED_SERVICE_UUID,
  DEPRECATED_SERVICE_UUID_16_CORDOVA,
  DEPRECATED_CHAR_RESULT_UUID,
  DEPRECATED_CHAR_RESULTS_INDEX,
} from "../_snowpack/pkg/@topmonks/postcube.js";
import { t } from "../_snowpack/pkg/i18next.js";

function parseResult(raw, characteristic) {
  const buffer = new Uint8Array(raw);
  const index = DEPRECATED_CHAR_RESULTS_INDEX[characteristic];
  const result = buffer[index];
  return result;
}

const promisify = (method, ...args) =>
  new Promise((resolve, reject) => {
    method(...args, resolve, reject);
  });
export default function BleAdapter(bleDevice) {
  const adapter = {
    native: bleDevice,
    type: "cordova",

    get id() {
      return parseDeviceId(bleDevice);
    },

    get name() {
      return bleDevice.name;
    },

    async connect() {
      await promisify(ble.connect, bleDevice.id);
    },

    async disconnect() {
      await promisify(ble.disconnect, bleDevice.id);
    },

    async readBattery() {
      return new Promise((resolve, reject) => {
        ble.read(
          bleDevice.id,
          "0x180f",
          "0x2a19",
          (data) => {
            const [firstBite] = new Uint8Array(data);
            return resolve(firstBite);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    async send(charUUID, data, { timeoutInSecs = 30 } = {}) {
      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          resolve(new Error("timeout"));
        }, timeoutInSecs * 1000);

        const handler = (data) => {
          clearTimeout(timeout);
          const result = parseResult(data, charUUID);
          resolve(result);
        };
        ble.startNotification(
          bleDevice.id,
          DEPRECATED_SERVICE_UUID,
          DEPRECATED_CHAR_RESULT_UUID,
          handler,
          resolve
        );

        const dataToWrite = splitCommand(new Uint8Array(data), 20);
        for (const chunk of dataToWrite) {
          const copied = new Uint8Array([...chunk]);
          ble.write(
            bleDevice.id,
            DEPRECATED_SERVICE_UUID,
            charUUID,
            copied.buffer,
            () => {},
            resolve
          );
        }
      });
    },
  };

  return adapter;
}

BleAdapter.scanStart = (onSuccess, onFailure) => {
  // This is a workaround for https://github.com/don/cordova-plugin-ble-central/issues/828
  const tryToScan = () => {
    ble.startScan(
      [DEPRECATED_SERVICE_UUID, DEPRECATED_SERVICE_UUID_16_CORDOVA],
      (foundDevice) => {
        onSuccess(BleAdapter(foundDevice));
      },
      (error) => {
        console.error(error);
      }
    );
  };
  ble.isEnabled(tryToScan, () => {
    setTimeout(tryToScan, 100);
  });

  ble.startStateNotifications((state) => {
    switch (state) {
      case "on": // ok, good user
        return onFailure(null);
      case "off": // user needs to turn bt on
        return onFailure({ code: ERROR_BLUETOOTH_DISABLED });
      case "unauthorized": // user needs to grant bt presmission
        return onFailure({ code: ERROR_BLUETOOTH_UNAUTHORIZED });
      case "unsupported": // user has unsupported device
        return onFailure({ code: ERROR_BLUETOOTH_UNSUPPORTED });
      case "resetting": // bt is just resseting
        return onFailure({ code: ERROR_BLUETOOTH_RESSETING });
      default:
        console.error("Unknown StateNotification state:", state);
    }
  });
};

BleAdapter.scanStop = (success = () => {}, failure = () => {}) => {
  console.log("stopScan");

  ble.stopScan(success, failure);
  ble.stopStateNotifications();
};

BleAdapter.findDevice = ({ id } = {}) => {
  const timeoutInSecs = 4;
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      if (id) {
        reject({
          code: "timeout",
          message: t("BT:deviceIdNotFound", { id }),
        });
        return;
      }

      reject({ code: "timeout", message: t("BT:noDeviceFound") });
    }, timeoutInSecs * 1000);

    ble.startScan(
      [DEPRECATED_SERVICE_UUID, DEPRECATED_SERVICE_UUID_16_CORDOVA],
      (bleDevice) => {
        bleDevice.name = bleDevice.advertising.kCBAdvDataLocalName;
        if (parseDeviceId(bleDevice) === id) {
          clearTimeout(timer);
          ble.stopScan(() => {
            resolve(BleAdapter(bleDevice));
          });
        }
      },
      reject
    );
  });
};
