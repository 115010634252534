import React, {useEffect, useMemo} from "../../_snowpack/pkg/react.js";
import "./DeliveryScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import IconBox from "../../images/icon-box.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Loader from "../../components/Loader.js";
import Button from "../../components/Button.js";
import useAlert from "../../hooks/useAlert.js";
import {formatDateTime} from "../library/datetime.js";
import {remoteConfig} from "../../firebase.js";
import {getBoolean} from "../../_snowpack/pkg/firebase/remote-config.js";
import useDoc from "../../hooks/useDoc.js";
import useCollection from "../../hooks/useCollection.js";
export default function DeliveryScreen({
  id,
  route: {
    state: {delivery: deliveryPreview}
  }
}) {
  const navigation = useNavigation();
  const alert = useAlert();
  const hasHolubiPostaFeature = useMemo(() => getBoolean(remoteConfig, "holubi_posta"), []);
  const [logs = [], logsLoading, logsError] = useCollection(`/deliveries/${id}/logs`, {
    orderBy: ["createdAt", "desc"]
  });
  const [delivery, deliveryLoading, deliveryErrors] = useDoc(`/deliveries/${id}`, {skip: deliveryPreview});
  useEffect(() => {
    for (let error of [logsError, deliveryErrors]) {
      if (error)
        alert.error(error);
    }
  }, [logsError, deliveryErrors, alert]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery-wombat"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null), /* @__PURE__ */ React.createElement(IconBox, {
    fill: "var(--color-white)",
    height: "24px",
    lenght: "24px",
    onClick: () => {
      navigation.navigate(`${id}/map`, {
        state: {delivery}
      });
    }
  })), delivery && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery-wombat__info"
  }, delivery.sender.type === "eshop" ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, delivery.sender.orderNumber), /* @__PURE__ */ React.createElement("p", null, "Public Id: ", delivery.publicId), /* @__PURE__ */ React.createElement("h2", null, "Sender"), /* @__PURE__ */ React.createElement("p", null, "Eshop: ", delivery.sender.name), /* @__PURE__ */ React.createElement("p", null, "Adresa: ", delivery.sender.address || "adresa"), /* @__PURE__ */ React.createElement("h2", null, "Recipient"), /* @__PURE__ */ React.createElement("p", null, "Jmeno: ", delivery.recipient.name), /* @__PURE__ */ React.createElement("p", null, "Adresa: ", delivery.recipient.address || "adresa"), /* @__PURE__ */ React.createElement("p", null, " Box: ", delivery.recipient.id, " ")) : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, delivery.code), /* @__PURE__ */ React.createElement("p", null, "Public Id: ", delivery.publicId), /* @__PURE__ */ React.createElement("h2", null, "Sender"), /* @__PURE__ */ React.createElement("p", null, "Jmeno: ", delivery.sender.name), /* @__PURE__ */ React.createElement("p", null, "Adresa: ", delivery.sender.address || "adresa"), /* @__PURE__ */ React.createElement("p", null, "Box: ", delivery.sender.id), /* @__PURE__ */ React.createElement("h2", null, "Recipient"), /* @__PURE__ */ React.createElement("p", null, "Jmeno: ", delivery.recipient.name), /* @__PURE__ */ React.createElement("p", null, "Adresa: ", delivery.recipient.address || "adresa"), /* @__PURE__ */ React.createElement("p", null, " Box: ", delivery.recipient.id, " "))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery-wombat__container"
  }, /* @__PURE__ */ React.createElement("h1", null, "Logs"), logs.map(({createdAt, state, author}) => /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery-wombat__log",
    key: createdAt
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "screen-delivery-wombat__state"
  }, state), /* @__PURE__ */ React.createElement("p", null, formatDateTime(createdAt)), /* @__PURE__ */ React.createElement("p", null, author))), hasHolubiPostaFeature && /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery-wombat__state-change"
  }, /* @__PURE__ */ React.createElement("h2", null, "Holubí pošta"), /* @__PURE__ */ React.createElement("p", null, "DELIVERY_STATE"), /* @__PURE__ */ React.createElement(Button, null, "Další krok")))), (logsLoading || deliveryLoading) && /* @__PURE__ */ React.createElement(Loader, null));
}
