import React, {useState, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {
  DELIVERY_TRANSITION_NAME,
  DELIVERY_STATE_NAMES,
  DELIVERY_DIRECTION,
  DELIVERY_STATE_VISIBILITY
} from "../../_snowpack/pkg/@topmonks/postcube.js";
import useAlert from "../../hooks/useAlert.js";
import DeliveryStatus from "../../components/DeliveryStatus.js";
import Loader from "../../components/Loader.js";
import Button from "../../components/Button.js";
import {dateFromTimestamp, money} from "../../library/format.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import useDelivery from "../../hooks/useDelivery.js";
import useMyBoxes from "../../hooks/useMyBoxes.js";
import IconBox from "../../images/icon-box.js";
import useBleDevicesNearby from "../../hooks/useBleDevicesNearby.js";
import DeliveryTimeline from "../../components/DeliveryTimeline.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import Warning from "../../components/Warning.js";
import "./Screen.css";
export default function ScreenDeliveryRecipient({
  route: {
    data: {user}
  },
  deliveryId
}) {
  const [unlocking, setUnlocking] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const alert = useAlert();
  const navigation = useNavigation();
  const {t} = useTranslation();
  const {
    devices: bleDevicesNearby,
    findNearby: findBleDeviceNearby,
    error: bleError
  } = useBleDevicesNearby();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const {unlock: unlockMyBox} = useMyBoxes(user, {
    onError: handleScreenError
  });
  const {delivery, deliveryLoading, updateDelivery} = useDelivery(deliveryId, {onError: handleScreenError});
  const isPublicReception = ["INSTANT_RECEPTION", "REQUEST_RECEPTION"].includes(delivery?.transportProvider?.type);
  const handleUnlockBox = async () => {
    setUnlocking(true);
    try {
      const box = delivery.recipient;
      let bleDevice = bleDevicesNearby.find(({id}) => id === box.id);
      if (!bleDevice)
        bleDevice = await findBleDeviceNearby(box);
      await unlockMyBox(bleDevice);
      alert.success(t("box:opened"));
      await updateDelivery(null, DELIVERY_TRANSITION_NAME.RECIPIENT_OPENS_BOX);
      await navigation.navigate(`/deliveries/${DELIVERY_DIRECTION.INCOMING}/${deliveryId}/confirm`, {
        state: {canNavGoBack: true}
      });
    } catch (err) {
      handleScreenError(err);
    } finally {
      setUnlocking(false);
    }
  };
  const handleConfirm = () => {
    if (confirm(t("delivery:makeSureItsOkay"))) {
      setConfirming(true);
      updateDelivery(null, DELIVERY_TRANSITION_NAME.RECIPIENT_CONFIRMS_DELIVERY).then(() => {
        alert.success(t("delivery:confirmed"));
        setConfirming(false);
      });
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery screen-delivery--recipient"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    text: t("back")
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "app-header"
  }, t("delivery:label", {count: 1}), " ", delivery?.sender?.type === "eshop" ? delivery?.sender.orderNumber : delivery?.code), bleError && delivery?.state === DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX && /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__error"
  }, /* @__PURE__ */ React.createElement(Warning, {
    ...bleError,
    text: bleError.message
  })), !delivery && deliveryLoading ? /* @__PURE__ */ React.createElement(Loader, {
    className: "screen-delivery__loader",
    text: t("loadingData")
  }) : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(DeliveryStatus, {
    status: delivery?.state,
    type: DELIVERY_DIRECTION.INCOMING
  }), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, DELIVERY_STATE_VISIBILITY.MESSENGER_DELIVERED.includes(delivery?.state) && dateFromTimestamp(delivery?.updatedAt))), isPublicReception && /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__public-reception"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:type")), /* @__PURE__ */ React.createElement("p", null, {
    INSTANT_RECEPTION: t("delivery:instantInsert"),
    REQUEST_RECEPTION: t("delivery:confirmedInsert")
  }[delivery?.transportProvider?.type] || "-")), /* @__PURE__ */ React.createElement("h2", null, t("delivery:messageFromInserter")), delivery?.note || "-"), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__timeline"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__timeline-box"
  }, /* @__PURE__ */ React.createElement(DeliveryTimeline, {
    state: delivery?.state,
    type: isPublicReception ? "BOX_BOX_PUBLIC_RECEPTION" : "BOX_BOX_RECIPIENT"
  }))), [
    DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX,
    DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_RECIPIENT
  ].includes(delivery?.state) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
    onClick: handleUnlockBox,
    className: "mb8",
    disabled: false
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    light: true,
    text: t("box:opening")
  }) : t("box:open")), /* @__PURE__ */ React.createElement(Button, {
    onClick: handleConfirm,
    secondary: true,
    className: "mb8",
    disabled: unlocking || confirming
  }, confirming ? /* @__PURE__ */ React.createElement(Loader, {
    light: true,
    text: t("confirming")
  }) : t("delivery:status.takenProperly"))), delivery && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info mt32"
  }, /* @__PURE__ */ React.createElement("h2", null, t("delivery:detail")), delivery?.sender?.type !== "eshop" && /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:price")), /* @__PURE__ */ React.createElement("p", null, money(delivery.transportProvider?.price))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info"
  }, delivery.sender?.type === "eshop" ? /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:number")), /* @__PURE__ */ React.createElement("p", null, delivery.sender?.orderNumber)) : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:packageCode2")), /* @__PURE__ */ React.createElement("p", null, delivery.code)))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:creation")), /* @__PURE__ */ React.createElement("p", null, dateFromTimestamp(delivery.createdAt))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:collection")), /* @__PURE__ */ React.createElement("p", null, DELIVERY_STATE_VISIBILITY.MESSENGER_DELIVERED.includes(delivery.state) ? dateFromTimestamp(delivery.updatedAt) : "---"))), !isPublicReception && /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__address"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "screen-delivery__address-label color-grey mb8"
  }, t("delivery:sender").toUpperCase()), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, delivery.sender?.name), delivery.sender?.id && /* @__PURE__ */ React.createElement("small", null, /* @__PURE__ */ React.createElement(IconBox, {
    width: "14px",
    fill: "var(--color-primary)",
    className: "ml8 mr4"
  }), delivery.sender?.id)), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.sender?.addressStreet, " ", delivery.sender?.addressStreetNumber), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.sender?.addressPSC), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.sender?.addressCity)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__address"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "screen-delivery__address-label color-grey mb8"
  }, t("delivery:recipient").toUpperCase()), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, delivery.recipient?.name), delivery.recipient?.id && /* @__PURE__ */ React.createElement("small", null, /* @__PURE__ */ React.createElement(IconBox, {
    width: "14px",
    fill: "var(--color-primary)",
    className: "ml8 mr4"
  }), delivery.recipient?.id)), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.recipient?.addressStreet, " ", delivery.recipient?.addressStreetNumber), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.recipient?.addressPSC), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.recipient?.addressCity)))));
}
