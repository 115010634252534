import React, {Fragment, useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {APP_TYPE} from "../../_snowpack/pkg/@topmonks/postcube.js";
import Button from "../../components/Button.js";
import FormTextInput from "../../components/FormTextInput.js";
import ListSelect from "../../components/ListSelect.js";
import {APP_TYPE_LABEL} from "../constants.js";
import useAlert from "../../hooks/useAlert.js";
const appTypes = Object.keys(APP_TYPE_LABEL).map((type) => ({
  id: type,
  name: APP_TYPE_LABEL[type]
}));
export default function AddAppModal({onSave, onCancel}) {
  const [name, setName] = useState();
  const [callbackUrl, setCallbackUrl] = useState();
  const [type, setType] = useState(appTypes[0]);
  const alert = useAlert();
  const {t} = useTranslation();
  const handleSubmit = (event) => {
    event.preventDefault();
    const formError = (message) => {
      if (alert) {
        alert.error(message);
      }
    };
    let request = {};
    switch (type?.id) {
      case APP_TYPE.MACHINE_2_MACHINE:
        if (!name) {
          return formError(t("organisation:appMustHaveName"));
        }
        request = {
          name,
          type: type.id
        };
        break;
      case APP_TYPE.SINGLE_PAGE:
        if (!name) {
          return formError(t("organisation:appMustHaveName"));
        }
        if (!callbackUrl) {
          return formError(t("organisation:cbUrlRequired"));
        }
        request = {
          name,
          callbackUrl,
          type: type.id
        };
        break;
    }
    if (typeof onSave === "function") {
      onSave(request);
    }
  };
  const renderTypeSpecific = () => {
    switch (type?.id) {
      case APP_TYPE.MACHINE_2_MACHINE:
        return null;
      case APP_TYPE.SINGLE_PAGE:
        return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(FormTextInput, {
          label: "Callback URL",
          name: "callbackUrl",
          value: callbackUrl,
          onChange: (event) => {
            setCallbackUrl(event.target.value);
          },
          required: true
        }));
    }
    return null;
  };
  return /* @__PURE__ */ React.createElement("form", {
    className: "add-app-modal__container p16",
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement("h1", null, t("organisation:newApp")), /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "name",
    label: t("organisation:appName"),
    value: name,
    onChange: (event) => {
      setName(event.target.value);
    },
    required: true
  }), /* @__PURE__ */ React.createElement(ListSelect, {
    data: appTypes,
    render: (item) => /* @__PURE__ */ React.createElement("p", {
      className: "ml16"
    }, item.name),
    selected: type,
    onChange: setType
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mt32"
  }, renderTypeSpecific()), /* @__PURE__ */ React.createElement(Button, {
    type: "submit"
  }, t("create")), /* @__PURE__ */ React.createElement(Button, {
    onClick: onCancel,
    type: "button",
    modal: true
  }, t("cancel")));
}
