import React, {useState} from "../_snowpack/pkg/react.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import {animated} from "../_snowpack/pkg/react-spring.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import "./WelcomeScreen.css";
const motion = () => {
};
const wrap = () => {
};
export default function WelcomeScreen() {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, 3, page);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  const variants = {
    enter: (direction2) => {
      return {
        x: direction2 > 0 ? 1e3 : -1e3,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction2) => {
      return {
        zIndex: 0,
        x: direction2 < 0 ? 1e3 : -1e3,
        opacity: 0
      };
    }
  };
  const swipeConfidenceThreshold = 1e4;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };
  const pages = (index) => {
    if (index == 0) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__page"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__box"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__img1"
      }), /* @__PURE__ */ React.createElement("h1", {
        className: "welcome-screen__h1"
      }, t("welcome.1.heading")), /* @__PURE__ */ React.createElement("p", {
        className: "welcome-screen__h1 welcome-screen__p"
      }, t("welcome.1.text"))));
    }
    if (index == 1) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__page"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__box"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__img2"
      }), /* @__PURE__ */ React.createElement("h1", {
        className: "welcome-screen__h1"
      }, t("welcome.2.heading")), /* @__PURE__ */ React.createElement("p", {
        className: "welcome-screen__h1 welcome-screen__p"
      }, t("welcome.2.text"))));
    }
    if (index == 2) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__page"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__box"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__img3"
      }), /* @__PURE__ */ React.createElement("h1", {
        className: "welcome-screen__h1"
      }, t("welcome.3.heading")), /* @__PURE__ */ React.createElement("p", {
        className: "welcome-screen__h1 welcome-screen__p"
      }, t("welcome.3.text"))));
    }
  };
  const swipeButtons = (index) => {
    if (index == 0) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe"
      }, /* @__PURE__ */ React.createElement(motion.div, {
        className: "welcome-screen__swipe_circle",
        animate: {
          width: 30,
          borderRadius: "30%",
          backgroundColor: "white"
        }
      }), /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe_circle"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe_circle"
      }));
    }
    if (index == 1) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe_circle"
      }), /* @__PURE__ */ React.createElement(motion.div, {
        className: "welcome-screen__swipe_circle",
        animate: {
          x: 0,
          width: 30,
          borderRadius: "30%",
          backgroundColor: "white"
        }
      }), /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe_circle"
      }));
    }
    if (index == 2) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe_circle"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "welcome-screen__swipe_circle"
      }), /* @__PURE__ */ React.createElement(motion.div, {
        className: "welcome-screen__swipe_circle",
        animate: {
          width: 30,
          borderRadius: "30%",
          backgroundColor: "white"
        }
      }));
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "welcome-screen"
  }, /* @__PURE__ */ React.createElement(animated.div, {
    initial: false,
    custom: direction
  }, /* @__PURE__ */ React.createElement(motion.div, {
    className: "welcome-screen__container",
    key: page,
    custom: direction,
    variants,
    initial: "enter",
    animate: "center",
    exit: "exit",
    transition: {
      x: {type: "spring", stiffness: 300, damping: 30},
      opacity: {duration: 0.2}
    },
    drag: "x",
    dragConstraints: {left: 0, right: 0},
    dragElastic: 1,
    onDragEnd: (e, {offset, velocity}) => {
      const swipe = swipePower(offset.x, velocity.x);
      if (swipe < -swipeConfidenceThreshold && imageIndex != 2) {
        paginate(1);
      } else if (swipe > swipeConfidenceThreshold && imageIndex != 0) {
        paginate(-1);
      }
    }
  }, pages(imageIndex))), /* @__PURE__ */ React.createElement("div", {
    className: "welcome-screen__footer"
  }, swipeButtons(imageIndex), imageIndex != 2 ? /* @__PURE__ */ React.createElement("div", {
    className: "welcome-screen__navigation"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "welcome-screen__button",
    onClick: () => navigation.navigate("/register")
  }, t("skip")), /* @__PURE__ */ React.createElement("h2", {
    className: "welcome-screen__button",
    onClick: () => paginate(1)
  }, t("continue"))) : /* @__PURE__ */ React.createElement("div", {
    className: "welcome-screen__navigation"
  }, /* @__PURE__ */ React.createElement("p", null), /* @__PURE__ */ React.createElement("h2", {
    className: "welcome-screen__button",
    onClick: () => navigation.navigate("/register")
  }, t("begin")))));
}
