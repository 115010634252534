import React from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import "./HeaderTabs.css";
import {DELIVERY_DIRECTION} from "../_snowpack/pkg/@topmonks/postcube.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
export default function HeaderTabs({className, activeTab, onTabChange}) {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: cs(className, "header-tabs-component")
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-tabs-component__radio"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "header-tabs-component__radio__input",
    type: "radio",
    name: "selectType",
    id: DELIVERY_DIRECTION.INCOMING,
    value: DELIVERY_DIRECTION.INCOMING,
    checked: activeTab === DELIVERY_DIRECTION.INCOMING,
    onChange: () => onTabChange(DELIVERY_DIRECTION.INCOMING)
  }), /* @__PURE__ */ React.createElement("label", {
    className: "header-tabs-component__radio__label",
    htmlFor: DELIVERY_DIRECTION.INCOMING
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-tabs-component__radio__button"
  }, t("delivery:received")))), /* @__PURE__ */ React.createElement("div", {
    className: "header-tabs-component__radio"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "header-tabs-component__radio__input",
    type: "radio",
    name: "selectType",
    id: DELIVERY_DIRECTION.SENDING,
    value: DELIVERY_DIRECTION.SENDING,
    checked: activeTab === DELIVERY_DIRECTION.SENDING,
    onChange: () => onTabChange(DELIVERY_DIRECTION.SENDING)
  }), /* @__PURE__ */ React.createElement("label", {
    className: "header-tabs-component__radio__label",
    htmlFor: DELIVERY_DIRECTION.SENDING
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-tabs-component__radio__button"
  }, t("delivery:sent")))));
}
