import React from "../../_snowpack/pkg/react.js";
import "./DeliveryItem.css";
import {formatDateTime} from "../library/datetime.js";
export default function DeliveryItem({delivery, onClick}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "wombat-delivery-item",
    onClick
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, delivery.sender.orderNumber || delivery.code || "neznámo"), /* @__PURE__ */ React.createElement("p", null, delivery.sender.id || delivery.sender.name || "neznámo", " ", "≫", " ", delivery.recipient.id || "neznámo")), /* @__PURE__ */ React.createElement("p", null, formatDateTime(delivery.updatedAt)), /* @__PURE__ */ React.createElement("p", {
    className: "wombat-delivery-item__text"
  }, delivery.state));
}
