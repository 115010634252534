import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import "./BoxesMapScreen.css";
import {GoogleMap, LoadScript} from "../../_snowpack/pkg/@react-google-maps/api.js";
import {Marker} from "../../_snowpack/pkg/@react-google-maps/api.js";
import Geocode from "../../_snowpack/pkg/react-geocode.js";
import HeaderBack from "../../components/HeaderBack.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
const apiKey = __SNOWPACK_ENV__.GOOGLE_MAPS_APIKEY;
Geocode.setApiKey(apiKey);
export default function BoxesMapScreen({
  route: {
    state: {boxes, filter = ""}
  }
}) {
  const navigation = useNavigation();
  const [points, setPoints] = useState([]);
  const [searchText, setSearchText] = useState(filter);
  const applySearch = (data) => data.id.startsWith(searchText);
  const handleSearch = (text) => {
    setSearchText(text);
  };
  useEffect(() => {
    if (boxes.length === 1)
      setSearchText(boxes[0].id);
    const promises = boxes.map((box) => Geocode.fromAddress(box.address));
    Promise.allSettled(promises).then((responses) => {
      const boxesWithGps = responses.map((res, index) => ({
        ...res?.value?.results?.[0].geometry.location,
        ...boxes[index]
      }));
      if (boxesWithGps.length)
        setPoints(boxesWithGps.filter(Boolean));
    });
  }, [boxes]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "boxes-map-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "boxes-map-wombat-screen__container"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "boxes-map-wombat-screen__input",
    type: "text",
    placeholder: "Box",
    value: searchText,
    onChange: (event) => handleSearch(event.target.value),
    disabled: boxes.length < 2
  }), /* @__PURE__ */ React.createElement("div", {
    className: "boxes-map-wombat-screen__map"
  }, /* @__PURE__ */ React.createElement(LoadScript, {
    googleMapsApiKey: apiKey
  }, /* @__PURE__ */ React.createElement(GoogleMap, {
    mapContainerStyle: {
      width: "100%",
      height: "100%"
    },
    center: {
      lat: 50,
      lng: 15.5
    },
    zoom: 6
  }, points.filter(applySearch).map(({lat, lng, id}) => {
    const box = boxes.find((_box) => _box.id === id);
    return /* @__PURE__ */ React.createElement(Marker, {
      key: id,
      position: {lat, lng},
      icon: "../../../images/favicon-32x32.png",
      onClick: () => navigation.navigate(`/wombat/box/${id}`, {
        state: {box}
      })
    });
  }))))));
}
