import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import "./LogsScreen.css";
import ScreenHeader from "../../components/ScreenHeader.js";
import Tabbar from "../components/TabbarWombat.js";
import HeaderBack from "../../components/HeaderBack.js";
import Loader from "../../components/Loader.js";
import useAlert from "../../hooks/useAlert.js";
import {formatDateTime} from "../library/datetime.js";
export default function LogsScreen() {
  const [logs, setLogs] = useState([]);
  const alert = useAlert();
  useEffect(() => {
    if (logs.length)
      return;
    fetch("https://api.rollbar.com/api/1/items/?level=error&environment=production&status=active", {
      method: "GET",
      headers: {
        "X-Rollbar-Access-Token": `${__SNOWPACK_ENV__.ROLLBAR_WOMBAT}`
      }
    }).then((response) => response.json()).then((response) => {
      if (response.err)
        throw response.message;
      setLogs(response.result.items);
    }).catch((error) => {
      alert.error(error);
    });
  }, [alert, logs.length]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "logs-wombat-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    link: "/settings",
    text: "PostCube"
  }), null), /* @__PURE__ */ React.createElement("div", {
    className: "logs-wombat-screen__container"
  }, logs.map((item) => /* @__PURE__ */ React.createElement("div", {
    className: "logs-wombat-screen__item",
    key: item.counter,
    onClick: () => window.open("https://rollbar.com/TopMonks/postcube/items/" + item.counter, "_blank")
  }, /* @__PURE__ */ React.createElement("div", {
    className: "logs-wombat-screen__detail"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "logs-wombat-screen__h3"
  }, "#", item.counter), /* @__PURE__ */ React.createElement("h3", {
    className: "logs-wombat-screen__h3"
  }, item.total_occurrences, "x"), /* @__PURE__ */ React.createElement("h3", {
    className: "logs-wombat-screen__time"
  }, formatDateTime(item.last_occurrence_timestamp * 1e3))), /* @__PURE__ */ React.createElement("p", {
    className: "logs-wombat-screen__p"
  }, item.title))), !logs.length && /* @__PURE__ */ React.createElement(Loader, null)), /* @__PURE__ */ React.createElement(Tabbar, {
    logs: true
  }));
}
