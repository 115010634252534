import React, {useState} from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useNavigation} from "../_snowpack/pkg/react-navi.js";
import Card from "./Card.js";
import IconAlert from "../images/icon-alert.js";
import "./Warning.css";
export default function Warning({
  className,
  type = "alert",
  headline,
  text,
  link,
  linkState,
  onClick,
  devError
}) {
  const [fold, setFold] = useState(false);
  const navigation = useNavigation();
  const handleContainerClick = () => {
    if (link)
      navigation.navigate(link, {state: linkState});
    else if (onClick)
      onClick();
    else if (devError)
      setFold(!fold);
  };
  return /* @__PURE__ */ React.createElement(Card, {
    className: cs("warning-component", devError && "warning-component__deverror", fold && "warning-component__deverror--unfold", link, className),
    onClick: handleContainerClick
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs(type === "alert" && "warning-component__icon--yellow", type === "error" && "warning-component__icon--red", type === "info" && "warning-component__icon--blue")
  }, /* @__PURE__ */ React.createElement(IconAlert, {
    width: "24px",
    height: "24px",
    className: "ml16 mt16"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "warning-component__text"
  }, headline && /* @__PURE__ */ React.createElement("h3", {
    className: "mb8"
  }, headline), /* @__PURE__ */ React.createElement("p", null, text)));
}
