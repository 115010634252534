export default {
  goTo: "Proceed to payment",
  pay: "Pay",
  newCardPayment: "Pay with new card",
  paymentInfo: "Payment Information",
  selectSavedCard: "Select saved card",

  chooseMethod: "Select payment method",
  inProgress: "Payment in progress ...",
  money:
    "{{amount, currency(currency: CZK; minimumFractionDigits: 2; maximumFractionDigits: 2), stripCurrencyFraction}}",
  payAmount: 'Pay $t(payment:money, {"amount": {{amount}} })',
  paidAmount: 'Paid $t(payment:money, {"amount": {{amount}} })',
  totalPrice: 'Total price: $t(payment:money, {"amount": {{total}} })',
  wasSuccessful: "Payment was successful",

  card: {
    holder: "Card holder",
    number: "Card number",
    expiry: "Card expiry",
    cvc: "CVC code",
  },
};
