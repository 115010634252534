export default {
  label_one: "Box",
  label_other: "Boxes",
  labelWithId: "Box {{boxId}}",
  labelWithIdNumber: "Box # {{boxId}}",
  unlocking: "Unlocking",
  locationDesc: "Box location description",
  locationDesc2: "Box location description",
  locationDescShort: "Location description",

  unknown: "Unknown box",
  noName: "No name",
  isEmpty: "Box is empty",

  uploadingDeviceKey: "Uploading device key...",
  alreadyPaired: "The box is already paired with another account",
  withIdPaired: "Box no. {{boxId}} paired.",
  paired: "Box paired",
  pairSuccess: "Box successfully paired",
  notPaired: "Box is not paired with this device.",
  pairing: "Box pairing",
  pairing2: "Box pairing",

  doesNotExist: "This box does not exist",
  userBlocked: "Your account user is blocked on this box",
  notPermittedToSendFrom: "You are not allowed to send packages from this box",

  resumeUse: "Resume use of the mailbox",
  pauseUse: "Pause the use of the mailbox",
  callOwner: "Call the box user",

  closeCarefully: "Close the box carefully",
  checkIfCloseProperly: "Check that the box with parcel is closed carefully",

  idForCollection: "Collection box number",
  idForDelivery: "Delivery box number",

  find: "Find box",
  findAnother: "Find another box",
  findAround: "Find a box nearby",
  searchAround: "Find a box nearby",
  chosenWithId: "Selected box <1>{{id}}</1>",

  chooseSenderBox: "Select a sender box",
  insertId: "Enter recipient box code (6 digits)",

  details: "Details of your box.",
  edit: "Edit box details",
  edit2: "Edit box",
  editSuccess: "Box successfully edited",

  permissions: "Box permissions",
  editPermissions: "Edit box permissions",
  permissionsSettingsHere:
    "Here you will find the permission settings for your PostCube bo",
  permissionsToUse: "Permissions to use the box",

  openingRequest: {
    label: "BOX OPENING REQUEST",
    label2: "Box opening request",
    labelShort: "Opening request",
    refused: "The opening request was successfully rejected",
    approved: "The opening request was successfully approved",
    wasRefused: "Your request was rejected",
    wasApproved: "Your request has been approved",
    cantOpenBox: "The box cannot be opened.",
    nowOpenTheBox: "Now open the box in which you put the parcel.",
    send: "Send request",
    wasSent: "The open request was sent",
    waitForResponse: "Please wait for the owner of the box to respond",
    messageFromRequester: "Message from requester:",
    approve: "Approve request",
    refuse: "Reject request",
    closeBoxAndCheckPackageInserted:
      "Please close the box and confirm that the package is safely stored",
    messageForRecipient: "Message for recipient",
    messageText: "Message text",
    enterBoxOwnersPhone: "Enter the phone number of the box owner",
    doYouKnowOwner: "Do you know the owner?",
    messagePlaceholder:
      "Hi, this is -My Name-, I have a package for you. Thank you.",
  },

  fullAccess: "Allow full access",
  denyAccess: "Block access to the box",
  allowSendFrom: "Allow only sending from the box",
  allowSendTo: "Allow only sending to the box",
  allowReceiveTo: "Allow only delivery to the box",
  allowAnyoneToInsertDelivery: "Allow anyone to insert a shipment into the box",
  allowInsertDeliveryAfterConfirm:
    "Allow insertion of a shipment into the box after confirmation",

  batteryPercentageUpdatedAt: "{{percentage}}% battery, updated {{dateTime}}",
  pair: "Pair mailbox",
  opening: "Opening ...",
  opened: "Box opened",
  open: "Open box",
  unlock: "Unlock box",
  isOpen: "The box is open",

  filter: "Locks filter (shows only locks with this prefix)",
  filterHelp:
    "The application searches for boxes in range. Select a box and press <1>pair</1> to open it.",
  noneAround: "No box in range",
  noSuchAround: "No such box in range",

  keys: "Keys to open the box",
  key: {
    label: "Keys",
    loading: "Loading keys ...",
    none: "Nothing here",
    generationFailed: "Failed to create open link: No Data",
    generateNew: "Generate new key",
    generateInfo:
      "The generated key can be used by anyone you send the link to open the mailbox. <1>The key is one-time and valid for 24 hours.</1>",
    generating: "Generating...",
    generate: "Generate key",
    generation: "Generate key",

    openInfo:
      "Anyone you send the key to can open the mailbox. <1> The key is one-time and valid for 24 hours.</1>",
    copyToClipboard: "Copy the link to the clipboard",
    deactivate: "Deactivate the link",
    toOpen: "Key to open",
    createToOpen: "Create key to open",
    expiresIn: "Key expires in {{time}}",
    expired: "Key expired",
    expired2: "Key expired",

    help: {
      start: "Start by clicking <1>Find mailbox</1>",
      choose: "Select the mailbox to open from the list",
      pair: "Click <1>Pair</1>",
    },
  },

  pairTutorialText: 'Next, select the box and click "Pair"',

  delete: {
    dangerous: "Dangerous",
    idConfirmLabel: "Enter a mailbox number for confirmation",
    info: "You can completely remove the lock from your account here. But don't forget to also <1>reset the physical lock</1> so it can be added again in the future.",
    completely: "Delete the mailbox completely",
    areYouSure:
      "Do you really want to delete your mailbox? The physical lock will then also need to be reset for future use.",
    successful: "Mailbox successfully deleted.",
  },

  state: {
    free: "Free",
    paused: "Suspended",
    sending: "Sending",
    receiving: "Receiving",
  },

  warning: {
    missingDetails: {
      headline: "The Box {{id}} is missing data",
      text: "You must fill in the name and address of the box or the courier will not find you.",
    },
    nonePaired: {
      headline: "You have no paired box",
      text: "Box must be paired before use.",
      headline2: "You have no box paired",
      text2: "Box must be paired before use.",
      headline3: "Add your first box",
      text3: "No box is paired with your account. Start by adding a box.",
    },
    noName: {
      headline: "No box name filled in",
      text: "You must fill in the box name or the recipient will not recognize you.",
    },
    noAddress: {
      headline: "No address filled in",
      text: "You need to fill in the box address in the box settings or the courier will not find you",
      headlineRecipient: "The recipient box does not have a complete address",
      textRecipient:
        "The owner of the box must first fill in the necessary data or the parcel cannot be sent to him",
    },
    outOfBattery: {
      headline: "Dead box",
      text: "Your selected box is dead. Please replace the battery in your box and complete the shipment.",
      headlineRecipient: "Dead box",
      textRecipient:
        "The recipient's box is dead. Please wait until the recipient notices the dead batteries, remembers and purchases on the 28th trip to the store, finds a screwdriver and replaces the batteries, and then complete the shipment.",
      textWithPercent_one:
        "Your box has {{count}}% battery left. Please replace the batteries in your box and then complete the shipment.",
      textWithPercent_few:
        "Your box has {{count}}% battery left. Please replace the batteries in your box and then complete the shipment.",
      textWithPercent_other:
        "Your box has {{count}}% battery left. Please replace the batteries in your box and then complete the shipment.",
    },
    outOfBatteryMyBox: {
      headline: "Box {{boxId}} is almost dead",
      text1:
        "The {{boxId}} box has been temporarily suspended from receiving and sending shipments due to running out of battery.",
      text2:
        "Please replace the battery in this box. Please see the user manual for more information.",
    },
    unavailable: {
      headline: "Box temporarily unavailable",
      text: "Your selected box is temporarily unavailable due to sending/receiving another shipment. Please try to complete the shipment later or select another box.",
      textRecipient:
        "The recipient's box is temporarily unavailable due to sending/receiving another shipment. Please try to finish sending the shipment later or select another recipient box.",
    },
    occupied: {
      headline: "Multibox is fully occupied",
      text: "All boxes in the multibox you selected are occupied. Please try to finish sending the parcel later or select another box.",
    },
    addressNotComplete: {
      headline: "No complete address entered",
      text: "You need to complete the necessary information, otherwise the parcel cannot be delivered.",
    },
  },
};
