import React, {useState, useMemo, useCallback} from "../_snowpack/pkg/react.js";
import {Trans, useTranslation} from "../_snowpack/pkg/react-i18next.js";
import useAlert from "../hooks/useAlert.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Button from "../components/Button.js";
import useBleDevicesNearby from "../hooks/useBleDevicesNearby.js";
import useServiceBoxes from "../hooks/useServiceBoxes.js";
import Loader from "../components/Loader.js";
import {Capacitor} from "../_snowpack/pkg/@capacitor/core.js";
import ListSelect from "../components/ListSelect.js";
import Warning from "../components/Warning.js";
import HeaderBack from "../components/HeaderBack.js";
import FormTextInput from "../components/FormTextInput.js";
import useDoc from "../hooks/useDoc.js";
import "./UnlockScreen.css";
const isNative = Capacitor.isNativePlatform();
export default function UnlockScreen({id = ""}) {
  const [unlocking, setUnlocking] = useState(false);
  const [battery, setBattery] = useState(false);
  const [boxId, setBoxId] = useState(false);
  const [filterPrefix, setFilterPrefix] = useState(id);
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(t("error:oopsSomethingWentWrong"), err);
  }, [alert, t]);
  const {
    devices: bleDevicesNearby,
    findNearby: findBleDeviceNearby,
    pickedDevice: selectedBleDevice,
    setPickedDevice: setSelectedBleDevice,
    error: bleError
  } = useBleDevicesNearby();
  const {unlock} = useServiceBoxes({});
  const handleWebUnlock = async () => {
    if (unlocking)
      return;
    try {
      setUnlocking(true);
      const bleDevice = selectedBleDevice || await findBleDeviceNearby({id: filterPrefix});
      if (!bleDevice)
        return;
      const battery2 = await unlock(bleDevice);
      alert.success(t("box:opened"));
      setBattery(battery2);
      setBoxId(bleDevice.id);
    } catch (err) {
      handleScreenError(err);
    } finally {
      setUnlocking(false);
      setSelectedBleDevice(null);
    }
  };
  const [productionBox] = useDoc(`/production-boxes/${boxId}`, {
    skip: !boxId,
    onError: handleScreenError
  });
  const filteredBleDevicesNearby = useMemo(() => bleDevicesNearby.filter(({id: id2}) => !filterPrefix || id2.startsWith(filterPrefix)), [bleDevicesNearby, filterPrefix]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "servicer-unlock-screen"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, {
    title: t("settings:serviceOpening")
  }, /* @__PURE__ */ React.createElement(HeaderBack, null)), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, bleError && /* @__PURE__ */ React.createElement(Warning, {
    className: "mt8 mb8",
    ...bleError,
    text: bleError.message
  }), /* @__PURE__ */ React.createElement("div", {
    className: "servicer-unlock-screen__view"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "mb16"
  }, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "box:filterHelp",
    t
  }, "Aplikace vyhledá schránky v okolí. Vyberte schránku a stisknutím", /* @__PURE__ */ React.createElement("b", null, "spárovat"), "ho otevřete.")), /* @__PURE__ */ React.createElement("div", {
    className: "mb16"
  }, /* @__PURE__ */ React.createElement(FormTextInput, {
    name: "filter-prefix",
    label: t("box:filter"),
    value: filterPrefix,
    onChange: ({target}) => {
      setFilterPrefix(target.value);
    }
  })), isNative && /* @__PURE__ */ React.createElement("div", {
    className: "servicer-unlock-screen__devices-list"
  }, /* @__PURE__ */ React.createElement(ListSelect, {
    data: filteredBleDevicesNearby,
    render: (item) => /* @__PURE__ */ React.createElement("p", {
      className: "ml16"
    }, item.name),
    selected: selectedBleDevice,
    onChange: setSelectedBleDevice
  }), !filteredBleDevicesNearby.length && /* @__PURE__ */ React.createElement("p", {
    className: "center-text"
  }, filterPrefix ? t("box:noSuchAround") : t("box:noneAround"))), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16",
    onClick: handleWebUnlock,
    disabled: unlocking || isNative && !selectedBleDevice
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    text: t("box:opening")
  }) : t("box:open")), /* @__PURE__ */ React.createElement("div", {
    className: "mt16"
  }, boxId ? /* @__PURE__ */ React.createElement("p", null, "Id: ", boxId, " ") : /* @__PURE__ */ React.createElement("p", null), productionBox && /* @__PURE__ */ React.createElement("p", null, "Key:", " ", productionBox.secretCode.map((s) => s.toString(16).padStart(2, "0")), " "), battery ? /* @__PURE__ */ React.createElement("p", null, t("battery"), ": ", battery) : /* @__PURE__ */ React.createElement("p", null)))));
}
