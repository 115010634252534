import React, {useCallback, useMemo} from "../../_snowpack/pkg/react.js";
import cs from "../../_snowpack/pkg/classnames.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {deliveryStates, getDeliveryStatesLabels} from "../constants.js";
import ActionsModal from "../modals/ActionsModal.js";
import SingleBoxActionButtons from "../modals/SingleBoxActionButtons.js";
import IconBox from "../../images/icon-box.js";
import IconDots from "../../images/icon-dots.js";
import Delivery from "./Delivery.js";
import "./BoxStateRow.css";
const BoxStateRow = ({
  box: {id, occupancy: {deliveries} = {}, state},
  openModal
}) => {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const delivery = deliveries?.[0];
  const deliveryState = delivery && delivery.isOccupying ? deliveryStates.READY_FOR_COLLECTION : deliveryStates.IN_TRANSIT;
  const deliveryStateLabel = useMemo(() => delivery && getDeliveryStatesLabels(t)[deliveryState], [deliveryState, delivery, t]);
  const handleOpenActions = useCallback(() => {
    openModal(/* @__PURE__ */ React.createElement(ActionsModal, {
      title: t("box:labelWithId", {boxId: id}),
      subtitle: deliveryStateLabel,
      actionButtons: /* @__PURE__ */ React.createElement(SingleBoxActionButtons, {
        navigation,
        boxState: state,
        boxId: id,
        userPhoneNumber: "737001657"
      })
    }));
  }, [id, deliveryStateLabel, openModal, navigation, state, t]);
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("BoxStateRow", {
      "BoxStateRow--empty": !delivery
    })
  }, /* @__PURE__ */ React.createElement("div", {
    className: "BoxStateRow-header"
  }, /* @__PURE__ */ React.createElement(IconBox, {
    className: "BoxStateRow-boxIcon"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "BoxStateRow-boxNo"
  }, id), delivery && /* @__PURE__ */ React.createElement("div", {
    className: "BoxStateRow-boxState"
  }, deliveryStateLabel), /* @__PURE__ */ React.createElement(IconDots, {
    className: "BoxStateRow-iconDots",
    onClick: handleOpenActions
  })), delivery ? /* @__PURE__ */ React.createElement(Delivery, {
    ...delivery
  }) : /* @__PURE__ */ React.createElement("div", {
    className: "Delivery"
  }, t("box:isEmpty")));
};
export default BoxStateRow;
