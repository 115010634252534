import {
  DEPRECATED_CHAR_RESULT_UUID,
  DEPRECATED_SERVICE_UUID_16,
  DEPRECATED_SERVICE_UUID,
  DEPRECATED_CHAR_RESULTS_INDEX,
} from "../_snowpack/pkg/@topmonks/postcube.js";
import { t } from "../_snowpack/pkg/i18next.js";
import {
  parseDeviceId,
  splitCommand,
  ERROR_BLUETOOTH_DISABLED,
} from "./BleAdapter.js";

export function parseResult(response, charUUID) {
  const buffer = new Uint8Array(response.buffer);
  const index = DEPRECATED_CHAR_RESULTS_INDEX[charUUID];
  const result = buffer[index];
  return result;
}

async function writeToCharacteristic(char, data) {
  const chunks = splitCommand(new Uint8Array(data), 20);

  for (const i in chunks) {
    const chunk = chunks[i];
    await char.writeValue(chunk);
  }
}

export default function BleAdapter(bleDevice) {
  const handleDisconnected = (event) => {
    console.log(`BLE device ${bleDevice.id} disconnected`, { event });
  };

  const adapter = {
    native: bleDevice,
    type: "web",

    get id() {
      return parseDeviceId(bleDevice);
    },

    get name() {
      return bleDevice.name;
    },

    async connect() {
      if (bleDevice.gatt.connected) return;
      await bleDevice.gatt.connect();
      bleDevice.addEventListener("gattserverdisconnected", handleDisconnected);
    },

    async disconnect() {
      if (!bleDevice.gatt.connected) return;
      await bleDevice.gatt.disconnect();
      bleDevice.removeEventListener(
        "gattserverdisconnected",
        handleDisconnected
      );
    },

    async characteristic(uuid) {
      const service = await bleDevice.gatt.getPrimaryService(
        DEPRECATED_SERVICE_UUID
      );
      await service.getCharacteristics();
      return [
        await service.getCharacteristic(uuid),
        await service.getCharacteristic(DEPRECATED_CHAR_RESULT_UUID),
      ];
    },

    async readBattery() {
      const service = await bleDevice.gatt.getPrimaryService("battery_service");
      const char = await service.getCharacteristic("battery_level");
      const value = await char.readValue();
      const level = value.getUint8(0);
      return level;
    },

    async send(charUUID, data, { timeoutInSecs = 30 } = {}) {
      console.log("send; charUUID:", charUUID);
      const [charToWrite, charToRead] = await adapter.characteristic(charUUID);
      await charToRead.startNotifications();

      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          resolve(new Error("timeout"));
        }, timeoutInSecs * 1000);

        const handler = ({ target: { value } }) => {
          clearTimeout(timeout);
          const result = parseResult(value, charUUID);
          resolve(result);
        };
        charToRead.addEventListener("characteristicvaluechanged", handler);

        return writeToCharacteristic(charToWrite, data);
      });
    },
  };

  return adapter;
}

BleAdapter.scanStart = () => {};
BleAdapter.scanStop = () => {};

BleAdapter.findDevice = async ({ id } = {}) => {
  if (!navigator.bluetooth) {
    throw {
      message: t("BT:notAvailable"),
      code: ERROR_BLUETOOTH_DISABLED,
    };
  }
  const filters = [{ services: [DEPRECATED_SERVICE_UUID_16] }];
  // filter device by name using id. Sadly there is no namePostfix param like namePrefix
  // https://developer.mozilla.org/en-US/docs/Web/API/Bluetooth/requestDevice#parameters 👈
  // so we need to complete exact name like Chytry Box 123123
  if (id) {
    filters[0].namePrefix = `PostCube ${id}`;
  }

  const bleDevice = await navigator.bluetooth.requestDevice({
    filters,
    acceptAllDevices: false,
    optionalServices: [DEPRECATED_SERVICE_UUID, "battery_service"],
  });
  return BleAdapter(bleDevice);
};
