import React, {memo, useCallback} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import Button from "../../components/Button.js";
import {boxStates} from "../constants.js";
import {boxHistory} from "../urls.js";
import ModalBoxUnlockButton from "./ModalBoxUnlockButton.js";
const SingleBoxActionButtons = ({
  boxId,
  boxState,
  navigation,
  userPhoneNumber
}) => {
  const {t} = useTranslation();
  const handleEnableBox = useCallback(() => {
  }, []);
  const handleDisableBox = useCallback(() => {
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ModalBoxUnlockButton, null), boxState === boxStates.DISABLED && /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: () => handleEnableBox(boxId)
  }, t("box:resumeUse")), boxState === boxStates.FREE && /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: () => handleDisableBox(boxId)
  }, t("box:pauseUse")), [boxStates.SENDING, boxStates.RECEIVING].includes(boxStates) && /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    link: `tel:${userPhoneNumber}`
  }, t("box:callOwner")), /* @__PURE__ */ React.createElement(Button, {
    modal: true,
    onClick: () => navigation.navigate(boxHistory(boxId))
  }, t("delivery:history")));
};
export default memo(SingleBoxActionButtons);
