import { useState, useEffect, useCallback } from "../_snowpack/pkg/react.js";
import { useTranslation } from "../_snowpack/pkg/react-i18next.js";
import { auth, firestore } from "../firebase.js";
import { getDoc, doc } from "../_snowpack/pkg/firebase/firestore.js";
import { onAuthStateChanged } from "../_snowpack/pkg/firebase/auth.js";
import useBleDevicesNearby from "./useBleDevicesNearby.js";

export default function useScanAndSelectSender({
  onError = console.error,
  scan = false,
} = {}) {
  const { t } = useTranslation();
  const { findBleDeviceNearby: selectNearbyBox, error: bleError } =
    useBleDevicesNearby({ onError, scan });
  const [selectedNearbyBox, setSelectedNearbyBox] = useState();

  useEffect(() => {
    if (bleError) onError(bleError);
  }, [bleError, onError]);

  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    return onAuthStateChanged(auth(), setCurrentUser);
  }, []);

  const handleScanAndSelect = useCallback(
    async (event) => {
      if (typeof event.preventDefault === "function") {
        event.preventDefault();
      }

      const selectedNearbyBoxDevice = await selectNearbyBox();

      if (selectedNearbyBoxDevice?.id) {
        try {
          const boxRef = await getDoc(
            doc(firestore(), `boxes/${selectedNearbyBoxDevice.id}`)
          );

          if (!boxRef.exists()) {
            throw Error(t("box:doesNotExist"));
          }

          const box = boxRef.data();

          if (!currentUser) {
            throw Error(t("auth:notLoggedIn"));
          }

          const userId = currentUser.uid;

          if (~(box?.permissions?.denyAccess || []).indexOf(userId)) {
            throw Error(t("box:userBlocked"));
          }

          if (
            box?.ownerId !== userId &&
            !~(box?.permissions?.fullAccess || []).indexOf(userId) &&
            !~(box?.permissions?.allowSendFrom || []).indexOf(userId)
          ) {
            throw Error(t("box:notPermittedToSendFrom"));
          }

          const selectedNearbyBox = {
            ...box,
            id: selectedNearbyBoxDevice.id,
          };

          setSelectedNearbyBox(selectedNearbyBox);
        } catch (err) {
          onError(err);
        }
      }
    },
    [currentUser, onError, selectNearbyBox, t]
  );

  return {
    selectedNearbyBox,
    scanAndSelect: handleScanAndSelect,
  };
}
