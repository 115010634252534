import { RES_OK } from "../_snowpack/pkg/@topmonks/postcube.js";

export default function VirtualBox(box) {
  if (!box.virtual) throw new Error("VirtualBox expects virtual box");

  return {
    id: box.id,

    async transaction() {},

    async saveAccountKey() {
      throw new Error("VirtualBox should be already paired manually");
    },

    async saveDeviceKey() {
      throw new Error("VirtualBox should be already paired manually");
    },

    async unlockWithOnlineKey() {
      return RES_OK;
    },

    async unlockWithOfflineKey() {
      return RES_OK;
    },

    async unlock() {
      return RES_OK;
    },

    async syncTime() {
      return RES_OK;
    },

    async createOfflineUnlockCommand() {
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },

    async readBattery() {
      return 1;
    },
  };
}
