import { useState, useCallback, useEffect } from "../_snowpack/pkg/react.js";
import { useTranslation } from "../_snowpack/pkg/react-i18next.js";
import BleAdapter, {
  ERROR_BLUETOOTH_DISABLED,
  ERROR_BLUETOOTH_UNAUTHORIZED,
} from "../library/BleAdapter.js";

const REFRESH_INTERVAL = 100 * 1000;

export default function useBleDevicesNearby({
  scan = true,
  onError = console.error,
} = {}) {
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [pickedDevice, setPickedDevice] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    const retry = () => setError(null);

    if (error) {
      onError(error, retry);
    }
  }, [error, onError]);

  const addDeviceIfNewAndPickIfFirst = useCallback(
    (bleDevice) => {
      if (bleDevice && !devices.find((x) => x.id === bleDevice.id)) {
        setDevices([...devices, bleDevice]);
        if (!pickedDevice) setPickedDevice(bleDevice);
      }
    },
    [devices, pickedDevice]
  );

  const handleError = useCallback(
    (error) => {
      if (!error) {
        setError(null);
        return;
      }
      switch (error.code) {
        case ERROR_BLUETOOTH_DISABLED:
          setError({
            message: `${t("BT:notAvailable")}: ${t("BT:turnItOnPlease")}`,
          });
          break;
        case ERROR_BLUETOOTH_UNAUTHORIZED:
          setError({
            message: `${t("BT:missingPermission")}: ${t("BT:allowItPlease")}`,
          });
          break;

        case 8:
          // no-op, user just closed BLE device picker
          break;

        default:
          setError({
            code: error.code,
            message: `${t("error:unknownBtError")}: ${error.message} (${
              error.code
            })`,
          });
      }
    },
    [t]
  );

  useEffect(() => {
    if (!scan) return;
    BleAdapter.scanStart(addDeviceIfNewAndPickIfFirst, handleError);
    return () => {
      BleAdapter.scanStop();
    };
  }, [addDeviceIfNewAndPickIfFirst, handleError, scan]);

  useEffect(() => {
    const nolost = () => {
      setDevices([]);
      // const withoutDisconnected = devices.filter(BleAdapter.available);
      // if (devices.length > withoutDisconnected.length) {
      //   setDevices(withoutDisconnected);
      // }
    };
    const interval = setInterval(nolost, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [devices]);

  const findNearby = useCallback(
    async ({ id, virtual } = {}) => {
      try {
        const found = virtual
          ? { id, virtual } // 👈 that's basically a mock
          : await BleAdapter.findDevice({ id });
        if (found) setPickedDevice(found);
        return found;
      } catch (err) {
        handleError(err);
        return null;
      }
    },
    [handleError]
  );

  return {
    devices,
    findNearby,
    pickedDevice,
    setPickedDevice,
    error,
  };
}
