import { useEffect, useState, useCallback } from "../_snowpack/pkg/react.js";
import { functions } from "../firebase.js";
import { httpsCallable } from "../_snowpack/pkg/firebase/functions.js";

export default function useBoxPublicState(
  boxId,
  { onError = console.error } = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [publicState, setPublicState] = useState();

  const fetchPublicState = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await httpsCallable(
        functions(),
        "box-publicState"
      )({
        boxId,
      });
      setPublicState(data);
      setIsLoaded(true);
    } catch (err) {
      onError(err);
    } finally {
      setIsLoading(false);
    }
  }, [boxId, onError]);

  useEffect(() => {
    if (!isLoaded && !isLoading) fetchPublicState();
  }, [boxId, fetchPublicState, isLoaded, isLoading]);

  return {
    isLoading,
    publicState,
    reload: useCallback(() => {
      setIsLoaded(false);
    }, []),
  };
}
