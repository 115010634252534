import React from "../_snowpack/pkg/react.js";
import cs from "../_snowpack/pkg/classnames.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import Card from "./Card.js";
import IconArrow from "../images/icon-arrow-recipient.js";
import "./DeliveryAddress.css";
export default function DeliveryAdress({className, sender, recipient}) {
  const {t} = useTranslation();
  const {
    name = "Default",
    addressStreet = t("address.notFound"),
    addressStreetNumber,
    addressCity,
    addressPSC
  } = sender || recipient;
  return /* @__PURE__ */ React.createElement("div", {
    className: cs("delivery-address-component", className)
  }, sender && /* @__PURE__ */ React.createElement("div", {
    className: "delivery-address-component__label"
  }, /* @__PURE__ */ React.createElement("p", null, t("delivery:sender"))), recipient && /* @__PURE__ */ React.createElement("div", {
    className: "delivery-address-component__label"
  }, /* @__PURE__ */ React.createElement(IconArrow, {
    className: "mr8",
    width: "16px"
  }), /* @__PURE__ */ React.createElement("p", null, t("delivery:recipient"))), /* @__PURE__ */ React.createElement(Card, {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "mb8"
  }, name), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, addressStreet, " ", addressStreetNumber), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, addressCity), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, addressPSC)));
}
