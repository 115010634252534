import React, {useCallback} from "../../_snowpack/pkg/react.js";
import "./Screen.css";
import {
  DELIVERY_STATE_NAMES,
  DELIVERY_STATE_VISIBILITY,
  TRANSPORT_PROVIDER_TYPES
} from "../../_snowpack/pkg/@topmonks/postcube.js";
import useAlert from "../../hooks/useAlert.js";
import Loader from "../../components/Loader.js";
import useDelivery from "../../hooks/useDelivery.js";
import {dateFromTimestamp, money} from "../../library/format.js";
import ScreenHeader from "../../components/ScreenHeader.js";
import HeaderBack from "../../components/HeaderBack.js";
import SenderDeliveryAction from "./SenderDeliveryAction.js";
import IconPaid from "../../images/icon-paid.js";
import IconBox from "../../images/icon-box.js";
import Warning from "../../components/Warning.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
export default function DeliverySenderScreen({
  deliveryId,
  route: {
    state: {backPath},
    data: {user}
  }
}) {
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const handleScreenSuccess = useCallback((msg) => {
    alert.success(msg);
  }, [alert]);
  const {
    delivery = {},
    deliveryLoading,
    updateDelivery
  } = useDelivery(deliveryId, {onError: handleScreenError});
  const transporterResult = delivery.transportProviderResult;
  const transporterError = transporterResult?.error || transporterResult?.errors;
  const isDeliveryPaid = [
    void 0,
    DELIVERY_STATE_NAMES.NONE,
    DELIVERY_STATE_NAMES.FORM_IN_PROGRESS,
    DELIVERY_STATE_NAMES.PAYMENT_IN_PROGRESS
  ].includes(delivery.state) === false;
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery screen-delivery--sender"
  }, /* @__PURE__ */ React.createElement(ScreenHeader, null, /* @__PURE__ */ React.createElement(HeaderBack, {
    text: backPath ? t("toHome") : t("back"),
    path: backPath
  })), /* @__PURE__ */ React.createElement("div", {
    className: "p16"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "app-header"
  }, t("delivery:label", {count: 1}), " ", delivery.code), deliveryLoading && /* @__PURE__ */ React.createElement(Loader, {
    className: "screen-delivery__loader",
    text: t("loadingData")
  }), !deliveryLoading && isDeliveryPaid && /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__pay-state"
  }, /* @__PURE__ */ React.createElement(IconPaid, {
    width: "24px",
    height: "24px",
    className: "mr8"
  }), /* @__PURE__ */ React.createElement("span", null, t("payment:paidAmount", {
    amount: delivery.transportProvider?.price
  }))), transporterError && /* @__PURE__ */ React.createElement(Warning, {
    type: "error",
    devError: true,
    headline: t("error:carrier"),
    text: JSON.stringify(transporterError),
    className: "mt16"
  }), transporterResult?.type === TRANSPORT_PROVIDER_TYPES.VIRTUAL && /* @__PURE__ */ React.createElement(Warning, {
    type: "info",
    devError: true,
    headline: transporterResult.name,
    text: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("a", {
      href: transporterResult.linkForDriver,
      target: "_blank",
      rel: "noreferrer"
    }, t("delivery:linkForCarrier"))),
    className: "mt16"
  }), delivery.state && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(SenderDeliveryAction, {
    ...{
      delivery,
      deliveryId,
      updateDelivery,
      alert,
      user,
      onError: handleScreenError,
      onSuccess: handleScreenSuccess
    }
  }), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info mt32"
  }, /* @__PURE__ */ React.createElement("h2", null, t("delivery:detail")), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:price")), /* @__PURE__ */ React.createElement("p", null, money(delivery.transportProvider?.price))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:transport")), /* @__PURE__ */ React.createElement("p", null, delivery.transportProvider?.name)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:packageCode2")), /* @__PURE__ */ React.createElement("p", null, delivery.code)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:creation")), /* @__PURE__ */ React.createElement("p", null, dateFromTimestamp(delivery.createdAt))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:collection")), /* @__PURE__ */ React.createElement("p", null, DELIVERY_STATE_VISIBILITY.MESSENGER_DELIVERED.includes(delivery.state) ? dateFromTimestamp(delivery.updatedAt) : "---"))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__address"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "screen-delivery__address-label color-grey mb8"
  }, t("delivery:sender").toUpperCase()), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, delivery.sender?.name), delivery.sender?.id && /* @__PURE__ */ React.createElement("small", null, /* @__PURE__ */ React.createElement(IconBox, {
    width: "14px",
    fill: "var(--color-primary)",
    className: "ml8 mr4"
  }), delivery.sender?.id)), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.sender?.addressStreet, " ", delivery.sender?.addressStreetNumber), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.sender?.addressPSC), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.sender?.addressCity)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-delivery__address"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "screen-delivery__address-label color-grey mb8"
  }, t("delivery:recipient").toUpperCase()), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, delivery.recipient?.name), delivery.recipient?.id && /* @__PURE__ */ React.createElement("small", null, /* @__PURE__ */ React.createElement(IconBox, {
    width: "14px",
    fill: "var(--color-primary)",
    className: "ml8 mr4"
  }), delivery.recipient?.id)), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.recipient?.addressStreet, " ", delivery.recipient?.addressStreetNumber), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.recipient?.addressPSC), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, delivery.recipient?.addressCity)))));
}
