/**
 * Woking with basic date and firebase date
 * @param {*} someDate
 * @returns {string}
 */
export const formatDateTime = (someDate) => {
  if (typeof someDate.toDate === "function") {
    someDate = someDate.toDate();
  }
  const date = new Date(someDate);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
