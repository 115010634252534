import { removeSpaces } from "./utils/functions.js";

export const isPhoneValid = (phone = "") => {
  const formattedPhone = removeSpaces(phone);
  if (!formattedPhone.startsWith("+420") && !formattedPhone.startsWith("+421"))
    return false;
  if (formattedPhone.length !== 13) return false;
  return !isNaN(Number(formattedPhone));
};

export const validBoxFields = [
  "id",
  "addressStreet",
  "addressStreetNumber",
  "addressCity",
  "addressCountry",
  "addressPSC",
];
export const isValidBox = (box = {}) =>
  !validBoxFields.find((field) => !box?.[field]);

export const validAddressFields = [
  "addressStreet",
  "addressStreetNumber",
  "addressCity",
  "addressPSC",
  "phoneNumber",
];
export const isValidAddress = (address = {}) =>
  !validAddressFields.find((field) => !address?.[field]) &&
  isPhoneValid(address.phoneNumber);

export const isValidBoxId = (id) => `${id}`.length === 6;
