import { useCallback, useEffect } from "../_snowpack/pkg/react.js";
import { functions } from "../firebase.js";
import { httpsCallable } from "../_snowpack/pkg/firebase/functions.js";
import { DELIVERY_TRANSITION_NAME } from "../_snowpack/pkg/@topmonks/postcube.js";
import useDoc from "./useDoc.js";
import { deleteDoc } from "../_snowpack/pkg/firebase/firestore.js";

export default function useDelivery(
  deliveryId,
  { onError = console.error } = {},
  callback = (data) => data
) {
  const [data, loading, error, docRef] = useDoc(`/deliveries/${deliveryId}`, {
    skip: !deliveryId,
    onError,
  });

  useEffect(() => {
    callback(data || undefined);
  }, [data]); // eslint-disable-line

  const updateDelivery = useCallback(
    (updates, transition = DELIVERY_TRANSITION_NAME.UPDATE_FORM) =>
      httpsCallable(
        functions(),
        "delivery-upsert"
      )({
        deliveryId,
        deliveryData: updates,
        transition,
      }).catch(onError),
    [onError, deliveryId]
  );

  // TODO: enable address token
  // const updateDelivery = useCallback(async(updates, transition = DELIVERY.TRANSITION_NAME.UPDATE_FORM) => {
  //   try {
  //     let addressTokenId;

  //     if (updates?.recipient && !updates?.recipient?.placeId) {
  //       const createAddressResponse = await httpsCallable(functions(), "address-create")({
  //         scope: "delivery",
  //         street: updates.recipient.addressStreet,
  //         streetNumber: updates.recipient.addressStreetNumber,
  //         city: updates.recipient.addressCity,
  //         psc: updates.recipient.addressPSC,
  //         country: updates.recipient.addressCountry,
  //       });

  //       if (!createAddressResponse?.data?.isValid) {
  //         onError(new Error("Vámi zadaná adresa nebyla nalezena"));
  //         return;
  //       }

  //       addressTokenId = createAddressResponse.data.addressTokenId;
  //     }

  //     await httpsCallable(functions(), "delivery-upsert")({
  //       deliveryId,
  //       deliveryData: { ...updates, addressTokenId },
  //       transition,
  //     });
  //   } catch(err) {
  //     onError(err);
  //   }
  // }, [onError, deliveryId]);

  const deleteDelivery = useCallback(() => deleteDoc(docRef), [docRef]);

  return {
    delivery: data,
    deliveryLoading: loading,
    deliveryError: error,
    updateDelivery,
    deleteDelivery,
  };
}
