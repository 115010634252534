import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import React, {useCallback, useState} from "../../_snowpack/pkg/react.js";
import {Trans, useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useNavigation} from "../../_snowpack/pkg/react-navi.js";
import cs from "../../_snowpack/pkg/classnames.js";
import Divider from "../../components/Divider.js";
import Warning from "../../components/Warning.js";
import Button from "../../components/Button.js";
import {dateFromTimestamp} from "../../library/format.js";
import {DELIVERY_STATE_NAMES} from "../../_snowpack/pkg/@topmonks/postcube.js";
import useAlert from "../../hooks/useAlert.js";
import Loader from "../../components/Loader.js";
import NotFound from "./NotFound.js";
import useBleDevicesNearby from "../../hooks/useBleDevicesNearby.js";
import useMessengerDelivery from "../../hooks/useMessengerDelivery.js";
import Logo from "../../images/logo-inline.js";
import IconBox from "../../images/icon-box.js";
import IconShop from "../../images/icon-shop.js";
import IconHelp from "../../images/icon-help.js";
import IconLocation from "../../images/icon-location.js";
import IconCheck from "../../images/icon-check-circle.js";
import IconPackage from "../../images/icon-package.js";
import useMobileModal from "../../hooks/useMobileModal.js";
import "./Screen.css";
const version = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CIRCLE_SHA1 || "dev";
const TutorialModalContent = ({openBox, closeModal}) => {
  const {t} = useTranslation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "TutorialModalContent"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "TutorialModalContent-gif"
  }, /* @__PURE__ */ React.createElement("img", {
    width: "100%",
    src: "/images/deliveryBTTutorial2.gif"
  })), /* @__PURE__ */ React.createElement("p", null, t("box:pairTutorialText")), /* @__PURE__ */ React.createElement(Button, {
    className: "TutorialModalConent-button",
    onClick: () => {
      closeModal();
      openBox();
    }
  }, t("understood")));
};
export default function ScreenMessengerDelivery({deliveryId}) {
  const navigation = useNavigation();
  const alert = useAlert();
  const {t} = useTranslation();
  const handleScreenError = useCallback((err) => {
    alert.error(err);
  }, [alert]);
  const {
    delivery = {},
    deliveryLoading,
    updateDeliveryState,
    unlockBox
  } = useMessengerDelivery(deliveryId, {onError: handleScreenError});
  const {openModal, closeModal, modal} = useMobileModal();
  const isSender = [
    DELIVERY_STATE_NAMES.PACKAGE_WAITING_FOR_DELIVERY,
    DELIVERY_STATE_NAMES.DELIVERY_ACCEPTED_BY_TRANSPORT_COMPANY,
    DELIVERY_STATE_NAMES.SENDER_BOX_OPENED_BY_TRANSPORT_COMPANY
  ].includes(delivery.state);
  const [unlocking, setUnlocking] = useState(false);
  const {findNearby: findBleDeviceNearby} = useBleDevicesNearby({
    onError: handleScreenError
  });
  const handleUnlockBox = useCallback(async () => {
    if (unlocking || !delivery)
      return;
    try {
      const bleDevice = await findBleDeviceNearby(isSender ? delivery.sender : delivery.recipient);
      setUnlocking(true);
      await unlockBox(bleDevice, isSender ? delivery.senderCmd : delivery.recipientCmd);
      alert.success(t("box:opened"));
      const deliveryState = isSender ? DELIVERY_STATE_NAMES.SENDER_BOX_OPENED_BY_TRANSPORT_COMPANY : DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_TRANSPORT_COMPANY;
      await updateDeliveryState(deliveryState);
    } catch (err) {
      handleScreenError(err);
    } finally {
      setUnlocking(false);
    }
  }, [
    alert,
    delivery,
    findBleDeviceNearby,
    handleScreenError,
    isSender,
    unlockBox,
    unlocking,
    updateDeliveryState,
    t
  ]);
  const showExplainConfirmModal = useCallback(() => openModal(/* @__PURE__ */ React.createElement(TutorialModalContent, {
    openBox: handleUnlockBox,
    closeModal
  })), [openModal, closeModal, handleUnlockBox]);
  const senderIsEshop = delivery.sender?.type === "eshop";
  if (!delivery.id)
    return /* @__PURE__ */ React.createElement(NotFound, {
      loading: deliveryLoading
    });
  if ([
    DELIVERY_STATE_NAMES.SENDER_BOX_OPENED_BY_TRANSPORT_COMPANY,
    DELIVERY_STATE_NAMES.RECIPIENT_BOX_OPENED_BY_TRANSPORT_COMPANY,
    DELIVERY_STATE_NAMES.PACKAGE_IN_RECIPIENT_BOX
  ].includes(delivery.state)) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "screen-messenger"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "screen-messenger__header"
    }, /* @__PURE__ */ React.createElement(Logo, {
      height: "32px",
      fill: "var(--color-white)"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "screen-messenger__view"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "screen-messenger__hero"
    }, /* @__PURE__ */ React.createElement(IconCheck, {
      width: "96px",
      fill: "var(--color-primary)",
      className: "mt32 mb16"
    }), isSender ? /* @__PURE__ */ React.createElement("h1", null, t("delivery:collect")) : /* @__PURE__ */ React.createElement("h1", null, t("delivery:insert")), /* @__PURE__ */ React.createElement("div", {
      className: "screen-messenger__package-number mb32"
    }, /* @__PURE__ */ React.createElement("b", null, t("delivery:packageCode")), /* @__PURE__ */ React.createElement(IconPackage, {
      width: "24px",
      className: "ml8 mr8"
    }), /* @__PURE__ */ React.createElement("p", null, delivery.code)), /* @__PURE__ */ React.createElement(Divider, null), isSender ? /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, t("delivery:collectDeliveryFromBox")), /* @__PURE__ */ React.createElement("li", null, t("delivery:closeCarefully")), /* @__PURE__ */ React.createElement("li", null, t("other:returnToMessengerAppAndContinue"))) : /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, t("delivery:insertToBox")), /* @__PURE__ */ React.createElement("li", null, t("delivery:closeCarefully")), /* @__PURE__ */ React.createElement("li", null, t("other:returnToMessengerAppAndContinue"))), /* @__PURE__ */ React.createElement("p", {
      className: "screen-messenger__finish-text"
    }))));
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__header"
  }, /* @__PURE__ */ React.createElement(Logo, {
    height: "32px",
    fill: "var(--color-white)"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__view"
  }, !(isSender && senderIsEshop) && /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__hero"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "delivery-action__box-animation mb32"
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("delivery-action__box-animation-package", isSender && "delivery-action__box-animation-package--pickup")
  }), /* @__PURE__ */ React.createElement(IconBox, {
    width: "64px",
    height: "64px",
    fill: "var(--color-primary)"
  })), /* @__PURE__ */ React.createElement(Divider, null), isSender ? /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, t("BT:turnItOnAndClickBtn")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "BT:chooseBoxInListAndPair",
    t
  }, "Vyberte v seznamu", /* @__PURE__ */ React.createElement("b", null, "PostCube ", {boxId: delivery?.sender?.id}), " a klikněte na Párovat")), /* @__PURE__ */ React.createElement("li", null, t("BT:boxWillNotifyAndOpen")), /* @__PURE__ */ React.createElement("li", null, t("delivery:collectAndCloseBox"))) : /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, t("BT:turnItOnAndClickBtn")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Trans, {
    i18nKey: "BT:chooseBoxInListAndPair",
    t
  }, "Vyberte v seznamu", /* @__PURE__ */ React.createElement("b", null, "PostCube ", {boxId: delivery?.recipient?.id}), " a klikněte na Párovat")), /* @__PURE__ */ React.createElement("li", null, t("BT:boxWillNotifyAndOpen")), /* @__PURE__ */ React.createElement("li", null, t("delivery:insertAndCloseBox")))), isSender && senderIsEshop && /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__hero"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__hero"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "delivery-action__box-animation mb32"
  }, /* @__PURE__ */ React.createElement("div", {
    className: cs("delivery-action__box-animation-package", isSender && "delivery-action__box-animation-package--pickup")
  }), /* @__PURE__ */ React.createElement(IconShop, {
    width: "64px",
    height: "64px",
    fill: "var(--color-primary)"
  })), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, t("delivery:collectFromSeller")), /* @__PURE__ */ React.createElement("li", null, t("other:returnToMessengerAppAndContinue"))))), !(isSender && senderIsEshop) && /* @__PURE__ */ React.createElement("div", null, !navigator.bluetooth && /* @__PURE__ */ React.createElement(Warning, {
    className: "mb16",
    headline: t("BT:turnItOnInDevice"),
    text: t("BT:turnItOnBeforeOpeningBox")
  }), /* @__PURE__ */ React.createElement(Button, {
    className: "mb16 screen-messenger__button--unlock",
    loader: true,
    onClick: showExplainConfirmModal,
    disabled: unlocking,
    type: "submit",
    large: true
  }, unlocking ? /* @__PURE__ */ React.createElement(Loader, {
    ghost: true,
    text: t("box:opening")
  }) : t("box:open")), modal), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__location mt16"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(IconLocation, {
    width: "24px",
    className: "mr8"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__location-text"
  }, /* @__PURE__ */ React.createElement("b", null, isSender && senderIsEshop ? t("box:locationDescShort") : t("box:locationDesc2"), ":"), /* @__PURE__ */ React.createElement("p", null, (isSender ? delivery.sender.description : delivery.recipient.description) || /* @__PURE__ */ React.createElement("em", null, t("notSpecified"), ".")))), /* @__PURE__ */ React.createElement(Button, {
    className: "mt16 mb8 screen-messenger__help",
    onClick: () => {
      navigation.navigate(`/messenger/help`);
    },
    disabled: unlocking,
    type: "submit",
    secondary: true
  }, /* @__PURE__ */ React.createElement(IconHelp, {
    width: "24px",
    className: "mr8"
  }), t("lost")), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__detail mt32"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info"
  }, senderIsEshop ? /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:number")), /* @__PURE__ */ React.createElement("p", null, delivery.sender?.orderNumber)) : /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, isSender ? t("box:idForCollection") : t("box:idForDelivery")), /* @__PURE__ */ React.createElement("p", null, isSender ? delivery.sender.id : delivery.recipient.id)), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:packageCode2")), /* @__PURE__ */ React.createElement("p", null, delivery.code)))), /* @__PURE__ */ React.createElement("div", {
    className: "screen-messenger__info-item"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "color-grey"
  }, t("delivery:creation")), /* @__PURE__ */ React.createElement("p", null, dateFromTimestamp(delivery.createdAt))), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey mt16 mb8"
  }, t("delivery:sender"), ":"), /* @__PURE__ */ React.createElement("h3", null, delivery.sender?.name, delivery.sender?.id && /* @__PURE__ */ React.createElement("small", null, /* @__PURE__ */ React.createElement(IconBox, {
    width: "14px",
    fill: "var(--color-primary)",
    className: "ml8 mr4"
  }), delivery.sender?.id)), /* @__PURE__ */ React.createElement("p", null, delivery.sender?.address), /* @__PURE__ */ React.createElement("p", {
    className: "color-grey mt16 mb8"
  }, t("delivery:recipient"), ":"), /* @__PURE__ */ React.createElement("h3", null, delivery.recipient?.name, delivery.recipient?.id && /* @__PURE__ */ React.createElement("small", null, /* @__PURE__ */ React.createElement(IconBox, {
    width: "14px",
    fill: "var(--color-primary)",
    className: "ml8 mr4"
  }), delivery.recipient?.id)), /* @__PURE__ */ React.createElement("p", null, delivery.recipient?.address), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("a", {
    className: "screen-messenger__phone-button",
    href: `tel:+420739304189`
  }, t("other:callSupport")), /* @__PURE__ */ React.createElement("p", {
    className: "screen-messenger__version"
  }, version))));
}
